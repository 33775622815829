import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { BookOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { getColumnSearchProps, getColumnSearchPropsHard } from '../../components/tableHelpers/getColumnSearchProps';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';

import { SchoolController } from '../../api/SchoolController';
import { ClassroomController } from '../../api/ClassroomController';
import ErrorDTO from '../../models/common/ErrorDTO';
import { SchoolYear } from '../../constants/SchoolYears';
import './TeacherClassrooms.scss';

interface TeacherClassroomsTableDataObject {
    id: string;
    name: string;
    gradeLevel: number;
    gradeLevelString: string;
    schoolId: string;
    teacherFirstName: string;
    teacherLastName: string;
    numberOfStudents: number;
    schoolYear: SchoolYear;
}

interface TeacherClassroomsProps extends PageProps<{}> {
    // From Redux
    User: UserState;
}

interface TeacherClassroomsState {
    isLoading: boolean;
    activeTableData: TeacherClassroomsTableDataObject[];
    inactiveTableData: TeacherClassroomsTableDataObject[];
}

class TeacherClassrooms extends React.Component<TeacherClassroomsProps, TeacherClassroomsState> {
    private notificationKey = "TeacherClassroomsPage";
    private tableColumns: ColumnProps<TeacherClassroomsTableDataObject>[];
    constructor(props: TeacherClassroomsProps) {
        super(props);
        bindAllOfThis(this, TeacherClassrooms.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<TeacherClassroomsTableDataObject>("name", "Name"),
        }, {
            title: 'Teacher Last Name',
            dataIndex: 'teacherLastName',
            key: 'teacherLastName',
            sorter: (a, b) => a.teacherLastName.localeCompare(b.teacherLastName),
            ...getColumnSearchProps<TeacherClassroomsTableDataObject>("teacherLastName", "Teacher Last Name"),
        }, {
            title: 'Teacher First Name',
            dataIndex: 'teacherFirstName',
            key: 'teacherFirstName',
            sorter: (a, b) => a.teacherFirstName.localeCompare(b.teacherFirstName),
            ...getColumnSearchProps<TeacherClassroomsTableDataObject>("teacherFirstName", "Teacher First Name"),
            // }, {
            //     title: 'Grade',
            //     dataIndex: 'gradeLevelString',
            //     key: 'gradeLevelString',
            //     width: 120,
            //     sorter: (a, b) => a.gradeLevel - b.gradeLevel,
            //     ...getColumnSearchProps<TeacherClassroomsTableDataObject>("gradeLevelString", "Grade"),
        }, {
            title: 'School Year',
            dataIndex: 'fullYear',
            key: 'schoolYear',
            // defaultSortOrder: 'descend',
            width: 150,
            sorter: (a, b) => a.schoolYear.startYear - b.schoolYear.startYear,
            // defaultFilteredValue: [yearToFilter],
            ...getColumnSearchPropsHard('fullYear', 'School Year'),
        }, {
            title: 'Manage My Classroom',
            key: 'myClassroom',
            width: 165,
            align: "center",
            render: (text, record) => {
                return (
                    <Tooltip title="Manage My Classroom" placement="top">
                        <Link to={Routes.TEACHER_MY_CLASSROOM(record.id).toRoute}>
                            <Button shape="circle" icon={<BookOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: "center",
            render: (text, record) => {
                return (
                    <Tooltip title="Edit Classroom" placement="top">
                        <Link to={Routes.TEACHER_ADD_EDIT_CLASSROOM(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: "center",
            render: (text, record) => {
                return record.numberOfStudents === 0 ?
                    <Tooltip title="Delete Class" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleDeleteClassroom(record.id)} />
                    </Tooltip> : null;
            }
        }];

        this.state = {
            isLoading: true,
            activeTableData: [],
            inactiveTableData: [],
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadTableData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadTableData() {
        if (this.props.User.schoolId == null) {
            return Promise.resolve();
        }

        var today = new Date();
        var endOfSchoolYear = new Date(today.getFullYear(), 6, 1); // Can edit to determine End of school years
        var yearToFilter = (endOfSchoolYear < today) // Today is after end of school year
            ? today.getFullYear()
            : (today.getFullYear() - 1);

        return SchoolController.GetClassroomsBySchool(this.props.User.schoolId).then(result => {
            let tableData = result.data.map<TeacherClassroomsTableDataObject>(record => ({
                ...record,
                gradeLevelString: record.gradeLevel > 0 ? record.gradeLevel.toString() : record.gradeLevel === 0 ? "K" : "PK",
                fullYear: record.schoolYear.startYear + " - " + record.schoolYear.endYear,
            }));
            let activeTableData = tableData.filter(x => x.schoolYear.startYear === yearToFilter);
            let inactiveTableData = tableData.filter(x => x.schoolYear.startYear !== yearToFilter);
            this.setState({
                activeTableData,
                inactiveTableData
            });
        });
    }

    handleDeleteClassroom(classroomId: string) {
        notification.info({
            key: this.notificationKey,
            message: "Deleting Class",
            description: "Please wait while we delete the classs...",
            duration: 0
        });

        return ClassroomController.DeleteDeleteClassroom(classroomId).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Deleted Class",
                description: "Successfully deleted the class!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.loadAll();
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Delete Class",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });
        });
    }

    render() {
        if (this.props.User.schoolId == null) {
            return <Redirect to={Routes.TEACHER_SELECT_MY_SCHOOL().toRoute} />;
        }

        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return (
            <div className='teacher-classrooms'>
                <h1>Classes</h1>
                <p>Here, you will be able to manage your classes - which students go in each class and which classes will use each course. You will be able to organize student responses to Existing Knowledge, Exit Ticket, and Discussion Questions, and parent/guardian responses to School Home Partnerships. </p>

                <p>Add classes by clicking the “Add Classes” button at the top of the screen and organize the students into classes! (Add students in the “Students” tab on the blue toolbar on the left portion of your screen.)</p>
                <p>By clicking the “Manage my Classroom” button for the desired course, you are able to add students to your class and open and close access to lessons for your students.</p>
                <p>To see classrooms from previous or future school years, click the magnifying glass icon in the School Year column and press reset.</p>
                <Link key="table-link" to={Routes.TEACHER_ADD_EDIT_CLASSROOM("").toRoute}>
                    <Button icon={<EditOutlined />}>Add Class</Button>
                </Link>
                <br /> <br />
                <h2>Active Classrooms</h2>
                <Table
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.activeTableData}
                    rowKey={record => record.id}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                />

                <h2>Inactive Classrooms</h2>
                <Table
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.inactiveTableData}
                    rowKey={record => record.id}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherClassrooms);
