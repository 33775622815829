import * as React from 'react';
import { RouteComponentProps, withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    AmazonOutlined,
    AppleOutlined,
    FacebookOutlined,
    GoogleOutlined,
    InstagramOutlined,
    TwitterOutlined,
    YoutubeOutlined,
} from '@ant-design/icons';

import { Layout, Row, Col } from 'antd';
import bindAllOfThis from '../../src/utils/BindThisHelper';
import { WorkflowState } from '../redux/WorkflowReducer';
import ReduxStoreModel from '../redux/ReduxModel';
import { UserState } from '../redux/UserReducer';

import './GeneralFooter.scss';

const { Footer } = Layout;

interface GeneralFooterProps extends RouteComponentProps<{}> {
    // From Redux
    Workflow: WorkflowState;
    User: UserState;
}

interface GeneralFooterState {
}

class GeneralFooter extends React.Component<GeneralFooterProps, GeneralFooterState> {
    constructor(props: GeneralFooterProps) {
        super(props);
        bindAllOfThis(this, GeneralFooter.prototype);

        this.state = {
        };
    }

    render() {
        return (
            <Footer className="general-footer">
                <Row className="icon-row"  justify="center">
                    <Col>
                        <Link key="facebookLink" to="//facebook.com/RockInPrevention" target="_blank">
                            <FacebookOutlined />
                        </Link>
                    </Col>
                    <Col>
                        <Link key="instagramLink" to="//instagram.com/rockinprevention" target="_blank">
                            <InstagramOutlined />
                        </Link>
                    </Col>
                    <Col>
                        <Link key="twitterLink" to="//twitter.com/rockinprevent" target="_blank">
                            <TwitterOutlined />
                        </Link>
                    </Col>
                    <Col>
                        <Link key="youtubeLink" to="//youtube.com/channel/UC2yrT5SFgVN2SVhY3r7TCjw" target="_blank">
                            <YoutubeOutlined />
                        </Link>
                    </Col>
                </Row>
                <Row className="icon-row" justify="center">
                    <Col>
                        <Link key="appleLink" to="//music.apple.com/us/artist/pat-mcmanus/1449059275" target="_blank">
                            <AppleOutlined />
                        </Link>
                    </Col>
                    <Col>
                        <Link key="spotifyLink" to="//open.spotify.com/artist/4C6rCXXxcQmlpaZOQodUfy" target="_blank">
                            <img className="img-size" src={require('../assets/spotify.png')} alt="Spotify logo" />
                        </Link>
                    </Col>
                    <Col>
                        <Link key="googleLink" to="//play.google.com/music/preview/Alwkvh2t3wm2yabpn2mi47w5mgu?u=0" target="_blank">
                            <GoogleOutlined />
                        </Link>
                    </Col>
                    <Col>
                        <Link key="amazonLink" to="//music.amazon.com/artists/B000V5X3XU?tab=CATALOG" target="_blank">
                            <AmazonOutlined />
                        </Link>
                    </Col>
                </Row>
                <Row className="copyright-row" justify="center">
                    <Col>
                      <p className="text-style">Rock Digi is a product of Rock In Prevention - &copy; {new Date().getFullYear()} Rock In Prevention, Inc All Rights Reserved</p>
                    </Col>
                </Row>
            </Footer>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps)(withRouter(GeneralFooter));
