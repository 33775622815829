import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ReloadOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, notification, Modal, Checkbox } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { StudentDTO } from '../../models/student/studentDTO';
import { AccountController } from '../../api/AccountController';
import { SchoolController } from '../../api/SchoolController';
import { StudentController } from '../../api/StudentController';
import ErrorDTO from '../../models/common/ErrorDTO';
import Upload, { UploadProps } from 'antd/lib/upload';
import { getColumnSearchProps, getColumnSearchPropsHard } from '../../components/tableHelpers/getColumnSearchProps';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';

interface TeacherStudentsProps extends PageProps<{}> {
    // From Redux
    User: UserState;
}

interface TeacherStudentsState {
    isLoading: boolean;
    isSubmitting: boolean;
    tableData: StudentDTO[];
    uploadErrors: string[];
    isModalVisible: boolean;
    isButtonEnabled: boolean;
    isChecked: boolean;
}

class TeacherStudents extends React.Component<TeacherStudentsProps, TeacherStudentsState> {
    private notificationKey = "TeacherStudents";
    tableColumns: ColumnProps<StudentDTO>[];
    constructor(props: TeacherStudentsProps) {
        super(props);
        bindAllOfThis(this, TeacherStudents.prototype);

        this.tableColumns = [{
            title: 'Username',
            dataIndex: 'lastName',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            ...getColumnSearchProps<StudentDTO>("lastName", "Username")
        }, {
            title: 'Nickname',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            ...getColumnSearchProps<StudentDTO>("firstName", "Nickname"),
        }, {
            title: 'Email',
            dataIndex: 'email',
            width: 200,
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchProps<StudentDTO>("email", "Email"),
        }, {
            title: 'School Year',
            dataIndex: 'fullYear',
            width: 120,
            sorter: (a, b) => a.schoolYear.startYear - b.schoolYear.startYear,
            ...getColumnSearchPropsHard('fullYear', 'School Year'),
        }, {
            title: 'Passcode',
            dataIndex: 'passcode',
            width: 120,
            sorter: (a, b) => a.passcode.localeCompare(b.passcode),
            ...getColumnSearchProps<StudentDTO>('passcode', 'Passcode'),
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return !record.tiedToClassroom ?
                    <Tooltip title="Delete Student" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleDeleteStudent(record.id)} />
                    </Tooltip> : null;
            }
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit" placement="top">
                        <Link to={Routes.TEACHER_ADD_EDIT_STUDENT(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Change Passcode',
            key: 'changePasscode',
            width: 100,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Change Passcode" placement="top">
                        <Button shape="circle" icon={<ReloadOutlined />} onClick={() => this.handleChangePasscode(record.id)} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: true,
            isSubmitting: false,
            tableData: null,
            uploadErrors: [],
            isModalVisible: false,
            isButtonEnabled: false,
            isChecked: false,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    ensureCheckboxChecked(rule: any, value: any, callback: any) {
        // const form = this.props.form;
        if (!value) {
            callback('The student must agree to the privacy policy.');
        }
        callback();
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadTableData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadTableData() {
        if (this.props.User.schoolId == null) {
            return Promise.resolve();
        }

        return SchoolController.GetStudentsBySchool(this.props.User.schoolId).then(results => {
            let students = results.data.map<StudentDTO>(student => ({
                ...student,
                fullYear: student.schoolYear.startYear + " - " + student.schoolYear.endYear,
            }));
            // Initial sort
            students.sort((a, b) => a.id.localeCompare(b.id));
            this.setState({
                tableData: students,
                uploadErrors: []
            });
        });
    }

    handleDeleteStudent(studentId: string) {
        notification.info({
            key: this.notificationKey,
            message: "Deleting Student",
            description: "Please wait while we delete the student...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        return StudentController.DeleteStudent(studentId).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Deleted Student",
                description: "Successfully deleted the student!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.loadTableData().then(() => this.setState({ isSubmitting: false }));
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Delete Student",
                description: ["There was an issue deleting the Student.", ...messages].map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleChangePasscode(studentId: string) {
        notification.info({
            key: this.notificationKey,
            message: "Changing Passcode",
            description: "Please wait while we change the passcode...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        return StudentController.PutChangePasscode(studentId).then(result => {
            notification.success({
                key: this.notificationKey,
                message: `Passcode Changed to: ${result.data.passcode}`,
                description: "Successfully changed the passcode!",
                duration: 5,
            });

            this.loadTableData().then(() => this.setState({ isSubmitting: false }));
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Change Passcode",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleGetMassStudentUploadTemplate() {
        return SchoolController.GetMassStudentUploadTemplate().then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'MassStudentUpload.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    }

    handleMassStudentUpload(options: any) {
        notification.info({
            key: this.notificationKey,
            message: "Uploading Students",
            description: "Please wait while we upload the students...",
            duration: 0
        });

        let formData = new FormData();
        formData.append('file', options.file);

        this.setState({ isLoading: true });
        return SchoolController.PostMassStudentUpload(this.props.User.schoolId, formData).then(result => {
            if (result.data.length > 0) {
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Upload Specific Students",
                    description: result.data,
                    duration: 5
                });

                this.setState({
                    isLoading: false,
                    uploadErrors: result.data
                });
            } else {
                notification.success({
                    key: this.notificationKey,
                    message: "Successfully Uploaded All Students",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.handleModal_OnUploadSuccess();
                this.loadAll();
            }
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Upload Students",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isLoading: false });
            this.handleOnUnchecked();
        });
    }

    handleModal_OnUploadSuccess() {
        this.setState({
            isModalVisible: false
        });
    }

    handleModal_OnCancel() {
        this.setState({
            isModalVisible: false
        });
    }

    handleModal_OnOpen() {
        this.setState({
            isButtonEnabled: false,
            isChecked: false,
            isModalVisible: true
        });
    }

    handleOnChecked() {
        this.setState({
            isButtonEnabled: true,
            isChecked: true
        });
    }

    handleOnUnchecked() {
        this.setState({
            isButtonEnabled: false,
            isChecked: false
        });
    }

    renderFileUpload() {
        let uploadProps: UploadProps = {
            name: "awesome-file",
            action: "",
            listType: "picture",
            customRequest: this.handleMassStudentUpload,
            type: "select"
        };
        return (
            <Upload key="upload" {...uploadProps}>
                <Button id="btnSelectFile" disabled={this.state.isButtonEnabled ? false : true} type="primary" loading={this.state.isLoading}>
                    <UploadOutlined /> <span>Select a file</span>
                </Button>
            </Upload>
        );
    }

    renderUploadErrors() {
        return this.state.uploadErrors.map((error, index) => <div key={index} style={{ color: "#FF0000" }}>{error}</div>);
    }

    render() {
        if (this.props.User.schoolId == null) {
            return <Redirect to={Routes.TEACHER_SELECT_MY_SCHOOL().toRoute} />;
        }

        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return (
            <div>
                <h1>Students</h1>
                <p>Here is where you are able to add students that will be using Rock Digi’s program!</p>

                <p>You are able to add students individually by clicking “Add Student” or you can download the “Mass Upload Template,” fill it out, and then upload it using the “Mass Upload” button. </p>

                <p>Each student will be identified by a username that you may assign or allow students to come up with. Usernames are for you as the educator to identify students with.</p>
                <Link key="table-link" to={Routes.TEACHER_ADD_EDIT_STUDENT("").toRoute}>
                    <Button icon={<EditOutlined />}>Add Student</Button>
                </Link>
                <Button key="download" icon={<DownloadOutlined />} onClick={() => this.handleGetMassStudentUploadTemplate()}>Mass Upload Template</Button>
                <Button icon={<UploadOutlined />} onClick={() => this.handleModal_OnOpen()}>Mass Upload</Button>
                <Modal
                    title="Mass Students Upload"
                    visible={this.state.isModalVisible}
                    closable={true}
                    onCancel={this.handleModal_OnCancel}
                    destroyOnClose
                    footer={[<Button key="back" onClick={() => this.handleModal_OnCancel()}>Cancel</Button>]}
                >
                    <p>Upload the file by clicking the upload button below</p>
                    <Checkbox
                        style={{ paddingBottom: "24px" }}
                        onChange={() => this.state.isButtonEnabled ? this.handleOnUnchecked() : this.handleOnChecked()}
                    >
                        The students and/or parents/guardians of the students to be added agree to the
                        <Link target='#' to={Routes.PRIVACY_POLICY().toRoute}> Privacy Policy</Link>
                    </Checkbox>
                    {this.renderFileUpload()}
                </Modal>
                {this.renderUploadErrors()}
                <br />
                <Table
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                    rowKey={record => record.id}
                    loading={this.state.isSubmitting}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherStudents);
