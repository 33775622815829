import * as React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Upload, notification, Progress } from 'antd';
import { AxiosResponse } from 'axios';
import bindAllOfThis from '../../utils/BindThisHelper';
import { UploadProps, RcFile } from "antd/lib/upload/interface";
import ErrorDTO from '../../models/common/ErrorDTO';
import { CourseController } from '../../api/CourseController';
import { CourseDTO } from '../../models/course/courseDTO';

export interface AdminCourseAppendixModalDataObject {
    courseId: string;
    url: string;
}

interface AdminCourseAppendixModalProps {
    isVisible: boolean;
    formValues: AdminCourseAppendixModalDataObject;
    onComplete: () => void;
    onClose: () => void;
}

interface AdminCourseAppendixModalState {
    isEdit: boolean;
    isValidating: boolean;
    isSubmitting: boolean;
    uploadPercent: number;
}

class AdminCourseAppendixModal extends React.Component<AdminCourseAppendixModalProps, AdminCourseAppendixModalState> {
    private notificationKey = "AdminCourseAppendixModal";
    constructor(props: AdminCourseAppendixModalProps) {
        super(props);
        bindAllOfThis(this, AdminCourseAppendixModal.prototype);

        this.state = {
            isEdit: false,
            isValidating: false,
            isSubmitting: false,
            uploadPercent: 0
        };
    }

    componentWillReceiveProps(nextProps: AdminCourseAppendixModalProps) {
        let isEdit = nextProps.formValues != null && nextProps.formValues.url != null;
        if (isEdit !== this.state.isEdit) {
            this.setState({ isEdit: isEdit });
        }
    }

    handleOnSubmit(options: any) {
        let formData = new FormData();
        formData.append('file', options.file);

        notification.info({
            key: this.notificationKey,
            message: "Course Appendix Save",
            description: "Saving the Course Appendix...",
            duration: 0
        });
        this.setState({ isSubmitting: true });

        let request = CourseController.PostSaveStandardsAppendix(this.props.formValues.courseId, formData, this.handleUploadProgress);
        return request
            .then(this.handleOnSubmit_OnSuccess)
            .catch(this.handleOnSubmit_OnFailure);
    }

    handleOnSubmit_OnSuccess(result: AxiosResponse<CourseDTO>) {
        notification.success({
            key: this.notificationKey,
            message: "Course Appendix Saved",
            description: "The Course Appendix has been saved successfully!",
            duration: 5,
            onClick: () => notification.close(this.notificationKey)
        });
        this.props.onComplete();
    }

    handleOnSubmit_OnFailure(error: any) {
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        notification.error({
            key: this.notificationKey,
            message: "Course Appendix Saved",
            description: ["There was an issue saving the Course Appendix.", ...messages].map(x => <div>{x}</div>),
            duration: 0
        });
        this.setState({ isSubmitting: false });
    }

    handleUploadProgress(progressEvent: { loaded: number, total: number }) {
        // There is ALOT on the progressEvent but we only need a few to calculate progress
        let percent = Math.round(progressEvent.loaded / progressEvent.total * 10000) / 100;
        this.setState({ uploadPercent: percent });
    }

    handleFileValidation(file: RcFile, FileList: RcFile[]): boolean | PromiseLike<void> {
        this.setState({ isValidating: true });
        notification.info({
            key: this.notificationKey + "val",
            message: "Course Appendix Validation",
            description: "Validating the file to be uploaded",
            duration: 0
        });

        return this.handleFileValidationHelper(file, FileList)
            .then(result => {
                this.setState({ isValidating: false });
                if (result.length < 1) {
                    return notification.success({
                        key: this.notificationKey + "val",
                        message: "Course Appendix Validation",
                        description: "The file has passed validation and is now being upload",
                        duration: 5,
                        onClick: () => notification.close(this.notificationKey)
                    });
                }
                notification.error({
                    key: this.notificationKey + "val",
                    message: "Course Appendix Validation",
                    description: `The file failed to upload: ${result}`,
                    duration: 0
                });

                // Antd Upload requires that we reject the promise -_-
                throw new Error();
            });
    }

    /** Handles generating the validation promise */
    handleFileValidationHelper(file: RcFile, FileList: RcFile[]): PromiseLike<string> {
        // Check if file is within the size constraints. Size is in bytes
        return new Promise((resolve, reject) => {
            // This function is executed automatically when the promise is constructed
            // Artificial delay to show that "something" is happening
            setTimeout(() => {
                let sizeInMB = Math.round(file.size / 1024 / 1024 * 100) / 100; // The "100" rounds with 2 decimal place precision
                if (sizeInMB > 100) {
                    resolve(`File is too large (${sizeInMB}MB)`);
                } else {
                    resolve("");
                }
            }, 1000);
        });
    }

    renderFileUpload() {
        let uploadProps: UploadProps = {
            name: "file",
            action: "",
            listType: "text",
            type: "select",
            customRequest: this.handleOnSubmit,
            beforeUpload: this.handleFileValidation,
            defaultFileList: this.state.isEdit
                ? [{
                    uid: "1",
                    name: this.props.formValues.url.split("/").slice(-1)[0],
                    status: "success",
                    url: this.props.formValues.url,
                } as UploadProps as any]
                : [],
            onRemove: () => false, // Basically removes ability to remove the file
        };
        return (
            <Upload {...uploadProps}>
                <Button type="primary" loading={this.state.isSubmitting}>
                    <UploadOutlined /> <span>Select a file</span>
                </Button>
            </Upload>
        );
    }

    render() {
        return <Modal
            key="lesson-content-modal"
            title={this.props.isVisible ? `${this.state.isEdit ? "Edit" : "Add"} Course Appendix` : ""}
            visible={this.props.isVisible}
            destroyOnClose
            closable={!this.state.isSubmitting && !this.state.isValidating}
            onCancel={!this.state.isSubmitting && !this.state.isValidating ? this.props.onClose : null}
            footer={[<Button key="back" onClick={this.props.onClose} loading={this.state.isSubmitting || this.state.isValidating}>Close</Button>]}
        >
            <p>Upload the file by clicking the upload button below</p>
            <p>The maximum supported file size is <strong>100MB</strong></p>
            {this.renderFileUpload()}
            {this.state.isSubmitting &&
                <div>
                    <h3>Upload Progress</h3>
                    <Progress key="upload-progress" percent={this.state.uploadPercent} />
                </div>
            }
        </Modal>;
    }
}

export default AdminCourseAppendixModal;
