import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { EditOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Select, Empty } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { DistrictDTO } from '../../models/district/districtDTO';
import { DistrictController } from '../../api/DistrictController';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { States } from '../../constants/States';

interface AdminDistrictsPageProps extends PageProps<{}> {
}

interface AdminDistrictsPageState {
    isLoading: boolean;
    tableData: DistrictDTO[];
}

class AdminDistrictsPage extends React.Component<AdminDistrictsPageProps, AdminDistrictsPageState> {
    tableColumns: ColumnProps<DistrictDTO>[];
    private states = Object.keys(States);
    constructor(props: AdminDistrictsPageProps) {
        super(props);
        bindAllOfThis(this, AdminDistrictsPage.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<DistrictDTO>("name", "Name"),
        }, {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 80,
            sorter: (a, b) => {
                let newAcode = a.code || '';
                let newBcode = b.code || '';
                return newAcode.localeCompare(newBcode);
                },
                ...getColumnSearchProps<DistrictDTO>("code", "Code"),

        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit District" placement="top">
                        <Link to={Routes.ADMIN_DISTRICT_ADD_EDIT(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: false,
            tableData: null
        };
    }

    componentDidMount() {
        //this.loadTableData();
    }

    loadTableData(stateAbbrev: string) {
        this.setState({ isLoading: true });
        DistrictController.GetDistrictsByState(stateAbbrev).then(result => {
            this.setState({
                tableData: result.data,
                isLoading: false,
            });
        });
    }

    onStateChange(state: string) {
        if (state != null && state.length > 0) {
            this.loadTableData(States[state]);
        }
    }

    render() {
        return (
            <div>
                <h1>Districts</h1>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a state"
                    onChange={this.onStateChange}>
                    {
                        this.states.map(state => <Select.Option key={state} value={state}>{state}</Select.Option>)
                    }
                </Select>
                <Table
                    title={(data) => {
                        return [
                            <Link key="table-link" to={Routes.ADMIN_DISTRICT_ADD_EDIT("").toRoute}>
                                <Button icon={<EditOutlined />}>Add District</Button>
                            </Link>,
                        ];
                    }}
                    rowKey={record => record.id}
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Districts! Please select a state with districts." />  }}
                    loading={{ indicator: <Spin className="spinner-centered very-large-spinner" />, spinning: this.state.isLoading }}
                />
            </div>
        );
    }
}

export default AdminDistrictsPage;
