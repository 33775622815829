import * as React from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Modal, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { CouponController } from '../../api/CouponController';
import { CouponDTO } from '../../models/coupon/couponDTO';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import ErrorDTO from '../../models/common/ErrorDTO';

interface AdminCouponsPageProps extends PageProps<{}> {
}

interface AdminCouponsPageState {
    isLoading: boolean;
    tableData: CouponDTO[];
}

class AdminCouponsPage extends React.Component<AdminCouponsPageProps, AdminCouponsPageState> {
    private notificationKey = "AdminCouponsPage";
    private tableColumns: ColumnProps<CouponDTO>[];
    constructor(props: AdminCouponsPageProps) {
        super(props);
        bindAllOfThis(this, AdminCouponsPage.prototype);

        this.tableColumns = [{
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
            ...getColumnSearchProps<CouponDTO>("code", "Code"),
        }, {
            title: 'Percent Off',
            key: 'percentAmount',
            width: 120,
            sorter: (a, b) => a.percentAmount - b.percentAmount,
            ...getColumnSearchProps<CouponDTO>("percentAmount", "Percent Amount"),
            render: (text: any, record: CouponDTO) => {
                if (record.percentAmount != null) {
                    return <span>{record.percentAmount}%</span>;
                }
                return null;
            }
        }, {
            title: 'Start Date',
            key: 'startDate',
            width: 120,
            sorter: (a, b) => a.startDate.localeCompare(b.startDate),
            ...getColumnSearchProps<CouponDTO>("startDate", "Start"),
            render: (text: any, record: CouponDTO) => (
                <div>
                    {moment(record.startDate).format("DD-MMM-YYYY")}
                </div>)
        }, {
            title: 'End Date',
            key: 'endDate',
            width: 120,
            sorter: (a, b) => a.endDate.localeCompare(b.endDate),
            ...getColumnSearchProps<CouponDTO>("endDate", "End"),
            render: (text: any, record: CouponDTO) => (
                <div>
                    {moment(record.endDate).format("DD-MMM-YYYY")}
                </div>)
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit Coupon" placement="top">
                        <Link to={Routes.ADMIN_COUPON_ADD_EDIT(record.couponId).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Delete Content" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleOnRemove(record)} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: true,
            tableData: null
        };
    }

    componentDidMount() {
        this.loadTableData();
    }

    loadTableData() {
        CouponController.GetCoupons().then(result => {
            this.setState({
                tableData: result.data,
                isLoading: false,
            });
        });
    }

    handleOnRemove(record: CouponDTO) {
        // Ask if they are sure
        Modal.confirm({
            title: "Are you sure?",
            content: `Are you sure you want to delete this coupon? "${record.code}"`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                CouponController.DeleteCoupon(record.couponId).then(result => {
                    notification.success({
                        key: this.notificationKey,
                        message: "Coupon Removal",
                        description: `The Coupon "${record.code}" has been removed`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                    this.loadTableData();
                }).catch(error => {
                    let messages = error != null && error.response != null && error.response.data.messages != null
                        ? (error.response.data as ErrorDTO).messages
                        : ["Critical Error"];
                    notification.error({
                        key: this.notificationKey,
                        message: "Coupon Removal",
                        description: ["There was an issue removing the Coupon.", ...messages].map(x => <div>{x}</div>),
                        duration: 10
                    });
                });
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return (
            <div>
                <h1>Coupons</h1>
                <Link key="table-link" to={Routes.ADMIN_COUPON_ADD_EDIT("").toRoute}>
                    <Button icon={<EditOutlined />}>Add Coupon</Button>
                </Link>
                <br /><br />
                <Table
                    rowKey={record => record.couponId}
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                />
            </div>
        );
    }
}

export default AdminCouponsPage;
