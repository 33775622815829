import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Row, Col, Select, Radio, InputNumber, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import { LicenseDTO } from '../../models/license/licenseDTO';
import { CouponDTO } from '../../models/coupon/couponDTO';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { ConstantsDTO } from '../../models/constant/constantsDTO';
import Routes from '../../core/Routes';
import { ParamUtil } from '../../utils/ParamUtil';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { CouponController } from '../../api/CouponController';
import { CourseDTO } from '../../models/course/courseDTO';
import moment, { Moment } from 'moment';
import { PricingTiers, PricingTier } from '../../constants/PricingTiers';
import { EditShoppingCartItemDTO } from '../../models/shoppingCart/editShoppingCartItemDTO';
import InputWithExtra from '../../components/InputWithExtra';

export interface TeacherAddEditMySchoolFormDataObject {
    id: string;
    startDate: string;
    endDate: string;
    courseId: string;
    course: KeyLabel;
    licenseId: string;
    // pricingTier: KeyLabel;
    additionalLicenseCount: number;
    licenseCount: number;
    schoolYearId: string;
    tripleYearLicense: boolean;
    couponId?: string;

    // Returned to caller
    paymentAmountInCents: number;
    pricingTier: PricingTier;
}

interface TeacherAddEditMySchoolFormProps extends FormComponentProps, ReactStripeElements.InjectedStripeProps, RouteComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: TeacherAddEditMySchoolFormDataObject) => void;
    initialValues?: Partial<TeacherAddEditMySchoolFormDataObject>;
    shoppingCartItemValues?: Partial<EditShoppingCartItemDTO>;
    courses: SimpleCourseDTO[];
    constants: ConstantsDTO;
    isEdit: boolean;
}

interface TeacherAddEditMySchoolFormState {
    isEdit: boolean;
    isCartEdit: boolean;

    filteredCourses: SimpleCourseDTO[];
    courseFilter: string;
    selectedCourseId: string;

    licenseCount: number;
    additionalLicenseCount: number;
    tripleYearLicense: boolean;

    totalLicenseCostInCents: number;
    baseLicenseCostInCents: number;
    licenseCountCostInCents: number;
    discountInCents: number;
    paymentAmountInCents: number;

    stripeErrorMessage: string;

    couponCode: string;
    coupon: CouponDTO;
    couponErrorMessage: string;

    licenseTier: PricingTier;
    newSeats: number;
}

// todo: page styling
class TeacherAddEditMySchoolForm extends React.Component<TeacherAddEditMySchoolFormProps, TeacherAddEditMySchoolFormState> {
    // Just need to initialize sub objects
    constructor(props: TeacherAddEditMySchoolFormProps) {
        super(props);
        bindAllOfThis(this, TeacherAddEditMySchoolForm.prototype);

        let isEdit = props.initialValues != null;
        let isCartEdit = props.shoppingCartItemValues != null;
        let licenseTier = this.getLicenseTier(isEdit, isCartEdit);
        let cartEditLicenseCount = isCartEdit ? props.shoppingCartItemValues.licenseCount : 0;

        this.state = {
            isEdit: isEdit,
            isCartEdit: isCartEdit,
            courseFilter: "",
            filteredCourses: this.props.courses,
            selectedCourseId: null,
            licenseCount: 0,
            additionalLicenseCount: licenseTier != null && licenseTier.id === "4" && isCartEdit ? cartEditLicenseCount - licenseTier.numberOfSeats : 1,
            tripleYearLicense: isCartEdit ? props.shoppingCartItemValues.isTripleYearLicense : false,
            baseLicenseCostInCents: null,
            licenseCountCostInCents: 0,
            discountInCents: null,
            paymentAmountInCents: null,
            stripeErrorMessage: null,
            couponCode: null,
            coupon: null,
            couponErrorMessage: null,
            licenseTier: licenseTier,
            totalLicenseCostInCents: null,
            newSeats: 0,
        };
    }

    componentDidMount() {
        // Calculate payment then assign cent value to state
        this.calculateTotalCost();
    }

    async handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        let id: string = null;
        if(this.props.shoppingCartItemValues != null) {
            id = this.props.shoppingCartItemValues.id;
        }
        else if(this.props.initialValues != null) {
            id = this.props.initialValues.id;
        }

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: TeacherAddEditMySchoolFormDataObject): void => {
            values.id = id;
            values.paymentAmountInCents = this.state.paymentAmountInCents;
            values.couponId = this.state.coupon != null ? this.state.coupon.couponId : null;
            values.startDate = moment(values.startDate).format('YYYY-MM-DD');
            values.pricingTier = this.state.licenseTier;
            values.additionalLicenseCount = this.state.additionalLicenseCount;
            values.licenseCount = this.state.licenseCount;
            values.licenseId = this.props.initialValues != null ? this.props.initialValues.licenseId : null;
            values.course = this.props.initialValues != null ? this.props.initialValues.course : values.course;
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    convertCentsToDollars(cents: number): string {
        return (cents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
    }

    getLicenseTier(isEdit: boolean, isCartEdit: boolean) {
        if (isEdit || isCartEdit) {
            let values = this.props.initialValues;

            if(isCartEdit) {
                values = this.props.shoppingCartItemValues;
            }

            if (values.licenseCount <= 5) {
                return PricingTiers.One;
            }
            else if (values.licenseCount <= 10) {
                return PricingTiers.Two;
            }
            else if (values.licenseCount <= 15) {
                return PricingTiers.Three;
            }
            else if (values.licenseCount > 15) {
                return PricingTiers.Four;
            }
        }
        return null;
    }

    calculateTotalCost(): void {
        let perStudentLicenseCostInCents = this.props.constants.studentLicenseCostInCents;

        if (this.state.isEdit) {
            // Updating Existing License
            let startYear: number = (new Date(this.props.initialValues.startDate)).getFullYear();
            let endYear: number = (new Date(this.props.initialValues.endDate)).getFullYear();
            let licenseDuration: number = endYear - startYear;
            let newSeats: number = 0;
            let isThreeYear = licenseDuration > 1;

            let paymentAmountInCents: number = 0;
            if (this.props.initialValues.licenseCount <= 5) {
                if (this.state.licenseTier === PricingTiers.Two || this.state.licenseTier === PricingTiers.Three) {
                    newSeats = this.state.licenseTier.numberOfSeats - this.props.initialValues.licenseCount;
                    paymentAmountInCents = isThreeYear ?
                        (this.state.licenseTier.threeYearCost - PricingTiers.One.threeYearCost) * 3 :
                        (this.state.licenseTier.oneYearCost - PricingTiers.One.oneYearCost);
                }
                if (this.state.licenseTier === PricingTiers.Four) {
                    newSeats = 15 + this.state.additionalLicenseCount - this.props.initialValues.licenseCount;
                    paymentAmountInCents = isThreeYear ?
                        ((this.state.licenseTier.threeYearCost - PricingTiers.One.threeYearCost) + (this.state.additionalLicenseCount * perStudentLicenseCostInCents)) * 3 :
                        (this.state.licenseTier.oneYearCost - PricingTiers.One.oneYearCost) + (this.state.additionalLicenseCount * perStudentLicenseCostInCents);
                }
            }
            else if (this.props.initialValues.licenseCount <= 10) {
                if (this.state.licenseTier === PricingTiers.Three) {
                    newSeats = 15 - this.props.initialValues.licenseCount;
                    paymentAmountInCents = isThreeYear ?
                        (this.state.licenseTier.threeYearCost - PricingTiers.Two.threeYearCost) * 3 :
                        (this.state.licenseTier.oneYearCost - PricingTiers.Two.oneYearCost);
                }
                if (this.state.licenseTier === PricingTiers.Four) {
                    newSeats = 15 + this.state.additionalLicenseCount - this.props.initialValues.licenseCount;
                    paymentAmountInCents = isThreeYear ?
                        ((this.state.licenseTier.threeYearCost - PricingTiers.Two.threeYearCost) + (this.state.additionalLicenseCount * perStudentLicenseCostInCents)) * 3 :
                        (this.state.licenseTier.oneYearCost - PricingTiers.Two.oneYearCost) + (this.state.additionalLicenseCount * perStudentLicenseCostInCents);
                }
            }
            else if (this.props.initialValues.licenseCount <= 15) {
                if (this.state.licenseTier === PricingTiers.Four) {
                    newSeats = 15 + this.state.additionalLicenseCount - this.props.initialValues.licenseCount;
                    paymentAmountInCents = isThreeYear ?
                        this.state.additionalLicenseCount * perStudentLicenseCostInCents * 3 :
                        this.state.additionalLicenseCount * perStudentLicenseCostInCents;
                }
            }
            else if (this.props.initialValues.licenseCount > 15) {
                if (this.state.licenseTier === PricingTiers.Four) {
                    newSeats = this.state.additionalLicenseCount;

                    paymentAmountInCents = isThreeYear ?
                        newSeats * perStudentLicenseCostInCents * 3 :
                        newSeats * perStudentLicenseCostInCents;
                }
                if (newSeats < 0) {
                    newSeats = 0;
                }
            }

            let newLicenseCount = 0;
            if (this.state.licenseTier != null) {
                newLicenseCount = this.state.licenseTier === PricingTiers.Four ?
                    15 + this.state.additionalLicenseCount :
                    this.state.licenseTier.numberOfSeats;
            }

            this.setState({
                tripleYearLicense: isThreeYear,
                paymentAmountInCents: paymentAmountInCents,
                newSeats: newSeats,
                licenseCount: newLicenseCount,
            });
        } else {
            // New License
            let baseLicenseCostInCents: number = this.calculateBaseCost();
            let totalCostInCents: number = this.state.tripleYearLicense ?
                baseLicenseCostInCents * 3 :
                baseLicenseCostInCents;

            let licenseCountCostInCents = 0;

            if (this.state.licenseTier === PricingTiers.Four) {
                licenseCountCostInCents = this.state.tripleYearLicense ?
                    this.state.additionalLicenseCount * perStudentLicenseCostInCents * 3 :
                    this.state.additionalLicenseCount * perStudentLicenseCostInCents;
            }
            let discountInCents = null;
            if (this.state.coupon != null && this.state.couponErrorMessage == null) {
                let dollarAmount = this.state.coupon.dollarAmount;
                let percentAmount = this.state.coupon.percentAmount;

                discountInCents = dollarAmount != null ?
                    dollarAmount * 100 : totalCostInCents * (percentAmount / 100.0);
            }


            let paymentAmountInCents: number = totalCostInCents + licenseCountCostInCents - discountInCents;


            this.setState({
                totalLicenseCostInCents: totalCostInCents,
                baseLicenseCostInCents: baseLicenseCostInCents,
                licenseCountCostInCents: licenseCountCostInCents,
                discountInCents: discountInCents,
                paymentAmountInCents: paymentAmountInCents
            });
        }
    }

    calculateBaseCost(): number {
        if (this.state.licenseTier != null) {
            let baseCost = this.state.tripleYearLicense ?
                this.state.licenseTier.threeYearCost :
                this.state.licenseTier.oneYearCost;
            return baseCost;
        }
        return null;
    }

    renderCostCalculation(): JSX.Element {
        let perStudentLicenseCost = this.convertCentsToDollars(this.props.constants.studentLicenseCostInCents);

        if (this.state.isEdit) {
            // Updating Existing License
            let additionalLicenseCountCost = this.convertCentsToDollars(this.state.paymentAmountInCents);
            let perYearPrice = (this.state.paymentAmountInCents - (this.props.constants.studentLicenseCostInCents * this.state.additionalLicenseCount) * 3) / 3;
            if (this.state.licenseTier === PricingTiers.Four) {
                if (this.props.initialValues.licenseCount <= 15) {
                    additionalLicenseCountCost = this.state.tripleYearLicense ?
                        this.convertCentsToDollars((this.props.constants.studentLicenseCostInCents * this.state.additionalLicenseCount * 3)) :
                        this.convertCentsToDollars((this.props.constants.studentLicenseCostInCents * this.state.additionalLicenseCount));
                    return this.state.tripleYearLicense ?
                        <h5>
                            + {additionalLicenseCountCost} ({this.state.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost x 3 Years)
                        + Tier Upgrade Cost: {this.convertCentsToDollars(perYearPrice)} x 3 Years
                </h5>
                        :
                        <h5>
                            + {additionalLicenseCountCost} ({this.state.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost)
                        + Tier Upgrade Cost: {this.convertCentsToDollars(this.state.paymentAmountInCents - (this.props.constants.studentLicenseCostInCents * this.state.additionalLicenseCount))}
                        </h5>;
                }
                return this.state.tripleYearLicense ?
                    <h5>
                        + {additionalLicenseCountCost} ({this.state.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost x 3 Years)
                </h5>
                    :
                    <h5>
                        + {additionalLicenseCountCost} ({this.state.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost)
                </h5>;
            }
            else {
                return !this.state.tripleYearLicense ? <h5>
                    Tier Upgrade Cost: {this.convertCentsToDollars(this.state.paymentAmountInCents)}
                </h5> :
                    <h5>
                        Tier Upgrade Cost: {this.convertCentsToDollars(perYearPrice)} x 3 Years
            </h5>;
            }

        } else {

            // New License
            let totalLicenseCostInCents = this.convertCentsToDollars(this.state.totalLicenseCostInCents);
            let baseLicenseCost = this.convertCentsToDollars(this.state.baseLicenseCostInCents);
            let studentCost = this.convertCentsToDollars(this.state.licenseCountCostInCents);
            let discountCost = this.state.discountInCents != null ? this.convertCentsToDollars(this.state.discountInCents) : null;
            return this.state.tripleYearLicense ?
                <h5>
                    +  {totalLicenseCostInCents} ({baseLicenseCost} Base License Cost x 3 Years) <br />
                    {this.state.licenseTier === PricingTiers.Four && <div> + {studentCost} ({this.state.additionalLicenseCount} Students x {perStudentLicenseCost} Per Student Cost x 3 Years) <br /> </div>}
                    {discountCost != null ? <div>- <strong>{discountCost} Discount</strong></div> : null}
                </h5>
                :
                <h5>
                    + {baseLicenseCost} Base License Cost <br />
                    {this.state.licenseTier === PricingTiers.Four && <div> + {studentCost} ({this.state.additionalLicenseCount} Students x {perStudentLicenseCost} Per Student Cost) <br /> </div>}
                    {discountCost != null ? <div>- <strong>{discountCost} Discount</strong></div> : null}
                </h5>;
        }
    }

    handleAdditionalLicenseCountOnChange(value: React.ReactText) {
        if (value != null) {
            let additionalSeats = parseInt(value.toString(), 10);
            if (isNaN(additionalSeats)) {
                additionalSeats = 0;
            }
            this.setState({ additionalLicenseCount: additionalSeats }, this.calculateTotalCost);
        }
    }

    handleLicenseCountOnChange(value: number) {
        this.setState({ licenseCount: value }, this.calculateTotalCost);
    }

    handleTripleYearLicenseOnChange(event: any) {
        this.setState({ tripleYearLicense: event.target.value }, this.calculateTotalCost);
    }

    handleCourseChange(value: any) { //Need to figure out what exactly it is getting passed... For ALL select onChanges
        if (value == null) {
            this.setState({ selectedCourseId: null }, this.calculateTotalCost);
        } else {
            this.setState({ selectedCourseId: value.key });
        }
    }

    handleCourseFilterChange(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let courses = this.props.courses.filter(x => x.name.toLowerCase().includes(filter));
        this.setState({
            courseFilter: filter,
            filteredCourses: courses,
        });
    }

    handleTierSelect(value: any) {
        if (value == null) {
            this.setState({ licenseTier: PricingTiers.None });
        }
        else {
            let pricingTier = PricingTiers.FindById(value.key);
            this.setState({ licenseTier: pricingTier }, this.calculateTotalCost);
        }

    }

    renderCourseSelect() {
        if (this.props.courses == null || this.props.courses.length < 1) {
            return <Select placeholder={"Loading courses..."} loading={true}></Select>;
        }
        return <Select
            placeholder={"Please select a course"}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
            showSearch
            onChange={this.handleCourseChange}
            onSearch={this.handleCourseFilterChange}
            disabled={true}
        >
            {this.state.filteredCourses
                .sort((first, second) => first.name.localeCompare(second.name))
                .map(course => <Select.Option key={`course${course.id}`} value={`${course.id}`}>{course.name}</Select.Option>)}
        </Select>;
    }

    disabledDate(current: Moment) {
        // Can not select days before today
        return current && current < moment().startOf('day');
    }

    determineTier(values: any) {
        let label: KeyLabel;
        if (values == null) {
            return { key: "0", label: "0 students" };
        }
        if (values.licenseCount <= 5) {
            return { key: "1", label: "1-5 students" };
        }
        else if (values.licenseCount <= 10) {
            return { key: "2", label: "6-10 students" };
        }
        else if (values.licenseCount <= 15) {
            return { key: "3", label: "11-15 students" };
        }
        else if (values.licenseCount > 15) {
            return { key: "4", label: "16+ students" };
        }
    }

    renderTotalSeats() {
        if (this.state.licenseTier === PricingTiers.Four) {
            if (this.props.initialValues.licenseCount <= 15) {
                return <h3>
                    Previous total seats: {this.props.initialValues.licenseCount}
                    <br />
                New total seats: {15 + this.state.additionalLicenseCount}
                </h3>;
            }
            return <h3>
                Previous total seats: {this.props.initialValues.licenseCount}
                <br />
                New total seats: {this.props.initialValues.licenseCount + this.state.additionalLicenseCount}
            </h3>;
        }

    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                lg: { span: 6 },
                xl: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                lg: { span: 10 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};
        // TODO: JB - Please come back here and move this up to the calling page
        // BEGIN FUN HACK
        let params = new ParamUtil(this.props.location.search);
        let course = this.props.courses.find(x => x.id === params.get.CourseId);
        let initialCourse: KeyLabel = course != null ? { key: course.id, label: course.name } : null;
        let startTier: KeyLabel = this.determineTier(initialValues);
        // END FUN HACK

        let startDate = null;
        let additionalSeats = 1;
        if(this.state.isCartEdit) {
            startDate = moment(this.props.shoppingCartItemValues.startDate);
            course = this.props.courses.find(x => x.id === this.props.shoppingCartItemValues.courseId);
            initialCourse = course != null ? { key: course.id, label: course.name } : null;
            startTier = this.determineTier(this.props.shoppingCartItemValues);
            additionalSeats = startTier.key === "4" ? this.props.shoppingCartItemValues.licenseCount - 15 : 0;
        }

        // Dev note: Kind of ran out of time so this is what this will be forever
        let totalLicenseCount = this.props.isEdit
            ? this.props.initialValues.licenseCount + this.state.additionalLicenseCount
            : this.state.additionalLicenseCount + PricingTiers.Four.numberOfSeats;
        let totalStudentElement = <label style={{ lineHeight: "40px", marginLeft: 12, fontSize: 16 }}>Total Students: {totalLicenseCount}</label>;

        return <Form id="licenseForm" onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            {this.state.isEdit ?
                <h3>License Duration: {(new Date(this.props.initialValues.startDate)).getFullYear()} - {(new Date(this.props.initialValues.endDate)).getFullYear()}</h3>
                : null}
            <br />
            {!this.state.isEdit ?
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="License Duration"
                    name={NameOf<TeacherAddEditMySchoolFormDataObject>("tripleYearLicense")}
                    initialValue={this.state.tripleYearLicense}
                    rules={[{ required: false }]}
                >
                    <Radio.Group buttonStyle="solid" onChange={this.handleTripleYearLicenseOnChange}>
                        <Radio.Button value={false}>1 Year</Radio.Button>
                        <Radio.Button value={true}>3 Years</Radio.Button>
                    </Radio.Group>
                </FieldDecorator> : null}
            {!this.state.isEdit ?
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Start Date"
                    name={NameOf<TeacherAddEditMySchoolFormDataObject>("startDate")}
                    initialValue={startDate}
                >
                    <DatePicker disabledDate={this.disabledDate} />
                </FieldDecorator>
                : null
            }

            {!this.state.isEdit ?
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Course"
                    name={NameOf<TeacherAddEditMySchoolFormDataObject>("course")}
                    rules={[{ required: true, message: 'Course is required.' }]}
                    initialValue={initialCourse}
                >
                    {this.renderCourseSelect()}
                </FieldDecorator> : null}

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Number of Students"
                name="tierSelect"
                initialValue={startTier}
                rules={[{ required: true, message: "Number of Students is required." }]}
            >
                <Select
                    placeholder={"Please select number of students"}
                    labelInValue
                    allowClear
                    onChange={this.handleTierSelect} >
                    {/* //Need to figure out what exactly it is getting passed... For ALL select onChanges */}
                    <Select.Option key={'1'} value={'1'} > 1-5 Students </Select.Option>
                    <Select.Option key={'2'} value={'2'} > 6-10 Students </Select.Option>
                    <Select.Option key={'3'} value={'3'} > 11-15 Students </Select.Option>
                    <Select.Option key={'4'} value={'4'} > 16+ Students </Select.Option>
                </Select>
            </FieldDecorator>
            {this.state.licenseTier === PricingTiers.Four &&
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Additional Students"
                    name={NameOf<TeacherAddEditMySchoolFormDataObject>("additionalLicenseCount")}
                    initialValue={this.state.additionalLicenseCount}
                    rules={[{ required: true, message: "Additional Number of Students is required." }]}
                >
                    <InputWithExtra onChange={this.handleAdditionalLicenseCountOnChange} rightSide={totalStudentElement} />
                </FieldDecorator>}
            {this.state.isEdit ?
                this.renderTotalSeats() : null}
            <br />
            {this.renderCostCalculation()}
            <h3>Total: {this.convertCentsToDollars(this.state.paymentAmountInCents)}</h3>
            <br />


            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                    Add To Cart
                </Button>

                <Link to={!this.state.isCartEdit ? Routes.GET.TEACHER_MY_SCHOOL : Routes.GET.SHOPPING_CART} style={{ marginLeft: '6px' }}>
                    <Button className="login-form-button" size="large">
                        Go Back
                    </Button>
                </Link>

            </Form.Item>
        </Form>;
    }
}

export default withRouter(Form.create<TeacherAddEditMySchoolFormProps>()(TeacherAddEditMySchoolForm));
