import axios from 'axios';
import { CouponDTO } from '../models/coupon/couponDTO';
import { CreateCouponDTO } from '../models/coupon/createCouponDTO';
import { UpdateCouponDTO } from '../models/coupon/updateCouponDTO';

export class CouponController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetCouponsRoute = () => `coupons`;
    public static GetCoupons() {
        return axios.get<CouponDTO[]>(this.GetCouponsRoute());
    }

    public static PostCreateCouponRoute = () => `coupons`;
    public static PostCreateCoupon(request: CreateCouponDTO) {
        return axios.post<CouponDTO>(this.PostCreateCouponRoute(), request);
    }

    public static PutUpdateCouponRoute = () => `coupons`;
    public static PutUpdateCoupon(request: UpdateCouponDTO) {
        return axios.put<CouponDTO>(this.PutUpdateCouponRoute(), request);
    }

    public static DeleteCouponRoute = (couponId: string) => `coupons/${couponId}`;
    public static DeleteCoupon(couponId: string) {
        return axios.delete(this.DeleteCouponRoute(couponId));
    }

    public static GetCouponRoute = (couponId: string) => `coupons/${couponId}`;
    public static GetCoupon(couponId: string) {
        return axios.get<CouponDTO>(this.GetCouponRoute(couponId));
    }

    public static GetCouponByCodeRoute = (code: string) => `coupons/${code}/getByCode`;
    public static GetCouponByCode(code: string) {
        return axios.get<CouponDTO>(this.GetCouponByCodeRoute(code));
    }
}
