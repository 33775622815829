export default class ObjectUtil {
    /***
     * General note about 'object'. Typescript has decided that you should avoid it, so Record<string, unknown> is used
     * From MS: "Don't use `object` as a type. The `object` type is currently hard to use"
     ***/

    public static ObjectToArray(theObject: Record<string, unknown>): { key: string; value: Record<string, unknown> }[] {
        return Object.keys(theObject).map((x) => {
            return {
                key: x,
                value: theObject[x] as Record<string, unknown>
            };
        });
    }
    //eslint-disable-next-line
    public static DiagnosticInfo(obj: any): void {
        let output = '';

        const objType = typeof obj;
        const keys = Object.keys(obj);
        const isArray = Array.isArray(obj);
        const isNumber = Number.isInteger(obj);
        const hasSubMembers = keys.some((x) => x != null && typeof x === 'object' && !Array.isArray(x));

        // eslint-disable-next-line
        output =
            `## Object Diagnostic Info ##
            Type: ${objType}
            IsNumber: ${isNumber}
            IsArray: ${isArray}
            HasSubMembers: ${hasSubMembers}
            Keys: \n${keys.map((x) => ` ${x}: ${typeof obj[x]}`).join('\n')}`;

        console.warn(output);
    }

    /**
     * Applies a second object onto the first, traversing the sub-objects to do the same.
     *
     * @param {T} left The source object. These params are overwritten if they exist in both.
     * @param {T} right The params from this object overwrite the params in the first object.
     * @param {boolean} [arrayRule=false] Indicates if arrays should be appended to one another. Default is to merge each array item based on index.
     */
    //eslint-disable-next-line
    public static DeepMergeObjects<T extends { [key: string]: any }>(left: T, right: T, arrayRule: 'default' | 'take-left' | 'take-right' | 'concat' | 'merge-index' = 'default'): T {
        const obj = { ...(left || {}), ...(right || {}) } as T;
        const keys = Object.keys(obj);
        keys.forEach((key) => {
            const innerObj = obj[key];
            const isArray = Array.isArray(innerObj);
            const isObject = typeof innerObj === 'object';

            if (innerObj != null && isObject && !isArray) {
                const first: any = left == null ? { [key]: null } : left;
                const second: any = right == null ? { [key]: null } : right;

                // Recurse and put result back into merged object
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                (obj as any)[key] = this.DeepMergeObjects(first[key], second[key], arrayRule);
            } else if (isArray) {
                let arrayResult = [];
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                const safeLeft: Array<any> = (left || { [key]: [] })[key] || [];
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                const safeRight: Array<any> = (right || { [key]: [] })[key] || [];

                switch (arrayRule) {
                    case 'take-left':
                        arrayResult = safeLeft;
                        break;
                    case 'take-right':
                        arrayResult = safeRight;
                        break;
                    case 'concat':
                        arrayResult = [...safeLeft, ...safeRight];
                        break;
                    case 'default':
                    case 'merge-index':
                        const minLength = Math.min(safeLeft.length, safeRight.length);
                        const maxLength = Math.max(safeLeft.length, safeRight.length);

                        for (let index = 0; index < minLength; index++) {
                            // Account for nulls, then account for non objects
                            if (safeLeft == null || safeRight == null) {
                                // Nothing to calculate, just return whatever is not null, or null, starting with the right
                                arrayResult.push(safeRight[index] || safeLeft[index]);
                            }
                            if (typeof safeLeft[index] !== 'object' || typeof safeRight[index] !== 'object') {
                                // One or both are primitives of some kind. Pick left if it's an object, else default to right
                                // Also, errors should only show when we are choosing this on purpose
                                arrayRule === 'merge-index' &&
                                    console.warn('Type error when merging array objects. Defaulting to rightmost object', safeLeft[index], safeRight[index]);
                                if (typeof safeLeft[index] === 'object') {
                                    arrayResult.push(safeLeft[index]);
                                } else {
                                    arrayResult.push(safeRight[index]);
                                }
                                continue;
                            }
                            arrayResult.push(this.DeepMergeObjects(safeLeft[index], safeRight[index], arrayRule));
                        }

                        if (minLength !== maxLength) {
                            // Add extras that have no match
                            const arr = safeLeft.length > minLength ? safeLeft : safeRight;
                            for (let index = minLength; index < maxLength; index++) {
                                arrayResult.push(arr[index]);
                            }
                        }

                        break;
                    default:
                        arrayResult = [];
                }

                // Put result back into merged object
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                (obj as any)[key] = arrayResult;
            }
        });

        return obj;
    }
}
