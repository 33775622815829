import axios from 'axios';
import { DistrictDTO } from '../models/district/districtDTO';
import { CreateDistrictDTO } from '../models/district/createDistrictDTO';
import { UpdateDistrictDTO } from '../models/district/updateDistrictDTO';
import { SchoolDTO } from '../models/school/schoolDTO';

export class DistrictController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetDistrictsRoute = () => `districts`;
    public static GetDistricts() {
        return axios.get<DistrictDTO[]>(this.GetDistrictsRoute());
    }

    public static PostCreateDistrictRoute = () => `districts`;
    public static PostCreateDistrict(request: CreateDistrictDTO) {
        return axios.post<DistrictDTO>(this.PostCreateDistrictRoute(), request);
    }

    public static PutUpdateDistrictRoute = () => `districts`;
    public static PutUpdateDistrict(request: UpdateDistrictDTO) {
        return axios.put<DistrictDTO>(this.PutUpdateDistrictRoute(), request);
    }

    public static DeleteDistrictRoute = (districtId: string) => `districts/${districtId}`;
    public static DeleteDistrict(districtId: string) {
        return axios.delete(this.DeleteDistrictRoute(districtId));
    }

    public static GetDistrictRoute = (districtId: string) => `districts/${districtId}`;
    public static GetDistrict(districtId: string) {
        return axios.get<DistrictDTO>(this.GetDistrictRoute(districtId));
    }

    public static GetSchoolsByDistrictRoute = (districtId: string) => `districts/${districtId}/schools`;
    public static GetSchoolsByDistrict(districtId: string) {
        return axios.get<SchoolDTO[]>(this.GetSchoolsByDistrictRoute(districtId));
    }

    public static GetDistrictsByStateRoute = (str: string) => `districts/byState/${str}`;
    public static GetDistrictsByState(str: string) {
        return axios.get<DistrictDTO[]>(this.GetDistrictsByStateRoute(str));
    }
}

