// This is a class to help handle external urls, such as aws or azure
import DebugUtil from "../utils/DebugUtil";

type AwsBucketTypes = "courses" | "static" | "web";

export default class ConfigureURLs {
    /**
     * Returns a url for an AWS resource at a given path. Takes into account the current environment from `DebugUtil`
     */
    public static GetAWSUrl(bucket: AwsBucketTypes, path: string) {
        let newPath = path != null && path[0] === "/" ? path : "/" + path;
        switch (bucket) {
            case "courses":
            case "web":
                return this.GetUrlInternal(bucket, true, false) + newPath;
            case "static":
                return this.GetUrlInternal(bucket, false, true) + newPath;
            default:
                break;
        }
    }

    protected static GetUrlInternal(bucketName: string, hasDevl: boolean, hasEnvFolder: boolean) {
        /** Dev Note
         * It could, theoretically be inferred that is hasDevl is false then hasEnvFolder is true.
         * Then, the one time that isn't true, we will be back to separate variables.
         */
        // Indented for readability
        const envFolder = hasEnvFolder
            ? DebugUtil.GetCurrentEnvironment() === "production"
                ? "/prod"
                : "/devl"
            : "";
        // Let it be known that I absolutely hate the devl name. No developer in their right mind would not just use dev
        return `https://rock-digi-${bucketName}${hasDevl && DebugUtil.GetCurrentEnvironment() !== 'production' ? "-devl" : ""}.s3.amazonaws.com${envFolder}`;
    }
}

