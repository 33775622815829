import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Card } from 'antd';
import { ColProps } from 'antd/lib/col';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import "./AboutUs.scss";
import ReactPlayer from 'react-player';
import ConfigureURLs from '../../core/ConfigureUrls';

interface AboutUsPageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
}

class AboutUsPage extends React.Component<AboutUsPageProps> {
    constructor(props: AboutUsPageProps) {
        super(props);
        bindAllOfThis(this, AboutUsPage.prototype);
    }

    renderTheBestParts() {
        let makeItLarge: React.CSSProperties = {
            height: "54px",
            fontSize: "22px",
            width: "100%"
        };

        let colParams: ColProps = {
            xs: 24,
            lg: 12,
            style: { paddingBottom: "16px" }
        };

        if (this.props.User.state === "finished") {
            return <Row gutter={16}>
                <Col {...colParams}>
                    <Link key="demoLink" to={Routes.DEMO().toRoute}><Button type="primary" size="large" style={makeItLarge}>Free Trial Lessons</Button></Link>
                </Col>
                <Col {...colParams}>
                    <Link key="homeLink" to={Routes.HOME().toRoute}><Button type="primary" size="large" style={makeItLarge}>Go to My Dashboard</Button></Link>
                </Col>
            </Row>;
        }

        return <Row gutter={16}>
            <Col {...colParams}>
                <Link key="registerLink" to={Routes.REGISTER().toRoute}><Button type="primary" size="large" style={makeItLarge}>Register to Try a Sample Lesson</Button></Link>
            </Col>
            <Col {...colParams}>
                <Link key="loginLink" to={Routes.LOGIN().toRoute}><Button type="primary" size="large" style={makeItLarge}>Login</Button></Link>
            </Col>
        </Row>;
    }

    render() {
        const layoutSpans = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };
        const textSpan = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            lg: { span: 8 },
            xl: { span: 10 },
            xxl: { span: 12 },
        };
        const span8 = {
            xs: { span: 24 },
            lg: { span: 8 },
        };
        const span12 = {
            xs: { span: 24 },
            md: { span: 12 },
        };
        const span16 = {
            xs: { span: 24 },
            lg: { span: 16 },
        };
        const reviewsTextSpan = {
            xs: { span: 24, order: 1 },
            md: { span: 12, order: 1 },
        };
        const reviewsImgSpan = {
            xs: { span: 24, order: 2 },
            md: { span: 12, order: 1 },
        };

        return <div className="about-us-page">
            <Row  justify="center">
                <Col {...layoutSpans}>
                    {this.renderTheBestParts()}
                </Col>
            </Row>

            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className= "center" >About Rock Digi</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <p>Rock Digi is an online curriculum, created by Rock In Prevention, that uses
                                music and the arts to teach Social Emotional Learning (SEL) and life skills for
                                success in an effort to prevent bullying and drug and alcohol abuse. </p>
                                <p>Rock In Prevention has helped 1.5 million kids since 1990 through the use of
                                music to teach Social Emotional Learning and life skills for success.</p>
                                <p>For 27 years, Rock In Prevention's Rock LIVE program served students through
                                in-school, educational workshops that provided educational messaging, social
                                dramas, and interaction among students, their teachers, and trained high
                                school mentors.</p>
                                <p>Rock Digi was created to take the practices used in the Rock LIVE program,
                                and make it so it could be used in thousands of places simultaneously
                                through an online platform. This way, we are able to help more kids learn the
                                skills needed to be successful.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row align="middle" style={{ padding: '16px 0', margin: '0px -16px 16px -16px', backgroundColor: '#0B3375', color: 'white', textAlign: 'center' }}>
                <Col xs={24} md={14}>
                    <ReactPlayer url={ConfigureURLs.GetAWSUrl("static", "about-us-intro.mp4")}
                        controls={true} width={null} height={null} playing={false} volume={0.5} />
                </Col>
                <Col xs={24} md={0}>
                    <p style={{ fontSize: 32, marginBottom: 0, padding: '0px 16px' }}>&ldquo;Music is one of those motivating factors that makes a kid really want to learn.&rdquo;</p>
                </Col>
                <Col xs={0} md={10} lg={0}>
                    <p style={{ fontSize: 32, marginBottom: 0, padding: '0px 16px' }}>&ldquo;Music is one of those motivating factors that makes a kid really want to learn.&rdquo;</p>
                </Col>
                <Col xs={0} lg={10} xxl={0}>
                    <p style={{ fontSize: 40, marginBottom: 0, padding: '0px 16px' }}>&ldquo;Music is one of those motivating factors that makes a kid really want to learn.&rdquo;</p>
                </Col>
                <Col xs={0} xxl={10}>
                    <p style={{ fontSize: 48, marginBottom: 0, padding: '0px 16px' }}>&ldquo;Music is one of those motivating factors that makes a kid really want to learn.&rdquo;</p>
                </Col>
            </Row>

            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className= "center" >Our Mission</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <p>Rock In Prevention, Inc. is a nonprofit corporation that creates evidence-based,
                                educational software that teaches children, teachers, and families social
                                emotional intelligence and life skills for success.</p>
                                <p>Social Emotional Learning is ultimately what leads a child to success with
                                academics and in life. Students, teachers, and parents or guardians are trained
                                in what to say and what to do when confronted with difficult situations.
                                Rock Digi teaches students to be empathetic towards people that are different from
                                themselves: school by school and around the world.</p>
                                <p>Rock Digi uses music and interactive curriculum to teach students, teachers,
                                parents and guardians methods and skills to prevent bullying and alcohol,
                                tobacco, and other drug use.</p>
                                <p>Rock Digi lessons are based on American School Counselors Association (ASCA),
                                Health Resources and Services Administration (HRSA), and Common Core
                                standards and educational goals. Rock Digi lessons are also based on the
                                CASEL Social Emotional Learning Core Competencies - Self-Awareness,
                                Self-Management, Social-Awareness, Relationship Skills, and Responsible
                                Decision Making.</p>
                                <p>Rock creates, tests, and implements state of the art online curriculum to help
                                children to learn empathy and provide safe and supportive learning
                                environments so that children can live to their fullest potential.</p>
                                <p>Rock's curriculum works to increase parental and guardian's involvement in
                                their child's education and their healthy physical, mental, emotional, and social
                                development. Rock continues to research ways to help more children, teachers,
                                counselors, and parents or guardians from multiple cultures across the United
                                States and around the world.</p>
                                <p>It is our desire to help millions of children, teachers, counselors, parents, and
                                guardians learn life skills for success to reduce bullying and drug use and help
                                all live to their fullest potential.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className= "center" >Meet the Team</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <h2>Our team of teachers, counselors, musicians, engineers, animators,
                                producers, and many others work together to create the Rock Digi
                                curriculum in an effort to help students learn the skills needed to be
                                successful and achieve their goals.</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img className="lower-space" src={require("../../assets/Pat.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Pat</span><br />
                                <div className="italics">CEO and President</div>
                                <p>Pat is a Certified International Alcohol and Drug Counselor and has worked in the
                                addictions field for 36 years. Pat is the Program Developer of the Rock In Prevention
                                program, Rock LIVE, and has helped over 1.5 million children and families since
                                1990. Pat developed the Rock In Prevention program, which was named to the
                                National Registry of Evidence Based Programs and Practices (NREPP) list. The
                                research for this project was funded by the Substance Abuse and Mental Health
                                Services Administration, Center for Substance Abuse Prevention. Pat is a producer,
                                songwriter, and recording artist that has over 200 published releases. Pat is
                                passionate about helping young people to be successful and live drug-free, and that
                                is why he started Rock In Prevention in 1990.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img className="lower-space" src={require("../../assets/Christina.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Christina</span><br />
                                <div className="italics">Special Projects Coordinator</div>
                                <p>Christina has been working for Rock In Prevention since October 2017. In her time at
                                Rock In Prevention, Christina has helped Pat and an international team to develop
                                and create Rock Digi. Christina works with the software development team to build
                                the Rock Digi site as well as ensure a great user experience. She also edits and
                                produces whiteboard animations, music videos, and other video content for Rock
                                Digi. Christina is dedicated to helping students learn the skills needed to be
                                successful and achieve their goals.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img className="lower-space" src={require("../../assets/Billie.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Billie</span><br />
                                <div className="italics">Curriculum Writer</div>
                                <p>Billie is one of our curriculum writers, and she specializes in Counseling and Social Emotional Learning (SEL).
                                She was a School Counselor for 28 years, and worked in schools before that as a classroom teacher, and for the
                                Head Start Program. She does an incredible job writing scripts for Whiteboard Animations as well as all other
                                components of the Rock Digi curriculum. She specializes in writing our Drill Down Activities and Discussion Questions.
                                Billie is dedicated to writing the curriculum so educators can use differentiated instruction to target all types of learners to
                                ensure all students have the opportunity to achieve their goals and be successful.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img className="lower-space" src={require("../../assets/Lynn.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Lynn</span><br />
                                <div className="italics">Curriculum Writer</div>
                                <p>Lynn is one of our curriculum writers, and she has a great role in Rock Digi's elementary level curricula.
                                She taught for 41 years. Lynn is great at bringing her experience in the classroom into the Rock Digi curricula.
                                She does amazing in writing Discussion Questions, as well as revising all materials to ensure it fits the age
                                level well. We love and appreciate Lynn's dedication to making sure all materials are written in a way that is
                                both effective in teaching the topics but also catches the attention of learners.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img className="lower-space" src={require("../../assets/Michele.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Michele</span><br />
                                <div className="italics">Curriculum Writer</div>
                                <p>Michele is one of our curriculum writers, and she has a major role in Rock Digi's elementary level curricula.
                                She has taught 2nd-4th grade for 28 years. She does a wonderful job making sure that our lessons are fitting
                                standards targeted for each age level. She specializes in writing Whiteboard Animation Scripts for Rock Digi's
                                2nd-4th grade lessons as well as making sure all other parts of the curriculum are tied together well. Michele
                                brings so much energy to do what is going to best benefit all learners. She loves to learn new ways to help students,
                                and we love it when she brings those ideas to use in the Rock Digi curriculum.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img className="lower-space" src={require("../../assets/Pete.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Pete</span><br />
                                <div className="italics">Curriculum Writer</div>
                                <p>Pete is one of our curriculum writers, and he specializes in the instruction of all things in the Social Sciences
                                curriculum 5th-12th grade. He has been teaching for 23 years. We love how Pete was able to help us to blend the
                                learning of Social Studies and Social Emotional Learning together into one curriculum. Pete's enthusiasm for using
                                differentiated teaching methods to make sure all students have the opportunity to succeed is incredible, and the reason
                                why his work helped to make our Social Studies and Middle and High School curricula so great.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col {...span12}>
                                <img src={require("../../assets/Todd.jpg")} />
                            </Col>
                            <Col {...textSpan}>
                                <span className="bold">Todd</span><br />
                                <div className="italics">Musician</div>
                                <p>Todd is a fantastic musician, who can play a number of instruments incredibly such as: guitar, bass, drums, and many
                                others. Todd is also a great singer and rapper. He loves to play music in all types of genres so throughout the curriculum,
                                every student will get the opportunity to learn SEL through their favorite music genre. We are amazed at his wide variety of
                                skills in music, and we are extremely grateful to have him play on so many of the songs that we use in Rock Digi!</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>


            <Row className="reviews-section" justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center">Reviews from Educators around the world!</h1>
                        <Row  align="middle" justify="space-between" gutter={16}>
                            <Col {...reviewsTextSpan}>
                                <p>Minimal Prep time, everything is super simple to follow.</p>
                                <p>Students love implementing technology.</p>
                                <p>Very engaging, lessons are well defined.</p>
                                <p>I think it is very easy to use and navigate. It is very organized and broken up
                                into specific sections to activate learning and deeper understanding.</p>
                            </Col>
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 01.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="space-between">
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 02.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col {...reviewsTextSpan}>
                                <p className="right">I loved the multicultural aspects of it.</p>
                                <p className="right">Students can relate to what they already know about cultures and then think beyond what they do not know and gain understanding of others different from themselves.</p>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="space-between">
                            <Col {...reviewsTextSpan}>
                                <p>I find it very helpful that the standards are included in the lesson. It is always
                                best practice to include the standards in any lesson plan or activity. It helps to
                                keep discussions focused and provides a reason for the lesson.</p>
                                <p>Expanding the student's world view and creating a caring community is so
                                important</p>
                                <p>I really like the Existing Knowledges and the Exit Tickets to see what the
                                students have learned. It will make data collection easy.</p>
                            </Col>
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 03.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="space-between">
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 04.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col {...reviewsTextSpan}>
                                <p className="right">I really enjoyed the whiteboard animation and the music video! This helps to engage
                                students with different learning styles.</p>
                                <p className="right">The videos are powerful. They are engaging for the kids and gives them a visual for a
                                concept that could be difficult.</p>
                                <p className="right">Great content and ability to deliver in a short time.</p>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="space-between">
                            <Col {...reviewsTextSpan}>
                                <p>The School Home Partnership helps connect families to one another and builds
                                trust. It is important to make that home - school connection in order for
                                students to fully experience success.</p>
                                <p>I think that any information we can provide to our parents/guardians, the
                                better. We cannot force the parents to take part, however, we can still provide
                                them with the information or ways to find information.</p>
                                <p>Any time there is parent/guardian involvement, the lessons are even more
                                meaningful and it opens a door for connection.</p>
                            </Col>
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 05.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="lower-space" align="middle" justify="space-between">
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 06.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col {...reviewsTextSpan}>
                                <p className="right">The most powerful part of the program were the activities. Having the students
                                think and step outside of their comfort zone to engage in activities about topics they
                                would not have a conversation about on a daily basis is powerful.</p>
                                <p className="right" >The most powerful part of the program is that the material is represented in
                                multiple ways. There is a learning style for each student.</p>
                                <p>There are lots of abilities for students to relate to others not only in their own local
                                community but also in a global context.</p>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="space-between">
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 07.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col {...reviewsImgSpan}>
                                <Row  justify="center">
                                    <Col>
                                        <img src={require("../../assets/About Us Picture 08.jpg")} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(AboutUsPage);
