import * as React from 'react';
import { Modal, Input, Select } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';

import Form, { FormComponentProps } from '@ant-design/compatible/lib/form';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { DistrictDTO } from '../../models/district/districtDTO';
import ValidateZipCode from '../../utils/ValidateZipCode';

export interface TeacherSelectMySchoolModalDataObject {
    name: string;
    gradeStart: KeyLabel;
    gradeEnd: KeyLabel;
    city: string;
    state: string;
    zip: string;
}

interface TeacherSelectMySchoolModalProps extends FormComponentProps {
    isVisible: boolean;
    onSubmit: (data: TeacherSelectMySchoolModalDataObject) => void;
    onCancel: () => void;
}

interface TeacherSelectMySchoolModalState {
    isSubmitting: boolean;
}

class TeacherSelectMySchoolModal extends React.Component<TeacherSelectMySchoolModalProps, TeacherSelectMySchoolModalState> {
    private gradeLevels: KeyLabel[] = [
        { key: "-1", label: "PK" },
        { key: "0", label: "K" },
        { key: "1", label: "1st" },
        { key: "2", label: "2nd" },
        { key: "3", label: "3rd" },
        { key: "4", label: "4th" },
        { key: "5", label: "5th" },
        { key: "6", label: "6th" },
        { key: "7", label: "7th" },
        { key: "8", label: "8th" },
        { key: "9", label: "9th" },
        { key: "10", label: "10th" },
        { key: "11", label: "11th" },
        { key: "12", label: "12th" },
    ];
    constructor(props: TeacherSelectMySchoolModalProps) {
        super(props);
        bindAllOfThis(this, TeacherSelectMySchoolModal.prototype);

        this.state = {
            isSubmitting: false
        };
    }

    onModalOkay() {
        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: TeacherSelectMySchoolModalDataObject): void => {
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    onModalCancel() {
        this.props.onCancel();
    }

    renderGradeSelect() {
        return <Select
            placeholder="Please select a grade"
            filterOption={false}
            notFoundContent={null}
            size="large"
            labelInValue
            allowClear
        >
            {this.gradeLevels.map(gradeLevel => <Select.Option key={gradeLevel.key} value={gradeLevel.key}>{gradeLevel.label}</Select.Option>)}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        return <Modal
            key="new-school-modal"
            title="Add New School"
            visible={this.props.isVisible}
            destroyOnClose
            closable
            onCancel={this.onModalCancel}
            onOk={this.onModalOkay}
            okText="Add"
        >
            <Form hideRequiredMark autoComplete="new-password">
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Name"
                    name={NameOf<TeacherSelectMySchoolModalDataObject>("name")}
                    rules={[{ required: true, message: "Name is required." }]}
                >
                    <Input size="large" placeholder="Name" {...disableAutoCompleteTags()} />
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Grade Start"
                    name={NameOf<TeacherSelectMySchoolModalDataObject>("gradeStart")}
                    rules={[{ required: true, message: "Grade Start is required." }]}
                >
                    {this.renderGradeSelect()}
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Grade End"
                    name={NameOf<TeacherSelectMySchoolModalDataObject>("gradeEnd")}
                    rules={[{ required: true, message: "Grade End is required." }]}
                >
                    {this.renderGradeSelect()}
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="City"
                    name={NameOf<TeacherSelectMySchoolModalDataObject>("city")}
                    rules={[{ required: true, message: "City is required." }]}
                >
                    <Input size="large" placeholder="City" {...disableAutoCompleteTags()} />
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="State"
                    name={NameOf<TeacherSelectMySchoolModalDataObject>("state")}
                    rules={[{ required: true, message: "State is required." }]}
                >
                    <Input size="large" placeholder="State" maxLength={2} {...disableAutoCompleteTags()} />
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Zip"
                    name={NameOf<TeacherSelectMySchoolModalDataObject>("zip")}
                    rules={[{ required: true, message: "Zip is required." }, { validator: ValidateZipCode }]}
                >
                    <Input size="large" placeholder="Zip" maxLength={5} {...disableAutoCompleteTags()} />
                </FieldDecorator>

            </Form>
        </Modal>;
    }
}

export default Form.create<TeacherSelectMySchoolModalProps>()(TeacherSelectMySchoolModal); //Create Form removed, find workaround?
