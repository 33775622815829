import { Role, Roles } from "./Roles";

export interface Checkpoint {
    id: string;
    name: string;
    display: string;
    order: number;
}

export class Checkpoints {
    public static readonly Home: Checkpoint = { id: "1", name: "Home", display: "Lesson Home", order: 1 };
    public static readonly StartSelfAssessment: Checkpoint = { id: '12', name: 'StartSelfAssessment', display: 'Assessment', order: 2 }
    public static readonly LessonPlan: Checkpoint = { id: "2", name: "LessonPlan", display: "Lesson Plan", order: 3 };
    public static readonly EntranceTest: Checkpoint = { id: "3", name: "EntranceTest", display: "Existing Knowledge", order: 4 };
    public static readonly Whiteboard: Checkpoint = { id: "4", name: "Whiteboard", display: "Whiteboard Animation", order: 5 };
    public static readonly Video: Checkpoint = { id: "5", name: "Video", display: "Lyric Music Video", order: 6 };
    public static readonly Discussion: Checkpoint = { id: "6", name: "Discussion", display: "Discussion Questions", order: 7 };
    public static readonly DrillDown: Checkpoint = { id: "7", name: "DrillDown", display: "Drill Down Activities", order: 8 };
    public static readonly ExitTest: Checkpoint = { id: "8", name: "ExitTest", display: "Exit Ticket", order: 9 };
    public static readonly EndSelfAssessment: Checkpoint = { id: "9", name: "EndSelfAssessment", display: "Assessment", order: 10 };
    public static readonly Results: Checkpoint = { id: "10", name: "Results", display: "Results", order: 11 };
    public static readonly SchoolHomePartnership: Checkpoint = { id: "11", name: "SchoolHomePartnership", display: "School Home Partnership", order: 12 };
    public static readonly Survey: Checkpoint = { id: "13", name: "Survey", display: "Survey", order: 13 };

    public static All: Checkpoint[] = [
        Checkpoints.Home,
        Checkpoints.StartSelfAssessment,
        Checkpoints.LessonPlan,
        Checkpoints.EntranceTest,
        Checkpoints.Whiteboard,
        Checkpoints.Video,
        Checkpoints.Discussion,
        Checkpoints.DrillDown,
        Checkpoints.ExitTest,
        Checkpoints.EndSelfAssessment,
        Checkpoints.Results,
        Checkpoints.SchoolHomePartnership,
    ];

    // I don't want to tie the checkpoints too deeply to a role, but we are short on time and mental energy
    public static Student: Checkpoint[] = [
        Checkpoints.Home,
        Checkpoints.StartSelfAssessment,
        Checkpoints.EntranceTest,
        Checkpoints.Whiteboard,
        Checkpoints.Video,
        Checkpoints.Discussion,
        Checkpoints.DrillDown,
        Checkpoints.ExitTest,
        Checkpoints.EndSelfAssessment,
        Checkpoints.Results,
    ];
    public static Teacher: Checkpoint[] = [
        Checkpoints.Home,
        Checkpoints.StartSelfAssessment,
        Checkpoints.LessonPlan,
        Checkpoints.EntranceTest,
        Checkpoints.Whiteboard,
        Checkpoints.Video,
        Checkpoints.Discussion,
        Checkpoints.DrillDown,
        Checkpoints.ExitTest,
        Checkpoints.EndSelfAssessment,
        // Checkpoints.Results,
        Checkpoints.SchoolHomePartnership,
    ];
    public static Parent: Checkpoint[] = [
        Checkpoints.Home,
        Checkpoints.Whiteboard,
        Checkpoints.Video,
        Checkpoints.SchoolHomePartnership,
    ];
    public static Demo: Checkpoint[] = [
        Checkpoints.Home,
        Checkpoints.StartSelfAssessment,
        Checkpoints.LessonPlan,
        Checkpoints.EntranceTest,
        Checkpoints.Whiteboard,
        Checkpoints.Video,
        Checkpoints.Discussion,
        Checkpoints.DrillDown,
        Checkpoints.ExitTest,
        Checkpoints.EndSelfAssessment,
        Checkpoints.SchoolHomePartnership,
        Checkpoints.Survey,
    ];

    public static FindById(id: string) {
        return this.All.find(x => x.id === id);
    }

    public static GetCheckpointsByRole(role: Role) {
        switch (role) {
            case Roles.Admin:
                return this.All;
            case Roles.Teacher:
                return this.Teacher;
            case Roles.Student:
                return this.Student;
            case Roles.Demo:
                return this.Demo;
            case Roles.Parent:
                return this.Parent;
            case Roles.None:
            default:
                return [];
        }
    }

    //public static GetNext(checkpoint: Checkpoint) {
    //    let order = checkpoint.order + 1;
    //    return this.All.find(x => x.order === order);
    //}

    //public static GetPrevious(checkpoint: Checkpoint) {
    //    let order = checkpoint.order - 1;
    //    return this.All.find(x => x.order === order);
    //}
}
