import axios from 'axios';

import { QuestionAnswerDTO } from '../models/questionAnswer/questionAnswerDTO';
import { CreateQuestionAnswerDTO } from '../models/questionAnswer/createQuestionAnswerDTO';
import { UpdateQuestionAnswerDTO } from '../models/questionAnswer/updateQuestionAnswerDTO';

export class QuestionAnswerController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostCreateQuestionAnswerRoute = () => `questionAnswers`;
    public static PostCreateQuestionAnswer(request: CreateQuestionAnswerDTO) {
        return axios.post<QuestionAnswerDTO>(this.PostCreateQuestionAnswerRoute(), request);
    }

    public static PutUpdateQuestionAnswerRoute = () => `questionAnswers`;
    public static PutUpdateQuestionAnswer(request: UpdateQuestionAnswerDTO) {
        return axios.put<QuestionAnswerDTO>(this.PutUpdateQuestionAnswerRoute(), request);
    }

    public static DeleteQuestionAnswerRoute = (questionAnswerId: string) => `questionAnswers/${questionAnswerId}`;
    public static DeleteQuestionAnswer(questionAnswerId: string) {
        return axios.delete(this.DeleteQuestionAnswerRoute(questionAnswerId));
    }
}
