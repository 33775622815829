import { Reducer } from 'redux';
import { KnownActions } from './ConstantsActions';
import { Roles } from '../constants/Roles';
import { LoadingStatusType } from '../models/common/LoadingStatusType';
import { Checkpoints } from '../constants/Checkpoints';
import { LessonContentTypes } from '../constants/LessonContentTypes';
import { SchoolYears } from '../constants/SchoolYears';
import { PricingTiers, PricingTier } from '../constants/PricingTiers';
import { DocumentaryDTO } from '../models/documentary/documentaryDTO';
import ConfigureURLs from '../core/ConfigureUrls';

export type ConstantState = {
    roles: Roles;
    checkpoints: Checkpoints;
    lessonContentTypes: LessonContentTypes;
    schoolYears: SchoolYears;
    pricingTiers: PricingTiers;
    state: LoadingStatusType;
    documentaries: DocumentaryDTO[];
};
let DefaultConstantState: ConstantState = {
    roles: Roles,
    checkpoints: Checkpoints,
    lessonContentTypes: LessonContentTypes,
    schoolYears: SchoolYears,
    pricingTiers: PricingTiers,
    documentaries: [],
    state: "none",
};

export const ConstantReducer: Reducer<ConstantState, KnownActions> = (state: ConstantState = DefaultConstantState, action: KnownActions) => {
    switch (action.type) {
        case "UPDATE_ROLES_CONSTANTS":
            for (const item of action.data) {
                let role = Roles.All.find(x => x.name.toLowerCase() === item.name.toLowerCase());
                if (role == null) {
                    // Could not find a role to match up to
                    continue;
                }
                role.id = item.id;
            }
            return {
                ...state,
                roles: Roles
            };
        case "UPDATE_PRICING_TIERS_CONSTANTS":
            for (const item of action.data) {
                let pricingTier = PricingTiers.All.find(x => x.id === item.id);
                if (pricingTier == null) {
                    continue;
                }
                pricingTier.id = item.id;
            }
            return {
                ...state,
                pricingTiers: PricingTiers
            };
        case "UPDATE_CHECKPOINTS_CONSTANTS":
            for (const item of action.data) {
                let checkpoint = Checkpoints.All.find(x => x.name.toLowerCase() === item.name.toLowerCase());
                if (checkpoint == null) {
                    // Could not find a checkpoint to match up to
                    continue;
                }
                checkpoint.id = item.id;
            }
            return {
                ...state,
                checkpoints: Checkpoints
            };
        case "UPDATE_LESSON_CONTENT_TYPES_CONSTANTS":
            for (const item of action.data) {
                let contentType = LessonContentTypes.All.find(x => x.name.toLowerCase() === item.name.toLowerCase());
                if (contentType == null) {
                    // Could not find a lesson content to match up to
                    continue;
                }
                contentType.id = item.id;
            }
            return {
                ...state,
                lessonContentTypes: LessonContentTypes
            };
        case "UPDATE_SCHOOL_YEARS_CONSTANTS":
            for (const item of action.data) {
                let schoolYear = SchoolYears.All.find(x => x.startYear === item.startYear);
                if (schoolYear == null) {
                    // Could not find a school year to match up to
                    continue;
                }
                schoolYear.id = item.id;
            }
            return {
                ...state,
                schoolYears: SchoolYears
            };
        case "UPDATE_DOCUMENTARIES_CONSTANTS":
            let documentaries = action.data.map(x => ({
                ...x,
                videoUrl: ConfigureURLs.GetAWSUrl("static", "documentaries/" + x.videoUrl),
                postVideoPDFUrl: ConfigureURLs.GetAWSUrl("static", "documentaries/" + x.postVideoPDFUrl),
            }));
            return {
                ...state,
                documentaries
            };
        case "UPDATE_STATE_CONSTANTS":
            return {
                ...state,
                state: action.data,
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // JB - Clarification: The following line is magic
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    // (or default initial state if none was supplied)
    return state;
};
