import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { RouteComponentProps, withRouter, Link, Redirect } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, notification } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import Routes from '../../../core/Routes';
import LoginForm from './LoginForm';
import UserAction from '../../../redux/UserActions';
import ReduxStoreModel from '../../../redux/ReduxModel';
import { UserState } from '../../../redux/UserReducer';
import { LoginDTO } from '../../../models/account/loginDTO';
import { PageProps } from '../../../models/common/ComponentProps';
import ActionResultDTO from '../../../models/common/ActionResultDTO';

import "./Login.scss";

interface LoginPageProps extends PageProps<{}> {
    // From Redux

    User: UserState;
    Login: (data: LoginDTO) => Promise<ActionResultDTO>;
}

interface LoginPageState {
    isLoggedIn: boolean;
    isSubmitting: boolean;
}

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
    private notificationKey = "LoginPage";
    constructor(props: LoginPageProps) {
        super(props);
        bindAllOfThis(this, LoginPage.prototype);

        this.state = {
            isLoggedIn: false,
            isSubmitting: false,
        };
    }

    handleLoginClick(data: LoginDTO) {
        // Don't crush the login endpoint
        if (this.state.isSubmitting) {
            return;
        }

        notification.info({
            key: this.notificationKey,
            message: "Login",
            description: "Logging in to the site...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        this.props.Login(data).then(result => {
            if (!result.isError) {
                notification.success({
                    key: this.notificationKey,
                    message: "Login",
                    description: "Login success!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

            } else {
                notification.error({
                    key: this.notificationKey,
                    message: "Login",
                    description: ["Login returned an error.", result.message].map(x => <div>{x}</div>),
                    duration: 10
                });
                this.setState({ isSubmitting: false });
            }
        });
    }

    render() {
        if (this.props.User.state === "finished") {
            if(this.props.User.redirect != ""){
                return <Redirect to={this.props.User.redirect}/>
            }
            return <Redirect to={Routes.HOME().toRoute} push />;
        }

        return <div className="login-page">
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 10, offset: 7 }}
                    xl={{ span: 8, offset: 8 }}
                >
                    <h2>Login Page</h2>
                    <LoginForm
                        onSubmit={this.handleLoginClick}
                        isSubmitting={this.state.isSubmitting} />

                    <br/>
                    <Link to={Routes.REGISTER().toRoute}><span>Need to Register?</span></Link>
                    <br/>
                    <Link to={Routes.FORGOT_PASSWORD().toRoute}><span>Forgot Password?</span></Link>
                    <br/>
                    <Link to={Routes.STUDENT_LOGIN().toRoute}><span>Have a Passcode?</span></Link>
                </Col>
            </Row>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        Login: (data: LoginDTO) => UserAction.Login(dispatch, data),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
