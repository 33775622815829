import axios from 'axios';

import { TeacherDTO } from '../models/teacher/teacherDTO';
import { UpdateTeacherDTO } from '../models/teacher/updateTeacherDTO';
import { ClassroomDTO } from '../models/classroom/classroomDTO';
import { StudentDTO } from '../models/student/studentDTO';
import { SimpleSchoolDTO } from '../models/school/simpleSchoolDTO';

export class TeacherController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetAllTeachersRoute = () => `teachers`;
    public static GetAllTeachers() {
        return axios.get<TeacherDTO[]>(this.GetAllTeachersRoute());
    }

    public static GetTeacherByIdRoute = (teacherId: string) => `teachers/byId/${teacherId}`;
    public static GetTeacherById(teacherId: string) {
        return axios.get<TeacherDTO>(this.GetTeacherByIdRoute(teacherId));
    }

    public static PutUpdateTeacherRoute = () => `teachers`;
    public static PutUpdateTeacher(request: UpdateTeacherDTO) {
        return axios.put<TeacherDTO>(this.PutUpdateTeacherRoute(), request);
    }

    public static GetClassroomsByTeacherRoute = (teacherId: string) => `teachers/${teacherId}/classrooms`;
    public static GetClassroomsByTeacher(teacherId: string) {
        return axios.get<ClassroomDTO[]>(this.GetClassroomsByTeacherRoute(teacherId));
    }

    public static GetStudentsByTeacherRoute = (teacherId: string) => `teachers/${teacherId}/students`;
    public static GetStudentsByTeacher(teacherId: string) {
        return axios.get<StudentDTO[]>(this.GetStudentsByTeacherRoute(teacherId));
    }

    public static GetSchoolsByTeacherRoute = (teacherId: string) => `teachers/${teacherId}/schools`;
    public static GetSchoolsByTeacher(teacherId: string) {
        return axios.get<SimpleSchoolDTO[]>(this.GetSchoolsByTeacherRoute(teacherId));
    }

    public static GetAddTeacherToSchoolRoute = (teacherId: string, schoolId: string) => `teachers/addToSchool/${teacherId}/${schoolId}`;
    public static GetAddTeacherToSchool(teacherId: string, schoolId: string) {
        return axios.get<void>(this.GetAddTeacherToSchoolRoute(teacherId, schoolId));
    }

    public static GetRemoveTeacherFromSchoolRoute = (teacherId: string, schoolId: string) => `teachers/removeFromSchool/${teacherId}/${schoolId}`;
    public static GetRemoveTeacherFromSchool(teacherId: string, schoolId: string) {
        return axios.get<void>(this.GetRemoveTeacherFromSchoolRoute(teacherId, schoolId));
    }

    public static GetUpdateTeacherActiveSchoolRoute = (teacherId: string, schoolId: string) => `teachers/setActive/${teacherId}/${schoolId}`;
    public static GetUpdateTeacherActiveSchool(teacherId: string, schoolId: string) {
        return axios.get<void>(this.GetUpdateTeacherActiveSchoolRoute(teacherId, schoolId));
    }
}
