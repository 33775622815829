import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Button, Col, notification, Spin, Row } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import { WorkflowState } from '../../redux/WorkflowReducer';

interface StudentCourseHomePageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
}

interface StudentCourseHomePageState {
}

class StudentCourseHomePage extends React.Component<StudentCourseHomePageProps, StudentCourseHomePageState> {
    private notificationKey = "StudentCourseHomePage";
    constructor(props: StudentCourseHomePageProps) {
        super(props);
        bindAllOfThis(this, StudentCourseHomePage.prototype);

        this.state = {
        };
    }

    render() {
        if (!this.props.Workflow.currentCourse.lessons.some(x => x.available)) {
            return <div>
                <h1>{this.props.Workflow.courseState === "finished" && this.props.Workflow.currentCourse.name} - Home</h1>
                <p>It looks like there are no lessons for you to complete! Check with your teacher to find out more.</p>
            </div>;
        }

        return <div>
            <h1>{this.props.Workflow.courseState === "finished" && this.props.Workflow.currentCourse.name} - Home</h1>
            <p>Your course has been selected! Check out the left side of your screen to start working on your lessons.</p>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps)(StudentCourseHomePage);
