import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import Routes from '../../core/Routes';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { Roles } from '../../constants/Roles';
import { Checkpoints } from '../../constants/Checkpoints';
import "./Workflow.scss";

interface WorkflowHomePageProps extends PageProps<{ classroomId?: string, courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowHomePageState {
}

class WorkflowHomePage extends React.Component<WorkflowHomePageProps, WorkflowHomePageState> {
    constructor(props: WorkflowHomePageProps) {
        super(props);
        bindAllOfThis(this, WorkflowHomePage.prototype);
    }

    componentDidMount() {
        this.figureOutOnNext();
    }

    figureOutOnNext() {
        this.props.setButtonState("visible", "visible");
    }

    gradeAsString(grade: number) {
        return grade > 0 ? grade.toString() : "K";
    }

    renderGradeRange() {
        // need to render out 0 as K but the line was getting long, so it has been moved to a render function
        let gradeStart = this.gradeAsString(this.props.Workflow.currentCourse.gradeLevelStart);
        let gradeEnd = this.gradeAsString(this.props.Workflow.currentCourse.gradeLevelEnd);
        return `${gradeStart}-${gradeEnd}`;
    }

    renderQuickLinks() {
        let courseId = this.props.match.params.courseId;
        let lessonId = this.props.match.params.lessonId;
        let classroomId = this.props.match.params.classroomId;

        if (classroomId == null) {
            let checkpoints = this.props.Workflow.isDemoMode ? [...Checkpoints.Demo] : [...Checkpoints.GetCheckpointsByRole(this.props.User.role)];
            checkpoints.sort((a, b) => a.order - b.order);

            if (this.props.Workflow && this.props.Workflow.currentCourse && this.props.Workflow.currentCourse.lessons) {
                let lastIndex = this.props.Workflow.currentCourse.lessons.length - 1;
                if (this.props.Workflow.currentCourse.lessons.findIndex(x => x.id === this.props.match.params.lessonId) !== 0) {
                    checkpoints = checkpoints.filter(x => x != Checkpoints.StartSelfAssessment);
                }
                if (this.props.Workflow.currentCourse.lessons.findIndex(x => x.id === this.props.match.params.lessonId) !== lastIndex) {
                    checkpoints = checkpoints.filter(x => x != Checkpoints.EndSelfAssessment);
                }
            }


            let buttons = checkpoints.map(checkpoint => {
                let route = Routes.WORKFLOW(courseId, lessonId, checkpoint.name.toLowerCase()).toRoute;
                return <Col span={24} style={{ marginBottom: "6px" }}>
                    <Link key={`link-${checkpoint.id}`} to={route}>
                        <Button type="primary">{checkpoint.display}</Button>
                    </Link>
                </Col>;
            });

            return <React.Fragment>
                <h2>Quick Links</h2>
                <Row>
                    {buttons}
                </Row>
            </React.Fragment>;
        }

        return null;
    }

    render() {
        if (this.props.User.role === Roles.Parent) {
            return <div>
                <h1>Welcome Parents! - {this.props.Workflow.currentCourse.name}</h1>
                <h3 style={{ marginBottom: "14px" }}>{this.props.Workflow.currentCourse.description}</h3>
                <p>Thank you for visiting this lesson for your child!</p>
                <p>There are a few items to complete with them. These are shown on the left side of the screen. You can click 'Next' and 'Back' at the bottom of the page.</p>
                <p>Please make sure that you fill out answers on the {Checkpoints.SchoolHomePartnership.display} page.</p>
            </div>;
        }
        return <div>
            <h1>{this.props.Workflow.currentCourse.name}</h1>
            <h3 style={{ marginBottom: "14px" }}>{this.props.Workflow.currentCourse.description}</h3>
            <p>Welcome to Rock Digi! This is your {this.props.Workflow.isDemoMode ? <span style={{ fontWeight: 700 }}>free trial </span> : ""}lesson for Grades {this.renderGradeRange()}!</p>
            <p>You will need to use the "Next" and "Back" buttons to navigate at the bottom of your screen.</p>
            {this.renderQuickLinks()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowHomePage);
