import axios from 'axios';
import { SimpleLessonDTO } from '../models/lesson/simpleLessonDTO';
import { DemoDTO } from '../models/demo/DemoDTO';
import { CreateDemoDTO } from '../models/demo/CreateDemoDTO';
import { UpdateDemoDTO } from '../models/demo/UpdateDemoDTO';

export class DemoController{
    constructor(){
        throw new Error("noo");
    }

    public static GetDemoLessonsRoute = () => `demo/lessons`;
    public static GetDemoLessons(){
        return axios.get<SimpleLessonDTO[]>(this.GetDemoLessonsRoute());
    }

    public static GetDemosRoute = () => `demo`;
    public static GetDemos(){
        return axios.get<DemoDTO[]>(this.GetDemosRoute());
    }

    public static GetDemoRoute = (demoId: string) => `demo/${demoId}`;
    public static GetDemo(demoId: string){
        return axios.get<DemoDTO>(this.GetDemoRoute(demoId));
    }

    public static GetDemoCourseRoute = (courseId: string) => `demo/course/${courseId}`;
    public static GetDemoCourse(courseId: string){
        return axios.get<DemoDTO>(this.GetDemoCourseRoute(courseId));
    }

    public static PostCreateDemoRoute  = () => `demo`;
    public static PostCreateDemo(request: CreateDemoDTO){
        return axios.post<DemoDTO>(this.PostCreateDemoRoute(), request);
    }   

    public static PostSetDemoLessonRoute  = (lessonId: string) => `demo/add/${lessonId}`;
    public static PostSetDemoLesson(lessonId: string){
        return axios.post(this.PostSetDemoLessonRoute(lessonId));
    }

    public static PutRemoveLessonFromDemoRoute = (lessonId: string) => `demo/remove/${lessonId}`;
    public static PutRemoveLessonFromDemo(lessonId: string){
        return axios.put(this.PutRemoveLessonFromDemoRoute(lessonId));
    }
    
    public static PutUpdateDemoRoute = () => `demo`;
    public static PutUpdateDemo(request: UpdateDemoDTO){
        return axios.put<DemoDTO>(this.PutUpdateDemoRoute(), request);
    }

    public static DeleteRemoveDemoRoute = (demoId: string) => `demo/${demoId}`;
    public static DeleteRemoveDemo(demoId: string){
        return axios.delete(this.DeleteRemoveDemoRoute(demoId));
    }
}
