import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Modal, notification, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import ErrorDTO from '../../models/common/ErrorDTO';
import { PageProps } from '../../models/common/ComponentProps';
import { LicenseController } from '../../api/LicenseController';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { LicenseDTO } from '../../models/license/licenseDTO';
import './AdminLicensesPage.scss';

interface AdminLicensesPageProps extends PageProps<{}> {
}

interface AdminLicensesPageState {
    isLoading: boolean;
    tableData: LicenseDTO[];
}

class AdminLicensesPage extends React.Component<AdminLicensesPageProps, AdminLicensesPageState> {
    private notificationKey = "AdminLicenseTable";
    tableColumns: ColumnProps<LicenseDTO>[];
    constructor(props: AdminLicensesPageProps) {
        super(props);
        bindAllOfThis(this, AdminLicensesPage.prototype);

        this.tableColumns = [{
            title: 'School',
            dataIndex: 'schoolName',
            key: 'schoolName',
            sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
            ...getColumnSearchProps<LicenseDTO>("schoolName", "School"),
        }, {
            title: 'Name',
            dataIndex: 'courseDocumentaryName',
            key: 'courseDocumentaryName',
            sorter: (a, b) => a.courseDocumentaryName.localeCompare(b.courseDocumentaryName),
            ...getColumnSearchProps<LicenseDTO>("courseDocumentaryName", "Course"),
        }, {
            title: 'License Type',
            dataIndex: 'licenseType',
            width: 120,
            sorter: (a, b) => a.startDate.localeCompare(b.startDate),
            ...getColumnSearchProps<LicenseDTO>("licenseType", "License Type"),
        }, {
            title: 'Start Date',
            key: 'startDate',
            width: 120,
            sorter: (a, b) => a.startDate.localeCompare(b.startDate),
            ...getColumnSearchProps<LicenseDTO>("startDate", "Start"),
            render: (text: any, record: LicenseDTO, index: number) => (
                <div>
                    {moment(record.startDate).format("DD-MMM-YYYY")}
                </div>
            )
        }, {
            title: 'End Date',
            key: 'endDate',
            width: 120,
            sorter: (a, b) => a.endDate.localeCompare(b.endDate),
            ...getColumnSearchProps<LicenseDTO>("endDate", "End"),
            render: (text: any, record: LicenseDTO, index: number) => (
                <div>
                    {moment(record.endDate).format("DD-MMM-YYYY")}
                </div>
            )
        }, {
            title: 'License Count',
            dataIndex: 'licenseCount',
            key: 'licenseCount',
            width: 150,
            sorter: (a, b) => a.licenseCount - b.licenseCount,
            ...getColumnSearchProps<LicenseDTO>("licenseCount", "License Count"),
        }, {
            title: 'Edit',
            key: 'edit',
            align: 'center',
            width: 60,
            render: (text, record) => {
                return (
                    <Tooltip title="Edit License" placement="left">
                        <Link to={Routes.ADMIN_LICENSE_ADD_EDIT(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Delete License" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleOnRemove(record)} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: true,
            tableData: null
        };
    }

    componentDidMount() {
        this.loadTableData();
    }

    loadTableData() {
        LicenseController.GetLicenses().then(result => {
            this.setState({
                tableData: result.data.map(x => ({ ...x, licenseType: x.courseId != null ? 'Course' : 'Documentary' })),
                isLoading: false
            });
        });
    }

    handleOnRemove(record: LicenseDTO) {
        // Ask if they are sure
        Modal.confirm({
            title: <p className="title">Warning!!!</p>,
            content: <div><p className="content">You are about to delete license "{record.courseDocumentaryName}" from "{record.schoolName}".</p> <p className="large-space"> Continue?</p></div>,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                LicenseController.DeleteLicense(record.id).then(result => {
                    notification.success({
                        key: this.notificationKey,
                        message: "License Removal",
                        description: `This License "${record.courseDocumentaryName}" has been removed`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });

                    this.loadTableData();
                }).catch(error => {
                    let messages = error != null && error.response != null && error.response.data.messages != null
                        ? (error.response.data as ErrorDTO).messages
                        : ["Critical Error"];
                    notification.error({
                        key: this.notificationKey,
                        message: "License Content Removal",
                        description: ["There was an issue removing the License Content.", ...messages].map(x => <div>{x}</div>),
                        duration: 10
                    });
                });
            }
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return (
            <div>
                <h1>Licenses</h1>
                <Space>
                    <Link key="add-link" to={Routes.ADMIN_LICENSE_ADD_EDIT("").toRoute + "?licenseType=course"}>
                        <Button icon={<EditOutlined />}>Add License</Button>
                    </Link>
                    <Link key="add-link2" to={Routes.ADMIN_LICENSE_ADD_EDIT("").toRoute + "?licenseType=documentary"}>
                        <Button icon={<EditOutlined />}>Add Documentary</Button>
                    </Link>
                </Space>
                <br /><br />
                <Table
                    rowKey={record => record.id}
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                />
            </div>
        );
    }
}

export default AdminLicensesPage;
