import axios from 'axios';

import { StudentLessonDTO } from '../models/studentLesson/studentLessonDTO';
import { CreateStudentLessonDTO } from '../models/studentLesson/createStudentLessonDTO';
import { UpdateStudentLessonDTO } from '../models/studentLesson/updateStudentLessonDTO';

export class StudentLessonController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostCreateStudentLessonRoute = () => `studentLessons`;
    public static PostCreateStudentLesson(request: CreateStudentLessonDTO) {
        return axios.post<StudentLessonDTO>(this.PostCreateStudentLessonRoute(), request);
    }

    public static PutUpdateStudentLessonRoute = () => `studentLessons`;
    public static PutUpdateStudentLesson(request: UpdateStudentLessonDTO) {
        return axios.put<StudentLessonDTO>(this.PutUpdateStudentLessonRoute(), request);
    }

    public static DeleteStudentLessonsByLessonIdRoute = (lessonId: string) => `studentLessons/${lessonId}`;
    public static DeleteStudentLessonsByLessonId(lessonId: string) {
        return axios.delete(this.DeleteStudentLessonsByLessonIdRoute(lessonId));
    }
}
