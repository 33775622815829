import * as React from 'react';
import { Button, Row, Col, Card } from 'antd';

const redStyle: React.CSSProperties = { color: "red" };
const boldStyle: React.CSSProperties = { fontWeight: 700 };

class BetaUserAgreementPage extends React.Component {
    render() {
        const layoutSpans = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };
        return <div>
            <Row justify="center">
                <Col {...layoutSpans}>
                    <h1>About the Beta Participant Agreement</h1>
                    <p>
                        <span>Congratulations on being selected to participate in an upcoming beta test! This Beta Participant Agreement is a legal document being executed between you and</span>
                        <span style={redStyle}> Rock in Prevention (sometimes referred to as the “Company”) </span>
                        <span>that outlines both your obligations and the company’s. It is important for you and</span>
                        <span style={redStyle}> Rock in Prevention </span>
                        <span>that you are comfortable with these terms. While it may not be exciting reading, you should read the entire agreement before you sign it. If you do not understand or agree to any part of the agreement, you should not sign this document and instead ask questions before completing it.</span>
                    </p>
                    <p>
                        <span>By signing this document, you are agreeing to all the provisions. Included in these terms is your agreement to:</span>
                    </p>
                    <ul>
                        <li>Actively use and evaluate the product or service being tested</li>
                        <li>Provide the types of feedback requested and respond to questions</li>
                        <li>Give Rock in Prevention all rights to any feedback you submit</li>
                        <li>Not disclose that you are participating in this test</li>
                        <li>Not show the materials to others</li>
                        <li>Not share copies, pictures, or videos of the test materials in any form</li>
                        <li>Return the test materials if requested</li>
                    </ul>
                    <p>
                        <span>Thank you! We look forward to your participation in the test.</span>
                    </p>
                    <p>&nbsp;</p><hr /><p>&nbsp;</p>
                    <h1>Beta Participant Agreement</h1>
                    <p>
                        <span>This Beta Participant Agreement (“Agreement”) is made and entered into between the visitor and Rock in Prevention, Inc. (“Company”), and is effective as of the date of acceptance by the Participant.</span>
                    </p>
                    <p>
                        <span>Participant is an individual or business that desires to participate in “beta testing”, which provides access to confidential materials or services currently in development by Company.  Company wishes to obtain the benefit of Participant’s services and reports as a beta tester of Company’s materials or service. In consideration of the promises, and of the mutual covenants and conditions, the parties, agree to the following terms and conditions:</span>
                    </p>
                    <ol type="1">
                        <li>
                            <span style={boldStyle}>Beta Test Material. </span>
                            <span>Each material or service listed on Exhibit A and all components, accessories, and documentation related to each such Beta Test Material and/or technology provided by Company including, but not limited to, specifications and other technical information, and all updates or revisions thereto, shall be referred to in the following provisions as the “Beta Test Material.” The parties hereto agree that Participant shall be a beta tester for those Beta Test Materials set forth on Exhibit A, as may be amended from time to time byCompany.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>License. </span>
                            <span>Company hereby grants Participant a non-exclusive, non-transferable consent and license to use eachBeta Test Material solely for the purpose of evaluating and testing the Beta Test Material for Company as described in Exhibit A. The Beta Test Material may not be publicly disclosed, sublicensed, sold, assigned,leased, loaned,  or otherwise transferred by Participant to any third party for any reason.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Company’s Obligations. </span>
                            <ol type="a">
                                <li>Company will deliver the Beta Test Material to Participant at Company’s expense.</li>
                                <li>Company will provide the support or warranty service for the Beta Test Material described in theExhibit and no other support or services.</li>
                                <li>Company has no obligation to develop or provide any updates or revisions to the Beta Test Material,and Company reserves the right to alter or adjust performance specifications for the Beta Test Material as it deems necessary or desirable.</li>
                                <li>Company will provide the instructions, safety information, warnings or cautions concerning the BetaTest Material described or included in Exhibit A and no other.</li>
                            </ol>
                        </li>
                        <li>
                            <span style={boldStyle}>Participant’s Obligations. </span>
                            <ol type="a">
                                <li>Participant agrees to test and evaluate the Beta Test Material as requested and described in Exhibit A.Participant agrees to familiarize itself with the Beta Test Material information provided by Company.Participant will notify Company of any and all functional flaws, errors, anomalies,  and problems directly or indirectly associated with the Beta Test Material known to or discovered by Participant. In addition, Participant agrees to provide Company with such reports via the methods designated byCompany and to promptly respond to any and all reasonable inquiries, questionnaires, surveys, and other test documents submitted to Participant by Company.</li>
                                <li>Upon Company’s request for the return of the Beta Test Material, Participant agrees to return the BetaTest Material in good condition (taking into account reasonable wear and tear for any hardware basedBeta Test Material) to Company within seven days of Participant’s receipt of Company’s request to do so. If Participant fails to return the Beta Test Material within this seven-day period, Participant agrees to purchase the Beta Test Material from Company at the retail price for such Beta Test Material, along with all shipping costs and any applicable processing fees, as set forth in an invoice from Company. If the Beta Test Material is lost, damaged or destroyed during the term of this Agreement while inParticipant’s possession, Participant shall be responsible for the costs of repair or replacement of suchBeta Test Material at its sole expense.</li>
                                <li>Any feedback, ideas, modifications, suggestions, improvements,  and the like made by Participant with respect to the Beta Test Material (“Supportive Information”) will be the property of Company.Participant agrees to assign, and hereby assigns, all right, title,  and interest worldwide in theSupportive Information and the related intellectual property rights, including but not limited to patents,trademarks, copyrights, including any and all moral rights, if applicable, and trade secrets to Company and agrees to assist Company, at Company’s expense, in perfecting and enforcing such rights without any additional consideration to Participant. Company may disclose or use Supportive Information for any purposes whatsoever without any obligation to Participant.</li>
                                <li>Participant agrees to pay all incidental costs (such as, costs for Internet and phone services,accessories, cabling, etc.) associated with the testing of the Beta Test Material and incurred duringParticipant’s possession of the Beta Test Material, unless otherwise agreed to in writing by both parties and described in Exhibit A.</li>
                            </ol>
                        </li>
                        <li>
                            <span style={boldStyle}>Confidentiality. </span>
                            <ol type="a">
                                <li>Participant acknowledges that as a beta tester, Participant may have access to, and Company may disclose to Participant, certain valuable information belonging to and relating to Company whichCompany considers confidential, including, but not limited to, information concerning the Beta TestMaterial, the Beta Test Material’s trademark(s) and trade name(s), computer programs, user manuals,sales and marketing plans, business plans, processes, customer lists,  and other trade secrets(“Confidential Information”). Participant shall use the Confidential Information solely for testing purposes and, for a period of three (3) years from Participant’s receipt of the Confidential Information,shall not disclose, without Company’s written consent, such Confidential Information to third parties or use such Confidential Information for its own benefit or for the benefit of third parties.</li>
                                <li>If Participant is a company or other entity, Participant shall disclose Confidential Information only to those of its employees who need to know such information for the purpose of the agreed-upon beta testing, and shall ensure that its employees observe the confidentiality obligations in this Section 5.Participant acknowledges that the Beta Test Material contains Confidential Information developed or acquired by Company and that all rights therein and in other Company Confidential Information remain with Company. Participant will not disclose that it is evaluating or testing or has evaluated or tested the Beta Test Material to any third party without Company’s prior written consent. In addition,Participant agrees to treat any communications and reports prepared under this Agreement, including,but not limited to, those prepared in accordance with Section 4(a), as Confidential Information and will Beta Test Participation License Agreement not divulge the existence or content of such communications or reports to any third party without Company’s prior written consent.</li>
                                <li>This Agreement shall impose no obligation of confidentiality upon Participant with respect to any portion of the Confidential Information which: (i) now or hereafter, through no act or failure to act onParticipant’s part, becomes generally known or available; (ii) is known to Participant at the timeParticipant receives the Confidential Information from Company as evidenced by written records; or(iii) is hereafter furnished to Participant by a third party as a matter of right and without restriction on disclosure.</li>
                            </ol>
                        </li>
                        <li>
                            <span style={boldStyle}>Proprietary Rights; No Right to Copy, Modify, or Disassemble. </span>
                            <ol type="a">
                                <li>The Beta Test Material provided by Company   and all copies thereof, are proprietary to and the property of Company. All applicable rights in all copyrights, trademarks, trade secrets, trade names,patents and other intellectual property rights in or associated with the Beta Test Material are and will remain the sole and exclusive property of Company. Participant shall have no such intellectual property rights in the Beta Test Material.</li>
                                <li>Participant may not copy or reproduce the Beta Test Material without Company’s prior written consent, except as reasonably needed to perform its obligations hereunder and subject to the following restrictions. Participant may not copy or reproduce any software or documentation provided byCompany, without Company’s prior written consent, except as is reasonably needed to performParticipant’s obligations under this Agreement. Each copy of software or documentation made byParticipant must contain Company’s proprietary and copyright notices in the same form as on the original. Participant shall not remove or deface any portion of any legend provided on any part of theBeta Test Material.  Participant shall not use any Beta Test Material, including but not limited to any software, with more CPUs/machines that the number licensed or by more users, including but not limited to developers, than the number licensed</li>
                                <li>Participant agrees to secure and protect the Beta Test Material and all copies thereof in a manner consistent with the maintenance of Company’s rights therein and to take appropriate actions by instruction or agreement with any of its employees or agents permitted access thereto to satisfy its obligations hereunder.</li>
                                <li>Participant shall not reverse engineer, alter, modify, disassemble or decompile the Beta Test Material,or any part thereof, without Company’s prior written consent.  Participant shall not create any derivative works of any Beta Test Material, including translation or localization, however code written to published application programming interfaces (“API”) for any software of the Beta Test Material shall not be deemed derivative works.  To the extent any derivative works are created by Participant based on the Beta Test Material, Participant agrees such derivative works shall be considered works made for hire and considered Supportive Information and subject to the terms set forth in Section 4.</li>
                            </ol>
                        </li>
                        <li>
                            <span style={boldStyle}>Delivery of Beta Test Material. </span>
                            <span>Participant acknowledges and agrees that the Beta Test Material, or a portion thereof, may be provided to Participant directly,  via the Internet, or via a locally hosted environment. UponCompany’s request, Participant agrees to setup and provide a proper, secure, confidential hosting environment for the Beta Test Material, or a portion thereof, to ensure proper functionality in emulated production scenarios. Any and all designs, development and documentation are subject to feedback by the Participant, as per the terms of this Agreement. Participant acknowledges that such feedback is necessary for the continued development of the Beta Test Material.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Disclaimer of Warranty. </span>
                            <span>By its nature, the Beta Test Material may contain errors, bugs and other problems that could cause system failure and the testing and quality assurance of the Beta Test Material may not yet be completed. Because the Beta Test Material is subject to change, Company reserves the right to alter the BetaTest Material at any time, and any reliance on the Beta Test Material is at Participant’s own risk.PARTICIPANT ACCEPTS THE BETA TEST MATERIAL “AS IS.” COMPANY MAKES NO WARRANTY OF ANY KIND REGARDING THE BETA TEST MATERIAL. COMPANY HEREBY EXPRESSLY DISCLAIMS ALL IMPLIED AND STATUTORY WARRANTIES, INCLUDING, BUT NOT LIMITED TO,ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTIES RIGHTS.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Term and Termination. </span>
                            <ol type="a">
                                <li>The term of this Agreement shall begin on the date set forth above (or, if no date is given, then the date both parties accept this Agreement by written signature) and shall continue until terminated as set forth below. Upon termination of this Agreement for any reason, the three-year obligation to protectConfidential Information, as set forth in Section 5(a), shall survive such termination.</li>
                                <li>This Agreement may be terminated at any time for any reason by either party giving ten days prior written notice to the other party, subject to Section 9(c) below. A particular Beta Test MaterialAgreement shall automatically terminate upon the general release to the public of the final product derived from the Beta Test Material by Company, or sooner upon ten days prior written notice by either party. The parties acknowledge that Company is under no obligation to release any final product or Beta Test Material to the public.</li>
                                <li>Upon termination of this Agreement or a particular Beta Test Material Agreement, Participant agrees to (a) return the Beta Test Material and all copies thereof to Company, if requested by Company in writing to do so, within seven days after such termination, or (b) if requested by Company to do so,certify to Company in writing that the Beta Test Material and all copies thereof have been destroyed,or (c) purchase the Beta Test Material as set forth in Section 4(c). The provisions of, and the obligations of the parties under, Sections 4(c), 4(d), 5, 6, 8, 9 and 10, and any other provisions that would normally survive, shall survive the termination of this Agreement.</li>
                            </ol>
                        </li>
                        <li>
                            <span style={boldStyle}>Limitation of Liability. </span>
                            <span>IN NO EVENT SHALL COMPANY BE LIABLE TO PARTICIPANT OR ANY THIRD PARTY FOR ANY GENERAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT, DIRECT,SPECIAL,  OR PUNITIVE DAMAGES, ARISING OUT OF OR RELATING TO THE BETA TEST MATERIAL OR THE TRANSACTIONS CONTEMPLATED HEREIN.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Exporting Restrictions; United States Government Legends. </span>
                            <span>Beta Test Material, including Software,provided to Participant may be subject to United States Export Restrictions. Participant agrees not to export or re-export any Beta Test Material or accompanying documentation in violation of any applicable laws and regulations of the United States or the country in which Participant obtained them. The software, firmware or other parts of the Beta Test Material covered by this Agreement may contain strong data encryption code, which cannot be exported outside the United States or Canada. Participant agrees not to export or re-export, either physically or electronically, an encrypted Beta Test Material or accompanying documentation without obtaining written authorization from the U.S. Department of Commerce.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Waiver. </span>
                            <span> A waiver of any default hereunder or of any of the terms and conditions of this Agreement shall not be deemed to be a continuing waiver or a waiver of any other default or of any other term or condition, but shall apply solely to the instance to which such waiver is directed.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Assignment; Severability. </span>
                            <span>Participant agrees not to assign any rights under this Agreement; any attempted assignment shall be null and void and shall result in the termination of this Agreement. If any part of thisAgreement is found by a court of competent jurisdiction to be invalid or unenforceable, such invalidity or un-enforceability shall not affect the validity or enforceability of any other part or provision of this Agreement which shall remain in full force and effect.  With respect to any provision(s) found invalid or unenforceable, such provision(s) shall be enforced to the maximum extent permitted by law with respect to the original intent of the parties.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Governing Law. </span>
                            <span>This Agreement shall be governed by the laws of the State of Iowa, without respect to its conflicts of law provisions. The parties agree that the federal or state courts located in Polk County, Iowa shall have exclusive jurisdiction to hear any dispute related to or arising out of this Agreement.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Entire Agreement. </span>
                            <span>This Agreement and the Exhibits hereto, including any additional terms and conditions listed in Exhibit A for certain Beta Test Material represent the entire agreement between the parties regarding the subject matter hereof and supersede any and all prior agreements between the parties, whether written or oral, regarding the subject matter hereof. This Agreement may not be modified or amended, except as expressly provided herein, unless by the written acceptance of both parties.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Headings. </span>
                            <span>The section headings contained in this Agreement are for reference purposes only and shall not affect the meaning or interpretation of this Agreement.</span>
                        </li>
                        <li>
                            <span style={boldStyle}>Reliance. </span>
                            <span> Participant acknowledges that he/she/it has read this entire Agreement and that he/she/it has been afforded the opportunity to discuss the terms and conditions herein with an attorney of his/her/its choice.Participant further swears and represents that in executing this Agreement, Participant is not relying upon any statement, promise or representation of fact by Company other than what is contained in this Agreement, and that Participant or Participant’s representative has the legal capacity to enter into this Agreement.</span>
                        </li>
                    </ol>
                    <p>IN WITNESS WHEREOF, the Participant agrees to the terms of this Agreement effective as of the date of this acceptance</p>
                </Col>
            </Row>
        </div>;
    }
}
export default BetaUserAgreementPage;
