import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, DatePicker, InputNumber, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import moment, { Moment } from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';

export interface AdminCouponFormDataObject {
    couponId?: string;
    code: string;
    dollarAmount?: number;
    percentAmount?: number;
    startDate: Moment;
    endDate: Moment;
    course: KeyLabel;
}

interface AdminCouponFormProps extends FormComponentProps {
    isSubmitting: boolean;
    courses: SimpleCourseDTO[];
    onSubmit: (data: AdminCouponFormDataObject) => void;
    initialValues?: Partial<AdminCouponFormDataObject>;
}

interface AdminCouponFormState {
    isEdit: boolean;

    // Course Filtering
    filteredCourses: SimpleCourseDTO[];
    courseFilter: string;
}

class AdminCouponForm extends React.Component<AdminCouponFormProps, AdminCouponFormState> {
    // Just need to initialize sub objects
    constructor(props: AdminCouponFormProps) {
        super(props);
        bindAllOfThis(this, AdminCouponForm.prototype);

        let isEdit = props.initialValues != null && props.initialValues.couponId != null;
        let course = props.initialValues != null && props.initialValues.course != null
            ? props.courses.find(x => x.id === props.initialValues.course.key)
            : null;

        let filteredCourses = course != null ? [course] : [];
        let courseFilter = course != null ? course.name : "";

        this.state = {
            isEdit: isEdit,
            filteredCourses: filteredCourses,
            courseFilter: courseFilter,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminCouponFormDataObject): void => {
            if (errors == null) {
                // Validate the start and end date
                let startDate = moment(values.startDate);
                let endDate = moment(values.endDate);

                if (endDate.isBefore(startDate)) {
                    return this.props.form.setFields({
                        [NameOf<AdminCouponFormDataObject>("endDate")]: {
                            value: values.endDate, // Need this, otherwise antd will clear that field
                            errors: [new Error("End Date must be after the Start Date.")] // Must be an array
                        }
                    });
                }

                // Fill values that aren't in the form, but should be passed back
                values.couponId = this.props.initialValues != null ? this.props.initialValues.couponId : null;
                this.props.onSubmit(values);
            }
        });
    }

    handleCourseFilterChange(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let courses = filter.length > 2 ? this.props.courses.filter(x => x.name.toLowerCase().includes(filter)) : [];
        if (filter === "*") {
            courses = this.props.courses;
        }
        this.setState({
            courseFilter: filter,
            filteredCourses: courses,
        });
    }

    renderCourseSelect() {
        if (this.props.courses == null || this.props.courses.length < 1) {
            return <Select placeholder={"Loading courses..."} loading={true}></Select>;
        }
        return <Select
            placeholder="Type 3 or more letters to search courses"
            size="large"
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
            showSearch
            onChange={this.handleCourseFilterChange}
            onSearch={this.handleCourseFilterChange}
        >
            {this.state.filteredCourses.map(course => <Select.Option value={`${course.id}`}>{course.name}</Select.Option>)}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        return <Form onSubmit={this.handleFormSubmit} autoComplete="new-password">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Coupon Code"
                name={NameOf<AdminCouponFormDataObject>("code")}
                initialValue={initialValues.code}
                rules={[{ required: true, message: "Coupon Code is required." }]}
            >
                <Input size="large" placeholder="Coupon Code" maxLength={20} {...disableAutoCompleteTags()} />
            </FieldDecorator>

            {/* <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="License Duration"
                name={NameOf<AdminCouponFormDataObject>("tripleYearLicense")}
                initialValue={false}
                rules={[{ required: false }]}
            >
                <Radio.Group buttonStyle="solid" onChange={this.handleTripleYearLicenseOnChange}>
                    <Radio.Button value={false}>1 Year</Radio.Button>
                    <Radio.Button value={true}>3 Years</Radio.Button>
                </Radio.Group>
            </FieldDecorator> */}

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Percent Off"
                name={NameOf<AdminCouponFormDataObject>("percentAmount")}
                initialValue={initialValues.percentAmount}
                rules={[{ required: true, message: "Percent Amount is required." }]}
            >
                <InputNumber size="large" placeholder="Percent Amount" min={0} max={100} precision={0} {...disableAutoCompleteTags()} style={{ width: '100%' }} />
            </FieldDecorator>
            {/* <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Dollar Off"
                name={NameOf<AdminCouponFormDataObject>("dollarAmount")}
                initialValue={initialValues.code}
                rules={[{ required: true, message: "Dollar Amount is required." }]}
            >
                <Input size="large" placeholder="Dollar Amount" {...disableAutoCompleteTags()} />
            </FieldDecorator> */}

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Start Date"
                name={NameOf<AdminCouponFormDataObject>("startDate")}
                initialValue={initialValues.startDate}
                rules={[{ required: true, message: "Start Date is required." }]}
            >
                <DatePicker size="large" placeholder="Start Date" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="End Date"
                name={NameOf<AdminCouponFormDataObject>("endDate")}
                initialValue={initialValues.endDate}
                rules={[{ required: true, message: "End Date is required." }]}
            >
                <DatePicker size="large" placeholder="End Date" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Course"
                name={NameOf<AdminCouponFormDataObject>("course")}
                initialValue={initialValues.course}
            >
                {this.renderCourseSelect()}
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save Coupon" : "Add Coupon"}
                </Button>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminCouponFormProps>()(AdminCouponForm);
