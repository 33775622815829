import * as React from 'react';
import { Row, Col} from 'antd';

import { ColProps } from 'antd/lib/grid/col';

interface FalseFormItemProps {
    className?: string;
    label?: React.ReactNode;
    labelCol?: ColProps;
    wrapperCol?: ColProps;
    style?: React.CSSProperties;
}

/**
 * This was created to provide similar benefits to a Form.Item without requiring a form
 */
class FalseFormItem extends React.Component<FalseFormItemProps> {
    render() {
        // Can be changed. This project uses legacy, so we need this one
        let prefixCls = "ant-legacy-form-item";
        let { className, label, labelCol, wrapperCol, style, children } = this.props;
        const lableTitle = label != null ? label.toString() : undefined;

        return <Row style={style} className={`${prefixCls} ${className != null ? className : ''}`}>
            <Col {...labelCol}>
                <label title={lableTitle}>{label}</label>
            </Col>
            <Col {...wrapperCol}>
                <div className={`${prefixCls}-control`}>
                    <span className={`${prefixCls}-children`}>
                        {children}
                    </span>
                </div>
            </Col>
        </Row>;
    }
}

export default FalseFormItem;
