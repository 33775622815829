import * as React from "react";
import * as H from 'history';
import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { ConnectedComponent }  from "react-redux";
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Spin } from "antd";
import bindAllOfThis from '../../src/utils/BindThisHelper';
import Routes from "./Routes";
import ReduxStoreModel from "../redux/ReduxModel";
import { UserState } from "../redux/UserReducer";
import { WorkflowState } from "../redux/WorkflowReducer";
import UserAction from "../redux/UserActions";
import ConstantsAction from "../redux/ConstantsActions";
import ActionResultDTO from "../models/common/ActionResultDTO";
import { AccountController } from "../api/AccountController";
import DebugUtil from "../utils/DebugUtil";

// Layouts
import PublicLayout from "../../src/layouts/public/PublicLayout";
import WorkflowLayout from "../../src/layouts/workflow/WorkflowLayout";
import AdminLayout from "../../src/layouts/admin/AdminLayout";
import TeacherLayout from "../layouts/teacher/TeacherLayout";


/**
 * A Slimmed down version of RouteProps
 */
interface LayoutComponentProps {
    // Required Props
    /** The path to this component. Think of it as a page location */
    path: string;
    /** The actual react component to render */
    component: React.SFC<RouteComponentProps<any> | any> | React.ComponentClass<RouteComponentProps<any> | any> | ConnectedComponent<any, any>;
    /** The layout to render the component in */
    layout: typeof PublicLayout | typeof WorkflowLayout | typeof AdminLayout | typeof TeacherLayout;

    // Optional
    /** Forces the page to render only after checking the user context */
    requireLogin?: boolean;

    // Other props not passed it, but are coming from the context somewhere
    /** Defines if the path is exact or 'close enough' */
    exact?: boolean;
    /** Yet another way to get the url. 'Search' contains only the '?foo=bar' raw text from the url */
    location?: H.Location;

    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    LoadConstants: () => Promise<boolean>;
    SoftLogin: () => Promise<ActionResultDTO>;
    SetRedirectUrl: (input: string) => void;
}

interface LayoutComponentState {
    isLoading: boolean;
}

class LayoutComponent extends React.Component<LayoutComponentProps, LayoutComponentState> {
    interval: NodeJS.Timeout = undefined;
    constructor(props: LayoutComponentProps) {
        super(props);
        bindAllOfThis(this, LayoutComponent.prototype);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        // Component is only mounted once and never re-mounted

        // Set the keep-alive interval
        if (this.interval != null) {
            clearInterval(this.interval);
        }
        this.interval = this.runKeepAlive();

        // Get constants first as we HAVE to have those for role and checkpoint info
        return this.props.LoadConstants()
            .then(() => this.props.SoftLogin()) // Get authentication
            .catch(() => { }) // Nom the errors out of existence
            .then(() => this.setState({ isLoading: false })); // .Finally()
    }

    runKeepAlive() {
        if (DebugUtil.isDebugEnabled()) {
            return setInterval(() => {
                // Call keep alive endpoint only if we are logged in
                if (!this.props.User.isLoggedIn) {
                    return;
                }

                AccountController.GetKeepAlive().then(result => {
                    console.warn("(keep alive) it was fun"); // JB - Remove this console.warn
                }).catch(error => {
                    console.warn("(keep alive) IT WAS NOT FUN!"); // JB - Remove this console.warn
                });
            }, 300000); // 5 minute
        }
        return setInterval(() => {
            // Call keep alive endpoint only if we are logged in
            if (!this.props.User.isLoggedIn) {
                return;
            }

            AccountController.GetKeepAlive().catch(error => {
                // Calling the login process should resolve issues, or send them to the login
                this.props.SoftLogin();
            });
        }, 180000); // 3 minutes
    }

    getComponent(routerContext: RouteComponentProps<{}>): JSX.Element {
        // Each route can require login. This is checked here
        let spinner = <Spin key="fancySpinnerOfDoom" className="spinner-centered very-large-spinner" />;
        if (this.state.isLoading) {
            return spinner;
        }

        // Loading is considered finished by now, so errors should direct user to login
        if (this.props.requireLogin) {
            if (this.props.User.state !== "finished") {
                // Redirect them to login, which should know where to put the user
                this.props.SetRedirectUrl(routerContext.location.pathname);
                return <Redirect push to={Routes.LOGIN().toRoute} from={routerContext.location.pathname} />;
            }
        }

        // Render the layout with the component. It is passed the routerContext, which contains some really nice page level information
        return (
            <this.props.layout {...routerContext}>
                <this.props.component {...routerContext} key={window.location.href} />
            </this.props.layout>
        );
    }

    render() {
        return <Route key="routeKey" exact={this.props.exact} path={this.props.path} render={routerContext => this.getComponent(routerContext)} />;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        SetRedirectUrl: (input:string) => UserAction.RedirectUrl(dispatch, input),
        LoadConstants: () => ConstantsAction.LoadConstants(dispatch),
        SoftLogin: () => UserAction.SoftLogin(dispatch),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
