import * as React from 'react';
import { Spin, notification } from 'antd';
import moment from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { CouponController } from '../../api/CouponController';
import AdminCouponForm, { AdminCouponFormDataObject } from './AdminCouponForm';
import ErrorDTO from '../../models/common/ErrorDTO';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';
import { CourseController } from '../../api/CourseController';
import { AxiosResponse } from 'axios';
import { CouponDTO } from '../../models/coupon/couponDTO';

interface AdminCouponPageProps extends PageProps<{ couponId?: string }> {
}

interface AdminCouponPageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    couponId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: AdminCouponFormDataObject;

    // Extra data
    courses: SimpleCourseDTO[];
}

class AdminCouponPage extends React.Component<AdminCouponPageProps, AdminCouponPageState> {
    private notificationKey = "AdminCouponPage";
    constructor(props: AdminCouponPageProps) {
        super(props);
        bindAllOfThis(this, AdminCouponPage.prototype);

        let isEdit = props.match.params.couponId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            couponId: props.match.params.couponId,
            isLoading: true,
            formData: null,
            courses: [],
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AdminCouponFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving Coupon",
            description: "Please wait while we save the coupon...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = this.state.isEdit
            ? CouponController.PutUpdateCoupon({
                couponId: data.couponId,
                code: data.code,
                dollarAmount: data.dollarAmount,
                percentAmount: data.percentAmount,
                startDate: data.startDate.toISOString(),
                endDate: data.endDate.toISOString(),
                courseId: data.course != null ? data.course.key : null
            })
            : CouponController.PostCreateCoupon({
                code: data.code,
                dollarAmount: data.dollarAmount,
                percentAmount: data.percentAmount,
                startDate: data.startDate.toISOString(),
                endDate: data.endDate.toISOString(),
                courseId: data.course != null ? data.course.key : null
            });

        return request
            .then(this.handleOnSubmit_OnSuccess)
            .catch(this.handleOnSubmit_OnFailure);
    }

    handleOnSubmit_OnSuccess(result: AxiosResponse<CouponDTO>) {
        notification.success({
            key: this.notificationKey,
            message: "Saved Coupon",
            description: "Successfully saved the coupon!",
            duration: 5,
            onClick: () => notification.close(this.notificationKey)
        });

        this.setState({ isSubmitting: false });
        this.props.history.push(Routes.ADMIN_COUPONS().toRoute);
    }

    handleOnSubmit_OnFailure(error: any) {
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        notification.error({
            key: this.notificationKey,
            message: "Failed to Save Coupon",
            description: messages.map(x => <div>{x}</div>),
            duration: 10
        });
        this.setState({ isSubmitting: false });
    }

    loadAll() {
        return Promise.all([this.loadFormData(), this.loadCourses()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        // Load the user here
        return CouponController.GetCoupon(this.state.couponId).then(result => {
            let data: AdminCouponFormDataObject = {
                couponId: result.data.couponId,
                code: result.data.code,
                dollarAmount: result.data.dollarAmount,
                percentAmount: result.data.percentAmount,
                startDate: moment(result.data.startDate),
                endDate: moment(result.data.endDate),
                course: result.data.courseId != null ? { key: result.data.courseId, label: result.data.courseName } : null
            };
            this.setState({ formData: data });
        });
    }

    loadCourses() {
        return CourseController.GetCourses().then(results => {
            return this.setState({ courses: results.data });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <h1>{this.state.isEdit ? "Edit" : "Add"} Coupon</h1>
            <AdminCouponForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
                courses={this.state.courses}
            />
        </div>;
    }
}

export default AdminCouponPage;
