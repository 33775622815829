import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Radio, Table, Spin } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import { LessonContentDTO } from '../../models/lessonContent/lessonContentDTO';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';
import IframeGoogleDocs from '../../utils/IframeGoogleDocs';

interface WorkflowLessonPlanPageProps extends PageProps<{ courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowLessonPlanPageState {
    lesson: LessonDTO;
    lessonContent: LessonContentDTO;
    isLoading: boolean;
}

class WorkflowLessonPlanPage extends React.Component<WorkflowLessonPlanPageProps, WorkflowLessonPlanPageState> {
    constructor(props: WorkflowLessonPlanPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowLessonPlanPage.prototype);

        this.state = {
            lesson: null,
            lessonContent: null,
            isLoading: true,
        };
    }

    componentDidMount() {
        let lesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        let lessonContent = (lesson.lessonContents || []).find(x => x.lessonContentTypeId === LessonContentTypes.LessonPlansWithStandards.id);
        this.setState({
            lesson: lesson,
            lessonContent: lessonContent,
            isLoading: false
        });
        this.figureOutOnNext();
    }

    figureOutOnNext() {
        this.props.setButtonState("visible", "visible");
    }

    renderDebugInfo() {
        if (DebugUtil.isDebugEnabled() && this.state.lessonContent != null) {
            return <div>Content Url: {this.state.lessonContent.url}</div>;
        }
        return null;
    }

    renderPDF() {
        if (this.state.lessonContent == null) {
            return <h3>There was an error loading the lesson content</h3>;
        }

        let url = encodeURI(EncodeSpecialCharacters(this.state.lessonContent.url));          
        
        return <IframeGoogleDocs srcUrl={url}></IframeGoogleDocs>;
                
    }

    renderDemoHelperText() {
        if (this.props.Workflow.isDemoMode) {
            return <p className="demo-helper-text">This Lesson Plan is meant to help educators out. It brings all the different sections of the lesson into one place!</p>;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            <h1>Lesson Plan</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderDemoHelperText()}
            <p>The Lesson Plan includes all of the standards that the lesson meets – including American School Counselors Association (ASCA) standards, Health Recourses and Services Administration (HRSA) standards, and Common Core Standards.</p>
            <p>The Lesson Plan also includes the script for the Whiteboard Animation, lyrics to the song, Discussion Questions, Drill Down Activities, and the questions for the School Home Partnership.</p>
            {this.renderDebugInfo()}
            {this.renderPDF()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowLessonPlanPage);
