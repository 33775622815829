import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Checkbox, Modal, InputNumber } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';

export interface AdminQuestionAnswerTableModalDataObject {
    id?: string;
    text: string;
    ordering: number;
    isCorrect: boolean;
}

interface AdminQuestionAnswerTableModalProps extends FormComponentProps {
    isVisible: boolean;
    onSubmit: (data: AdminQuestionAnswerTableModalDataObject) => void;
    onCancel: () => void;
    initialValues?: Partial<AdminQuestionAnswerTableModalDataObject>;
}

interface AdminQuestionAnswerTableModalState {
}


class AdminQuestionAnswerTableModal extends React.Component<AdminQuestionAnswerTableModalProps, AdminQuestionAnswerTableModalState> {
    constructor(props: AdminQuestionAnswerTableModalProps) {
        super(props);
        bindAllOfThis(this, AdminQuestionAnswerTableModal.prototype);

        this.state = {
        };
    }

    onModalOkay() {
        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminQuestionAnswerTableModalDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    onModalCancel() {
        this.props.onCancel();
    }

    renderModalFooter(): JSX.Element[] {
        return [
            <Button key="submit" type="primary" onClick={this.onModalOkay}>Save</Button>,
            <Button key="back" onClick={this.onModalCancel}>Cancel</Button>
        ];
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
        };

        let initialValues = this.props.initialValues || {};
        let isEdit = this.props.initialValues != null && this.props.initialValues.id != null;

        return <Modal
            key="question-answer-modal"
            title={`${isEdit ? "Edit" : "Add"} Question Answer`}
            visible={this.props.isVisible}
            destroyOnClose
            closable
            onCancel={this.onModalCancel}
            onOk={this.onModalOkay}
            okText="Save"
        >
            <Form hideRequiredMark autoComplete="new-password">
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Text"
                    name={NameOf<AdminQuestionAnswerTableModalDataObject>("text")}
                    initialValue={initialValues.text}
                    rules={[{ required: true, message: "Text is required." }]}
                >
                    <Input size="large" placeholder="Text" {...disableAutoCompleteTags()} />
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Order"
                    name={NameOf<AdminQuestionAnswerTableModalDataObject>("ordering")}
                    initialValue={initialValues.ordering}
                    rules={[{ required: true, message: "Order is required." }]}
                >
                    <InputNumber size="large" placeholder="Order" {...disableAutoCompleteTags()} />
                </FieldDecorator>
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="Is Correct"
                    name={NameOf<AdminQuestionAnswerTableModalDataObject>("isCorrect")}
                    initialValue={initialValues.isCorrect}
                    valuePropName="checked" // Needed for checkboxes
                >
                    <Checkbox>Correct Answer</Checkbox>
                </FieldDecorator>
            </Form>
        </Modal>;
    }
}

export default Form.create<AdminQuestionAnswerTableModalProps>()(AdminQuestionAnswerTableModal);
