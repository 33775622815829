import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CreditCardOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';

import { SchoolController } from '../../api/SchoolController';
import { LicenseDTO } from '../../models/license/licenseDTO';
import { SchoolDTO } from '../../models/school/schoolDTO';
import { DocumentaryDTO } from '../../models/documentary/documentaryDTO';
import { NameOf } from '../../utils/NameOf';
import { DocumentaryController } from '../../api/DocumentaryController';
import { Roles } from '../../constants/Roles';

interface TeacherMySchoolProps extends PageProps<{}> {
    // From Redux
    User: UserState;
}

interface TeacherMySchoolState {
    isLoading: boolean;
    tableData: LicenseDTO[];
    documentaryTableData: DocumentaryDTO[];
    school: SchoolDTO;
}

class TeacherMySchool extends React.Component<TeacherMySchoolProps, TeacherMySchoolState> {
    tableColumns: ColumnProps<LicenseDTO>[];
    documentaryTableColumns: ColumnProps<DocumentaryDTO>[];

    constructor(props: TeacherMySchoolProps) {
        super(props);
        bindAllOfThis(this, TeacherMySchool.prototype);

        this.tableColumns = [{
            title: 'View Course',
            key: 'view',
            align: 'center',
            width: 60,
            render: (text, record) => {
                return (
                    <Tooltip title="View Course" placement="top">
                        <Link to={Routes.WORKFLOW_COURSE_HOME(record.courseId).toRoute}>
                            <Button shape="circle" icon={<EyeOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Course Name',
            dataIndex: 'courseDocumentaryName',
            sorter: (a, b) => a.courseDocumentaryName.localeCompare(b.courseDocumentaryName),
            ...getColumnSearchProps<LicenseDTO>("courseDocumentaryName", "Course Name"),
        }, {
            title: 'Number of Students',
            dataIndex: 'licenseCount',
            width: 150,
            sorter: (a, b) => a.licenseCount.toString().localeCompare(b.licenseCount.toString()),
            ...getColumnSearchProps<LicenseDTO>("licenseCount", "Seat Count"),
        }, {
            title: 'Claimed Seats',
            dataIndex: 'claimedLicenseCount',
            width: 150,
            sorter: (a, b) => a.claimedLicenseCount.toString().localeCompare(b.claimedLicenseCount.toString()),
            ...getColumnSearchProps<LicenseDTO>("claimedLicenseCount", "Claimed Seats"),
        }, {
            title: 'Start Date',
            key: 'startDate',
            width: 120,
            sorter: (a, b) => a.startDate.localeCompare(b.startDate),
            render: (text: any, record: LicenseDTO, index: number) => (
                <div>
                    {moment(record.startDate).format("DD-MMM-YYYY")}
                </div>
            )
        }, {
            title: 'End Date',
            key: 'endDate',
            width: 120,
            sorter: (a, b) => a.endDate.localeCompare(b.endDate),
            render: (text: any, record: LicenseDTO, index: number) => (
                <div>
                    {moment(record.endDate).format("DD-MMM-YYYY")}
                </div>
            )
        }, {
            title: 'Edit',
            key: 'edit',
            align: 'center',
            width: 60,
            render: (text, record) => {
                return (
                    <Tooltip title="Edit License" placement="top">
                        <Link to={Routes.TEACHER_ADD_EDIT_MY_SCHOOL(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }];

        this.documentaryTableColumns = [{
            title: 'View Documentary',
            key: 'view',
            align: 'center',
            width: 100,
            render: (text, record) => {
                return (
                    <Tooltip title="View Documentary" placement="top">
                        <Link to={Routes.DOCUMENTARY_HOME(record.id).toRoute}>
                            <Button shape="circle" icon={<EyeOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Documentary Name',
            dataIndex: NameOf<DocumentaryDTO>("name"),
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<DocumentaryDTO>("name", "Documentary Name"),
        }];

        this.state = {
            isLoading: true,
            tableData: [],
            documentaryTableData: [],
            school: null
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    private async loadAll() {
        this.setState({ isLoading: true });
        await Promise.all([this.loadLicenseData(), this.loadDocumentaryData(), this.loadSchool()]);
        this.setState({ isLoading: false });
    }

    private async loadLicenseData() {
        if (this.props.User.schoolId == null) {
            // Sanity checking
            return Promise.resolve();
        }

        let result = await SchoolController.GetLicensesBySchool(this.props.User.schoolId);

        this.setState({ tableData: result.data.filter(x => x.documentaryId == null) });
        return Promise.resolve();
    }

    private async loadDocumentaryData() {
        if (this.props.User.schoolId == null) {
            // Sanity checking
            return Promise.resolve();
        }

        let result = await DocumentaryController.GetDocumentariesForSchool(this.props.User.schoolId);

        this.setState({ documentaryTableData: result.data });
        return Promise.resolve();
    }

    private async loadSchool() {
        if (this.props.User.schoolId == null) {
            // Sanity checking
            return Promise.resolve();
        }
        let result = await SchoolController.GetSchool(this.props.User.schoolId);

        this.setState({school: result.data});
        return Promise.resolve();
    }

    render() {
        // Dev Note: Yes, this is a weird redirect. However, something is messed up when logging in after a teacher, even when refreshing the page
        // So, yeah, punt them back to the admin section
        if (this.props.User.role === Roles.Admin) {
            return <Redirect to={Routes.ADMIN_DASHBOARD().toRoute} />;
        }

        if (this.props.User.schoolId == null) {
            return <Redirect to={Routes.TEACHER_SELECT_MY_SCHOOL().toRoute} />;
        }

        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return (
            <div>
                <h1>My School &amp; Courses</h1>
                <p>Here, if your school has purchased courses, you’ll find the courses that have been purchased by your school. You may choose to purchase additional courses for your school by clicking “Purchase License.” </p>

                <p>Your school’s join code allows other faculty to add themselves to the school so they are able to access the courses purchased by the school, instead of having to purchase the course again. </p>

                <p>Additional students can be purchased for each course by clicking the “Edit” button for the desired course.</p>
                <br /><hr />
                <h2>{this.state.school.name} - {this.state.school.districtName}</h2>
                <h3>Join Code: {this.state.school.id.substring(0, 7)}</h3>
                <hr /><br />

                <h1>Licenses</h1>
                <Link key="table-link" to={Routes.PRICING().toRoute}>
                    <Button icon={<CreditCardOutlined />}>Purchase License</Button>
                </Link>
                <br /><br />
                <Table
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                    rowKey={record => record.id}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                />
                {this.state.documentaryTableData.length > 0 &&
                    <React.Fragment>
                        <h1>Documentaries</h1>
                        <Table
                            size="middle"
                            columns={this.documentaryTableColumns}
                            dataSource={this.state.documentaryTableData}
                            rowKey={record => record.id}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                hideOnSinglePage: true,
                                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                            }}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherMySchool);
