import axios from 'axios';
import { ClassroomOverviewDTO } from '../models/report/classroomOverviewDTO';

export class ReportController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetClassroomOverviewRoute = (classroomId: string, licenseId: string, lessonId: string) => `reports/classrooms/${classroomId}/licenses/${licenseId}/lessons/${lessonId}/overview`;
    public static GetClassroomOverview(classroomId: string, licenseId: string, lessonId: string) {
        return axios.get<ClassroomOverviewDTO>(this.GetClassroomOverviewRoute(classroomId, licenseId, lessonId));
    }

    public static DownloadLessonClassroomRatingsRoute = () => `reports/lessonClassroomRatings`;
    public static DownloadLessonClassroomRatings() {
        return axios.get<any>(this.DownloadLessonClassroomRatingsRoute(), {responseType: 'blob'});
    }

    public static DownloadLicensesRoute = () => `reports/licenses`;
    public static DownloadLicenses() {
        return axios.get<any>(this.DownloadLicensesRoute(), {responseType: 'blob'});
    }

    public static DownloadTeachersRoute = () => `reports/teachers`;
    public static DownloadTeachers() {
        return axios.get<any>(this.DownloadTeachersRoute(), {responseType: 'blob'});
    }

    public static DownloadDiscussionQuestionResponsesRoute = (classroomId: string, licenseId: string, lessonId: string) => `reports/classrooms/${classroomId}/licenses/${licenseId}/lessons/${lessonId}/discussionQuestionResponses`;
    public static DownloadDiscussionQuestionResponses(classroomId: string, licenseId: string, lessonId: string) {
        return axios.get<any>(this.DownloadDiscussionQuestionResponsesRoute(classroomId, licenseId, lessonId), { responseType: 'blob' });
    }

    public static DownloadGuardianSurveyResponsesRoute = (classroomId: string, licenseId: string, lessonId: string) => `reports/classrooms/${classroomId}/licenses/${licenseId}/lessons/${lessonId}/guardianSurveyResponses`;
    public static DownloadGuardianSurveyResponses(classroomId: string, licenseId: string, lessonId: string) {
        return axios.get<any>(this.DownloadGuardianSurveyResponsesRoute(classroomId, licenseId, lessonId), {responseType: 'blob'});
    }

    public static DownloadTeacherSurveyResponsesRoute = (lessonId: string, sinceDate: string) => `reports/lessons/${lessonId}/${sinceDate}/teacherSurveyResponses`;
    public static DownloadTeacherSurveyResponses(lessonId: string, sinceDate: string) {
        return axios.get<any>(this.DownloadTeacherSurveyResponsesRoute(lessonId, sinceDate), {responseType: 'blob'});
    }

    public static DownloadSchoolOverviewRoute = (courseId: string, schoolYearId: string) => `/reports/courses/${courseId}/schoolYears/${schoolYearId}/schoolOverview`;
    public static DownloadSchoolOverview(courseId: string, schoolYearId: string) {
        return axios.get<any>(this.DownloadSchoolOverviewRoute(courseId, schoolYearId), {responseType: 'blob'});
    }

    public static DownloadStudentAssessmentResultsRoute = (classroomId: string, licenseId: string, lessonId: string) => `reports/classrooms/${classroomId}/licenses/${licenseId}/lessons/${lessonId}/studentAssessmentResults`;
    public static DownloadStudentAssessmentResults(classroomId: string, licenseId: string, lessonId: string) {
        return axios.get<any>(this.DownloadStudentAssessmentResultsRoute(classroomId, licenseId, lessonId), { responseType: 'blob' });
    }

    public static DownloadSelfAssessmentAvgReportRoute = (schoolYearId: string) => `reports/schoolYears/${schoolYearId}/SelfAssessmentAvgReport`;
    public static DownloadSelfAssessmentAvgReport(schoolYearId: string) {
        return axios.get<any>(this.DownloadSelfAssessmentAvgReportRoute(schoolYearId), { responseType: 'blob' });
    }

    public static DownloadStudentsReachedReportRoute = (schoolYearId: string) => `/reports/schoolYears/${schoolYearId}/StudentsReachedReport`;
    public static DownloadStudentsReachedReport(schoolYearId: string) {
        return axios.get<any>(this.DownloadStudentsReachedReportRoute(schoolYearId), { responseType: 'blob' });
    }

    public static DownloadOverallEffectivenessReportRoute = (schoolYearId: string, courseId: string) => `/reports/schoolYears/${schoolYearId}/courses/${courseId}/OverallEffectivenessReport`;
    public static DownloadOverallEffectivenessReport(schoolYearId: string, courseId: string) {
        return axios.get<any>(this.DownloadOverallEffectivenessReportRoute(schoolYearId, courseId), { responseType: 'blob' });
    }
}
