import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip, Button, notification, Modal, Input, Checkbox } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import ErrorDTO from '../../models/common/ErrorDTO';
import { QuestionAnswerController } from '../../api/QuestionAnswerController';
import { UpdateQuestionAnswerDTO } from '../../models/questionAnswer/updateQuestionAnswerDTO';
import { CreateQuestionAnswerDTO } from '../../models/questionAnswer/createQuestionAnswerDTO';

export interface AdminQuestionAnswerTableDataObject {
    id: string;
    text: string;
    ordering: number;
    isCorrect: boolean;
}

interface AdminQuestionAnswerTableProps {
    tableData: AdminQuestionAnswerTableDataObject[];
    isLoading: boolean;
    isSubmitting: boolean;
    questionId: string;
    onAnswerEdit: (data: AdminQuestionAnswerTableDataObject) => void;
    onAnswerUpdate: (record: AdminQuestionAnswerTableDataObject) => void;
}

interface AdminQuestionAnswerTableState {
}

class AdminQuestionAnswerTable extends React.Component<AdminQuestionAnswerTableProps, AdminQuestionAnswerTableState> {
    private notificationKey = "AdminQuestionAnswerTable";
    private tableColumns: ColumnProps<AdminQuestionAnswerTableDataObject>[];
    constructor(props: AdminQuestionAnswerTableProps) {
        super(props);
        bindAllOfThis(this, AdminQuestionAnswerTable.prototype);

        this.tableColumns = [{
            title: 'Answer Text',
            dataIndex: 'text',
            key: 'text',
            sorter: (a, b) => a.text.localeCompare(b.text),
            ...getColumnSearchProps<AdminQuestionAnswerTableDataObject>("text", "Text"),
        }, {
            title: 'Is Correct',
            dataIndex: 'isCorrect',
            key: 'isCorrect',
            ...getColumnSearchProps<AdminQuestionAnswerTableDataObject>("isCorrect", "Is Correct?"),
            render: (text, record) => record.isCorrect ? "Yes" : null
        }, {
            title: 'Order',
            dataIndex: 'ordering',
            defaultSortOrder: 'ascend',
            key: 'ordering',
            width: 80,
            sorter: (a, b) => a.ordering - b.ordering,
        }, {
            title: 'Change Order',
            key: 'swap',
            width: 120,
            render: (text, record) => {
                let up = <Button key="swap-up" shape="circle" icon={<UpOutlined />} onClick={() => this.handleMoveUp(record)} disabled={this.props.isSubmitting} />;
                let down = <Button key="swap-down" shape="circle" icon={<DownOutlined />} onClick={() => this.handleMoveDown(record)} disabled={this.props.isSubmitting} />;
                let empty = <span key="swap-empty" style={{ width: "32px", display: "inline-block" }}>&nbsp;</span>;
                if (record.ordering === 1) {
                    return [empty, down];
                }
                return [up, down];
            }
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit Answer" placement="top">
                        <Button shape="circle" icon={<EditOutlined />} onClick={() => this.props.onAnswerEdit(record)} disabled={this.props.isSubmitting} />
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Delete Answer" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleOnRemove(record)} disabled={this.props.isSubmitting} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
        };
    }

    handleMoveUp(record: AdminQuestionAnswerTableDataObject) {
        this.props.onAnswerUpdate({
            ...record,
            ordering: record.ordering - 1
        });
    }

    handleMoveDown(record: AdminQuestionAnswerTableDataObject) {
        this.props.onAnswerUpdate({
            ...record,
            ordering: record.ordering + 1
        });
    }

    handleOnRemove(record: AdminQuestionAnswerTableDataObject) {
        // Ask if they are sure
        Modal.confirm({
            title: "Are you sure?",
            content: `Are you sure you want to delete this answer? "${record.text}"`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                return QuestionAnswerController.DeleteQuestionAnswer(record.id).then(result => {
                    notification.success({
                        key: this.notificationKey,
                        message: "Answer Removal",
                        description: `The Question Answer "${record.text}" has been removed`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                    this.props.onAnswerUpdate(null);
                }).catch(error => {
                    let messages = error != null && error.response != null && error.response.data.messages != null
                        ? (error.response.data as ErrorDTO).messages
                        : ["Critical Error"];
                    notification.error({
                        key: this.notificationKey,
                        message: "Answer Removal",
                        description: ["There was an issue removing the Question Answer.", ...messages].map(x => <div>{x}</div>),
                        duration: 10
                    });

                    this.props.onAnswerUpdate(null);
                });
            }
        });
    }

    render() {
        return <div>
            <Table
                loading={this.props.isLoading}
                rowKey={record => record.id}
                size="small"
                columns={this.tableColumns}
                dataSource={this.props.tableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default AdminQuestionAnswerTable;
