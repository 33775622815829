import * as React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Table, Button, Tooltip } from 'antd';
import { ColumnProps,  TablePaginationConfig } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import { StudentDTO } from '../../models/student/studentDTO';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface TeacherMyClassroomStudentsProps  {
    isLoading?: boolean;
    tableData: StudentDTO[];
    size: SizeType;
    showPasscode: boolean;
    selectedRowKeys: React.ReactText[];
    handleSelectionChange?: (selectedRowKeys: React.ReactText[], selectedRows: StudentDTO[]) => void;
    handleChangePasscode?: (studentId: string) => void;
    pagination?: TablePaginationConfig;
}

interface TeacherMyClassroomStudentsState {
}

class TeacherMyClassroomStudents extends React.Component<TeacherMyClassroomStudentsProps, TeacherMyClassroomStudentsState> {
    tableColumns: ColumnProps<StudentDTO>[];

    constructor(props: TeacherMyClassroomStudentsProps) {
        super(props);
        bindAllOfThis(this, TeacherMyClassroomStudents.prototype);

        let passcodeColumn = this.props.showPasscode ? {
            title: 'Passcode',
            dataIndex: 'passcode',
            sorter: (a: any, b: any) => a.passcode.localeCompare(b.passcode),
            ...getColumnSearchProps<StudentDTO>('passcode', 'Passcode')
        } : {};

        let commandColumn: ColumnProps<StudentDTO> = this.props.showPasscode ? {
            title: 'Change Passcode',
            key: 'changePasscode',
            width: 100,
            align: 'center',
            render: (text: string, record: StudentDTO) => {
                return (
                    <Tooltip title="Change Passcode" placement="top">
                        <Button shape="circle" icon={<ReloadOutlined />} onClick={() => this.props.handleChangePasscode(record.id)}/>
                    </Tooltip>
                );
            }
        } : {};

        this.tableColumns = [{
            title: 'Username',
            dataIndex: 'lastName',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            ...getColumnSearchProps<StudentDTO>("lastName", "Username")
        }, {
            title: 'Nickname',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            ...getColumnSearchProps<StudentDTO>("firstName", "Nickname")
        }, passcodeColumn, commandColumn];
    }

    render() {
        return  <Table
            size={this.props.size}
            loading={this.props.isLoading}
            columns={this.tableColumns}
            dataSource={this.props.tableData}
            rowKey={record => record.id}
            rowSelection={{
                selectedRowKeys: this.props.selectedRowKeys,
                onChange: this.props.handleSelectionChange
            }}
            pagination={this.props.pagination}
        />;
    }
}

export default TeacherMyClassroomStudents;
