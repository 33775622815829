export interface SchoolYear {
    id: string;
    startYear: number;
    endYear: number;
    order: number;
}

export class SchoolYears {
    public static readonly SchoolYear2018: SchoolYear = { id: "0", startYear: 2018, endYear: 2019, order: 1 };
    public static readonly SchoolYear2019: SchoolYear = { id: "1", startYear: 2019, endYear: 2020, order: 2 };
    public static readonly SchoolYear2020: SchoolYear = { id: "2", startYear: 2020, endYear: 2021, order: 3 };
    public static readonly SchoolYear2021: SchoolYear = { id: "3", startYear: 2021, endYear: 2022, order: 4 };
    public static readonly SchoolYear2022: SchoolYear = { id: "4", startYear: 2022, endYear: 2023, order: 5 };
    public static readonly SchoolYear2023: SchoolYear = { id: "5", startYear: 2023, endYear: 2024, order: 6 };
    public static readonly SchoolYear2024: SchoolYear = { id: "6", startYear: 2024, endYear: 2025, order: 7 };
    public static readonly SchoolYear2025: SchoolYear = { id: "7", startYear: 2025, endYear: 2026, order: 8 };
    public static readonly SchoolYear2026: SchoolYear = { id: "8", startYear: 2026, endYear: 2027, order: 9 };
    public static readonly SchoolYear2027: SchoolYear = { id: "9", startYear: 2027, endYear: 2028, order: 10 };
    public static readonly SchoolYear2028: SchoolYear = { id: "10", startYear: 2028, endYear: 2029, order: 11 };
    public static readonly SchoolYear2029: SchoolYear = { id: "11", startYear: 2029, endYear: 2030, order: 12 };
    public static readonly SchoolYear2030: SchoolYear = { id: "12", startYear: 2030, endYear: 2031, order: 13 };
    public static readonly SchoolYear2031: SchoolYear = { id: "13", startYear: 2031, endYear: 2032, order: 14 };
    public static readonly SchoolYear2032: SchoolYear = { id: "14", startYear: 2032, endYear: 2033, order: 15 };
    public static readonly SchoolYear2033: SchoolYear = { id: "15", startYear: 2033, endYear: 2034, order: 16 };
    public static readonly SchoolYear2034: SchoolYear = { id: "16", startYear: 2034, endYear: 2035, order: 17 };

    public static All: SchoolYear[] = [
        SchoolYears.SchoolYear2018,
        SchoolYears.SchoolYear2019,
        SchoolYears.SchoolYear2020,
        SchoolYears.SchoolYear2021,
        SchoolYears.SchoolYear2022,
        SchoolYears.SchoolYear2023,
        SchoolYears.SchoolYear2024,
        SchoolYears.SchoolYear2025,
        SchoolYears.SchoolYear2026,
        SchoolYears.SchoolYear2027,
        SchoolYears.SchoolYear2028,
        SchoolYears.SchoolYear2029,
        SchoolYears.SchoolYear2030,
        SchoolYears.SchoolYear2031,
        SchoolYears.SchoolYear2032,
        SchoolYears.SchoolYear2033,
        SchoolYears.SchoolYear2034,
    ];

    public static FindById(id: string) {
        return this.All.find(x => x.id === id);
    }
}
