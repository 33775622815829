import * as React from 'react';
import { connect } from 'react-redux';
import { UserState } from '../redux/UserReducer';
import ReduxStoreModel from '../redux/ReduxModel';
import { Spin, notification } from 'antd';
import { Elements } from 'react-stripe-elements';
import ShoppingCartForm, { ShoppingCartFormDataObject } from './ShoppingCartForm';
import { ConstantsDTO } from '../models/constant/constantsDTO';
import { CouponDTO } from '../models/coupon/couponDTO';
import ShoppingCartItem from './ShoppingCartItem';
import { PricingTier, PricingTiers } from '../constants/PricingTiers';
import { ConstantController } from '../api/ConstantController';
import { ShoppingCartController } from '../api/ShoppingCartController';
import { CouponController } from '../api/CouponController';
import { ShoppingCartItemDTO } from '../models/shoppingCart/shoppingCartItemDTO';
import bindAllOfThis from '../utils/BindThisHelper';
import { PurchaseShoppingCartItemsDTO } from '../models/shoppingCart/purchaseShoppingCartItemsDTO';
import ErrorDTO from '../models/common/ErrorDTO';
import { PageProps } from '../models/common/ComponentProps';
import Routes from '../core/Routes';

interface ShoppingCartProps extends PageProps {
  // From Redux
  User: UserState;
}

export interface ShoppingCartItemInfo {
  additionalLicenseCount: number;
  totalLicenseCostInCents: number;
  baseLicenseCostInCents: number;
  licenseCountCostInCents: number;
  discountInCents: number;
  paymentAmountInCents: number;
  licenseTier: PricingTier;
  shoppingCartItems: ShoppingCartItemDTO;
}

interface ShoppingCartState {
  total: number;
  constants: ConstantsDTO;
  stripeErrorMessage: string;
  isLoading: boolean;
  shoppingCartItems: ShoppingCartItemDTO[];
  shoppingCartItemInfoArray: ShoppingCartItemInfo[];
  couponCode: string;
  coupon: CouponDTO;
  couponErrorMessage: string;
  isSubmitting: boolean;
}

class ShoppingCart extends React.Component<ShoppingCartProps, ShoppingCartState> {
  private notificationKey = "ShoppingCartPage";
  constructor(props: ShoppingCartProps) {
      super(props);
      bindAllOfThis(this, ShoppingCart.prototype);

      this.state = {
        total: 0,
        constants: null,
        couponCode: null,
        coupon: null,
        couponErrorMessage: null,
        stripeErrorMessage: null,
        isLoading: true,
        shoppingCartItems: null,
        shoppingCartItemInfoArray: null,
        isSubmitting: false
      };
  }

  componentDidMount() {
    this.loadAll();
  }

  loadAll() {
    this.setState({ isLoading: true });
    return Promise.all([this.loadShoppingCarts(), this.loadConstants()]).then(x => {
      this.loadShoppingCartItemInfo();
      this.setState({ isLoading: false });
    });
  }

  loadShoppingCarts() {
    // Load the simple course list here
    return ShoppingCartController.GetShoppingCartItems(this.props.User.id).then(result => {
      this.setState({ shoppingCartItems: result.data });
    });
  }

  loadConstants() {
    return ConstantController.GetConstants().then(constantsResult => {
        this.setState({ constants: constantsResult.data });
    });
  }

  loadShoppingCartItemInfo() {
    let shoppingCartItemInfoArray: ShoppingCartItemInfo[] = [];
    let shoppingCartItemDTOArray: ShoppingCartItemDTO[] = [];
    let shoppingCartItems = [...this.state.shoppingCartItems];

    shoppingCartItems.forEach(value => {
        let shoppingCartItemInfo = this.calculateTotalCost(value);
        value.paymentAmountInCents = shoppingCartItemInfo.paymentAmountInCents;

        shoppingCartItemDTOArray.push(value);
        shoppingCartItemInfoArray.push(shoppingCartItemInfo);
    });

    this.setState({ shoppingCartItemInfoArray: shoppingCartItemInfoArray, shoppingCartItems: shoppingCartItemDTOArray});
  }

  handleDeleteShoppingCartItem(shoppingCartItemId: string) {
    notification.info({
        key: this.notificationKey,
        message: "Deleting Item",
        description: "Please wait while we delete the item...",
        duration: 0
    });

    return ShoppingCartController.DeleteShoppingCartItem(shoppingCartItemId).then(result => {
        notification.success({
            key: this.notificationKey,
            message: "Deleted Item",
            description: "Successfully deleted the item!",
            duration: 5,
            onClick: () => notification.close(this.notificationKey)
        });
        this.loadAll();
    }).catch(error => {
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        notification.error({
            key: this.notificationKey,
            message: "Failed to Delete Item",
            description: messages.map(x => <div>{x}</div>),
            duration: 10
        });
    });
  }

  handleApplyCodeOnClick(couponCode: string) {
    CouponController.GetCouponByCode(couponCode).then(result => {
        this.setState({ coupon: result.data }, this.validateCoupon);
    }).catch(error => {
        this.setState({ coupon: null, couponErrorMessage: "Coupon not found." });
        notification.error({
          key: this.notificationKey,
          message: "Coupon not found.",
          description: "",
          duration: 10
      });
    });
  }

  handleOnSubmit(data: ShoppingCartFormDataObject, stripeToken: string) {
    notification.info({
        key: this.notificationKey,
        message: "Purchasing Licenses",
        description: "Please wait while we finalize your purchase...",
        duration: 0
    });

    this.setState({ isSubmitting: true });
    let request = this.handleOnSubmitPurchase(data, stripeToken);
    return request.then(result => {
        notification.success({
            key: this.notificationKey,
            message: "Purchase Complete",
            description: "Successfully completed the purchase!",
            duration: 5,
            onClick: () => notification.close(this.notificationKey)
        });

        this.setState({ isSubmitting: false });
        this.props.history.push(Routes.GET.HOME);
        this.loadAll();
    }).catch(error => {
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        notification.error({
            key: this.notificationKey,
            message: "Failed to Complete the Purchase",
            description: messages.map(x => <div>{x}</div>),
            duration: 10
        });

        this.setState({ isSubmitting: false });
    });
  }

  handleOnSubmitPurchase(data: ShoppingCartFormDataObject, stripeToken: string) {
    let request: PurchaseShoppingCartItemsDTO = {
        stripeToken: stripeToken,
        paymentAmountInCents: data.paymentAmountInCents,
        couponId: this.state.coupon != null ? this.state.coupon.couponId : null,
        shoppingCartItemDTOs: this.state.shoppingCartItems
    };

    return ShoppingCartController.PostPurchaseShoppingCartItems(request);
  }

  validateCoupon(): void {
    if (this.state.coupon != null) {

      if (!this.state.coupon.active) {
          this.setState({ couponErrorMessage: "Coupon is not active." }, this.loadShoppingCartItemInfo);
          notification.error({
            key: this.notificationKey,
            message: "Coupon is not active.",
            description: "",
            duration: 10
        });
      }
      else {
          this.setState({ couponErrorMessage: null }, this.loadShoppingCartItemInfo);
      }

    } else {
        this.loadShoppingCartItemInfo();
    }
  }

  calculateBaseCost(licenseTier: PricingTier, isTripleYearLicense: boolean): number {
    if (licenseTier != null) {
        let baseCost = isTripleYearLicense ?
            licenseTier.threeYearCost :
            licenseTier.oneYearCost;
        return baseCost;
    }
    return null;
  }

  getLicenseTier(licenseCount: number) {
    let licenseTier = null;

    if (licenseCount <= 5) {
      licenseTier = PricingTiers.One;
    }
    else if (licenseCount <= 10) {
        licenseTier = PricingTiers.Two;
    }
    else if (licenseCount <= 15) {
        licenseTier = PricingTiers.Three;
    }
    else if (licenseCount > 15) {
        licenseTier = PricingTiers.Four;
    }

    return licenseTier;
  }

  calculateTotalCost(item: ShoppingCartItemDTO): ShoppingCartItemInfo {
    let perStudentLicenseCostInCents = this.state.constants.studentLicenseCostInCents;
    let licenseTier = PricingTiers.FindById(item.pricingTierId.toString());
    let possibleAdditionalSeats = item.licenseCount - licenseTier.numberOfSeats;
    let additionalLicenseCount = possibleAdditionalSeats > 0 ? possibleAdditionalSeats : 0;

    // Special condition for the documentary
    if(item.documentaryId != null) {
      let totalCostInCents = 500;

      return {
        additionalLicenseCount: 0,
        totalLicenseCostInCents: 0,
        baseLicenseCostInCents: totalCostInCents,
        licenseCountCostInCents: 0,
        discountInCents: null,
        paymentAmountInCents: totalCostInCents,
        licenseTier: licenseTier,
        shoppingCartItems: item
      };
    }

    let shoppingCartItemState: ShoppingCartItemInfo = {
      additionalLicenseCount: additionalLicenseCount,
      totalLicenseCostInCents: null,
      baseLicenseCostInCents: null,
      licenseCountCostInCents: 0,
      discountInCents: null,
      paymentAmountInCents: null,
      licenseTier: licenseTier,
      shoppingCartItems: item
    };

    if (item.licenseId != null) {
        // Updating Existing License
        let newSeats: number = 0;
        let isThreeYear = item.isTripleYearLicense;

        let paymentAmountInCents: number = 0;
        if (item.licenseDTO.licenseCount  <= 5) {
            if (licenseTier === PricingTiers.Two || licenseTier === PricingTiers.Three) {
                newSeats = licenseTier.numberOfSeats - item.licenseDTO.licenseCount;
                paymentAmountInCents = isThreeYear ?
                    (licenseTier.threeYearCost - PricingTiers.One.threeYearCost) * 3 :
                    (licenseTier.oneYearCost - PricingTiers.One.oneYearCost);
            }
            if (licenseTier === PricingTiers.Four) {
                newSeats = 15 + additionalLicenseCount - item.licenseDTO.licenseCount;
                paymentAmountInCents = isThreeYear ?
                    ((licenseTier.threeYearCost - PricingTiers.One.threeYearCost) + (additionalLicenseCount * perStudentLicenseCostInCents)) * 3 :
                    (licenseTier.oneYearCost - PricingTiers.One.oneYearCost) + (additionalLicenseCount * perStudentLicenseCostInCents);
            }
        }
        else if (item.licenseDTO.licenseCount <= 10) {
            if (licenseTier === PricingTiers.Three) {
                newSeats = 15 - item.licenseDTO.licenseCount;
                paymentAmountInCents = isThreeYear ?
                    (licenseTier.threeYearCost - PricingTiers.Two.threeYearCost) * 3 :
                    (licenseTier.oneYearCost - PricingTiers.Two.oneYearCost);
            }
            if (licenseTier === PricingTiers.Four) {
                newSeats = 15 + additionalLicenseCount - item.licenseDTO.licenseCount;
                paymentAmountInCents = isThreeYear ?
                    ((licenseTier.threeYearCost - PricingTiers.Two.threeYearCost) + (additionalLicenseCount * perStudentLicenseCostInCents)) * 3 :
                    (licenseTier.oneYearCost - PricingTiers.Two.oneYearCost) + (additionalLicenseCount * perStudentLicenseCostInCents);
            }
        }
        else if (item.licenseDTO.licenseCount <= 15) {
            if (licenseTier === PricingTiers.Four) {
                newSeats = 15 + additionalLicenseCount - item.licenseDTO.licenseCount;
                paymentAmountInCents = isThreeYear ?
                    additionalLicenseCount * perStudentLicenseCostInCents * 3 :
                    additionalLicenseCount * perStudentLicenseCostInCents;
            }
        }
        else if (item.licenseDTO.licenseCount > 15) {
            if (licenseTier === PricingTiers.Four) {
                newSeats = additionalLicenseCount;

                paymentAmountInCents = isThreeYear ?
                    newSeats * perStudentLicenseCostInCents * 3 :
                    newSeats * perStudentLicenseCostInCents;
            }
            if (newSeats < 0) {
                newSeats = 0;
            }
        }

        let newLicenseCount = 0;
        if (licenseTier != null) {
            newLicenseCount = licenseTier === PricingTiers.Four ?
                15 + additionalLicenseCount :
                licenseTier.numberOfSeats;
        }

        shoppingCartItemState.paymentAmountInCents = paymentAmountInCents;

        return shoppingCartItemState;
    } else {
        // New License

        let baseLicenseCostInCents: number = this.calculateBaseCost(licenseTier, item.isTripleYearLicense);
        let totalCostInCents: number = item.isTripleYearLicense ?
            baseLicenseCostInCents * 3 :
            baseLicenseCostInCents;

        let licenseCountCostInCents = 0;

        if (licenseTier === PricingTiers.Four) {
            licenseCountCostInCents = item.isTripleYearLicense ?
                additionalLicenseCount * perStudentLicenseCostInCents * 3 :
                additionalLicenseCount * perStudentLicenseCostInCents;
        }
        let discountInCents = null;
        if (this.state.coupon != null && this.state.couponErrorMessage == null && !(item.courseId != null && this.state.coupon.courseId != null && this.state.coupon.courseId !== item.courseId)) {
            let dollarAmount = this.state.coupon.dollarAmount;
            let percentAmount = this.state.coupon.percentAmount;

            discountInCents = dollarAmount != null ?
                dollarAmount * 100 : totalCostInCents * (percentAmount / 100.0);
        }


        let paymentAmountInCents: number = totalCostInCents + licenseCountCostInCents - discountInCents;
        shoppingCartItemState = {
          additionalLicenseCount: additionalLicenseCount,
          totalLicenseCostInCents: totalCostInCents,
          baseLicenseCostInCents: baseLicenseCostInCents,
          licenseCountCostInCents: licenseCountCostInCents,
          discountInCents: discountInCents,
          paymentAmountInCents: paymentAmountInCents,
          licenseTier: licenseTier,
          shoppingCartItems: item
        };

        return shoppingCartItemState;
    }
  }

  getTotal(item : ShoppingCartItemInfo[]) {
    const total = item.reduce((totalCost, item) => totalCost + item.paymentAmountInCents, 0);
    return total;
  }

  render() {
    if (this.state.isLoading) {
      return <Spin className="spinner-centered very-large-spinner" />;
    }

    return <div>
        <h1>Cart</h1>
        <div>
          {this.state.shoppingCartItemInfoArray.map(item => (
            <ShoppingCartItem
                key={item.shoppingCartItems.id}
                initialValues={item}
                constants={this.state.constants}
                coupon={this.state.coupon}
                couponErrorMessage={this.state.couponErrorMessage}
                onDelete={this.handleDeleteShoppingCartItem}
            />
          ))}
          {!this.state.shoppingCartItems.length &&
            <div style={{ marginTop: 15, marginBottom: 25}}>
              <h2>Cart is empty!</h2>
            </div>
          }
        </div>
        <Elements>
          <ShoppingCartForm
              total={this.getTotal(this.state.shoppingCartItemInfoArray)}
              isSubmitting={this.state.isSubmitting}
              onCouponSubmit={this.handleApplyCodeOnClick}
              onSubmit={this.handleOnSubmit}
          />
        </Elements>
      </div>;
    }
  }

  function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
  }

export default connect(mapStateToProps)(ShoppingCart);
