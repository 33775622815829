import { Dispatch } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import BaseAction from '../../src/models/common/BaseAction';
import { FullCourseDTO } from '../models/course/fullCourseDTO';
import { LoadingStatusType } from '../models/common/LoadingStatusType';
import { CourseController } from '../api/CourseController';
import { StudentController } from '../api/StudentController';
import { ClassroomToStudentLessonDTO } from '../models/studentLesson/classroomToStudentLessonDTO';
import { FullStudentCheckpointDTO } from '../models/studentLesson/fullStudentCheckpointDTO';
import ReduxStoreModel from './ReduxModel';
import ActionResultDTO from '../models/common/ActionResultDTO';
import ErrorDTO from '../models/common/ErrorDTO';
import DebugUtil from '../utils/DebugUtil';
import EnvironmentStaticContent from '../constants/EnvironmentStaticContent';
import { ClassroomController } from '../api/ClassroomController';
import { FullLessonDTO } from '../models/lesson/fullLessonDTO';
import { Checkpoints } from '../constants/Checkpoints';
import { StudentLessonController } from '../api/StudentLessonController';
import { StudentLessonDTO } from '../models/studentLesson/studentLessonDTO';
import { CourseDTO } from '../models/course/courseDTO';

// TODO: JB - Move this to its own file!
export type ButtonTriState = "none" | "disabled" | "visible" | "loading";

export interface SetButtonStateAction extends BaseAction { type: 'SET_BUTTON_STATE_WORKFLOW'; data: { next: ButtonTriState, back: ButtonTriState }; }
export interface SetOnNextCallbackAction extends BaseAction { type: 'SET_ON_NEXT_CALLBACK_WORKFLOW'; data: () => Promise<string>; }

export interface SetCourseAction extends BaseAction { type: 'SET_COURSE_WORKFLOW'; data: FullCourseDTO; }
export interface SetLessonCheckpointsAction extends BaseAction { type: 'SET_LESSON_CHECKPOINTS_WORKFLOW'; data: FullStudentCheckpointDTO[]; }
export interface SetCourseStateAction extends BaseAction { type: 'SET_COURSE_STATE_WORKFLOW'; data: LoadingStatusType; }
export interface SetStudentResponseStateAction extends BaseAction { type: 'SET_STUDENT_RESPONSE_STATE_WORKFLOW'; data: LoadingStatusType; }
export interface UpdateCheckpointAction extends BaseAction { type: 'UPDATE_LESSON_CHECKPOINT_WORKFLOW'; data: FullStudentCheckpointDTO; }

export interface SetCourseIdAction extends BaseAction { type: "SET_COURSE_ID"; data: string;}
export interface SetDemoIdAction extends BaseAction { type: "SET_DEMO_ID"; data: string;}
export interface SetDemoModeAction extends BaseAction { type: 'SET_DEMO_MODE_WORKFLOW'; data: boolean; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
// JB: You have to have atleast 2 in here for everything to work. It must be a typescript thing
export type KnownActions = SetButtonStateAction |
    SetOnNextCallbackAction |
    SetCourseAction |
    SetLessonCheckpointsAction |
    SetCourseStateAction |
    SetStudentResponseStateAction |
    UpdateCheckpointAction |
    SetDemoModeAction|
    SetCourseIdAction|
    SetDemoIdAction;

export default class WorkflowAction {
    constructor() {
        // Dont be that guy
        throw new Error("NOOOO");
    }

    public static SetButtonState(dispatch: Dispatch<KnownActions>, request: { next: ButtonTriState, back: ButtonTriState }) {
        dispatch({ type: "SET_BUTTON_STATE_WORKFLOW", data: request } as SetButtonStateAction);
    }

    public static SetOnNextCallback(dispatch: Dispatch<KnownActions>, request: () => Promise<string>) {
        dispatch({ type: "SET_ON_NEXT_CALLBACK_WORKFLOW", data: request } as SetOnNextCallbackAction);
    }

   private static loadDemo(dispatch: Dispatch<KnownActions>, courseId: string){
        var demoContent = null;
       
                CourseController.GetCourseFromDemo(courseId).then( result => {
                    demoContent = result.data;
                    if (demoContent != null) {
                     // Welp, we are loading demo content now
                    if (DebugUtil.isDebugEnabled()) {
                        console.warn("We are, in fact, loading a demo course");
                    }
                     courseId = demoContent.id;
                     dispatch({ type: "SET_DEMO_MODE_WORKFLOW", data: true } as SetDemoModeAction);
                     dispatch({ type: "SET_DEMO_ID", data: courseId} as SetDemoIdAction);
                     dispatch({ type: "SET_COURSE_WORKFLOW", data: demoContent } as SetCourseAction);
                     dispatch({ type: "SET_COURSE_STATE_WORKFLOW", data: "finished" } as SetCourseStateAction);
                     return;
                    } 
                }).catch((error: AxiosError) => {
                    
                    dispatch({ type: "SET_COURSE_WORKFLOW", data: null } as SetCourseAction);
                    dispatch({ type: "SET_COURSE_STATE_WORKFLOW", data: "failed" } as SetCourseStateAction);
                });  
         
    }            

    public static LoadCourse(dispatch: Dispatch<KnownActions>, courseId: string, classroomId?: string) {
        dispatch({ type: "SET_COURSE_STATE_WORKFLOW", data: "loading" } as SetCourseStateAction);
        /* Demo Content Check */
        
        /* Course and Classroom Loader */
        let coursePromise = CourseController.GetCourse(courseId);
        let classroomPromise = classroomId != null ? ClassroomController.GetCoursesByClassroom(classroomId) : null;
        Promise.all([coursePromise, classroomPromise]).then(results => {
            let course = results[0].data;
            let classroomCourses = results[1] != null ? results[1].data : null;

            let bestLessons = course.lessons.map<FullLessonDTO>(x => {
                return {
                    ...x,
                    available: true
                };
            });

            // Lesson availability changes when we have a classroom (ie. students)
            if (classroomCourses != null) {
                // Find the classroom course first to load "restrictions", that's what they will be called
                let classroomCourse = classroomCourses.find(x => x.id === course.id);
                if (classroomCourse != null) {
                    bestLessons = bestLessons.map<FullLessonDTO>(lesson => {
                        // Find the lesson (it should exist, maybe) and add availability. Else, it's not available
                        let classroomLesson = classroomCourse.lessons.find(x => x.id === lesson.id);
                        return {
                            ...lesson,
                            available: classroomLesson != null ? classroomLesson.available : false,
                        };
                    });
                }
            }

            // Don't forget to put it back onto the course! :D
            course.lessons = bestLessons;
            dispatch({ type: "SET_DEMO_MODE_WORKFLOW", data: false } as SetDemoModeAction);
            dispatch({type: "SET_COURSE_ID", data: course.id} as SetCourseIdAction);
            dispatch({ type: "SET_COURSE_WORKFLOW", data: course } as SetCourseAction);
            dispatch({ type: "SET_COURSE_STATE_WORKFLOW", data: "finished" } as SetCourseStateAction);
        }).catch((error: AxiosError) => {
            this.loadDemo(dispatch, courseId);
        });  
    }

    public static SetDemoMode(dispatch: Dispatch<KnownActions>, request: boolean) {
        dispatch({ type: "SET_DEMO_MODE_WORKFLOW", data: request } as SetDemoModeAction);
    }

    public static LoadUserCheckpoint(dispatch: Dispatch<KnownActions> | any): Promise<ActionResultDTO> {
        dispatch({ type: "SET_STUDENT_RESPONSE_STATE_WORKFLOW", data: "loading" } as SetStudentResponseStateAction);

        return dispatch((innerDispatch: Dispatch<KnownActions>, reduxState: () => ReduxStoreModel) => {
            let state = reduxState();
            return StudentController.GetStudentLessonsByStudent(state.User.id)
                .then(result => this.LoadUserCheckpoint_OnSuccess(innerDispatch, result))
                .catch(error => this.LoadUserCheckpoint_OnFailure(innerDispatch, error));
        });
    }

    private static LoadUserCheckpoint_OnSuccess(dispatch: Dispatch<KnownActions>, result: AxiosResponse<ClassroomToStudentLessonDTO[]>): ActionResultDTO {
        if (result.data != null && result.data.length > 0) {
            let flattenedData = result.data.flatMap<FullStudentCheckpointDTO>(x => {
                return x.studentLessons.map<FullStudentCheckpointDTO>(lesson => ({
                    ...lesson,
                    classroomId: x.classroomId
                }));
            });
            dispatch({ type: "SET_LESSON_CHECKPOINTS_WORKFLOW", data: flattenedData } as SetLessonCheckpointsAction);
        } else {
            // Can happen when the user is brand new? Probably
            dispatch({ type: "SET_LESSON_CHECKPOINTS_WORKFLOW", data: [] } as SetLessonCheckpointsAction);
        }
        dispatch({ type: "SET_STUDENT_RESPONSE_STATE_WORKFLOW", data: "finished" } as SetStudentResponseStateAction);
        return { isError: false };
    }

    private static LoadUserCheckpoint_OnFailure(dispatch: Dispatch<KnownActions>, error: AxiosError): ActionResultDTO {
        dispatch({ type: "SET_LESSON_CHECKPOINTS_WORKFLOW", data: null } as SetLessonCheckpointsAction);
        dispatch({ type: "SET_STUDENT_RESPONSE_STATE_WORKFLOW", data: "failed" } as SetStudentResponseStateAction);
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        return { isError: true, message: messages.join("\n") };
    }

    public static ClearCourse(dispatch: Dispatch<KnownActions>) {
        dispatch({ type: "SET_COURSE_WORKFLOW", data: null } as SetCourseAction);
        dispatch({ type: "SET_COURSE_STATE_WORKFLOW", data: "none" } as SetCourseStateAction);
    }

    public static UpdateUserCheckpoint(dispatch: Dispatch<KnownActions> | any, classroomId: string, lessonId: string, checkpointId: string, discussionQuestionResponse?: string) {
        return dispatch((innerDispatch: Dispatch<KnownActions>, reduxState: () => ReduxStoreModel) => {
            let state = reduxState();
            let studentId = state.User.id;

            // Find previous checkpoint
            let studentCheckpoint = state.Workflow.studentCheckpoints
                .find(x => x.classroomId === classroomId && x.lessonId === lessonId);

            // Couldn't find one
            if (studentCheckpoint == null) {
                studentCheckpoint = {
                    classroomId: classroomId,
                    studentLessonId: null,
                    lessonId: lessonId,
                    studentId: studentId,
                    checkpointId: checkpointId,
                    discussionQuestionResponse: discussionQuestionResponse
                };
                innerDispatch({ type: "UPDATE_LESSON_CHECKPOINT_WORKFLOW", data: studentCheckpoint } as UpdateCheckpointAction);
                return StudentLessonController.PostCreateStudentLesson({
                    lessonId: lessonId,
                    studentId: studentId,
                    checkpointId: checkpointId,
                    classroomId: classroomId,
                    discussionQuestionResponse: discussionQuestionResponse
                }).then(result => this.UpdateUserCheckpoint_OnComplete(innerDispatch, result, classroomId));
            }

            // Check if next checkpoint is higher than what we have already
            if (Checkpoints.FindById(studentCheckpoint.checkpointId).order < Checkpoints.FindById(checkpointId).order) {
                // Update first with what we think it should be
                innerDispatch({ type: "UPDATE_LESSON_CHECKPOINT_WORKFLOW", data: { ...studentCheckpoint, checkpointId: checkpointId } } as UpdateCheckpointAction);
                StudentLessonController.PutUpdateStudentLesson({
                    studentLessonId: studentCheckpoint.studentLessonId,
                    checkpointId: checkpointId,
                    discussionQuestionResponse: discussionQuestionResponse == null ? studentCheckpoint.discussionQuestionResponse : discussionQuestionResponse
                }).then(result => this.UpdateUserCheckpoint_OnComplete(innerDispatch, result, classroomId));
            }

            innerDispatch({ type: "UPDATE_LESSON_CHECKPOINT_WORKFLOW", data: studentCheckpoint } as UpdateCheckpointAction);
        });
    }

    public static UpdateUserCheckpoint_OnComplete(dispatch: Dispatch<KnownActions>, result: AxiosResponse<StudentLessonDTO>, classroomId: string) {
        let data: FullStudentCheckpointDTO = {
            ...result.data,
            classroomId: classroomId,
        };
        // Final update with what the API thinks it should be
        dispatch({ type: "UPDATE_LESSON_CHECKPOINT_WORKFLOW", data: data } as UpdateCheckpointAction);
    }
}
