import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { CreditCardOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { CourseController } from '../../api/CourseController';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { SimpleLessonDTO } from '../../models/lesson/simpleLessonDTO';

interface TeacherCoursesPageTableDataObject {
    id: string;
    name: string;
    description: string;
    gradeLevelStart: number;
    gradeLevelStartString: string;
    gradeLevelEnd: number;
    gradeLevelEndString: string;
    lessons: SimpleLessonDTO[];
}

interface TeacherCoursesPageProps extends PageProps<{}> {
}

interface TeacherCoursesPageState {
    isLoading: boolean;
    tableData: TeacherCoursesPageTableDataObject[];
}

class TeacherCoursesPage extends React.Component<TeacherCoursesPageProps, TeacherCoursesPageState> {
    tableColumns: ColumnProps<TeacherCoursesPageTableDataObject>[];
    constructor(props: TeacherCoursesPageProps) {
        super(props);
        bindAllOfThis(this, TeacherCoursesPage.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 140,
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<TeacherCoursesPageTableDataObject>("name", "Name"),
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            ...getColumnSearchProps<TeacherCoursesPageTableDataObject>("description", "Description"),
        }, {
            title: 'Grade Start',
            dataIndex: 'gradeLevelStartString',
            key: 'gradeLevelStartString',
            width: 120,
            sorter: (a, b) => a.gradeLevelStart - b.gradeLevelStart,
            ...getColumnSearchProps<TeacherCoursesPageTableDataObject>("gradeLevelStartString", "Start Grade"),
        }, {
            title: 'Grade End',
            dataIndex: 'gradeLevelEndString',
            key: 'gradeLevelEndString',
            width: 120,
            sorter: (a, b) => a.gradeLevelEnd - b.gradeLevelEnd,
            ...getColumnSearchProps<TeacherCoursesPageTableDataObject>("gradeLevelEndString", "End Grade"),
        }, {
            title: 'Number of Lessons',
            dataIndex: 'lessons.length',
            key: 'lessonCount',
            width: 120,
            sorter: (a, b) => (a.lessons || []).length - (b.lessons || []).length,
        }, {
            title: 'Purchase Course',
            key: 'purchase',
            width: 100,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Purchase" placement="top">
                        <Link to={{ pathname: Routes.TEACHER_ADD_EDIT_MY_SCHOOL("").toRoute, search: `?courseId=${record.id}` }}>
                            <Button shape="circle" icon={<CreditCardOutlined />}></Button>
                        </Link>
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: true,
            tableData: null
        };
    }

    componentDidMount() {
        this.loadTableData();
    }

    loadTableData() {
        CourseController.GetCourses().then(result => {
            let tableData = result.data
                .filter(course => course.availableForPurchase)
                .map<TeacherCoursesPageTableDataObject>(record => ({
                    ...record,
                    gradeLevelStartString: record.gradeLevelStart > 0 ? record.gradeLevelStart.toString() : record.gradeLevelStart === 0 ? "K" : "PK",
                    gradeLevelEndString: record.gradeLevelEnd > 0 ? record.gradeLevelEnd.toString() : record.gradeLevelEnd === 0 ? "K" : "PK",
                }));

            this.setState({
                tableData: tableData,
                isLoading: false
            });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <h1>Courses</h1>
            <Table
                rowKey={record => record.id}
                size="middle"
                columns={this.tableColumns}
                dataSource={this.state.tableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default TeacherCoursesPage;
