import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Card } from 'antd';
import { ColProps } from 'antd/lib/col';
import bindAllOfThis from '../../src/utils/BindThisHelper';
import Routes from '../core/Routes';
import ReduxStoreModel from '../redux/ReduxModel';
import { UserState } from '../redux/UserReducer';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import "./HomePage.scss";
import DebugUtil from '../utils/DebugUtil';
import ConfigureURLs from '../core/ConfigureUrls';

interface HomePageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
}

interface HomePageState {
}

class HomePage extends React.Component<HomePageProps, HomePageState> {
    constructor(props: HomePageProps) {
        super(props);
        bindAllOfThis(this, HomePage.prototype);

        this.state = {
        };
    }

    renderTheBestParts() {
        let makeItLarge: React.CSSProperties = {
            height: "54px",
            fontSize: "22px",
            width: "100%"
        };

        let colParams: ColProps = {
            xs: 24,
            lg: 12,
            style: { paddingBottom: "16px" }
        };

        if (this.props.User.state === "finished") {
            return <Row gutter={16}>
                <Col {...colParams}>
                    <Link key="demoLink" to={Routes.DEMO().toRoute}><Button type="primary" size="large" style={makeItLarge}>Free Trial Lessons</Button></Link>
                </Col>
                <Col {...colParams}>
                    <Link key="homeLink" to={Routes.HOME().toRoute}><Button type="primary" size="large" style={makeItLarge}>Go to My Dashboard</Button></Link>
                </Col>
            </Row>;
        }

        return <Row gutter={16}>
            <Col {...colParams}>
                <Link key="registerLink" to={Routes.REGISTER().toRoute}><Button type="primary" size="large" style={makeItLarge}>Register to Try a Sample Lesson</Button></Link>
            </Col>
            <Col {...colParams}>
                <Link key="loginLink" to={Routes.LOGIN().toRoute}><Button type="primary" size="large" style={makeItLarge}>Login</Button></Link>
            </Col>
        </Row>;
    }

    render() {
        const layoutSpans = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };

        const span8 = {
            xs: {span: 24 },
            lg: { span: 8 },
        };
        const span12 = {
            xs: {span: 24 },
            sm: { span: 12 },
        };
        const span16 = {
            xs: {span: 24 },
            lg: { span: 16 },
        };

        return <div className="home-page">
            <h1>Welcome to the Rock Digi experience!</h1>
            <Row justify="center">
                <Col {...layoutSpans}>
                    <p>Rock Digi uses music to teach social emotional learning and life skills for success in an effort to prevent bullying and drug and alcohol abuse.</p>
                </Col>
            </Row>

            <Row justify="center">
                <Col {...layoutSpans}>
                    {this.renderTheBestParts()}
                </Col>
            </Row>
            {/* Video placeholder */}
            <Row justify="center" style={{ padding: '16px 0', margin: '0px -16px 16px -16px', backgroundColor: '#0B3375', color: 'white' }}>
                <Col>
                    <Row align="middle">
                        <Col xs={24} md={14}>
                            <ReactPlayer url={ConfigureURLs.GetAWSUrl("static", "homepage-promo.mp4")}
                                controls={true} width={null} height={null} playing={false} volume={0.5} />
                        </Col>
                        <Col xs={24} md={0}>
                            <p style={{ fontSize: 32, marginBottom: 0, padding: '0px 16px', fontWeight: 'bold' }}>Creating more empathetic and caring communities.</p>
                        </Col>
                        <Col xs={0} md={10} lg={0}>
                            <p style={{ fontSize: 32, marginBottom: 0, padding: '0px 16px', fontWeight: 'bold' }}>Creating more empathetic and caring communities.</p>
                        </Col>
                        <Col xs={0} lg={10} xxl={0}>
                            <p style={{ fontSize: 48, marginBottom: 0, padding: '0px 16px', fontWeight: 'bold' }}>Creating more empathetic and caring communities.</p>
                        </Col>
                        <Col xs={0} xxl={10}>
                            <p style={{ fontSize: 64, marginBottom: 0, padding: '0px 16px', fontWeight: 'bold' }}>Creating more empathetic and caring communities.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* Webinars */}
            <Row justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row align="middle" justify="space-between">
                            <Col span={24}>
                                <h1 style={{ margin: 0 }}>Webinar Information</h1>
                                <p style={{ paddingBottom: 'unset' }}>Join us for one of our webinars where we discuss using music to teach Social Emotional Learning (SEL) for grades 2-12!</p>
                                <p style={{ paddingBottom: 12 }}>Music can be so powerful, making it a great fit to help students make deeper connections to topics they are being taught.</p>
                            </Col>
                            <Col span={24}>
                                <Row justify="center">
                                    <Col>
                                        <Button type="primary" size="large" href="https://docs.google.com/forms/d/e/1FAIpQLSfOvOTSu7djFftIlK5XLudpPNi4tagQHRpxyKuBBdA9uT6TXw/viewform">
                                            Webinar Sign Up
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row align="middle" justify="space-between">
                            <Col span={24}>
                                <h1 style={{ margin: 0 }}>Rock Digi Merch</h1>
                                <p style={{ paddingBottom: 12 }}>Brighten your day with one of our stickers, posters, or T-shirts!</p>
                            </Col>
                            <Col span={24}>
                                <Row justify="center">
                                    <Col>
                                        <Button type="primary" size="large" href="https://www.rockinprevention.org/collections/merch">
                                            Shop Merch
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row align="middle" justify="space-between">
                            <Col {...span8}>
                                <img className='merch-image' src={require("../assets/home-merch-poster.png")} />
                            </Col>
                            <Col {...span8}>
                                <img className='merch-image' src={require("../assets/home-merch-shirt.jpg")} />
                            </Col>
                            <Col {...span8}>
                                <img className='merch-image' src={require("../assets/home-merch-water-bottle.png")} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* Upper Section */}
            <Row justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row align="middle" justify="space-between">
                            <Col {...span8}>
                                <p>Rock Digi is a trailblazing, educational, cultural arts opportunity that supports and strengthens the social, emotional and academic success of our future leaders.</p>
                            </Col>
                            <Col {...span16}>
                                <img className='homepage-image' src={require("../assets/home-graphic-1.png")} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="middle" justify="space-between">
                            <Col {...span12}>
                                <img className='homepage-image' src={require("../assets/home-graphic-2.png")} />
                            </Col>
                            <Col {...span12}>
                                <p>Lessons utilize goal-centered music and support teacher-developed, intentional lessons in which children can feel the inclusion and therefore, go out and blaze their own trail.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="middle" justify="space-between">
                            <Col {...span8}>
                                <p>There are short- goal-driven, whiteboard animated lessons to launch each of the lessons. What follows will be the good work that our future leaders undertake.</p>
                            </Col>
                            <Col {...span16}>
                                <img className='homepage-image' src={require("../assets/home-graphic-3.png")} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            {/* Lower Section */}
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="top" justify="space-between">
                            <Col {...span12}>
                                <h2>Lesson Plans with Standards</h2>
                                <p>Lesson plans show what standards are met – paragraph by paragraph, stanza by stanza, and question by question. </p>
                                <p>Rock Digi meets Common Core, American School Counselors Association (ASCA), Health Resources and Services Administration (HRSA) standards and goals.</p>
                                <h2>Teacher Orientation and Training</h2>
                                <p>Teacher resources and instructions help successfully implement the Rock Digi experience in the classroom.</p>
                            </Col>
                            <Col {...span12}>
                                <h2>Whiteboard Animations</h2>
                                <p>Research shows that whiteboard animation increases retention by 15%. Rock Digi includes dictionary definitions – key concepts of engaging goal-driven lessons.</p>
                                <p>Whiteboard animations vary in length depending on age level - ranging from 90 seconds to 4 minutes long.</p>
                                <img className='homepage-image' src={require("../assets/home-graphic-4.png")} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="top" justify="space-between">
                            <Col {...span12}>
                                <img className='homepage-image' src={require("../assets/home-graphic-5.png")} />
                                <h2>Lyric Music Videos</h2>
                                <p>Music can change the world because it can change people.</p>
                                <p>Rock Digi uses original, standards-based songs created specifically for each lesson.</p>
                                <p>Lyric music videos are effective for auditory, visual, and kinesthetic learners. When you see the lyrics, it make the experience far more meaningful.</p>
                            </Col>
                            <Col {...span12}>
                                <h2>Group Discussion Questions</h2>
                                <p>Group Discussion takes knowledge and drives it to deeper understanding. When teachers and students share, they’re leaders in their own learning. Students benefit from hearing their peers share.</p>
                                <h2>Drill Down Activities</h2>
                                <p>Drill Downs contain creative and rigorous activities to dig deeper into the content of each lesson. This allows the educator to tailor the lesson to fit the needs of the students.</p>
                                <h2>Existing Knowledge (Pre-Test) and Exit Ticket (Post Test)</h2>
                                <p>Rock Digi provides instantaneous data on students’ level of understanding of each lesson, which gives teachers an opportunity to differentiate.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="top" justify="space-between">
                            <Col span={24}>
                                <h2>School-Home Partnership</h2>
                                <p>Rock Digi is a targeted, fun, and entertaining way for parents to be involved in their child’s education. </p>
                                <p>This short activity sparks important conversations at home, while also connecting what students are learning at school to at home to create consistency.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="middle" justify="space-between">
                            <Col {...span12}>
                                <img className='homepage-image' src={require("../assets/home-graphic-6.png")} />
                            </Col>
                            <Col {...span12}>
                                <h2>Meeting Educational Standards with Evidence-Based Practices</h2>
                                <p>Rock Digi encompasses the vision of the Every Student Succeeds Act (ESSA) by creating a “long-standing commitment to equal opportunity for all students.” (US Department of Education)</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <Row  align="middle" justify="center">
                            <Col span={24}>
                                <p>Rock Digi Supports all learners regardless of their academic, social, or emotional blueprint:</p>
                            </Col>
                            <Col>
                                <ul>
                                    <li>Title 1 (Socioeconomic Support)</li>
                                    <li>Title 3 (English Learner Support)</li>
                                    <li>Differentiated instruction for all learners</li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row  justify="center">
                <Col {...layoutSpans}>
                    {this.renderTheBestParts()}
                </Col>
            </Row>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(HomePage);
