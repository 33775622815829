function EncodeSpecialCharacters(input: string) {
    if (!input) {
        return "";
    }

    return input.split("+").join("%2B")
        .split(" ").join("+") // Replacement for spaces
        .split("#").join("%23")
        .split("?").join("%3F")
        .split("!").join("%21")
        .split("$").join("%24")
        .split("&").join("%26")
        .split("'").join("%27")
        .split("(").join("%28")
        .split(")").join("%29")
        .split("*").join("%2A")
        .split(",").join("%2C")
        .split(";").join("%3B")
        .split("=").join("%3D")
        .split("@").join("%40")
        .split("[").join("%5B")
        .split("]").join("%5D");
        // Following require special attention / not able to directly encode if needed
        //    .split("%").join("%25") does automatically?
        //    .split("/").join("%2F")
        //    .split(":").join("%3A");
}

export { EncodeSpecialCharacters };
