import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';

export interface AdminDistrictFormDataObject {
    id?: string;
    name: string;
    code: string;
}

interface AdminDistrictFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: AdminDistrictFormDataObject) => void;
    initialValues?: Partial<AdminDistrictFormDataObject>;
}

interface AdminDistrictFormState {
    isEdit: boolean;
}

class AdminDistrictForm extends React.Component<AdminDistrictFormProps, AdminDistrictFormState> {
    // Just need to initialize sub objects
    constructor(props: AdminDistrictFormProps) {
        super(props);
        bindAllOfThis(this, AdminDistrictForm.prototype);

        let isEdit = props.initialValues != null;

        this.state = {
            isEdit: isEdit,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminDistrictFormDataObject): void => {
            if (errors == null) {
                values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Name"
                name={NameOf<AdminDistrictFormDataObject>("name")}
                initialValue={initialValues.name}
                rules={[{ required: true, message: "Name is required." }]}
            >
                <Input size="large" placeholder="Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Code"
                name={NameOf<AdminDistrictFormDataObject>("code")}
                initialValue={initialValues.code}
                rules={[{ required: true, message: "Code is required." }]}
            >
                <Input size="large" placeholder="Code" maxLength={4} {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save District" : "Add District"}
                </Button>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminDistrictFormProps>()(AdminDistrictForm);
