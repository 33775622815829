import axios from 'axios';
import { LoginDTO } from '../models/account/loginDTO';
import { AccountDTO } from '../models/account/accountDTO';
import { CreateTeacherDTO } from '../models/teacher/createTeacherDTO';
import { ForgotPasswordDTO } from '../models/account/forgotPasswordDTO';
import { ResetPasswordDTO } from '../models/account/resetPasswordDTO';
import { StudentLoginDTO } from '../models/account/studentLoginDTO';
import { UpdateAdminDTO } from '../models/admin/updateAdminDTO';

export class AccountController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostForgotPasswordRoute = () => `forgotPassword`;
    public static PostForgotPassword(request: ForgotPasswordDTO) {
        return axios.post<void>(this.PostForgotPasswordRoute(), request);
    }

    public static GetKeepAliveRoute = () => `keepAlive`;
    public static GetKeepAlive() {
        return axios.get<void>(this.GetKeepAliveRoute());
    }

    public static PostLoginRoute = () => `login`;
    public static PostLogin(request: LoginDTO) {
        return axios.post<AccountDTO>(this.PostLoginRoute(), request);
    }

    public static LogoutRoute = () => `logout`;
    public static Logout() {
        return axios.get(this.LogoutRoute());
    }

    public static GetMeRoute = () => `me`;
    public static GetMe() {
        return axios.get<AccountDTO>(this.GetMeRoute());
    }

    public static PostRegisterRoute = () => `register`;
    public static PostRegister(request: CreateTeacherDTO) {
        return axios.post<AccountDTO>(this.PostRegisterRoute(), request);
    }

    public static PostResetPasswordRoute = () => `resetPassword`;
    public static PostResetPassword(request: ResetPasswordDTO) {
        return axios.post<any>(this.PostResetPasswordRoute(), request);
    }

    public static PostStudentLoginRoute = () => `studentLogin`;
    public static PostStudentLogin(request: StudentLoginDTO) {
        return axios.post<AccountDTO>(this.PostStudentLoginRoute(), request);
    }
}
