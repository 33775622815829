import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, InputNumber } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { FieldDecorator } from '../../components/formHelpers';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { convertFromRaw, EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./AdminDemoForm.scss";
export interface AdminDemoFormDataObject {
    id?: string;
    description: string;
    demoOrder: number;
}

interface AdminDemoFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: AdminDemoFormDataObject) => void;
    initialValues?: Partial<AdminDemoFormDataObject>;
}

interface AdminDemoFormState {
    isEdit: boolean;
    editorState: EditorState;
    contentState: ContentState;
}

class AdminDemoForm extends React.Component<AdminDemoFormProps, AdminDemoFormState> {

    constructor(props: AdminDemoFormProps) {
        super(props);
        bindAllOfThis(this, AdminDemoForm.prototype);

        let isEdit = props.initialValues != null;
        let html = isEdit ? props.initialValues.description : "<p>Enter description here!<p>";
        let contentBlock = htmlToDraft(html);
        let contentState = contentBlock != null ? ContentState.createFromBlockArray(contentBlock.contentBlocks) : null;
        let editorState = contentBlock != null ? EditorState.createWithContent(contentState) : null;

        this.state = {
            isEdit: isEdit,
            editorState,
            contentState,
        };
    }

    onEditorStateChange = (editorState: EditorState) => {
        this.setState({ editorState });
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        this.props.form.validateFields((errors: any, values: AdminDemoFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            values.description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        let initialValues = this.props.initialValues || {};
        const { editorState } = this.state;

        let toolbarOptions = {
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history']
        };

        return <div className="admin-demo-form">
            <Editor
                editorClassName={"description-editor"}
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                wrapperClassName="editor-container"
                toolbar={toolbarOptions}
                />

            <Form onSubmit={this.handleFormSubmit}>
                <FieldDecorator
                    form={this.props.form}
                    label="Demo Order"
                    name={NameOf<AdminDemoFormDataObject>("demoOrder")}
                    initialValue={initialValues.demoOrder}
                    rules={[{ required: true, type: "number", message: "Please enter a number." }]}
                >
                    <InputNumber min={1} size="large" placeholder="Order" {...disableAutoCompleteTags()} />
                </FieldDecorator>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                        Save Demo Content
                    </Button>
                </Form.Item>
            </Form>
        </div>;
    }
}

export default Form.create<AdminDemoFormProps>()(AdminDemoForm);
