type DebugValue = "set-true" | "set-false" | "unset";

export default class DebugUtil {
    /**
     * Checks if either the debug value is true or the host is local/dev and debug is no false
     */
    public static isDebugEnabled(): boolean {
        let debugValue = this.GetDebugValue();
        let isDevHost = this.isDevHost();

        return debugValue === "set-true" || (debugValue !== "set-false" && isDevHost);
    }

    public static GetDebugValue(): DebugValue {
        let item = localStorage.getItem("debug");
        if (item == null) {
            return "unset";
        } else if (item.toLowerCase() === "true") {
            return "set-true";
        } else {
            return "set-false";
        }
    }

    public static isDevHost(): boolean {
        let currentShortUrl: string = (window.location.hostname || "").toLowerCase();
        let allowedUrls: string[] = ["localhost"];
        return allowedUrls.some(x => x.toLowerCase() === currentShortUrl);
    }

    /**
     * Gets the current environment from "". Takes into account the local storage override, which also requires debug to be set
     */
    public static GetCurrentEnvironment(): "development" | "production" {
        if (this.isDebugEnabled()) {
            // Okay, debug is set!
            let item = localStorage.getItem("env");
            if (item != null) {
                if (item.toLowerCase().startsWith("prod")) {
                    return "production";
                }
                return "development";
            }
        }

        if ((process.env.REACT_APP_ENVIRONMENT || "").startsWith("prod")) {
            return "production";
        }
        return "development";
    }
}
