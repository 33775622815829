/**
 * Capitalizes the first character of each word in a given string
 * @param input Input string
 * @example "des moines" -> "Des Moines"
 */
function ToTitleCase(input: string): string {
    if (input == null) {
        return input;
    }

    return input.split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

/**
 * Trims whitespace from within the string, reducing duplicate spaces which are not rendered in html
 * @param input Input string
 * @example "   Des   Moines     " -> "Des Moines"
 */
function TrimExtraSpaces(input: string): string {
    // Inspiration: https://stackoverflow.com/questions/7764319/how-to-remove-duplicate-white-spaces-in-a-string/7764370
    if (input == null) {
        return input;
    }
    return input
        .replace(/\s+/g, ' ') // Replaces 1+ spaces with 1 space
        .trim(); // Trims the end space
        // Removed this because trim did a better job for start/end whitespace: replace(/^\s+|\s+$/, '');
}

/**
 * Checks if string is null or empty, returning true if it is. Purely for convenience
 */
function IsNullOrEmpty(input: string) {
    return !input || input.length < 1 || input.trim().length < 1;
}

export { ToTitleCase, IsNullOrEmpty, TrimExtraSpaces };
