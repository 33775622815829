import * as React from 'react';
import { Table, Radio} from 'antd';
import { ColumnProps, TablePaginationConfig, ColumnsType, ColumnGroupType} from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';

import { ClassroomOverviewDTO } from '../../models/report/classroomOverviewDTO';
import { StudentResponseOverviewRowDTO } from '../../models/report/studentResponseOverviewRowDTO';

interface TeacherMyClassroomOverviewProps  {
    isLoading?: boolean;
    tableData: ClassroomOverviewDTO;
    pagination?: TablePaginationConfig;
}

interface TeacherMyClassroomOverviewState {
}

class TeacherMyClassroomOverview extends React.Component<TeacherMyClassroomOverviewProps, TeacherMyClassroomOverviewState> {
    tableColumns:any[];

    constructor(props: TeacherMyClassroomOverviewProps) {
        super(props);
        bindAllOfThis(this, TeacherMyClassroomOverview.prototype);

        let beginningColumns = [{
            title: 'Student',
            dataIndex: 'name',
            align: "center",
            defaultSortOrder: 'ascend',
        }, {
            title: 'Overall',
            align: "center",
            children: [
                {
                    title: 'Pre',
                    width: 50,
                    align: "center",
                    render: (text: any, student: StudentResponseOverviewRowDTO) => {
                        return <div>{student.preTestScore == null ? "N/A" : student.preTestScore.toFixed(0) + "%"}</div>;
                    }
                }, 
                {
                    title: 'Post',
                    width: 50,
                    align: "center",
                    render: (text: any, student: StudentResponseOverviewRowDTO) => {
                        return <div>{student.postTestScore == null ? "N/A" : student.postTestScore.toFixed(0) + "%"}</div>;
                    }
                }
            ]
        }];

        let endColumn = [{
            title: 'Last Complete Step',
            align: "center",
            dataIndex: 'lastCompletedCheckpoint',
            defaultSortOrder: "",
        }];

        let dynamicQuestionColumns = this.props.tableData.questionColumnHeaders.sort((first, second) => first.questionOrdering - second.questionOrdering)
            .map(questionColumnHeader => {
                let correctAnswer = questionColumnHeader.correctAnswerOrdering == null ? "N/A" :
                    questionColumnHeader.correctAnswerOrdering.toString();

                let questionColumn = {
                    title: 'Q' + questionColumnHeader.questionOrdering + ' (' + correctAnswer + ')', 
                    align: "center",
                    children: [
                        {
                            title: 'Pre',
                            width: 50,
                            align: "center",
                            render: (text: any, student: StudentResponseOverviewRowDTO) => {

                                let studentResponse = student.questionResponses.find(x => x.questionId === questionColumnHeader.questionId);

                                let studentAnswer = studentResponse == null ? "N/A" : 
                                    studentResponse.preTestAnswerOrdering == null ? "N/A" :
                                        studentResponse.preTestAnswerOrdering.toString();

                                return this.renderColorCodedStudentAnswer(studentAnswer, correctAnswer);
                            }
                        }, 
                        {
                            title: 'Post',
                            width: 50,
                            align: "center",
                            render: (text: any, student: StudentResponseOverviewRowDTO) => {

                                let studentResponse = student.questionResponses.find(x => x.questionId === questionColumnHeader.questionId);

                                let studentAnswer = studentResponse == null ? "N/A" : 
                                    studentResponse.postTestAnswerOrdering == null ? "N/A" :
                                        studentResponse.postTestAnswerOrdering.toString();

                                return this.renderColorCodedStudentAnswer(studentAnswer, correctAnswer);
                            }
                        }
                    ]
                };

                return questionColumn;
            });


        this.tableColumns = beginningColumns.concat(dynamicQuestionColumns).concat(endColumn);
    }

    renderColorCodedStudentAnswer(studentAnswer: string, correctAnswer: string) {
        return studentAnswer === "N/A" ? 
            <div>{studentAnswer}</div> : 
             studentAnswer === correctAnswer ?
                <div style={{color: "#06C712"}}>{studentAnswer}</div> : 
                <div style={{color: "#FF0000"}}>{studentAnswer}</div> ;
    }

    render() {
        return <Table
            size="middle"
            loading={this.props.isLoading}
            columns={this.tableColumns}
            dataSource={this.props.tableData.studentResponseRows.sort((first, second) => first.name.localeCompare(second.name))}
            pagination={this.props.pagination}
        />;
    }
}

export default TeacherMyClassroomOverview;
