import axios from 'axios';
import { SurveyResponseDTO } from '../models/surveyResponse/surveyResponseDTO';
import { CreateSurveyResponseDTO } from '../models/surveyResponse/createSurveyResponseDTO';

export class SurveyResponseController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostCreateSurveyResponseRoute = () => `surveyResponses`;
    public static PostCreateSurveyResponse(request: CreateSurveyResponseDTO) {
        return axios.post<SurveyResponseDTO>(this.PostCreateSurveyResponseRoute(), request);
    }
}
