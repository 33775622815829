import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Col, Input, Row, Select, Spin, Table } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { FieldDecorator } from '../../components/formHelpers';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { ColumnProps } from 'antd/lib/table';

import { SimpleSchoolDTO } from '../../models/school/simpleSchoolDTO';
import { KeyLabel, KeyValue } from '../../models/common/KeyValueAndSimilar';
import Routes from '../../core/Routes';
import { Link } from 'react-router-dom';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { SchoolController } from '../../api/SchoolController';
import { SchoolSearchDTO } from '../../models/school/schoolSearchDTO';
import { SchoolSearchRequestDTO } from '../../models/school/schoolSearchRequestDTO';
import { StatesAsList } from '../../constants/States';
import { LabeledValue } from 'antd/lib/select';
import { IsNullOrEmpty, ToTitleCase, TrimExtraSpaces } from '../../utils/StringUtils';
import DebugUtil from '../../utils/DebugUtil';
import SchoolSearch from '../../components/SchoolSearch';

interface TeacherSelectMySchoolFormDataObject {
    state?: KeyLabel;
    city?: KeyLabel;
    school?: KeyLabel;
    joinCode?: string;
}

interface TeacherSelectMySchoolFormProps extends FormComponentProps {
    onSubmit: (data: SchoolSearchDTO) => void;
    isSubmitting: boolean;
}

interface TeacherSelectMySchoolFormState {
    joinCode: string;

    selectedSchool?: SchoolSearchDTO;
}

class TeacherSelectMySchoolForm extends React.Component<TeacherSelectMySchoolFormProps, TeacherSelectMySchoolFormState> {
    constructor(props: TeacherSelectMySchoolFormProps) {
        super(props);
        bindAllOfThis(this, TeacherSelectMySchoolForm.prototype);

        this.state = {
            joinCode: "",
            selectedSchool: null,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: TeacherSelectMySchoolFormDataObject): void => {
            if (errors == null) {
                let isJoinCodeRequired = this.state.selectedSchool.joinCodeRequired;

                // Check join code against selected school
                if (isJoinCodeRequired && values.joinCode.trim().toLowerCase() !== this.state.selectedSchool.id.substring(0, 7).toLowerCase()) {
                    return this.props.form.setFields({
                        [NameOf<TeacherSelectMySchoolFormDataObject>("joinCode")]: {
                            value: values.joinCode, // Need this, otherwise antd will clear that field
                            errors: [new Error("The join code is invalid.")] // Must be an array
                        }
                    });
                }

                // Join code is accepted or is not required
                this.props.onSubmit(this.state.selectedSchool);
            }
        });
    }

    render() {
        let { selectedSchool } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                lg: { span: 6 },
                xl: { span: 6 },
                xxl: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 14 },
                lg: { span: 13 },
                xl: { span: 12 },
                xxl: { span: 8 }
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                lg: { span: formItemLayout.wrapperCol.lg.span, offset: formItemLayout.labelCol.lg.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
                xxl: { span: formItemLayout.wrapperCol.xxl.span, offset: formItemLayout.labelCol.xxl.span % 24 },
            },
        };
        const tableItemLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };

        let joinCode: string = this.props.form.getFieldValue(NameOf<TeacherSelectMySchoolFormDataObject>("joinCode"));

        let isJoinCodeRequired = selectedSchool != null && selectedSchool.joinCodeRequired;
        let isSubmitDisabled = selectedSchool == null || (isJoinCodeRequired && IsNullOrEmpty(joinCode));

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <SchoolSearch
                onSelected={(e) => this.setState({ selectedSchool: e })}
                formItemLayout={formItemLayout}
                tableItemLayout={tableItemLayout}
            />

            {this.state.selectedSchool != null &&
                <div>
                    <Row style={{ marginBottom: 24 }}>
                        <Col {...tableItemLayout}>
                            <h2>Selected School</h2>
                            <Row justify="center">
                                <Col span={20}>
                                    <Card title={null} >
                                        <Row>
                                            <Col span={8} style={{ textAlign: "right", paddingRight: 8 }}>
                                                <p>School:</p>
                                                <p>Address:</p>
                                                <p>Teachers:</p>
                                                {DebugUtil.isDebugEnabled() && <p>Join Code (Debug Only):</p>}
                                            </Col>
                                            <Col span={16}>
                                                <p>{this.state.selectedSchool.name}</p>
                                                <p>{this.state.selectedSchool.city}, {this.state.selectedSchool.state} {this.state.selectedSchool.zip}</p>
                                                <p>{this.state.selectedSchool.teacherCount}</p>
                                                {DebugUtil.isDebugEnabled() && <p>{this.state.selectedSchool.id.substring(0, 7)}</p>}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {isJoinCodeRequired &&
                        <div>
                            <span>Enter the Join Code provided by a teacher already at this school to join.</span>
                            <FieldDecorator
                                {...formItemLayout}
                                form={this.props.form}
                                label="Join Code"
                                name={NameOf<TeacherSelectMySchoolFormDataObject>("joinCode")}
                                rules={[
                                    { required: isJoinCodeRequired, message: 'Join Code is required.' },
                                    { message: 'Join Code is only 7 characters!', max: 7 }
                                ]}
                            >
                                <Input placeholder="Join Code (7 characters, not case-sensitive)" {...disableAutoCompleteTags()} />
                            </FieldDecorator>
                        </div>}

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" className="login-form-button" size="large" disabled={isSubmitDisabled} loading={this.props.isSubmitting}>
                            Join School
                        </Button>

                        <Link to={Routes.GET.TEACHER_DASHBOARD} style={{ paddingLeft: "6px" }}>
                            <Button className="login-form-button" size="large">
                                Go Back
                            </Button>
                        </Link>
                    </Form.Item>
                </div>
            }
        </Form>;
    }
}

export default Form.create<TeacherSelectMySchoolFormProps>()(TeacherSelectMySchoolForm);
