import axios from 'axios';
import { LessonContentDTO } from '../models/lessonContent/lessonContentDTO';
import { CreateLessonContentDTO } from '../models/lessonContent/createLessonContentDTO';
import { UpdateLessonContentDTO } from '../models/lessonContent/updateLessonContentDTO';

export class LessonContentController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostCreateLessonContentRoute = (lessonId: string, lessonContentTypeId: string) => `lessoncontents?lessonId=${lessonId}&lessonContentTypeId=${lessonContentTypeId}`;
    public static PostCreateLessonContent(request: CreateLessonContentDTO, progressCallback?: (data: any) => void) {
        let route = this.PostCreateLessonContentRoute(request.lessonId, request.lessonContentTypeId);
        // headers: { 'Content-Type': 'multipart/form-data' }
        let headers = { 'content-type': `multipart/form-data; boundary=${(request.formData as any)._boundary}` };
        return axios.post<LessonContentDTO>(route, request.formData, { headers: headers, onUploadProgress: progressCallback });
    }

    public static PutUpdateLessonContentRoute = (id: string) => `lessoncontents?id=${id}`;
    public static PutUpdateLessonContent(request: UpdateLessonContentDTO, progressCallback?: (data: any) => void) {
        let route = this.PutUpdateLessonContentRoute(request.id);
        let headers = { 'content-type': `multipart/form-data; boundary=${(request.formData as any)._boundary}` };
        return axios.put<LessonContentDTO>(route, request.formData, { headers: headers, onUploadProgress: progressCallback });
    }

    public static DeleteLessonContentRoute = (lessonContentId: string) => `lessoncontents/${lessonContentId}`;
    public static DeleteLessonContent(lessonContentId: string) {
        return axios.delete(this.DeleteLessonContentRoute(lessonContentId));
    }

    public static GetLessonContentRoute = (lessonContentId: string) => `lessoncontents/${lessonContentId}`;
    public static GetLessonContent(lessonContentId: string) {
        return axios.get<LessonContentDTO>(this.GetLessonContentRoute(lessonContentId));
    }

    public static IncrementVideoViewCountRoute = (lessonContentId: string) => `lessoncontents/${lessonContentId}/incrementVideoViewCount`;
    public static IncrementVideoViewCount(lessonContentId: string) {
        return axios.put(this.IncrementVideoViewCountRoute(lessonContentId));
    }
}
