import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import {
    BookOutlined,
    HomeOutlined,
    LoadingOutlined,
    RollbackOutlined,
    SettingOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Tooltip } from 'antd';
import { CollapseType } from 'antd/lib/layout/Sider';
import { UnregisterCallback } from 'history';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { WorkflowState } from '../../redux/WorkflowReducer';
import ReduxStoreModel from '../../redux/ReduxModel';
import { Checkpoints, Checkpoint } from '../../constants/Checkpoints';
import { Roles } from '../../constants/Roles';
import { UserState } from '../../redux/UserReducer';
import EnvironmentStaticContent from '../../constants/EnvironmentStaticContent';
import { FullLessonDTO } from '../../models/lesson/fullLessonDTO';
import WorkflowAction from '../../redux/WorkflowAction';
import { DemoController } from '../../api/DemoController';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface DocumentarySideNavProps extends RouteComponentProps<{ documentaryId: string }> {
    // From Redux
    User: UserState;
}

interface DocumentarySideNavState {
    selectedKey: string[];
    openKey: string[];
    isLoading: boolean;
    /** Set exclusively by the sider. DO NOT SET ANYWHERE ELSE */
    isSiderCollapsed: boolean;
}

class DocumentarySideNav extends React.Component<DocumentarySideNavProps, DocumentarySideNavState> {
    constructor(props: DocumentarySideNavProps) {
        super(props);
        bindAllOfThis(this, DocumentarySideNav.prototype);

        // Find selected key
        let selectedKey = [this.props.match.url];

        // Find open key
        let openKey = ['submenu'];

        this.state = {
            selectedKey: selectedKey,
            openKey: openKey,
            isLoading: true,
            isSiderCollapsed: false,
        };
    }

    componentDidMount() {
        this.setOpenKey(this.props);
    }

    componentWillReceiveProps(nextProps: DocumentarySideNavProps) {
        if (this.props.match.url !== nextProps.match.url) {
            // Check open key
            this.setOpenKey(nextProps);
        }
    }

    setOpenKey(props: DocumentarySideNavProps) {
        this.setState({
            selectedKey: [props.match.url],
            openKey: ['submenu'],
            isLoading: false,
        });
    }

    onSiderCollapse(collapsed: boolean, type: CollapseType) {
        this.setState({ isSiderCollapsed: collapsed });
    }

    subMenuOnClick(courseId: string, lessonId: string) {
        let openKey = `submenu${courseId}${lessonId}`;

        // If the current key is "open" then remove it, closing it instead
        if (this.state.openKey.length > 0 && this.state.openKey[0] === openKey) {
            return this.setState({
                openKey: []
            });
        }
        this.setState({
            openKey: [openKey]
        });
    }

    renderLessonSubMenu() {
        let documentaryId = this.props.match.params.documentaryId;
        let menuItems: JSX.Element[] = [];
        let checkpoints: { name: string, route: string, icon: JSX.Element }[] = [
            { name: "Home", route: Routes.DOCUMENTARY_HOME(documentaryId).toRoute, icon: <TeamOutlined /> },
            { name: "Video", route: Routes.DOCUMENTARY_VIDEO(documentaryId).toRoute, icon: <TeamOutlined /> },
            { name: "Post-Video", route: Routes.DOCUMENTARY_POST_VIDEO(documentaryId).toRoute, icon: <TeamOutlined /> }
        ];

        menuItems = checkpoints.map(checkpoint => {
            return (<Menu.Item key={checkpoint.route}>
                <NavLink to={{ pathname: checkpoint.route }}>
                    {checkpoint.icon}<span>{checkpoint.name}</span>
                </NavLink>
            </Menu.Item>);
        });

        // Render
        return (
            <SubMenu key={'submenu'}
                title={[
                    <Tooltip key="lesson-icon-tooltip" placement="topRight" title={'Documentary'} mouseEnterDelay={0.5}><TeamOutlined key={`title`} /></Tooltip>,
                    <span key="lesson-text">{'Documentary'}</span>
                ]}
            >
                {menuItems}
            </SubMenu>
        );
    }

    renderMenuItems() {
        let menuItems: JSX.Element[] = [];

        /* Home or Go Back */
        let route = this.props.User.role === Roles.Teacher
                ? Routes.GET.TEACHER_MY_SCHOOL
                : Routes.GET.ADMIN_COURSES;

        menuItems.push(<Menu.Item key={route}>
            <NavLink to={route}>
                <RollbackOutlined />
                <span>Home</span>
            </NavLink>
        </Menu.Item>);

        return menuItems;
    }

    public render() {
        if (this.state.isLoading) {
            return null;
        }
        return <Sider
            width="250"
            className="side-nav"
            breakpoint="md"
            collapsible
            onCollapse={this.onSiderCollapse}
        >
            <Menu
                mode="inline"
                defaultOpenKeys={this.state.openKey}
                subMenuOpenDelay={0}
                subMenuCloseDelay={0.2}
                selectedKeys={this.state.selectedKey}
            >
                {this.renderMenuItems()}
                {this.renderLessonSubMenu()}
            </Menu>
        </Sider>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(withRouter(DocumentarySideNav));
