import * as React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Spin, notification, Button, Table } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { QuestionController } from '../../api/QuestionController';
import { CreateQuestionDTO } from '../../models/question/createQuestionDTO';
import { UpdateQuestionDTO } from '../../models/question/updateQuestionDTO';
import AdminQuestionForm, { AdminQuestionFormDataObject } from './AdminQuestionForm';
import AdminQuestionAnswerTable, { AdminQuestionAnswerTableDataObject } from './AdminQuestionAnswerTable';
import ErrorDTO from '../../models/common/ErrorDTO';
import AdminQuestionAnswerTableModal, { AdminQuestionAnswerTableModalDataObject } from './AdminQuestionAnswerTableModal';
import { QuestionAnswerController } from '../../api/QuestionAnswerController';
import { CreateQuestionAnswerDTO } from '../../models/questionAnswer/createQuestionAnswerDTO';
import { UpdateQuestionAnswerDTO } from '../../models/questionAnswer/updateQuestionAnswerDTO';
import { ParamUtil } from '../../utils/ParamUtil';
import { LessonController } from '../../api/LessonController';
import DebugUtil from '../../utils/DebugUtil';
import { AxiosResponse } from 'axios';
import { QuestionAnswerDTO } from '../../models/questionAnswer/questionAnswerDTO';
import { QuestionDTO } from '../../models/question/questionDTO';

interface AdminQuestionPageProps extends PageProps<{ courseId: string, lessonId: string, questionId?: string }> {
}

export enum QuestionType {
    PARENT,
    STUDENT,
    ASSESSMENT 
}

interface AdminQuestionPageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    questionId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: AdminQuestionFormDataObject;
    /** The data for the table */
    tableData: AdminQuestionAnswerTableDataObject[];
    /** Determines what type of question we are adding/editing */
    questionType: QuestionType;

    // Modal Values
    isModalVisible: boolean;
    isModalEdit: boolean;
    modalData?: Partial<AdminQuestionAnswerTableModalDataObject>;
}

class AdminQuestionPage extends React.Component<AdminQuestionPageProps, AdminQuestionPageState> {
    private notificationKey = "AdminQuestionPage";
    constructor(props: AdminQuestionPageProps) {
        super(props);
        bindAllOfThis(this, AdminQuestionPage.prototype);

        let isEdit = props.match.params.questionId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            questionId: props.match.params.questionId,
            isLoading: true,
            formData: null,
            tableData: [],
            questionType: QuestionType.STUDENT, // Now set from loadFormData()
            isModalVisible: false,
            isModalEdit: false,
            modalData: null,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleFormOnSubmit(data: AdminQuestionFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving Question",
            description: "Please wait while we save the question...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = this.state.isEdit ? this.handleFormOnSubmitUpdate(data) : this.handleFormOnSubmitAdd(data);
        return request
            .then(result => this.cleanAnswersForParents(result.data.id).then(() => result))
            .then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Saved Question",
                    description: "Successfully saved the question!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isSubmitting: false });
                if (this.state.isEdit) {
                    this.props.history.push(Routes.ADMIN_LESSON_ADD_EDIT(this.props.match.params.courseId, this.props.match.params.lessonId).toRoute);
                } else {
                    this.props.history.push(Routes.ADMIN_QUESTION_ADD_EDIT(this.props.match.params.courseId, this.props.match.params.lessonId, result.data.id).toRoute);
                }
            })
            .catch(this.handleFormOnSubmitOnFailure); // Catch final failures
    }

    handleFormOnSubmitOnFailure(error: any) {
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        notification.error({
            key: this.notificationKey,
            message: "Failed to Save Question",
            description: messages.map(x => <div>{x}</div>),
            duration: 10
        });

        this.setState({ isSubmitting: false });
    }

    handleFormOnSubmitAdd(data: AdminQuestionFormDataObject) {
        let request: CreateQuestionDTO = {
            text: data.text,
            ordering: data.ordering,
            lessonId: this.props.match.params.lessonId,
            questionType: QuestionType[this.state.questionType] //e.g. "STUDENT" for QuestionType.STUDENT
        };

        return QuestionController.PostCreateQuestion(request);
    }

    handleFormOnSubmitUpdate(data: AdminQuestionFormDataObject) {
        let request: UpdateQuestionDTO = {
            questionId: data.id,
            text: data.text,
            ordering: data.ordering,
            lessonId: this.props.match.params.lessonId,
            questionType: QuestionType[this.state.questionType] //e.g. "STUDENT" for QuestionType.STUDENT
        };

        return QuestionController.PutUpdateQuestion(request);
    }

    /** Adds default answer for a parent question
     *
     * Note: Removes all when a parent question has multiple answers
     */
    cleanAnswersForParents(questionId: string): Promise<AxiosResponse<QuestionAnswerDTO>> {
        // Objective: Ensure only one answer for parents, skip student and assessment questions (assessment default questions added on back end)
        if (this.state.questionType === QuestionType.STUDENT || this.state.questionType == QuestionType.ASSESSMENT) {
            return Promise.resolve({} as AxiosResponse<QuestionAnswerDTO>);
        }

        /* Parent Question! */
        if (this.state.isEdit) {
            // Okay, how many answers do we have? It's entirely probably to just skip
            if (this.state.tableData.length > 1) {
                // Removal all then add the default
                let deletePromises = this.state.tableData.map(answer => {
                    return QuestionAnswerController.DeleteQuestionAnswer(answer.id);
                });
                return Promise.all(deletePromises).then(() => {
                    return this.addDefaultParentAnswer(questionId);
                });
            }
        } else {
            // If add, add one answer
            return this.addDefaultParentAnswer(questionId);
        }
        return Promise.resolve({} as AxiosResponse<QuestionAnswerDTO>);
    }

    addDefaultParentAnswer(questionId: string) {
        return QuestionAnswerController.PostCreateQuestionAnswer({
            questionId: questionId,
            ordering: 1,
            text: "This is the default parent answer",
            isCorrect: true,
        });
    }

    handleFormOnCancel() {
        this.props.history.push(Routes.ADMIN_LESSON_ADD_EDIT(this.props.match.params.courseId, this.props.match.params.lessonId).toRoute);
    }


    handleModalOnSubmit(data: AdminQuestionAnswerTableModalDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Answer Save",
            description: "Please wait while we save the answer...",
            duration: 0
        });
        let request = this.state.isModalEdit ? this.handleModalOnSubmitUpdate(data) : this.handleModalOnSubmitAdd(data);
        request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Answer Saved",
                description: "The Answer has been saved successfully!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
            this.handleModelClose();
            // Redirect to current page and put into edit mode if not already
            // this.props.history.push({
            //     pathname: Routes.ADMIN_LESSON_ADD_EDIT(this.props.match.params.courseId, result.data.id).toRoute,
            //     hash: uuidv4(), // This will tell the browser we are on a new page everytime and not save it to the browser history
            // });
            this.loadAll();
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Answer Saved",
                description: ["There was an issue saving the Answer.", ...messages].map(x => <div>{x}</div>),
                duration: 10
            });
        });
    }

    handleModalOnSubmitAdd(data: AdminQuestionAnswerTableModalDataObject) {
        let request: CreateQuestionAnswerDTO = {
            questionId: this.props.match.params.questionId,
            ordering: data.ordering,
            text: data.text,
            isCorrect: data.isCorrect,
        };
        return QuestionAnswerController.PostCreateQuestionAnswer(request);
    }

    handleModalOnSubmitUpdate(data: AdminQuestionAnswerTableModalDataObject) {
        let request: UpdateQuestionAnswerDTO = {
            questionAnswerId: data.id,
            questionId: this.props.match.params.questionId,
            ordering: data.ordering,
            text: data.text,
            isCorrect: data.isCorrect,
        };

        return QuestionAnswerController.PutUpdateQuestionAnswer(request);
    }

    handleModelOpen(data?: Partial<AdminQuestionAnswerTableDataObject>) {
        let isEdit = data != null && data.id != null;
        if (!isEdit) {
            // Figure out an order by the current answers
            if (this.state.tableData == null || this.state.tableData.length < 1) {
                data = { ordering: 1 };
            } else {
                let value = [...this.state.tableData] // Spread operator for new array because sort mutates the array
                    .sort((a, b) => a.ordering - b.ordering)
                    .reduce((prev, current) => { // Reduce to return the item with the largest 'ordering' value
                        return prev.ordering < current.ordering ? current : prev;
                    });
                data = { ordering: value.ordering + 1 };
            }
        }

        this.setState({
            isModalVisible: true,
            isModalEdit: isEdit,
            modalData: data
        });
    }

    handleModelClose() {
        this.setState({
            isModalVisible: false,
            modalData: null
        });
    }

    handleOnAnswerUpdate(record: AdminQuestionAnswerTableDataObject) {
        this.setState({ isSubmitting: true });
        // Record typically null when deleting
        if (record == null) {
            return this.loadFormData().then(() => {
                return this.setState({ isSubmitting: false });
            });
        }

        // Update the answer
        return QuestionAnswerController.PutUpdateQuestionAnswer({
            questionAnswerId: record.id,
            text: record.text,
            ordering: record.ordering,
            isCorrect: record.isCorrect,
            questionId: this.props.match.params.questionId,
        }).then(result => {
            return this.loadFormData().then(() => {
                return this.setState({ isSubmitting: false });
            });
        });
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadFormData()]).then(x => {
            // Skip api call if editing
            if (this.state.isEdit) {
                return this.setState({ isLoading: false });
            }

            // Sets the order to the next order from the list of questions
            return this.loadNextOrderValue().then(orderResult => {
                return this.setState(state => {
                    return {
                        formData: { ...state.formData, ordering: orderResult },
                        isLoading: false
                    };
                });
            });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            let questionType: QuestionType;
            let param = new ParamUtil(this.props.location.search);
            if (param.has.IsParentQuestion) questionType = QuestionType.PARENT;
            if (param.has.IsStudentQuestion) questionType = QuestionType.STUDENT;
            if (param.has.IsAssessmentQuestion) questionType = QuestionType.ASSESSMENT;
            this.setState({
                formData: null,
                questionType: questionType
            });
            return Promise.resolve();
        }

        // Load the question here
        return QuestionController.GetQuestion(this.state.questionId).then(result => {
            let data: AdminQuestionFormDataObject = {
                id: result.data.id,
                text: result.data.text,
                ordering: result.data.ordering,
                lessonId: this.props.match.params.lessonId,
                //studentQuestion: result.data.studentQuestion,
            };
            this.setState({
                formData: data,
                questionType: QuestionType[result.data.questionType],
                tableData: result.data.questionAnswers
            });
        });
    }

    loadNextOrderValue() {
        return LessonController.GetLesson(this.props.match.params.lessonId).then(result => {
            // Find the next question order. Was going to do a "find the gap" but ran out of time. Just get largest and add one
            //todo
            let questions: QuestionDTO[];
            if (this.state.questionType == QuestionType.PARENT) questions = result.data.parentQuestions;
            if (this.state.questionType == QuestionType.STUDENT) questions = result.data.studentQuestions;
            if (this.state.questionType == QuestionType.ASSESSMENT) questions = result.data.studentSelfAssessmentQuestions;
            if (questions == null || questions.length < 1) {
                return 1;
            }
            let value = questions
                .sort((a, b) => a.ordering - b.ordering)
                .reduce((prev, current) => { // Reduce to return the item with the largest 'ordering' value
                    return prev.ordering < current.ordering ? current : prev;
                });
            return value.ordering + 1;
        });
    }

    renderDebugTable() {
        if (DebugUtil.isDebugEnabled() && this.state.isEdit && (this.state.questionType === QuestionType.PARENT)) {
            return [
                <h1 key="debug-answers-header">DEBUG TABLE</h1>,
                <p key="debug-answers-warning">Debug only: Shows the hidden parent question.</p>,
                <AdminQuestionAnswerTable
                    key="debug-answers-table"
                    tableData={this.state.tableData}
                    isLoading={this.state.isLoading}
                    isSubmitting={this.state.isSubmitting}
                    questionId={this.props.match.params.questionId}
                    onAnswerEdit={this.handleModelOpen}
                    onAnswerUpdate={this.handleOnAnswerUpdate}
                />
            ];
        }
        return null;
    }

    renderTable() {
        if (this.state.isEdit) {
            if (this.state.questionType === QuestionType.PARENT) {
                return [
                    <h1 key="answers-header">Answers</h1>,
                    <p>Parent questions do not have answers. They fill in a free form text box for their answer.</p>
                ];
            }
            if (this.state.questionType === QuestionType.ASSESSMENT) {
                return [
                    <h1 key="answers-header">Answers</h1>,
                    <p>Student self-assessment questions will have answers ranging from strongly agree to strongly disagree.</p>
                ];
            }
            return [
                <h1 key="answers-header">Answers</h1>,
                <Button key="answers-table-link" icon={<EditOutlined />} onClick={() => this.handleModelOpen()} loading={this.state.isLoading}>Add Answer</Button>,
                <AdminQuestionAnswerTable
                    key="answers-table"
                    tableData={this.state.tableData}
                    isLoading={this.state.isLoading}
                    isSubmitting={this.state.isSubmitting}
                    questionId={this.props.match.params.questionId}
                    onAnswerEdit={this.handleModelOpen}
                    onAnswerUpdate={this.handleOnAnswerUpdate}
                />
            ];
        }
        return null;
    }

    renderHeader() {
        let questionType: string;
        if (this.state.questionType === QuestionType.PARENT) questionType = "Parent";
        if (this.state.questionType === QuestionType.STUDENT) questionType = "Student";
        if (this.state.questionType === QuestionType.ASSESSMENT) questionType = "Student Self-Assessment";
        return <h1>{!this.state.isEdit ? "Add" : "Edit"} {questionType} Question</h1>;
    }

    render() {
        return <div>
            {this.renderHeader()}
            <AdminQuestionForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleFormOnSubmit}
                onCancel={this.handleFormOnCancel}
                initialValues={this.state.formData}
                isStudentQuestion={this.state.questionType === QuestionType.STUDENT}
                isLoading={this.state.isLoading}
            />
            {this.renderTable()}
            {this.renderDebugTable()}
            <AdminQuestionAnswerTableModal
                isVisible={this.state.isModalVisible}
                initialValues={this.state.modalData}
                onSubmit={this.handleModalOnSubmit}
                onCancel={this.handleModelClose} />
        </div>;
    }
}

export default AdminQuestionPage;
