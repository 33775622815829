import axios from 'axios';
import { ShoppingCartItemDTO } from '../models/shoppingCart/shoppingCartItemDTO';
import { CreateShoppingCartItemDTO } from '../models/shoppingCart/createShoppingCartItemDTO';
import { PurchaseShoppingCartItemsDTO } from '../models/shoppingCart/purchaseShoppingCartItemsDTO';
import { EditShoppingCartItemDTO } from '../models/shoppingCart/editShoppingCartItemDTO';

export class ShoppingCartController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetShoppingCartItemsRoute = (teacherId: string) => `shoppingCartItem/items/${teacherId}`;
    public static GetShoppingCartItems(teacherId: string) {
        return axios.get<ShoppingCartItemDTO[]>(this.GetShoppingCartItemsRoute(teacherId));
    }

    public static PostAddShoppingCartItemRoute = () => `shoppingCartItem/addItem`;
    public static PostAddShoppingCartItem(request: CreateShoppingCartItemDTO) {
        return axios.post<ShoppingCartItemDTO>(this.PostAddShoppingCartItemRoute(), request);
    }

    public static GetShoppingCartItemRoute = (shoppingCartId: string) => `shoppingCartItem/cart/${shoppingCartId}`;
    public static GetShoppingCartItem(shoppingCartId: string) {
        return axios.get<EditShoppingCartItemDTO>(this.GetShoppingCartItemRoute(shoppingCartId));
    }

    public static PostEditShoppingCartItemRoute = () => `shoppingCartItem/cart`;
    public static PostEditShoppingCartItem(request: EditShoppingCartItemDTO) {
        return axios.post<ShoppingCartItemDTO>(this.PostEditShoppingCartItemRoute(), request);
    }

    public static DeleteShoppingCartItemRoute = (shoppingCartId: string) => `shoppingCartItem/cart/${shoppingCartId}`;
    public static DeleteShoppingCartItem(shoppingCartId: string) {
        return axios.delete(this.DeleteShoppingCartItemRoute(shoppingCartId));
    }

    public static PostPurchaseShoppingCartItemsRoute = () => `shoppingCartItem/cart/purchase`;
    public static PostPurchaseShoppingCartItems(request: PurchaseShoppingCartItemsDTO) {
        return axios.post<PurchaseShoppingCartItemsDTO>(this.PostPurchaseShoppingCartItemsRoute(), request);
    }
}
