import * as React from 'react';
import { Row, Col, notification } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import { PageProps } from '../../../models/common/ComponentProps';

import "./ForgotPassword.scss";
import { AccountController } from '../../../api/AccountController';
import { ForgotPasswordDTO } from '../../../models/account/forgotPasswordDTO';
import ErrorDTO from '../../../models/common/ErrorDTO';
import ForgotPasswordForm from './ForgotPasswordForm';
import { Link } from 'react-router-dom';
import Routes from '../../../core/Routes';

interface ForgotPasswordPageProps extends PageProps<{}> {
}

interface ForgotPasswordPageState {
    isSubmitting: boolean;
    formData: ForgotPasswordDTO;
}

class ForgotPasswordPage extends React.Component<ForgotPasswordPageProps, ForgotPasswordPageState> {
    private notificationKey = "ForgotPasswordPage";
    constructor(props: ForgotPasswordPageProps) {
        super(props);
        bindAllOfThis(this, ForgotPasswordPage.prototype);

        this.state = {
            isSubmitting: false,
            formData: null
        };
    }

    handleForgotPasswordClick(data: ForgotPasswordDTO) {
        notification.info({
            key: this.notificationKey,
            message: "Sending Email",
            duration: 0
        });

        this.setState({ isSubmitting: true }, () => {
            AccountController.PostForgotPassword(data).then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Email Sent",
                    description: "Please check your email to proceed.",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isSubmitting: false });
            }).catch(error => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Error",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isSubmitting: false });
            });
        });
    }

    render() {
        return <div className="forgot-password-page">
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 10, offset: 7 }}
                    xl={{ span: 8, offset: 8 }}
                >
                    <h2>Forgot Password</h2>
                    <ForgotPasswordForm onSubmit={this.handleForgotPasswordClick} isSubmitting={this.state.isSubmitting} />
                    <Row  justify="center" style={{ paddingTop: "18px" }}>
                        <Link to={Routes.LOGIN().toRoute}><span style={{ display: "block" }}>Back to Login</span></Link>
                    </Row>
                </Col>
            </Row>
        </div>;
    }
}

export default ForgotPasswordPage;
