import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Card } from 'antd';
import { ColProps } from 'antd/lib/col';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import "./BenefitMePage.scss";

interface BenefitMePageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
}

interface BenefitMePageState {
}

class BenefitMePage extends React.Component<BenefitMePageProps, BenefitMePageState> {
    constructor(props: BenefitMePageProps) {
        super(props);
        bindAllOfThis(this, BenefitMePage.prototype);

        this.state = {
        };
    }

    renderTheBestParts() {
        let makeItLarge: React.CSSProperties = {
            height: "54px",
            fontSize: "22px",
            width: "100%"
        };

        let colParams: ColProps = {
            xs: 24,
            lg: 12,
            style: { paddingBottom: "16px" }
        };

        if (this.props.User.state === "finished") {
            return <Row gutter={16}>
                <Col {...colParams}>
                    <Link key="demoLink" to={Routes.DEMO().toRoute}><Button type="primary" size="large" style={makeItLarge}>Free Trial Lessons</Button></Link>
                </Col>
                <Col {...colParams}>
                    <Link key="homeLink" to={Routes.HOME().toRoute}><Button type="primary" size="large" style={makeItLarge}>Go to My Dashboard</Button></Link>
                </Col>
            </Row>;
        }

        return <Row gutter={16}>
            <Col {...colParams}>
                <Link key="registerLink" to={Routes.REGISTER().toRoute}><Button type="primary" size="large" style={makeItLarge}>Register to Try a Sample Lesson</Button></Link>
            </Col>
            <Col {...colParams}>
                <Link key="loginLink" to={Routes.LOGIN().toRoute}><Button type="primary" size="large" style={makeItLarge}>Login</Button></Link>
            </Col>
        </Row>;
    }

    render() {
        const layoutSpans = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };

        const imageSpans = {
            xs: { span: 24 },
            sm: { span: 18 },
            md: { span: 16 },
            lg: { span: 12 },
        };

        return <div className="benefit-me-page">
            <Row justify="center">
                <Col {...layoutSpans}>
                    {this.renderTheBestParts()}
                </Col>
            </Row>
            <Row justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center">How will this benefit me? My school? My students?</h1>
                        <Row align="middle" justify="space-between">
                            <Col span={24}>
                                <div className="bold">Overall, this will save educators tons of time.</div>
                                <ul>
                                    <li>Lessons are pre-made.</li>
                                    <li>Lessons track student learning and auto-grade assessments.</li>
                                    <li>Lessons identify all standards that it meets.</li>
                                </ul>
                                <div className="bold">Lessons can be differentiated and tailored to fit the needs of the students.</div >
                                <ul>
                                    <li>Lessons can be utilized through individualized learning, flipped learning, and teacher taught.</li>
                                    <li>Lessons contain a variety of different Drill Down Activities for a variety of different learning styles.</li>
                                    <ul>
                                        <li>Individual, small group, large group activities</li>
                                        <li>Activities for auditory, visual, and kinesthetic learners</li>
                                    </ul>
                                </ul>
                                <div className="bold">Reporting - Tracking Student Learning</div >
                                <p>Rock Digi provides schools with instantaneous data, comparing student's data at
                                    the beginning and end of each lesson as well as at the beginning and end of the
                                    entire curriculum.</p>
                                <p>At the beginning of each lesson, there is an <span className="bold">Existing Knowledge</span> questionnaire
                                    which questions the knowledge of the students on the topic of the lesson. After
                                    they have completed the lesson, students complete an <span className="bold">Exit Ticket</span>, which
                                    contains the same questions as the <span className="bold">Existing Knowledge</span>.</p>
                                <p>Students are also prompted to complete a <span className="bold">Self-Assessment</span> at the beginning of
                                    the first lesson and at the end of the last lesson. This helps the educator to get
                                    an understanding of how the students feel about their own social emotional
                                    intelligence. </p>
                                <p><span className="bold">Existing Knowledge, Exit Ticket, and Self-Assessments</span> can all be completed
                                    by the students on their own devices, then providing the educator with their
                                    answers. Educators are able to see how students answer, but do not manually
                                    have to grade each students' assignment - saving the educator's time.</p>
                                <p>Educators do not need to look through the entire lesson looking for ways that
                                    this meets the standards that they need to meet. <span className="underline">Each lesson also displays all
                                        the standards that the lesson meets in the <span className="bold">Lesson Plan</span> at the beginning of each
                                        lesson.</span> This lesson plan was created for educators - so they can have a place
                                    where they can see all of the components of the lesson at once. <span className="underline">Each lesson
                                        meets both American School Counselor Association (ASCA) standards as well as Common Core standards.</span></p>
                                <p><span className="bold">Whiteboard animations</span> help students by introducing the information about the
                                    topic of the lesson. Because students are anticipating what is being drawn, they
                                    are then more likely to remember the material. Whiteboard animations have
                                    been known to increase student retention by 15%.</p>
                            </Col>
                        </Row>
                        <Row align="middle" justify="center">
                            <Col {...imageSpans}>
                                <img className="center-img" src={require("../../assets/home-graphic-4.png")} />
                            </Col>
                        </Row>
                        <Row align="middle" justify="space-between">
                            <Col span={24}>
                                <p><span className="bold">Lyric Music Videos</span> help to bring emotion into the topic that the students are
                                    learning. Because students are able to see the lyrics of the song as they are
                                    being sung, students are able to better retain the lyrics of the song. Some
                                    songs are used as therapeutic story telling tools, and some songs have the
                                    concepts built right into the lyrics.</p>
                            </Col>
                        </Row>
                        <Row align="middle" justify="center">
                            <Col {...imageSpans}>
                                <img className="center-img" src={require("../../assets/home-graphic-5.png")} />
                            </Col>
                        </Row>
                        <Row align="middle" justify="space-between">
                            <Col span={24}>
                                <p><span className="bold">Discussion Questions</span> give the students the opportunity to share their own
                                    experiences with the educator and/or their fellow classmates.
                                    In group discussion, student learning is driven further when the students are
                                    able to discuss and collaborate together to form answers. Connections are
                                    made between students when they are able to hear about experiences from
                                    other students.</p>
                                <p>If answered individually, students are able to submit answers to their educator,
                                    providing them a safe space to answer the questions. This also helps the
                                    educator to get an understanding of the student's understanding of the topic
                                    and how they are feeling.</p>
                                <p>Each lesson contains a variety of different <span className="bold">Drill Down Activities</span> for a variety of
                                    different learning styles. Drill Down Activities include individual, small group,
                                    and large group activities that are directed towards visual, auditory, and kinesthetic learners.</p>
                                <p><span className="bold">School Home Partnership</span> was created to <span className="underline">increase parental involvement</span> through an easy and entertaining assignment that is a short time commitment.
                                    This activity sparks important conversations at home, and helps to create consistency between school and home.</p>
                                <p>All Rock Digi lessons can be done completely online - eliminating the need for
                                    heavy paper materials or the requirement to be in a physical classroom.</p>
                                <p>Rock Digi is a fun way to engage your students and create a caring community at
                                    your school. Every child deserves the opportunity to be successful. That's why
                                    our Rock Digi team is dedicated to creating curriculum that will provide students
                                    with the tools needed to be successful and achieve their goals.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(BenefitMePage);
