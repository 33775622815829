import * as React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Row, Col } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import Routes from '../../core/Routes';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';

export interface AdminDemoLessonTableDataObject {
    id: string;
    name: string;
    ordering: number;
    demo: boolean;
}

interface AdminDemoLessonTableProps{
    tableData: AdminDemoLessonTableDataObject[];
    isLoading: boolean;
    isSubmitting: boolean;
    onLessonUpdate: (record: AdminDemoLessonTableDataObject) => void;
}

interface AdminDemoLessonTableState{
}

class AdminDemoLessonTable extends React.Component<AdminDemoLessonTableProps, AdminDemoLessonTableState>{
    private notificationKey = "AdminDemoLessonTable";
    private tableColumns: ColumnProps<AdminDemoLessonTableDataObject>[];
    constructor(props: AdminDemoLessonTableProps){
        super(props);
        bindAllOfThis(this, AdminDemoLessonTable.prototype);

        this.tableColumns = [{
            title: 'Name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<AdminDemoLessonTableDataObject>("name", "Name"),
            render: (record) => {
                return <Row  justify="space-between">
                    <Col>{record.name}</Col>
                </Row>;
            }
        },
        {
            title: 'Order',
            dataIndex: 'ordering',
            defaultSortOrder: 'ascend',
            key: 'ordering',
            width: 100,
            sorter: (a, b) => a.ordering - b.ordering,
        },
        {
            title: 'Demo',
            key: 'edit',
            width: 100,
            align: 'center',
            render: (record) => {
                if (record.demo) {
                    return (
                        <Tooltip title="Remove From Demo" placement="top" mouseEnterDelay={0.5}>
                            <Button type="primary" shape="circle" icon={<CloseOutlined />} onClick={() => this.handleLessonDemo(record)} disabled={this.props.isSubmitting} />
                        </Tooltip>
                    );
                }
                else {
                    return (
                        <Tooltip title="Add To Demo" placement="top" mouseEnterDelay={0.5}>
                            <Button type="primary" shape="circle" icon={<CheckOutlined />} onClick={() => this.handleLessonDemo(record)} disabled={this.props.isSubmitting} />
                        </Tooltip>
                    );
                }
            }
        }];

        this.state = {};
    }

    handleLessonDemo(record: AdminDemoLessonTableDataObject) {
        if (record.demo) {
            this.props.onLessonUpdate({
                ...record,
                demo: false
            });
        }
        else {
            this.props.onLessonUpdate({
                ...record,
                demo: true
            });
        }
    }

    render() {
        return <div>
            <Table
                loading={this.props.isLoading}
                rowKey={record => record.id}
                size="small"
                columns={this.tableColumns}
                dataSource={this.props.tableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default AdminDemoLessonTable;
