export interface Role {
    id: string;
    name: string;
    /**
     * Lower numbers indicate higher roles while larger numbers mean roles with less
     *
     * Eg. 1 is admin while student is 3. None will be 99
     */
    order: number;
}

export class Roles {
    public static readonly None: Role = { id: "0", name: "None", order: 99 };
    public static readonly Admin: Role = { id: "1", name: "Admin", order: 1 };
    public static readonly Teacher: Role = { id: "2", name: "Teacher", order: 2 };
    public static readonly Student: Role = { id: "3", name: "Student", order: 3 };
    public static readonly Demo: Role = { id: "4", name: "Demo", order: 4 };
    public static readonly Parent: Role = { id: "5", name: "Parent", order: 5 };

    public static All: Role[] = [
        Roles.None,
        Roles.Admin,
        Roles.Teacher,
        Roles.Student,
        Roles.Demo,
        Roles.Parent,
    ];

    public static FindById(id: string) {
        return this.All.find(x => x.id === id);
    }
}
