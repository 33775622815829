import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BarsOutlined } from '@ant-design/icons';
import { Layout, Button, Dropdown, Menu, Row, Col } from 'antd';
import bindAllOfThis from '../../src/utils/BindThisHelper';
import Routes from '../../src/core/Routes';
import { WorkflowState } from '../redux/WorkflowReducer';
import ReduxStoreModel from '../redux/ReduxModel';
import DebugUtil from '../utils/DebugUtil';
import DisplayColumnSpans from '../components/development/DisplayColumnSpans';
import { UserState } from '../redux/UserReducer';
import { Roles } from '../constants/Roles';

const { Header } = Layout;

interface ReducedHeaderProps extends RouteComponentProps<{}> {
    // From Redux
    Workflow: WorkflowState;
    User: UserState;
}

interface ReducedHeaderState {
}

class ReducedHeader extends React.Component<ReducedHeaderProps, ReducedHeaderState> {
    constructor(props: ReducedHeaderProps) {
        super(props);
        bindAllOfThis(this, ReducedHeader.prototype);

        this.state = {
        };
    }

    renderColSpanIndicator() {
        if (DebugUtil.isDebugEnabled()) {
            return <DisplayColumnSpans />;
        }
        return null;
    }

    renderEnvironmentIndicator() {
        if (DebugUtil.isDebugEnabled()) {
            let env = DebugUtil.GetCurrentEnvironment();
            let envStyle = {
                color: env === "production" ? "red" : "palevioletred",
                fontWeight: env === "production" ? "bolder" : "500",
                backgroundColor: env === "production" ? "black" : "unset",
            } as React.CSSProperties;
            return <h1 style={{ color: "cornflowerblue" }}>Env: <span style={envStyle}>{env}</span></h1>;
        }
        return null;
    }

    renderLoginOrLogout(){
        if (this.props.User.isLoggedIn) {
           return <Link to={Routes.GET.LOGOUT} className="navbar-item">Logout</Link>;
        } else {
            return <Link to={Routes.GET.LOGIN} className="navbar-item">Login</Link>;
        }
    }

    renderUtilMenu() {
        let menuItems: JSX.Element[] = [];
        const home = <Menu.Item key="home"><Link to={Routes.GET.HOME}><Button type="primary">Home</Button></Link></Menu.Item>;
        const dashboard = <Menu.Item key="home"><Link to={Routes.GET.HOME}><Button type="primary">Dashboard</Button></Link></Menu.Item>;
        const freetrial = <Menu.Item key="demo"><Link to={Routes.GET.DEMO}><Button type="primary">Free Trial Lessons</Button></Link></Menu.Item>;
        const account = <Menu.Item key="account"><Link to={Routes.GET.ACCOUNT}><Button type="primary">Account</Button></Link></Menu.Item>;
        const login = <Menu.Item key="login"><Link to={Routes.GET.LOGIN}><Button type="primary">Login</Button></Link></Menu.Item>;
        const logout = <Menu.Item key="logout"><Link to={Routes.GET.LOGOUT}><Button type="primary">Logout</Button></Link></Menu.Item>;
        const register = <Menu.Item key="register"><Link to={Routes.GET.REGISTER}><Button type="primary">Register</Button></Link></Menu.Item>;
        const webinarinformation = <Menu.Item key="webinar">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfOvOTSu7djFftIlK5XLudpPNi4tagQHRpxyKuBBdA9uT6TXw/viewform">
                <button className='ant-btn ant-btn-primary'>Webinar Information</button>
            </a>
        </Menu.Item>;
        const merchStoreItem = <Menu.Item key="webinar">
            <a href="https://www.rockinprevention.org/collections/merch">
                <button className='ant-btn ant-btn-primary'>Shop Merch</button>
            </a>
        </Menu.Item>;
        const socialemotionallearning = <Menu.Item key="sel"><Link to={Routes.GET.SOCIAL_EMOTIONAL_LEARNING}><Button type="primary">Social Importance of SEL</Button></Link></Menu.Item>;
        const musicseffectiveness = <Menu.Item key="music-seffectiveness"><Link to={Routes.GET.MUSICS_EFFECTIVENESS}><Button type="primary">Music's Effectiveness</Button></Link></Menu.Item>;
        const benefitme = <Menu.Item key="benefit-me"><Link to={Routes.GET.BENEFIT_ME}><Button type="primary">Rock Digi's Benefits</Button></Link></Menu.Item>;
        const aboutus = <Menu.Item key="about-us"><Link to={Routes.GET.ABOUT_US}><Button type="primary">About Us</Button></Link></Menu.Item>;
        const changeActiveSchool = <Menu.Item key="changeActiveSchool"><Link to={Routes.GET.TEACHER_EDIT_ACTIVE_SCHOOL}><Button type="primary">Change Active School</Button></Link></Menu.Item>;

        const contactUs = <Menu.Item key="contact_us">
            <span>
                Contact Us:<br />
                Admin@rockdigi.org<br />
                515-255-0635
            </span>
        </Menu.Item>;

        /** Section 0 **/
        if (this.props.User.isLoggedIn) {
            if ([Roles.Student, Roles.Parent].includes(this.props.User.role)) {
                menuItems.push(home);
            } else {
                menuItems.push(dashboard);
                menuItems.push(freetrial);
            }
        } else {
            menuItems.push(home);
            menuItems.push(freetrial);
        }
        menuItems.push(<Menu.Divider key="divider" />);


        /** Section 1 **/
        menuItems.push(webinarinformation);
        menuItems.push(merchStoreItem);
        menuItems.push(socialemotionallearning);
        menuItems.push(musicseffectiveness);
        menuItems.push(benefitme);
        menuItems.push(aboutus);
        menuItems.push(<Menu.Divider key="divider" />);

        /** Section 2 **/
        if (this.props.User.isLoggedIn) {
            if (![Roles.Student, Roles.Parent].includes(this.props.User.role)) {
                menuItems.push(changeActiveSchool);
                menuItems.push(account);
            }
            menuItems.push(logout);
        } else {
            menuItems.push(login);
            menuItems.push(register);
        }

        menuItems.push(contactUs);

        // Return a menu with the items spread out of the array
        return <Menu theme="dark" className="util-menu">{menuItems}</Menu>;
    }

    render() {
        const menu = this.renderUtilMenu();
        const logo = require("../assets/logo.png");

        return (
            <Header className="general-header">
                <Row className="header-row"  justify="space-between">
                    <Col>
                        <Row  gutter={12}>
                            <Col>
                                <Link to={Routes.BASE_ROUTE().toRoute} className="logo">
                                    <img src={logo} alt="App Logo" className="header-logo" />
                                </Link>
                            </Col>
                            <Col>
                                <Link to={Routes.BASE_ROUTE().toRoute} className="logo">
                                    <h1 className="header-item">Rock Digi</h1>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row  justify="end" gutter={24}>
                            <Col>
                                <Row gutter={0}>
                                    <Col className="static-links">
                                        {
                                            this.props.User.role !== Roles.Student
                                                ? (
                                                    <Link to={Routes.GET.PRICING}>
                                                        <Button type="primary" style={{ marginRight: "6px" }}>Shop</Button>
                                                    </Link>
                                                )
                                                : null
                                        }
                                        {
                                            this.props.User.isLoggedIn && ![Roles.Student, Roles.Parent].includes(this.props.User.role)
                                                ? (
                                                    <Link to={Routes.GET.SHOPPING_CART}>
                                                        <Button type="default" style={{ marginRight: "6px" }}>Cart</Button>
                                                    </Link>
                                                )
                                                : null
                                        }
                                    </Col>
                                    <Col className="static-links">
                                        <Dropdown overlay={menu} trigger={['click']} className="header-nav">
                                            <Button className="menuButtonsToggle">Menu <BarsOutlined /></Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps)(withRouter(ReducedHeader));
