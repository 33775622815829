import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Select, InputNumber } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { FieldDecorator } from '../../components/formHelpers';
import { NameOf } from '../../utils/NameOf';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { DistrictDTO } from '../../models/district/districtDTO';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import ValidateZipCode from '../../utils/ValidateZipCode';

export interface AdminSchoolFormDataObject {
    id?: string;
    name: string;
    gradeStart: number;
    gradeEnd: number;

    district?: KeyLabel;
    city: string;
    state: string;
    zip: string;
}

interface AdminSchoolFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: AdminSchoolFormDataObject) => void;
    initialValues?: Partial<AdminSchoolFormDataObject>;
    districts: DistrictDTO[];
}

interface AdminSchoolFormState {
    isEdit: boolean;

    // District Filtering
    filteredDistricts: DistrictDTO[];
    districtFilter: string;
}

class AdminSchoolForm extends React.Component<AdminSchoolFormProps, AdminSchoolFormState> {
    private gradeLevels: KeyLabel[] = [
        { key: "-1", label: "PK" },
        { key: "0", label: "K" },
        { key: "1", label: "1st" },
        { key: "2", label: "2nd" },
        { key: "3", label: "3rd" },
        { key: "4", label: "4th" },
        { key: "5", label: "5th" },
        { key: "6", label: "6th" },
        { key: "7", label: "7th" },
        { key: "8", label: "8th" },
        { key: "9", label: "9th" },
        { key: "10", label: "10th" },
        { key: "11", label: "11th" },
        { key: "12", label: "12th" },
    ];

    // Just need to initialize sub objects
    constructor(props: AdminSchoolFormProps) {
        super(props);
        bindAllOfThis(this, AdminSchoolForm.prototype);

        let isEdit = props.initialValues != null;
        let district = isEdit && props.initialValues.district != null
            ? props.districts.find(x => x.id === props.initialValues.district.key)
            : null;

        let filteredDistricts = district != null ? [district] : [];
        let districtFilter = district != null ? district.name : "";

        this.state = {
            isEdit: isEdit,
            filteredDistricts: filteredDistricts,
            districtFilter: districtFilter,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminSchoolFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;

            // The select form items return a key label not ids
            // so for now we are doing this to get the id
            values.gradeStart = +((values.gradeStart as any) as KeyLabel).key;
            values.gradeEnd = +((values.gradeEnd as any) as KeyLabel).key;

            if (values.district == null) {
                values.district = { key: null, label: null };
            }

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    handleDistrictFilterChange(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let districts = filter.length > 2 ? this.props.districts.filter(x => x.name.toLowerCase().includes(filter)) : [];
        if (filter === "*") {
            districts = this.props.districts;
        }
        this.setState({
            districtFilter: filter,
            filteredDistricts: districts,
        });
    }

    renderGradeSelect() {
        return <Select
            placeholder="Please select a grade"
            filterOption={false}
            notFoundContent={null}
            size="large"
            labelInValue
            allowClear
        >
            {this.gradeLevels.map(gradeLevel => <Select.Option key={gradeLevel.key} value={gradeLevel.key}>{gradeLevel.label}</Select.Option>)}
        </Select>;
    }

    renderDistrictSelect() {
        if (this.props.districts == null || this.props.districts.length < 1) {
            return <Select placeholder={"Loading districts..."} loading={true}></Select>;
        }
        return <Select
            placeholder="Type 3 or more letters to search Districts"
            filterOption={false}
            notFoundContent={null}
            size="large"
            labelInValue
            allowClear
            showSearch
            onChange={this.handleDistrictFilterChange}
            onSearch={this.handleDistrictFilterChange}
        >
            {this.state.filteredDistricts.map(district => <Select.Option key={`district${district.id}`} value={`${district.id}`}>{district.name}</Select.Option>)}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        let initialGradeStartValue = this.state.isEdit ?
            this.gradeLevels.find(x => x.key === initialValues.gradeStart.toString()) :
            null;

        let initialGradeEndValue = this.state.isEdit ?
            this.gradeLevels.find(x => x.key === initialValues.gradeEnd.toString()) :
            null;

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Name"
                name={NameOf<AdminSchoolFormDataObject>("name")}
                initialValue={initialValues.name}
                rules={[{ required: true, message: "Name is required." }]}
            >
                <Input size="large" placeholder="Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Grade Start"
                name={NameOf<AdminSchoolFormDataObject>("gradeStart")}
                initialValue={initialGradeStartValue}
                rules={[{ required: true, message: "Grade Start is required." }]}
            >
                {this.renderGradeSelect()}
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Grade End"
                name={NameOf<AdminSchoolFormDataObject>("gradeEnd")}
                initialValue={initialGradeEndValue}
                rules={[{ required: true, message: "Grade End is required." }]}
            >
                {this.renderGradeSelect()}
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="District"
                name={NameOf<AdminSchoolFormDataObject>("district")}
                initialValue={initialValues.district}
                rules={[]}
            >
                {this.renderDistrictSelect()}
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="City"
                name={NameOf<AdminSchoolFormDataObject>("city")}
                initialValue={initialValues.city}
                rules={[{ required: true, message: "City is required." }]}
            >
                <Input size="large" placeholder="City" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="State"
                name={NameOf<AdminSchoolFormDataObject>("state")}
                initialValue={initialValues.state}
                rules={[{ required: true, message: "State is required." }]}
            >
                <Input size="large" placeholder="State" maxLength={2} {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Zip"
                name={NameOf<AdminSchoolFormDataObject>("zip")}
                initialValue={initialValues.zip}
                rules={[{ required: true, message: "Zip is required." }, { validator: ValidateZipCode }]}
            >
                <Input size="large" placeholder="Zip" maxLength={5} {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save School" : "Add School"}
                </Button>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminSchoolFormProps>()(AdminSchoolForm);
