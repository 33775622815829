var States: { [key: string]: string } = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'American Samoa': 'AS',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Guam': 'GU',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Marshall Islands': 'MH',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Palau': 'PW',
    'Pennsylvania': 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virgin Islands': 'VI',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

var StatesAsList: { id: string, name: string, label: string }[] = [
    { id: 'AL', name: 'Alabama',                        label: 'Alabama (AL)' },
    { id: 'AK', name: 'Alaska',                         label: 'Alaska (AK)' },
    { id: 'AS', name: 'American Samoa',                 label: 'American Samoa (AS)' },
    { id: 'AZ', name: 'Arizona',                        label: 'Arizona (AZ)' },
    { id: 'AR', name: 'Arkansas',                       label: 'Arkansas (AR)' },
    { id: 'CA', name: 'California',                     label: 'California (CA)' },
    { id: 'CO', name: 'Colorado',                       label: 'Colorado (CO)' },
    { id: 'CT', name: 'Connecticut',                    label: 'Connecticut (CT)' },
    { id: 'DE', name: 'Delaware',                       label: 'Delaware (DE)' },
    { id: 'DC', name: 'District Of Columbia',           label: 'District Of Columbia (DC)' },
    { id: 'FM', name: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia (FM)' },
    { id: 'FL', name: 'Florida',                        label: 'Florida (FL)' },
    { id: 'GA', name: 'Georgia',                        label: 'Georgia (GA)' },
    { id: 'GU', name: 'Guam',                           label: 'Guam (GU)' },
    { id: 'HI', name: 'Hawaii',                         label: 'Hawaii (HI)' },
    { id: 'ID', name: 'Idaho',                          label: 'Idaho (ID)' },
    { id: 'IL', name: 'Illinois',                       label: 'Illinois (IL)' },
    { id: 'IN', name: 'Indiana',                        label: 'Indiana (IN)' },
    { id: 'IA', name: 'Iowa',                           label: 'Iowa (IA)' },
    { id: 'KS', name: 'Kansas',                         label: 'Kansas (KS)' },
    { id: 'KY', name: 'Kentucky',                       label: 'Kentucky (KY)' },
    { id: 'LA', name: 'Louisiana',                      label: 'Louisiana (LA)' },
    { id: 'ME', name: 'Maine',                          label: 'Maine (ME)' },
    { id: 'MH', name: 'Marshall Islands',               label: 'Marshall Islands (MH)' },
    { id: 'MD', name: 'Maryland',                       label: 'Maryland (MD)' },
    { id: 'MA', name: 'Massachusetts',                  label: 'Massachusetts (MA)' },
    { id: 'MI', name: 'Michigan',                       label: 'Michigan (MI)' },
    { id: 'MN', name: 'Minnesota',                      label: 'Minnesota (MN)' },
    { id: 'MS', name: 'Mississippi',                    label: 'Mississippi (MS)' },
    { id: 'MO', name: 'Missouri',                       label: 'Missouri (MO)' },
    { id: 'MT', name: 'Montana',                        label: 'Montana (MT)' },
    { id: 'NE', name: 'Nebraska',                       label: 'Nebraska (NE)' },
    { id: 'NV', name: 'Nevada',                         label: 'Nevada (NV)' },
    { id: 'NH', name: 'New Hampshire',                  label: 'New Hampshire (NH)' },
    { id: 'NJ', name: 'New Jersey',                     label: 'New Jersey (NJ)' },
    { id: 'NM', name: 'New Mexico',                     label: 'New Mexico (NM)' },
    { id: 'NY', name: 'New York',                       label: 'New York (NY)' },
    { id: 'NC', name: 'North Carolina',                 label: 'North Carolina (NC)' },
    { id: 'ND', name: 'North Dakota',                   label: 'North Dakota (ND)' },
    { id: 'MP', name: 'Northern Mariana Islands',       label: 'Northern Mariana Islands (MP)' },
    { id: 'OH', name: 'Ohio',                           label: 'Ohio (OH)' },
    { id: 'OK', name: 'Oklahoma',                       label: 'Oklahoma (OK)' },
    { id: 'OR', name: 'Oregon',                         label: 'Oregon (OR)' },
    { id: 'PW', name: 'Palau',                          label: 'Palau (PW)' },
    { id: 'PA', name: 'Pennsylvania',                   label: 'Pennsylvania (PA)' },
    { id: 'PR', name: 'Puerto Rico',                    label: 'Puerto Rico (PR)' },
    { id: 'RI', name: 'Rhode Island',                   label: 'Rhode Island (RI)' },
    { id: 'SC', name: 'South Carolina',                 label: 'South Carolina (SC)' },
    { id: 'SD', name: 'South Dakota',                   label: 'South Dakota (SD)' },
    { id: 'TN', name: 'Tennessee',                      label: 'Tennessee (TN)' },
    { id: 'TX', name: 'Texas',                          label: 'Texas (TX)' },
    { id: 'UT', name: 'Utah',                           label: 'Utah (UT)' },
    { id: 'VT', name: 'Vermont',                        label: 'Vermont (VT)' },
    { id: 'VI', name: 'Virgin Islands',                 label: 'Virgin Islands (VI)' },
    { id: 'VA', name: 'Virginia',                       label: 'Virginia (VA)' },
    { id: 'WA', name: 'Washington',                     label: 'Washington (WA)' },
    { id: 'WV', name: 'West Virginia',                  label: 'West Virginia (WV)' },
    { id: 'WI', name: 'Wisconsin',                      label: 'Wisconsin (WI)' },
    { id: 'WY', name: 'Wyoming',                        label: 'Wyoming (WY)' }
];

export { States, StatesAsList };
