import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Card, Spin, notification } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import AccountForm, { AccountFormDataObject } from './AccountForm';
import UserAction from '../../../redux/UserActions';
import ReduxStoreModel from '../../../redux/ReduxModel';
import { UserState } from '../../../redux/UserReducer';
import { CreateTeacherDTO } from '../../../models/teacher/createTeacherDTO';
import { PageProps } from '../../../models/common/ComponentProps';
import { SchoolController } from '../../../api/SchoolController';
import { SimpleSchoolDTO } from '../../../models/school/simpleSchoolDTO';
import { AccountController } from '../../../api/AccountController';
import { KeyLabel } from '../../../models/common/KeyValueAndSimilar';
import ErrorDTO from '../../../models/common/ErrorDTO';
import { Roles } from '../../../constants/Roles';
import { AdminController } from '../../../api/AdminController';
import { TeacherController } from '../../../api/TeacherController';
import { UpdateAccountDTO } from '../../../models/account/updateAccountDTO';

// import "./Account.scss";

interface AccountPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    UpdateUserAccount: (request: UpdateAccountDTO) => void;
}

interface AccountPageState {
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** I be SUBMITTING */
    isSubmitting: boolean;
    /** The data for the form */
    formData: AccountFormDataObject;

    schools: SimpleSchoolDTO[];
}

class AccountPage extends React.Component<AccountPageProps, AccountPageState> {
    private notificationKey = "AccountPage";
    constructor(props: AccountPageProps) {
        super(props);
        bindAllOfThis(this, AccountPage.prototype);

        this.state = {
            isLoading: true,
            isSubmitting: false,
            isEdit: true,
            schools: [],
            formData: null,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AccountFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Account Save",
            description: "Saving your account details...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = this.props.User.role === Roles.Admin
            ? this.handleOnSubmit_AdminEdition(data)
            : this.handleOnSubmit_TeacherEdition(data);

        // .Finally() doesnt exist for Edge, so then/catch it is
        request
            .then(() => {
                this.props.UpdateUserAccount({
                    userId: data.id,
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber,
                    password: data.password,
                    schoolId: (data.school || { key: null }).key,
                });
                this.setState({ isSubmitting: false });
                return this.loadAll();
            })
            .catch(() => this.setState({ isSubmitting: false }));
    }

    handleOnSubmit_AdminEdition(data: AccountFormDataObject) {
        return AdminController.PutAdminAccount({
            adminId: data.id,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
        }).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Account Save",
                description: "Your Account has been updated successfully!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
            this.loadAll();
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Account Save",
                description: ["There was an issue saving your Account Details.", ...messages].map(x => <div>{x}</div>),
                duration: 10
            });
        });
    }

    handleOnSubmit_TeacherEdition(data: AccountFormDataObject) {
        return TeacherController.PutUpdateTeacher({
            teacherId: data.id,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            password: data.password,
            schoolId: data.school != null ? data.school.key : null,
        }).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Account Save",
                description: "Your Account has been updated successfully!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
            this.loadAll();
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Account Save",
                description: ["There was an issue saving your Account Details.", ...messages].map(x => <div>{x}</div>),
                duration: 10
            });
        });
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadFormData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    async loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        let user = this.props.User;
        var school = null;
        if (user.schoolId != null) {
            var response = await SchoolController.GetSchool(user.schoolId);
            school = {
                key: user.schoolId,
                label: response.data.name
            } as KeyLabel;
        }

        let data: AccountFormDataObject = {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            roleId: user.role.id,
            school: school,
        };

        return this.setState({
            formData: data
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <Row>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 18, offset: 3 }}
                    lg={{ span: 16, offset: 4 }}
                    xl={{ span: 14, offset: 5 }}
                >
                    <Card>
                        <h1>Account Page</h1>
                        <AccountForm
                            initialValues={this.state.formData}
                            onSubmit={this.handleOnSubmit}
                            isSubmitting={this.state.isSubmitting} />
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        UpdateUserAccount: (request: UpdateAccountDTO) => UserAction.UpdateUserAccount(dispatch, request)
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);

