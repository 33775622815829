import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Collapse, Checkbox } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../../utils/BindThisHelper';
import { FieldDecorator } from '../../../components/formHelpers';
import { CreateTeacherDTO } from '../../../models/teacher/createTeacherDTO';
import { NameOf } from '../../../utils/NameOf';
import Routes from '../../../core/Routes';
import { Link } from 'react-router-dom';
import { disableAutoCompleteTags } from '../../../utils/AutoComplete';

const Panel = Collapse.Panel;

interface ConferenceRegistrationFormProps extends FormComponentProps {
    onSubmit: (data: CreateTeacherDTO) => void;
    isSubmitting: boolean;
}

interface ConferenceRegistrationFormState {
    confirmDirty: boolean;
}

class ConferenceRegistrationForm extends React.Component<ConferenceRegistrationFormProps, ConferenceRegistrationFormState> {
    constructor(props: ConferenceRegistrationFormProps) {
        super(props);
        bindAllOfThis(this, ConferenceRegistrationForm.prototype);

        this.state = {
            confirmDirty: false,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: CreateTeacherDTO): void => {
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    handleConfirmBlur(e: any) {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    compareToFirstPassword(rule: any, value: any, callback: any) {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('The 2 password do not match.');
        } else {
            callback();
        }
    }

    validateToNextPassword(rule: any, value: any, callback: any) {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    ensureCheckboxChecked(rule: any, value: any, callback: any) {
        // const form = this.props.form;
        if (!value) {
            callback('You must accept the Beta User Agreement to register.');
        }
        callback();
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark layout="vertical">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Email"
                name={NameOf<CreateTeacherDTO>("email")}
                rules={[{ required: true, message: 'Email is required.' }, { type: 'email', message: 'Not a valid Email.' }]}
            >
                <Input size="large" placeholder="Email" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="First Name"
                name={NameOf<CreateTeacherDTO>("firstName")}
                rules={[{ required: true, message: 'First Name is required.' }]}
            >
                <Input size="large" placeholder="First Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Last Name"
                name={NameOf<CreateTeacherDTO>("lastName")}
                rules={[{ required: true, message: 'Last Name is required.' }]}
            >
                <Input size="large" placeholder="Last Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Phone Number"
                name={NameOf<CreateTeacherDTO>("phoneNumber")}
                rules={[{ required: true, message: 'Phone Number is required.' },
                        {min:7, message: 'Please enter a valid phone number'}]}
            >
                <Input size="large" placeholder="Phone Number" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            
            <FieldDecorator
                form={this.props.form}
                name={"acceptEula"}
                valuePropName="checked" // Needed for checkboxes
                rules={[{ validator: this.ensureCheckboxChecked }]}
            >
                <Checkbox style={{ paddingTop: "24px" }}>I have read and agree to the 
                <Link target='#' to={Routes.BETA_USER_AGREEMENT().toRoute}> Beta User Agreement</Link>
                </Checkbox>
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    Register
                </Button>
                <Link to={Routes.LOGIN().toRoute}>
                    <Button type="link" className="login-form-button" size="large" >
                        Already registered? Click to Login.
                    </Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<ConferenceRegistrationFormProps>()(ConferenceRegistrationForm);
