import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Select, Table, Divider, Popconfirm, notification, Modal } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { FieldDecorator } from '../../components/formHelpers';
import { NameOf } from '../../utils/NameOf';
import { Roles } from '../../constants/Roles';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { Link } from 'react-router-dom';
import { SchoolDTO } from '../../models/school/schoolDTO';
import { ColumnProps } from 'antd/lib/table';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { TeacherController } from '../../api/TeacherController';
import { TeacherDTO } from '../../models/teacher/teacherDTO';
import SchoolSearch from '../../components/SchoolSearch';

export interface AdminUserFormDataObject extends TeacherDTO {
    isAdmin: boolean;
}

interface AdminUserFormProps extends FormComponentProps {
    isSubmitting: boolean;
    initialValues?: Partial<AdminUserFormDataObject>;

    onSubmit: (data: AdminUserFormDataObject) => void;
    /** Indicates that the form needs reloading */
    onReload: () => void;
}

interface AdminUserFormState {
    isEdit: boolean;
    isAddSchoolVisible: boolean;
    /** The school on the teacher record if any */
    primarySchool: SchoolDTO;

    /** The school to be added from the modal */
    selectedSchool: SchoolDTO;
}

class AdminUserForm extends React.Component<AdminUserFormProps, AdminUserFormState> {
    private notificationKey = "AdminUserForm";
    private tableColumns: ColumnProps<SchoolDTO>[];
    constructor(props: AdminUserFormProps) {
        super(props);
        bindAllOfThis(this, AdminUserForm.prototype);
        let isEdit = props.initialValues != null && props.initialValues.id != null;

        this.tableColumns = [{
            title: 'Name',
            dataIndex: NameOf<SchoolDTO>("name"),
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<SchoolDTO>("name", "Name"),
        }, {
            title: 'City',
            dataIndex: NameOf<SchoolDTO>("city"),
            sorter: (a, b) => a.city.localeCompare(b.city),
            ...getColumnSearchProps<SchoolDTO>("city", "City"),
        }, {
            title: 'State',
            dataIndex: NameOf<SchoolDTO>("state"),
            sorter: (a, b) => a.state.localeCompare(b.state),
            ...getColumnSearchProps<SchoolDTO>("state", "State"),
        }, {
            title: 'Zip',
            dataIndex: NameOf<SchoolDTO>("zip"),
            sorter: (a, b) => a.zip.localeCompare(b.zip),
            ...getColumnSearchProps<SchoolDTO>("zip", "Zip"),
        }, {
            title: '',
            key: 'remove',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Popconfirm
                        title={`Remove this school? ${record.name}`}
                        okText="Yeah"
                        cancelText="Just Kidding!"
                        onConfirm={(e) => this.handleRemoveSchool(record)}
                        >
                        <Button type="link">Remove</Button>
                    </Popconfirm>
                );
            }
        }];

        this.state = {
            isEdit: isEdit,
            isAddSchoolVisible: false,
            primarySchool: props.initialValues.school,
            selectedSchool: null
        };
    }

    private handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminUserFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            values.isAdmin = this.props.initialValues != null ? this.props.initialValues.isAdmin : null;
            values.school = this.state.primarySchool;

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    private async handleRemoveSchool(e: SchoolDTO) {
        try {
            notification.info({
                key: this.notificationKey,
                message: "Removing School",
                description: "Please wait...",
                duration: 0
            });
            await TeacherController.GetRemoveTeacherFromSchool(this.props.initialValues.id, e.id);
            this.props.onReload && this.props.onReload();
            notification.success({
                key: this.notificationKey,
                message: "Removing School",
                description: "Removed School from Teacher",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
        } catch (error) {
            notification.error({
                key: this.notificationKey,
                message: "Removing School",
                description: `Error while removing: ${error}`,
                duration: 5
            });
        }
    }

    private async handleAddSchool(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (this.state.selectedSchool == null) {
            notification.warn({
                key: this.notificationKey,
                message: "Adding School",
                description: "You must actually select a school first",
                duration: 7
            });
            return;
        }
        try {
            notification.info({
                key: this.notificationKey,
                message: "Adding School",
                description: "Please wait...",
                duration: 0
            });
            await TeacherController.GetAddTeacherToSchool(this.props.initialValues.id, this.state.selectedSchool.id);
            this.setState({ selectedSchool: null });
            this.props.onReload && this.props.onReload();
            notification.success({
                key: this.notificationKey,
                message: "Adding School",
                description: "Added School to Teacher",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
        } catch (error) {
            notification.error({
                key: this.notificationKey,
                message: "Adding School",
                description: `Error while adding: ${error}`,
                duration: 5
            });
        }
    }

    private handleSelect(selected: SchoolDTO) {
        this.setState({ primarySchool: selected });
    }

    private handleShowModal(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setState({ isAddSchoolVisible: true });
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };
        const tableItemLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };

        const modalItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                xl: { span: 8 },
                xxl: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 16 },
                xl: { span: 16 },
                xxl: { span: 16 }
            },
        };
        const modalTableLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 24 },
        };

        let initialValues = this.props.initialValues || {};

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark>
            <Divider orientation="left">General</Divider>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="First Name"
                name={NameOf<AdminUserFormDataObject>("firstName")}
                rules={[{ required: true, message: "First Name is required" }]}
                initialValue={initialValues.firstName}
            >
                <Input size="large" placeholder="First Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Last Name"
                name={NameOf<AdminUserFormDataObject>("lastName")}
                rules={[{ required: true, message: "Last Name is required" }]}
                initialValue={initialValues.lastName}
            >
                <Input size="large" placeholder="Last Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Email"
                name={NameOf<AdminUserFormDataObject>("email")}
                rules={[{ required: true, message: "Email required" }, { type: "email", message: "Not a valid Email." }]}
                initialValue={initialValues.email}
            >
                <Input size="large" placeholder="Email" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Phone Number"
                name={NameOf<AdminUserFormDataObject>("phoneNumber")}
                rules={[{ required: !initialValues.isAdmin, message: "Phone Number required" }]}
                initialValue={initialValues.phoneNumber}
            >
                <Input size="large" placeholder="Phone Number" disabled={initialValues.isAdmin} {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Role"
                name={NameOf<AdminUserFormDataObject>("roleId")}
                rules={[{ required: !initialValues.isAdmin, message: "Role is required" }]}
                initialValue={initialValues.roleId}
            >
                <Select size="large" placeholder={"Role"} disabled={initialValues.isAdmin}>
                    {Roles.All.filter(x => x !== Roles.None).map(role => <Select.Option key={`role${role.id}`} value={`${role.id}`}>{role.name}</Select.Option>)}
                </Select>
            </FieldDecorator>

            <Divider orientation="left">Set User Password</Divider>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Password"
                name={NameOf<AdminUserFormDataObject>("password")}
                initialValue={initialValues.password}
            >
                <Input size="large" placeholder="Set password" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <Divider orientation="left">Schools</Divider>
            <Row>
                <Col {...tableItemLayout}>
                    <h2>Schools</h2>
                    <p>If the school is "selected" then it is the primary school. Schools can be added and subtracted and are saved immediately. Changes to the primary school are only saved with the form save (Save User).</p>
                    <Button onClick={this.handleShowModal}>Add School</Button>
                    <Table
                        rowKey={record => record.id}
                        bordered
                        rowSelection={{
                            type: "checkbox",
                            hideSelectAll: true,
                            selectedRowKeys: this.state.primarySchool != null ? [this.state.primarySchool.id] : [],
                            onSelect: (record, selected) => this.handleSelect(selected ? record : null),
                        }}
                        size="middle"
                        columns={this.tableColumns}
                        dataSource={this.props.initialValues.allSchools}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            hideOnSinglePage: true,
                            pageSizeOptions: ["5", "10", "25", "50", "100"],
                            defaultPageSize: 5,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                        }}
                    />
                </Col>
            </Row>

            {/* I would normally put this into a separate file but I am running desperately low on time */}
            {/* Aaaand I am already reusing it, fuck me. See TeacherEditActiveSchool.tsx */}
            <Modal
                title="Add School"
                visible={this.state.isAddSchoolVisible}
                okText={"Add Selected"}
                cancelText={"Cancel"}
                onOk={this.handleAddSchool}
                onCancel={() => this.setState({ isAddSchoolVisible: false })}
                closable={false}
                width={650}
            >
                <SchoolSearch
                    onSelected={(e) => this.setState({ selectedSchool: e })}
                    formItemLayout={modalItemLayout}
                    tableItemLayout={modalTableLayout}
                />
            </Modal>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                    {this.state.isEdit ? "Save User" : "Add User"}
                </Button>
                <Link to={Routes.ADMIN_USERS().toRoute}>
                    <Button type="default" className="login-form-button" size="large" >
                        Back
                    </Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminUserFormProps>()(AdminUserForm);
