import axios from 'axios';
import { ConstantsDTO } from '../models/constant/constantsDTO';

export class ConstantController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetConstantsRoute = () => `constants`;
    public static GetConstants() {
        return axios.get<ConstantsDTO>(this.GetConstantsRoute());
    }
}
