import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { Button, Row, Col } from 'antd';
import WorkflowAction from '../../redux/WorkflowAction';
import ReduxStoreModel from '../../redux/ReduxModel';
import { WorkflowState } from '../../redux/WorkflowReducer';

interface UserCoursesPageProps extends RouteComponentProps<{}> {
    // From Redux
    Workflow: WorkflowState;
    // setCurrentScene: (scene: Scene) => void;
}

interface UserCoursesPageState {
}

class UserCoursesPage extends React.Component<UserCoursesPageProps, UserCoursesPageState> {
    constructor(props: UserCoursesPageProps) {
        super(props);
        bindAllOfThis(this, UserCoursesPage.prototype);

        this.state = {
        };
    }

    onClick() {
        // Set the redux object and change pages
        // this.props.history.push(Routes.getRoute(TeacherSceneManager.Home.route, 1));
    }

    render() {
        return <div>
            <h1>User Courses Home</h1>
            <p>This page will show a high level view of the courses available</p>
            <p>Check out the lesson links below to get started</p>
            <Row>
                <Col><Link to={Routes.WORKFLOW(24, 2).toRoute}>Emotional Intelligence and Life Skills for Success (Grade 2-4) - Lesson 2</Link></Col>
                <Col><Link to={Routes.WORKFLOW(56, 4).toRoute}>Emotional Intelligence and Life Skills for Success (Grade 5-6) - Lesson 4</Link></Col>
                <Col><Link to={Routes.WORKFLOW(79, 1).toRoute}>Rock World Empathy (Grade 7-9) - Lesson 1</Link></Col>
            </Row>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        // setCurrentScene: (scene: Scene) => WorkflowAction.SetCurrentScene(dispatch, scene),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCoursesPage);
