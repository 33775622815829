import { Reducer } from 'redux';
import { KnownActions } from './UserActions';
import { Role, Roles } from '../constants/Roles';
import { LoadingStatusType } from '../models/common/LoadingStatusType';

export type UserState = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    schoolId: string;
    schoolName: string;
    phoneNumber: string;
    isLoggedIn: boolean;
    /** Indicates that the user **just** registered */
    isBrandNew: boolean;
    state: LoadingStatusType;
    role: Role;
    redirect: string;
};

let DefaultUserState: UserState = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber:"",
    schoolId: "",
    schoolName: "",
    isLoggedIn: false,
    isBrandNew: false,
    state: "none",
    role: Roles.None,
    redirect: "",
};

export const UserReducer: Reducer<UserState, KnownActions> = (state: UserState = DefaultUserState, action: KnownActions) => {
    switch (action.type) {
        case "LOGIN_USER":
            return {
                ...state,
                id: action.data.id,
                email: action.data.email,
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                phoneNumber: action.data.phoneNumber,
                schoolId: action.data.schoolId,
                schoolName: action.data.schoolName,
                role: action.data.role,
                isBrandNew: action.data.isBrandNew,
                isLoggedIn: true
            };
        case "CLEAR_LOGIN_STATE":
            return {
                ...state,
                ...DefaultUserState,
            };
        case "UPDATE_USER":
            return {
                ...state,
                id: action.data.id,
                email: action.data.email,
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                phoneNumber: action.data.phoneNumber,
                schoolId: action.data.schoolId,
                schoolName: action.data.schoolName,
                role: action.data.role,
                isLoggedIn: true,
            };
        case "UPDATE_USER_STATE":
            return {
                ...state,
                state: action.data
            };
        case "LOGIN_REDIRECT":
            return {
                ...state,
                redirect: action.data
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // JB - Clarification: The following line is magic
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    // (or default initial state if none was supplied)
    return state;
};
