import axios from 'axios';

import { QuestionDTO } from '../models/question/questionDTO';
import { CreateQuestionDTO } from '../models/question/createQuestionDTO';
import { UpdateQuestionDTO } from '../models/question/updateQuestionDTO';
import { StudentResponseDTO } from '../models/studentResponse/studentResponseDTO';

export class QuestionController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetQuestionRoute = (questionId: string) => `questions/${questionId}`;
    public static GetQuestion(questionId: string) {
        return axios.get<QuestionDTO>(this.GetQuestionRoute(questionId));
    }

    public static GetQuestionsRoute = () => `questions`;
    public static GetQuestions() {
        return axios.get<QuestionDTO[]>(this.GetQuestionsRoute());
    }

    public static PostCreateQuestionRoute = () => `questions`;
    public static PostCreateQuestion(request: CreateQuestionDTO) {
        return axios.post<QuestionDTO>(this.PostCreateQuestionRoute(), request);
    }

    public static PutUpdateQuestionRoute = () => `questions`;
    public static PutUpdateQuestion(request: UpdateQuestionDTO) {
        return axios.put<QuestionDTO>(this.PutUpdateQuestionRoute(), request);
    }

    public static DeleteQuestionRoute = (questionId: string) => `questions/${questionId}`;
    public static DeleteQuestion(questionId: string) {
        return axios.delete(this.DeleteQuestionRoute(questionId));
    }
}
