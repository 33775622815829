import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input } from 'antd';
import { FormComponentProps, ValidationRule  } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import { ColProps } from 'antd/lib/col';

export interface FieldDecoratorProps extends FormComponentProps {
    // Required
    name: string;
    // element: JSX.Element;

    // Optional
    rules?: ValidationRule[];
    initialValue?: {};
    /** Mainly used for checkboxes */
    valuePropName?: string;
    /** This is a weird param. If you aren't using an <Upload> you should probably leave it alone */
    getValueFromEvent?: (...e: any) => any;

    label?: React.ReactNode;
    labelCol?: ColProps;
    wrapperCol?: ColProps;

    // Kinda from React
    key?: React.Key;
}

export class FieldDecorator extends React.Component<FieldDecoratorProps> {
    constructor(props: FieldDecoratorProps) {
        super(props);
        bindAllOfThis(this, FieldDecorator.prototype);
    }

    private defaultGetValueFromEvent(e: any) {
        if (!e || !e.target) {
            return e;
        }
        const { target } = e;
        return target.type === 'checkbox' ? target.checked : target.value;
    }

    render() {
        let children: React.ReactNode = this.props.children;

        // Some fun defaults that I have had to use can be found here: https://github.com/react-component/form#option-object
        let fieldDecorator = this.props.form.getFieldDecorator(this.props.name, {
            initialValue: this.props.initialValue == null ? undefined : this.props.initialValue,
            valuePropName: this.props.valuePropName || "value", // Default set in antd
            getValueFromEvent: this.props.getValueFromEvent || this.defaultGetValueFromEvent, // Default set in antd somewhere
            rules: this.props.rules == null || this.props.rules.length === 0 ? undefined : this.props.rules
        });

        if (Array.isArray(children)) {
            console.warn("Form Item children must be a single react component, not an array. Rendering first element");
            children = children[0];
        }

        return <Form.Item key={this.props.key} label={this.props.label} labelCol={this.props.labelCol} wrapperCol={this.props.wrapperCol}>
            {fieldDecorator(this.props.children)}
        </Form.Item>;
    }

    public static defaultGetValueFromEventForUpload(e: any) {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }
}
