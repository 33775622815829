import * as React from 'react';
import { connect } from 'react-redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import { ClassroomDTO } from '../../models/classroom/classroomDTO';
import { PageProps } from '../../models/common/ComponentProps';
import { ClassroomController } from '../../api/ClassroomController';
import { SchoolController } from '../../api/SchoolController';
import { AccountController } from '../../api/AccountController';
import { notification, Spin } from 'antd';
import { CreateClassroomDTO } from '../../models/classroom/createClassroomDTO';
import { UpdateClassroomDTO } from '../../models/classroom/updateClassroomDTO';
import ErrorDTO from '../../models/common/ErrorDTO';
import Routes from '../../core/Routes';
import TeacherAddEditClassroomForm from './TeacherAddEditClassroomForm';
import { TeacherDTO } from '../../models/teacher/teacherDTO';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';

interface TeacherAddEditClassroomProps extends PageProps<{ classroomId?: string }> {
    User: UserState;
}

interface TeacherAddEditClassroomState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    idFromUrl?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: ClassroomDTO;

    teachers: TeacherDTO[];
}

class TeacherAddEditClassroom extends React.Component<TeacherAddEditClassroomProps, TeacherAddEditClassroomState> {
    private notificationKey = "TeacherAddEditClassroomPage";
    constructor(props: TeacherAddEditClassroomProps) {
        super(props);
        bindAllOfThis(this, TeacherAddEditClassroom.prototype);

        let isEdit = props.match.params.classroomId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            idFromUrl: props.match.params.classroomId,
            isLoading: true,
            formData: null,
            // schoolId: null,
            teachers: [],
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadFormData(), this.loadTeachers()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadTeachers() {
        return SchoolController.GetTeachersBySchool(this.props.User.schoolId).then(teachersResult => {
            // Place us at the top!
            let teachers = teachersResult.data;
            let self = teachers.find(x => x.id === this.props.User.id);
            if (self != null) {
                // Should never be null, but you never know...
                teachers = [self, ...teachers.filter(x => x.id !== self.id)];
            }

            this.setState({ teachers: teachers });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        // Load the classroom here
        return ClassroomController.GetGetClassroom(this.state.idFromUrl).then(result => {
            this.setState({ formData: result.data });
        });
    }

    handleOnSubmit(data: ClassroomDTO) {
        notification.info({
            key: this.notificationKey,
            message: "Saving Classroom",
            description: "Please wait while we save the classroom...",
            duration: 0
        });

        this.setState({ isSubmitting: true }, () => {
            let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);

            request.then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Saved Classroom",
                    description: "Successfully saved the classroom!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isSubmitting: false });
                this.props.history.push(Routes.TEACHER_CLASSROOMS().toRoute);
            }).catch(error => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Save Classroom",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isSubmitting: false });
            });
        });
    }

    handleOnSubmitAdd(data: ClassroomDTO) {
        let request: CreateClassroomDTO = {
            gradeLevel: data.gradeLevel,
            name: data.name,
            schoolId: this.props.User.schoolId,
            teacherId: data.teacherId,
            schoolYearId: data.schoolYearId,
        };
        return ClassroomController.PostCreateClassroom(request);
    }

    handleOnSubmitUpdate(data: ClassroomDTO) {
        let request: UpdateClassroomDTO = {
            classroomId: data.id,
            gradeLevel: data.gradeLevel,
            name: data.name,
            schoolId: data.schoolId,
            teacherId: data.teacherId,
            schoolYearId: data.schoolYearId,
        };

        return ClassroomController.PutUpdateClassroom(request);
    }

    renderHeader() {
        return this.state.isEdit ?
            <h1>Edit Classroom</h1> :
            <h1>Add Classroom</h1>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            {this.renderHeader()}
            <TeacherAddEditClassroomForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
                teachers={this.state.teachers}
            />
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherAddEditClassroom);
