import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Select, Tooltip } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import moment, { Moment } from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import { ClassroomDTO } from '../../models/classroom/classroomDTO';
import { TeacherDTO } from '../../models/teacher/teacherDTO';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { SchoolYear, SchoolYears } from '../../constants/SchoolYears';
import Routes from '../../core/Routes';
import { Link } from 'react-router-dom';


interface TeacherAddEditClassroomFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: ClassroomDTO) => void;
    initialValues?: Partial<ClassroomDTO>;
    teachers: TeacherDTO[];
}

interface TeacherAddEditClassroomFormState {
    isEdit: boolean;

    filteredTeachers: TeacherDTO[];
    teacherFilter: string;

    filteredSchoolYears: SchoolYear[];
    schoolYearFilter: string;
}

class TeacherAddEditClassroomForm extends React.Component<TeacherAddEditClassroomFormProps, TeacherAddEditClassroomFormState> {
    private gradeLevels: KeyLabel[] = [
        { key: "-1", label: "Pre-K" },
        { key: "0", label: "K" },
        { key: "1", label: "1st" },
        { key: "2", label: "2nd" },
        { key: "3", label: "3rd" },
        { key: "4", label: "4th" },
        { key: "5", label: "5th" },
        { key: "6", label: "6th" },
        { key: "7", label: "7th" },
        { key: "8", label: "8th" },
        { key: "9", label: "9th" },
        { key: "10", label: "10th" },
        { key: "11", label: "11th" },
        { key: "12", label: "12th" },
    ];

    // Just need to initialize sub objects
    constructor(props: TeacherAddEditClassroomFormProps) {
        super(props);
        bindAllOfThis(this, TeacherAddEditClassroomForm.prototype);

        let isEdit = props.initialValues != null;

        this.state = {
            isEdit: isEdit,

            filteredTeachers: this.props.teachers,
            teacherFilter: "",

            filteredSchoolYears: SchoolYears.All,
            schoolYearFilter: ""
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: ClassroomDTO): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            values.schoolId = this.props.initialValues != null ? this.props.initialValues.schoolId : null;

            // The select form items return a key label not ids
            // so for now we are doing this to get the id
            values.teacherId = ((values.teacherId as any) as KeyLabel).key;
            // values.gradeLevel = Number(((values.gradeLevel as any) as KeyLabel).key);
            values.gradeLevel = 0; // Grades are now being hidden, so we will just return 0, which is for 'K'

            // values.schoolYearId = this.state.isEdit ?
            //     this.props.initialValues.schoolYearId :
            //     ((values.schoolYearId as any) as KeyLabel).key;
            values.schoolYearId = values.schoolYearId == null
                ? this.props.initialValues.schoolYearId :
                ((values.schoolYearId as any) as KeyLabel).key;

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    handleTeacherFilterChange(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let teachers = this.props.teachers.filter(x => (x.lastName.toLowerCase() + ", " + x.firstName.toLowerCase()).includes(filter));
        this.setState({
            teacherFilter: filter,
            filteredTeachers: teachers,
        });
    }

    handleSchoolYearFilterChange(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let schoolYears = SchoolYears.All.filter(x => x.startYear.toString().includes(filter));
        this.setState({
            schoolYearFilter: filter,
            filteredSchoolYears: schoolYears,
        });
    }

    renderGradeSelect() {
        return <Select
            placeholder={"Please select a grade"}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
        >
            {this.gradeLevels.map(gradeLevel => <Select.Option key={gradeLevel.key} value={gradeLevel.key}>{gradeLevel.label}</Select.Option>)}
        </Select>;
    }

    renderSchoolYearSelect() {
        let { initialValues } = this.props;
        let disableSchoolYear = initialValues != null && initialValues.numberOfStudents > 0;
        if (disableSchoolYear) {
            return <Tooltip title='Students have already been added to classroom and the school year cannot be changed'>
                <Input value={initialValues.schoolYear.startYear + " - " + initialValues.schoolYear.endYear} className='ant-input-disabled' />
            </Tooltip>;
        }
        return <Select
            placeholder={"Please select a school year"}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
            showSearch
            disabled={disableSchoolYear}
            onChange={this.handleSchoolYearFilterChange}
            onSearch={this.handleSchoolYearFilterChange}
        >
            {this.state.filteredSchoolYears.map(schoolYear => <Select.Option key={`schoolYear${schoolYear.id}`} value={`${schoolYear.id}`}>{schoolYear.startYear} - {schoolYear.endYear}</Select.Option>)}
        </Select>;
    }

    renderTeacherSelect() {
        return <Select
            placeholder={"Please select a teacher"}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
            showSearch
            onChange={this.handleTeacherFilterChange}
            onSearch={this.handleTeacherFilterChange}
        >
            {this.state.filteredTeachers.map(teacher => <Select.Option key={`teacher${teacher.id}`} value={`${teacher.id}`}>{teacher.lastName + ", " + teacher.firstName}</Select.Option>)}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        // let initialGradeValue = this.state.isEdit ?
        //     this.gradeLevels.find(x => x.key === initialValues.gradeLevel.toString()) :
        //     null;

        let selectedTeacher = this.state.isEdit ? this.props.teachers.find(x => x.id === initialValues.teacherId) : null;
        let initialTeacherValue = this.state.isEdit
            ? { key: initialValues.teacherId, label: selectedTeacher.lastName + ", " + selectedTeacher.firstName } as KeyLabel
            : null;
        let initialSchoolYearValue = this.state.isEdit
            ? { key: initialValues.schoolYearId, label: initialValues.schoolYear.startYear + " - " + initialValues.schoolYear.endYear } as KeyLabel
            : null;

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Name"
                name={NameOf<ClassroomDTO>("name")}
                initialValue={initialValues.name}
                rules={[{ required: true, message: "Name is required." }]}
            >
                <Input size="large" placeholder="Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            {/* <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Grade"
                name={NameOf<ClassroomDTO>("gradeLevel")}
                initialValue={initialGradeValue}
                rules={[{ required: true, message: "Grade is required." }]}
            >
                {this.renderGradeSelect()}
            </FieldDecorator> */}

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Teacher"
                name={NameOf<ClassroomDTO>("teacherId")}
                initialValue={initialTeacherValue}
                rules={[{ required: true, message: 'Teacher is required.' }]}
            >
                {this.renderTeacherSelect()}
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="School Year"
                name={NameOf<ClassroomDTO>("schoolYearId")}
                initialValue={initialSchoolYearValue}
                rules={[{ required: true, message: 'School Year is required.' }]}
            >
                {this.renderSchoolYearSelect()}
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save Classroom" : "Add Classroom"}
                </Button>

                <Link to={Routes.GET.TEACHER_CLASSROOMS}>
                    <Button className="login-form-button" size="large">
                        Go Back
                    </Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<TeacherAddEditClassroomFormProps>()(TeacherAddEditClassroomForm);
