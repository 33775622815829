import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import { SurveyQuestionDTO } from '../../models/surveyQuestion/surveyQuestionDTO';
import { LessonController } from '../../api/LessonController';
import { Input, Button, Spin } from 'antd';
import AnswerDTO from '../../models/common/AnswerDTO';
import { SurveyResponseController } from '../../api/SurveyResponseController';
import RadioGroup from 'antd/lib/radio/group';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';

interface WorkflowSurveyPageProps extends PageProps<{ courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowSurveyPageState {
    lesson: LessonDTO;
    questions: SurveyQuestionDTO[];
    // answers are what get submitted to the back-end, they are not SurveyQuestionAnswer records
    answers: AnswerDTO[];
    isLoading: boolean;
    isSubmitting: boolean;
    /** Indicates that the page has completed */
    isComplete: boolean;
}

class WorkflowSurveyPage extends React.Component<WorkflowSurveyPageProps, WorkflowSurveyPageState> {
    constructor(props: WorkflowSurveyPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowSurveyPage.prototype);

        this.state = {
            lesson: null,
            questions: [],
            answers: [],
            isLoading: true,
            isSubmitting: false,
            isComplete: false
        };
    }

    componentDidMount() {
        let lesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);

        LessonController.GetSurveyQuestionsByLesson(lesson.id).then(response => {
            let questions = response.data;

            let answers = questions.map<AnswerDTO>(question => {
                return {
                    questionId: question.id,
                    // If the question is multiple choice do not default answer
                    answerId: question.multipleChoice ? null : question.surveyQuestionAnswer[0].id,
                    multipleChoice: question.multipleChoice
                };
            });

            this.setState({
                lesson: lesson,
                questions: questions,
                answers: answers,
                isLoading: false,
            }, () => this.figureOutOnNext);
        });
    }

    figureOutOnNext() {
        this.props.setButtonState("visible", "visible");
    }

    /** Returns true if all questions have been answered */
    checkQuestionAnswers(): boolean {
        return !this.state.isLoading && !this.state.answers.some(x => {
            let isIncomplete = x.multipleChoice ?
                x.answerId == null :
                x.text == null || x.text.trim().length < 1;

            return isIncomplete;
        });
    }

    handleSubmitClick() {
        // Check if we are missing questions
        if (!this.checkQuestionAnswers()) {
            return;
        }

        // Save Answers
        this.setState({ isSubmitting: true });
        let promises = this.state.answers.map(answer => {
            let request = SurveyResponseController.PostCreateSurveyResponse({
                teacherId: this.props.User.id,
                surveyQuestionAnswerId: answer.answerId,
                textResponse: answer.text,
            });
            return request;
        });

        Promise.all(promises).then(results => {
            this.setState({
                isSubmitting: false,
                isComplete: true
            });
        });
    }

    // Multiple choice
    onAnswerSelect(e: RadioChangeEvent, questionId: string) {
        let answers = this.state.answers;
        let answer = answers.find(x => x.questionId === questionId);

        // Set answer
        answer.answerId = e.target.value;

        this.setState({
            answers: answers,
        });
    }

    // Free form text
    onAnswerChange(e: React.ChangeEvent<HTMLTextAreaElement>, questionId: string) {
        let answers = this.state.answers;
        let answer = answers.find(x => x.questionId === questionId);

        // Set answer
        answer.text = e.target.value;

        this.setState({
            answers: answers,
        });
    }

    renderQuestions() {
        let questions = this.state.questions;

        if (questions.length < 1) {
            return <h3>There are no survey questions for this lesson.</h3>;
        }

        return questions
            .sort((first, second) => first.ordering - second.ordering)
            .map(question => {
                return <div key={question.id}>
                    <h3>{question.ordering}. {question.text}</h3>

                    {question.multipleChoice ?
                        <RadioGroup onChange={e => this.onAnswerSelect(e, question.id)} className="vertical left-padding" value={this.state.answers.find(x => x.questionId === question.id).answerId} >
                            {question.surveyQuestionAnswer
                                .sort((first, second) => first.ordering - second.ordering)
                                .map(answer => <Radio key={answer.id} value={answer.id}>{answer.text}</Radio>)}
                        </RadioGroup>
                        :
                        <Input.TextArea
                            placeholder="Enter your answer here"
                            //autosize={{ minRows: 2, maxRows: 6 }}//autosize still exists, why is this error???
                            onChange={(e) => this.onAnswerChange(e, question.id)}
                        />
                    }
                </div>;
            });
    }

    renderSubmitButton() {
        return <Button type="primary" onClick={this.handleSubmitClick} disabled={!this.checkQuestionAnswers()} loading={this.state.isSubmitting}>Submit</Button>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        if (this.state.isComplete) {
            return <div>
                <h1>Survey</h1>
                <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
                <p>Thank you for taking the time to participate in this survey! You can now close this page</p>
            </div>;
        }

        return <div>
            <h1>Survey</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            <p>We greatly appreciate any and all feedback that you provide. Let us know what you think below, then press submit!</p>
            {this.renderQuestions()}
            <br />
            {this.renderSubmitButton()}
        </div>; 
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSurveyPage);
