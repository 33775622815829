import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { CourseController } from '../../api/CourseController';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { SimpleLessonDTO } from '../../models/lesson/simpleLessonDTO';

interface AdminCoursesPageTableDataObject {
    id: string;
    name: string;
    description: string;
    gradeLevelStart: number;
    gradeLevelStartString: string;
    gradeLevelEnd: number;
    gradeLevelEndString: string;
    lessons: SimpleLessonDTO[];
}

interface AdminCoursesPageProps extends PageProps<{}> {
}

interface AdminCoursesPageState {
    isLoading: boolean;
    tableData: AdminCoursesPageTableDataObject[];
}

class AdminCoursesPage extends React.Component<AdminCoursesPageProps, AdminCoursesPageState> {
    tableColumns: ColumnProps<AdminCoursesPageTableDataObject>[];
    constructor(props: AdminCoursesPageProps) {
        super(props);
        bindAllOfThis(this, AdminCoursesPage.prototype);

        this.tableColumns = [{
            title: 'View Course',
            key: 'view',
            align: 'center',
            width: 60,
            render: (text, record) => {
                return (
                    <Tooltip title="View Course" placement="top">
                        <Link to={Routes.WORKFLOW_COURSE_HOME(record.id).toRoute}>
                            <Button shape="circle" icon={<EyeOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 240,
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<AdminCoursesPageTableDataObject>("name", "Name"),
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            ...getColumnSearchProps<AdminCoursesPageTableDataObject>("description", "Description"),
        }, {
            title: 'Grade Start',
            dataIndex: 'gradeLevelStartString',
            key: 'gradeLevelStartString',
            width: 100,
            sorter: (a, b) => a.gradeLevelStart - b.gradeLevelStart,
            ...getColumnSearchProps<AdminCoursesPageTableDataObject>("gradeLevelStartString", "Start Grade"),
        }, {
            title: 'Grade End',
            dataIndex: 'gradeLevelEndString',
            key: 'gradeLevelEndString',
            width: 100,
            sorter: (a, b) => a.gradeLevelEnd - b.gradeLevelEnd,
            ...getColumnSearchProps<AdminCoursesPageTableDataObject>("gradeLevelEndString", "End Grade"),
        }, {
            title: 'Number of Lessons',
            dataIndex: 'lessons.length',
            key: 'lessonCount',
            width: 110,
            sorter: (a, b) => (a.lessons || []).length - (b.lessons || []).length,
        }, {
            title: 'Edit',
            key: 'edit',
            align: 'center',
            width: 60,
            render: (text, record) => {
                return (
                    <Tooltip title="Edit Course" placement="top">
                        <Link to={Routes.ADMIN_COURSE_ADD_EDIT(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: true,
            tableData: null
        };
    }

    componentDidMount() {
        this.loadTableData();
    }

    loadTableData() {
        CourseController.GetCourses().then(result => {
            let tableData = result.data.map<AdminCoursesPageTableDataObject>(record => ({
                ...record,
                gradeLevelStartString: record.gradeLevelStart > 0 ? record.gradeLevelStart.toString() : record.gradeLevelStart === 0 ? "K" : "PK",
                gradeLevelEndString: record.gradeLevelEnd > 0 ? record.gradeLevelEnd.toString() : record.gradeLevelEnd === 0 ? "K" : "PK",
            }));
            this.setState({
                tableData: tableData,
                isLoading: false
            });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return (
            <div>
                <h1>Courses</h1>
                <Link key="table-link" to={Routes.ADMIN_COURSE_ADD_EDIT("").toRoute}>
                    <Button icon={<EditOutlined />}>Add Course</Button>
                </Link>
                <br /><br />
                <Table
                    rowKey={record => record.id}
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                />
            </div>
        );
    }
}

export default AdminCoursesPage;
