import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import bindAllOfThis from '../../utils/BindThisHelper';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';
import { SchoolYear, SchoolYears } from '../../constants/SchoolYears';
import { UploadOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Spin, Select, Button, notification, Row, Col, DatePicker } from 'antd';
import { CourseController } from '../../api/CourseController';
import ErrorDTO from '../../models/common/ErrorDTO';
import { ReportController } from '../../api/ReportController';
import { AdminController } from '../../api/AdminController';
import EnvironmentStaticContent from '../../constants/EnvironmentStaticContent';
import moment from 'moment';
import Upload, { UploadProps } from 'antd/lib/upload';


interface AdminHomePageProps extends RouteComponentProps<{}> {
}

interface AdminHomePageState {
    isUploadingHelpInstructions: boolean;
    isDownloadingTeacherSurveyResponses: boolean;
    isDownloadingTeachers: boolean;
    isDownloadingLicenses: boolean;
    isDownloadingLessonClassroomRatings: boolean;
    isLoading: boolean;

    // School overview
    selectedSchoolOverviewCourse: SimpleCourseDTO;
    selectedSchoolOverviewSchoolYear: SchoolYear;
    isDownloadingSchoolOverview: boolean;

    // Self assessment avg report
    selectedSelfAssessmentAvgSchoolYear: SchoolYear;
    isDownloadingSelfAssessmentAvgReport: boolean;

    // Students Reached report
    selectedStudentsReachedSchoolYear: SchoolYear;
    isDownloadingStudentsReachedReport: boolean;

    // Overall Effectiveness report
    selectedOverallEffectivenessCourse: SimpleCourseDTO;
    selectedOverallEffectivenessSchoolYear: SchoolYear;
    isDownloadingOverallEffectivenessReport: boolean;

    // Teacher survey responses
    selectedSinceDate: string;
    selectedDemoLesson: string;

    courses: SimpleCourseDTO[];
    schoolYears: SchoolYear[];

    filteredCourses: SimpleCourseDTO[];
    courseFilter: string;

    filteredSchoolYears: SchoolYear[];
    schoolYearFilter: string;
}

class AdminHomePage extends React.Component<AdminHomePageProps, AdminHomePageState> {
    private notificationKey = "AdminHome";

    constructor(props: AdminHomePageProps) {
        super(props);
        bindAllOfThis(this, AdminHomePage.prototype);

        this.state = {
            isUploadingHelpInstructions: false,
            isDownloadingSchoolOverview: false,
            isDownloadingTeacherSurveyResponses: false,
            isDownloadingTeachers: false,
            isDownloadingLicenses: false,
            isDownloadingLessonClassroomRatings: false,
            isDownloadingSelfAssessmentAvgReport: false,
            isLoading: true,
            selectedSchoolOverviewCourse: null,
            selectedSchoolOverviewSchoolYear: null,
            selectedSinceDate: null,
            selectedDemoLesson: null,
            courses: [],
            schoolYears: [],
            courseFilter: "",
            filteredCourses: [],
            schoolYearFilter: "",
            filteredSchoolYears: [],
            selectedSelfAssessmentAvgSchoolYear: null,
            selectedStudentsReachedSchoolYear: null,
            isDownloadingStudentsReachedReport: false,
            isDownloadingOverallEffectivenessReport: false,
            selectedOverallEffectivenessCourse: null,
            selectedOverallEffectivenessSchoolYear: null,
        };
    }

    componentDidMount() {
        return CourseController.GetCourses().then(result => {
            // Get all courses not just available for purchase
            this.setState({
                isLoading: false,
                courses: result.data,
                filteredCourses: result.data,
                schoolYears: SchoolYears.All,
                filteredSchoolYears: SchoolYears.All
            });
        });
    }

    handleSchoolOverviewSchoolYearChange(value : any) { //Need to figure out what exactly it is getting passed... For ALL select onChanges
        if (value == null) {
            this.setState({ selectedSchoolOverviewSchoolYear: null });
        } else {
            let selectedSchoolYear = this.state.schoolYears.find(x => x.id === value.key);
            this.setState({ selectedSchoolOverviewSchoolYear: selectedSchoolYear });
        }
    }

    handleOverallEffectivenessSchoolYearChange(value: any) {
        if (value == null) {
            this.setState({ selectedOverallEffectivenessSchoolYear: null });
        } else {
            let selectedSchoolYear = this.state.schoolYears.find(x => x.id === value.key);
            this.setState({ selectedOverallEffectivenessSchoolYear: selectedSchoolYear });
        }
    }

    handleStudentsReachedSchoolYearChange(value: any) {
        if (value == null) {
            this.setState({ selectedStudentsReachedSchoolYear: null });
        } else {
            let selectedSchoolYear = this.state.schoolYears.find(x => x.id === value.key);
            this.setState({ selectedStudentsReachedSchoolYear: selectedSchoolYear });
        }
    }

    handleSelfAssessmentAvgSchoolYearChange(value: any) {
        if (value == null) {
            this.setState({ selectedSelfAssessmentAvgSchoolYear: null });
        } else {
            let selectedSchoolYear = this.state.schoolYears.find(x => x.id === value.key);
            this.setState({ selectedSelfAssessmentAvgSchoolYear: selectedSchoolYear });
        }
    }

    handleSchoolYearFilter(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let schoolYears = this.state.schoolYears.filter(x => x.startYear.toString().toLowerCase().includes(filter));
        this.setState({
            schoolYearFilter: filter,
            filteredSchoolYears: schoolYears,
        });
    }

    handleSchoolOverviewCourseChange(value: any) {
        if (value == null) {
            this.setState({ selectedSchoolOverviewCourse: null });
        } else {
            let selectedCourse = this.state.courses.find(x => x.id === value.key);
            this.setState({ selectedSchoolOverviewCourse: selectedCourse });
        }
    }

    handleOverallEffectivenessCourseChange(value: any) {
        if (value == null) {
            this.setState({ selectedOverallEffectivenessCourse: null });
        } else {
            let selectedCourse = this.state.courses.find(x => x.id === value.key);
            this.setState({ selectedOverallEffectivenessCourse: selectedCourse });
        }
    }

    handleCourseFilter(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let courses = this.state.courses.filter(x => x.name.toLowerCase().includes(filter));
        this.setState({
            courseFilter: filter,
            filteredCourses: courses,
        });
    }

    handleDownloadSchoolOverview() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the school overview...",
            duration: 0
        });

        this.setState({ isDownloadingSchoolOverview: true }, async () => {
            ReportController.DownloadSchoolOverview(this.state.selectedSchoolOverviewCourse.id, this.state.selectedSchoolOverviewSchoolYear.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'SchoolOverview.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded school overview!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingSchoolOverview: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingSchoolOverview: false });
            });
        });
    }

    handleDemoLessonChange(value: any) {
        if (value == null) {
            this.setState({ selectedDemoLesson: null });
        } else {
            let selectedDemoLesson = value.key;
            this.setState({ selectedDemoLesson: selectedDemoLesson });
        }
    }

    handleSinceDateChange(date: any, dateString: any) {
        if (date == null) {
            this.setState({ selectedSinceDate: null });
        } else {
            this.setState({ selectedSinceDate: moment(dateString).format('YYYY-MM-DDT00:00:00') });
        }
    }

    handleDownloadTeacherSurveyResponses() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the teacher survey responses...",
            duration: 0
        });

        this.setState({ isDownloadingTeacherSurveyResponses: true }, async () => {
            ReportController.DownloadTeacherSurveyResponses(this.state.selectedDemoLesson, this.state.selectedSinceDate).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'TeacherSurveyResponses.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded teacher survey responses!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingTeacherSurveyResponses: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingTeacherSurveyResponses: false });
            });
        });
    }

    handleDownloadTeachers() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the registered teachers...",
            duration: 0
        });

        this.setState({ isDownloadingTeachers: true }, async () => {
            ReportController.DownloadTeachers().then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'RegisteredTeachers.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded registered teachers!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingTeachers: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingTeachers: false });
            });
        });
    }

    handleDownloadLicenses() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the purchased licenses...",
            duration: 0
        });

        this.setState({ isDownloadingLicenses: true }, async () => {
            ReportController.DownloadLicenses().then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'PurchasedLicenses.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded purchased licenses!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingLicenses: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingLicenses: false });
            });
        });
    }

    handleDownloadSelfAssessmentAvgReport() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the report...",
            duration: 0
        });

        this.setState({ isDownloadingSelfAssessmentAvgReport: true }, async () => {
            ReportController.DownloadSelfAssessmentAvgReport(this.state.selectedSelfAssessmentAvgSchoolYear.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'SelfAssessmentAvgReport.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded report!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingSelfAssessmentAvgReport: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingSelfAssessmentAvgReport: false });
            });
        });
    }

    handleDownloadStudentsReachedReport() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the report...",
            duration: 0
        });

        this.setState({ isDownloadingStudentsReachedReport: true }, async () => {
            ReportController.DownloadStudentsReachedReport(this.state.selectedStudentsReachedSchoolYear.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'StudentsReachedReport.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded report!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingStudentsReachedReport: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingStudentsReachedReport: false });
            });
        });
    }

    handleDownloadOverallEffectivenessReport() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the report...",
            duration: 0
        });

        this.setState({ isDownloadingOverallEffectivenessReport: true }, async () => {
            ReportController.DownloadOverallEffectivenessReport(this.state.selectedOverallEffectivenessSchoolYear.id, this.state.selectedOverallEffectivenessCourse.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'OverallEffectivenessReport.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded report!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingOverallEffectivenessReport: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingOverallEffectivenessReport: false });
            });
        });
    }

    handleDownloadLessonClassroomRatings() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the lesson classroom ratings...",
            duration: 0
        });

        this.setState({ isDownloadingLessonClassroomRatings: true }, async () => {
            ReportController.DownloadLessonClassroomRatings().then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'LessonClassroomRatings.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded lesson classroom ratings!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingLessonClassroomRatings: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingLessonClassroomRatings: false });
            });
        });
    }

    handleHelpInstructionsUpload(options: any) {
        notification.info({
            key: this.notificationKey,
            message: "Uploading Help Instructions",
            description: "Please wait while we upload the help instructions...",
            duration: 0
        });

        let formData = new FormData();
        formData.append('file', options.file);

        this.setState({ isUploadingHelpInstructions: true });
        AdminController.PutAdminHelpInstructions(formData).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Successfully Uploaded Help Instructions",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({isUploadingHelpInstructions: false});
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];

            notification.error({
                key: this.notificationKey,
                message: "Failed to Upload Help Instructions",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isUploadingHelpInstructions: false });
        });
    }

    renderFileUpload() {
        let uploadProps: UploadProps = {
            name: "file",
            action: "",
            listType: "picture",
            type: "select",
            customRequest: this.handleHelpInstructionsUpload
        };
        return (
            <Upload key="upload" {...uploadProps}>
                <Button loading={this.state.isUploadingHelpInstructions}>
                    <UploadOutlined /> <span>Select a file</span>
                </Button>
            </Upload>
        );
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        let wrapperCol = {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 14 },
            lg: { span: 12 },
            xl: { span: 10 },
            xxl: { span: 8 },
        };

        return (
            <div>
                <h1>Admin Dashboard</h1>

                <div>
                    <br/>
                    <h2>Upload New Teacher Help Instructions Here</h2>
                    {this.renderFileUpload()}
                    <br/><br/>
                </div>

                <div>
                    <h2>School Overview Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a school year"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleSchoolOverviewSchoolYearChange}
                            onSearch={this.handleSchoolYearFilter}
                        >
                            {this.state.filteredSchoolYears
                                .sort((first, second) => first.startYear)
                                .map(schoolYear => <Select.Option key={`schoolYear${schoolYear.id}`} value={`${schoolYear.id}`}>{schoolYear.startYear} - {schoolYear.endYear}</Select.Option>)}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a course"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleSchoolOverviewCourseChange}
                            onSearch={this.handleCourseFilter}
                        >
                            {this.state.filteredCourses
                                .sort((first, second) => first.name.localeCompare(second.name))
                                .map(course => <Select.Option key={`course${course.id}`} value={`${course.id}`}>{course.name}</Select.Option>)}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon type={this.state.isDownloadingSchoolOverview ? "loading" : "download"} />} disabled={this.state.isDownloadingSchoolOverview || this.state.selectedSchoolOverviewCourse == null || this.state.selectedSchoolOverviewSchoolYear == null} onClick={() => this.handleDownloadSchoolOverview()}>
                            Download School Overview
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Teacher Survey Responses Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a demo course/lesson"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            onChange={this.handleDemoLessonChange}
                        >
                        {/* TODO add new demos? */}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Select since date"
                            onChange={this.handleSinceDateChange}
                        />
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon
                            type={this.state.isDownloadingTeacherSurveyResponses ? "loading" : "download"} />} disabled={this.state.isDownloadingTeacherSurveyResponses || this.state.selectedSinceDate == null || this.state.selectedDemoLesson == null} onClick={() => this.handleDownloadTeacherSurveyResponses()}>
                            Download Teacher Survey Responses
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Registered Teacher Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon type={this.state.isDownloadingTeachers ? "loading" : "download"} />} onClick={() => this.handleDownloadTeachers()}>
                            Download Registered Teachers
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Purchased License Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon type={this.state.isDownloadingLicenses ? "loading" : "download"} />} onClick={() => this.handleDownloadLicenses()}>
                            Download Purchased Licenses
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Lesson Classroom Ratings Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon
                            type={this.state.isDownloadingLessonClassroomRatings ? "loading" : "download"} />} onClick={() => this.handleDownloadLessonClassroomRatings()}>
                            Download Lesson Classroom Ratings
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Self Assessment Average Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a school year"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleSelfAssessmentAvgSchoolYearChange}
                            onSearch={this.handleSchoolYearFilter}
                        >
                            {this.state.filteredSchoolYears
                                .sort((first) => first.startYear)
                                .map(schoolYear => <Select.Option key={`schoolYear${schoolYear.id}`} value={`${schoolYear.id}`}>{schoolYear.startYear} - {schoolYear.endYear}</Select.Option>)}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon
                            type={this.state.isDownloadingSelfAssessmentAvgReport ? "loading" : "download"} />}
                            onClick={() => this.handleDownloadSelfAssessmentAvgReport()}
                            disabled={this.state.selectedSelfAssessmentAvgSchoolYear == null}>
                            Download Self Assessment Average Report
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Students Reached Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a school year"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleStudentsReachedSchoolYearChange}
                            onSearch={this.handleSchoolYearFilter}
                        >
                            {this.state.filteredSchoolYears
                                .sort((first) => first.startYear)
                                .map(schoolYear => <Select.Option key={`schoolYear${schoolYear.id}`} value={`${schoolYear.id}`}>{schoolYear.startYear} - {schoolYear.endYear}</Select.Option>)}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon
                            type={this.state.isDownloadingStudentsReachedReport ? "loading" : "download"} />}
                            onClick={() => this.handleDownloadStudentsReachedReport()}
                            disabled={this.state.selectedStudentsReachedSchoolYear == null}>
                            Download Students Reached Report
                        </Button>
                    </Col></Row>
                </div>

                <br />
                <br />
                <div>
                    <h2>Overall Effectiveness Report</h2>
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a school year"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleOverallEffectivenessSchoolYearChange}
                            onSearch={this.handleSchoolYearFilter}
                        >
                            {this.state.filteredSchoolYears
                                .sort((first) => first.startYear)
                                .map(schoolYear => <Select.Option key={`schoolYear${schoolYear.id}`} value={`${schoolYear.id}`}>{schoolYear.startYear} - {schoolYear.endYear}</Select.Option>)}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Select
                            placeholder="Please select a course"
                            filterOption={false}
                            notFoundContent={null}
                            style={{ width: "100%" }}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleOverallEffectivenessCourseChange}
                            onSearch={this.handleCourseFilter}
                        >
                            {this.state.filteredCourses
                                .sort((first, second) => first.name.localeCompare(second.name))
                                .map(course => <Select.Option key={`course${course.id}`} value={`${course.id}`}>{course.name}</Select.Option>)}
                        </Select>
                    </Col></Row>
                    <br />
                    <Row><Col {...wrapperCol}>
                        <Button icon={<LegacyIcon
                            type={this.state.isDownloadingOverallEffectivenessReport ? "loading" : "download"} />}
                            onClick={() => this.handleDownloadOverallEffectivenessReport()}
                            disabled={this.state.selectedOverallEffectivenessSchoolYear == null}>
                            Download Overall Effectiveness Report
                        </Button>
                    </Col></Row>
                </div>
            </div>
        );
    }
}

export default AdminHomePage;
