import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import confetti from 'canvas-confetti';

interface WorkflowResultsPageProps extends PageProps<{ courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowResultsPageState {
    lesson: LessonDTO;
    isLoading: boolean;
}

class WorkflowResultsPage extends React.Component<WorkflowResultsPageProps, WorkflowResultsPageState> {
    constructor(props: WorkflowResultsPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowResultsPage.prototype);

        this.state = {
            lesson: null,
            isLoading: true,
        };
    }

    componentDidMount() {
        let lesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        this.setState({
            lesson: lesson,
            isLoading: false,
        });
        this.figureOutOnNext();
        this.launchConfetti();
    }

    launchConfetti(){
        //https://www.kirilv.com/canvas-confetti/ fireworks example
        var end = Date.now() + (5 * 1000);

        var interval : any = setInterval(function() {
            if (Date.now() > end) {
                return clearInterval(interval);
            }

            confetti({
                startVelocity: 30,
                spread: 360,
                ticks: 60,
                shapes: ['square'],
                origin: {
                    x: Math.random(),
                    // since they fall down, start a bit higher than random
                    y: Math.random() - 0.2
                }
            } as any);
        }, 200);
    }

    figureOutOnNext() {
        this.props.setButtonState("visible", "visible");
    }

    render() {
        return <div>
            <h1>Lesson Complete</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            <p>Congratulations! The next section will be completed by your parent or guardian. Please ask them to check their email.</p>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowResultsPage);
