import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import WorkflowAction from '../../redux/WorkflowAction';
import ReduxStoreModel from '../../redux/ReduxModel';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import { Roles, Role } from '../../constants/Roles';
import { PageProps } from '../../models/common/ComponentProps';

interface RoleRedirectPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
}

interface RoleRedirectPageState {
    isReady: boolean;
    route: string;
}

class RoleRedirectPage extends React.Component<RoleRedirectPageProps, RoleRedirectPageState> {
    constructor(props: RoleRedirectPageProps) {
        super(props);
        bindAllOfThis(this, RoleRedirectPage.prototype);

        this.state = {
            isReady: false,
            route: ""
        };
    }

    componentDidMount() {
        let route = this.whereToPutUser(this.props);
        this.setState({ route: route, isReady: true });
    }

    whereToPutUser(props: RoleRedirectPageProps): string {
        let user = props.User;
        if (user.state === "finished") {
            // We have a user!
            return this.roleBasedRedirect(user.role).toRoute;
        }
        // 'Failed' and 'none' come here so just send them to base_route
        return Routes.BASE_ROUTE().toRoute;
    }

    roleBasedRedirect(role: Role) {
        // I really hate that this name of this function exists even though i made it
        switch (role) {
            case Roles.Admin:
                return Routes.ADMIN_DASHBOARD();
            case Roles.Teacher:
                return Routes.TEACHER_DASHBOARD();
            case Roles.Student:
                return Routes.STUDENT_HOME();
            case Roles.Demo:
                return Routes.DEMO();
            case Roles.Parent:
                return Routes.STUDENT_HOME();
            default:
                return Routes.BASE_ROUTE();
        }
    }

    render(): JSX.Element {
        // Check if we have figured out whats going on
        if (!this.state.isReady) {
            return null;
        }

        // Purely going to render redirect tags
        return <Redirect to={this.state.route} />;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps)(RoleRedirectPage);
