import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Spin } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../../utils/BindThisHelper';
import { FieldDecorator } from '../../../components/formHelpers';
import { NameOf } from '../../../utils/NameOf';

import { KeyLabel, KeyValue } from '../../../models/common/KeyValueAndSimilar';
import { SimpleSchoolDTO } from '../../../models/school/simpleSchoolDTO';
import { Roles } from '../../../constants/Roles';
import { Link } from 'react-router-dom';
import Routes from '../../../core/Routes';
import DebugUtil from '../../../utils/DebugUtil';
import { disableAutoCompleteTags } from '../../../utils/AutoComplete';
import { SchoolController } from '../../../api/SchoolController';

import debounce from 'lodash/debounce';

export interface AccountFormDataObject {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    roleId: string;
    phoneNumber: string;
    /** Can be null for reasons */
    school?: KeyLabel;
    state?: string;
    password?: string;
}

interface AccountFormProps extends FormComponentProps {
    onSubmit: (data: AccountFormDataObject) => void;
    initialValues?: Partial<AccountFormDataObject>;
    isSubmitting: boolean;

    //schools: SimpleSchoolDTO[];
}

interface AccountFormState {
    isEdit: boolean;
    isSchoolDisabled: boolean;

    // Password dirty bit
    confirmDirty: boolean;
    schoolSearchText: string;
    fetchingSchools: boolean;
    schoolId?: string;
    schools: SimpleSchoolDTO[];
}

class AccountForm extends React.Component<AccountFormProps, AccountFormState> {
    constructor(props: AccountFormProps) {
        super(props);
        bindAllOfThis(this, AccountForm.prototype);

        let isEdit = props.initialValues != null && props.initialValues.id != null;

        this.fetchSchools = debounce(this.fetchSchools, 800);

        this.state = {
            isEdit: isEdit,
            isSchoolDisabled: true,
            confirmDirty: false,
            schoolSearchText: "",
            fetchingSchools: false,
            schoolId: "",
            schools: []
        };
    }

    /** Confirm Password Logic */
    handleConfirmBlur(e: any) {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    /** Confirm Password Logic */
    compareToFirstPassword(rule: any, value: any, callback: any) {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('The passwords do not match!');
        } else {
            callback();
        }
    }

    /** Confirm Password Logic */
    validateToNextPassword(rule: any, value: any, callback: any) {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }


    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AccountFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    fetchSchools(str: string) {
        if (str.length >= 3) {
            this.setState({
                fetchingSchools: true
            });
            SchoolController.GetSchoolsByName(str).then(response => {
                this.setState({
                    schools: response.data,
                    fetchingSchools: false
                });
            });
        }
    }

    handleSchoolChange(school: any) {
        this.setState({
            schoolId: school != null ? school.key : "",
        });
    }

    renderSchoolSelect() {
        return <Select
            labelInValue
            value={this.state.schoolSearchText}
            placeholder="Select School"
            notFoundContent={this.state.fetchingSchools ? <Spin size="small" /> : null}
            filterOption={false}
            allowClear
            showSearch
            onSearch={this.fetchSchools}
            onChange={this.handleSchoolChange}
            style={{ width: '100%' }}
            disabled={this.state.isSchoolDisabled}
        >
            {this.state.schools.map(d => (
                <Select.Option value={d.id}>{d.name}</Select.Option>
            ))}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        let initialValues = this.props.initialValues || {};
        let isTeacher = this.props.initialValues != null ? Roles.FindById(initialValues.roleId) === Roles.Teacher : false;

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark layout="vertical">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Email"
                name={NameOf<AccountFormDataObject>("email")}
                initialValue={initialValues.email}
                rules={[{ required: true, message: 'Email is required.' }, { type: 'email', message: 'Not a valid Email.' }]}
            >
                <Input size="large" disabled={true} placeholder="Email" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="First Name"
                name={NameOf<AccountFormDataObject>("firstName")}
                initialValue={initialValues.firstName}
                rules={[{ required: true, message: 'First Name is required.' }]}
            >
                <Input size="large" placeholder="First Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Last Name"
                name={NameOf<AccountFormDataObject>("lastName")}
                initialValue={initialValues.lastName}
                rules={[{ required: true, message: 'Last Name is required.' }]}
            >
                <Input size="large" placeholder="Last Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            {isTeacher && <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Phone Number"
                name={NameOf<AccountFormDataObject>("phoneNumber")}
                initialValue={initialValues.phoneNumber}
                rules={[{ required: true, message: 'Phone Number is required.' },
                        {min:7, message: 'Please enter a valid phone number'}]}
            >
                <Input size="large" placeholder="Phone Number" {...disableAutoCompleteTags()}/>
            </FieldDecorator> }
            {isTeacher &&
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="School"
                    name={NameOf<AccountFormDataObject>("school")}
                    initialValue={initialValues.school}
                >
                    {this.renderSchoolSelect()}
                </FieldDecorator>}
            {isTeacher && DebugUtil.isDebugEnabled() && <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label={<span style={{ color: "red", fontWeight: 700 }}>Debug Only</span>}
                name={"debug_do_not_use"}
            >
                <Button type="primary" onClick={() => {
                    this.setState({ isSchoolDisabled: false });
                }}>Allow School Edit</Button>
            </FieldDecorator>}


            <hr /><br />
            <h2>Change Password</h2>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Password"
                name={NameOf<AccountFormDataObject>("password")}
                rules={[{ validator: this.validateToNextPassword }]}
            >
                <Input size="large" type="password" aria-label="Password" placeholder="Password" {...disableAutoCompleteTags()}/>
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Confirm Password"
                name={"confirm"}
                rules={[{ validator: this.compareToFirstPassword }]}
            >
                <Input size="large" type="password" aria-label="Password Re-entry" placeholder="Reenter password" onBlur={this.handleConfirmBlur} />
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting} >
                    {this.state.isEdit ? "Save Changes" : "Add User"}
                </Button>
                <Link to={Routes.HOME().toRoute}>
                    <Button type="default" className="login-form-button" size="large" >Finish</Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AccountFormProps>()(AccountForm);
