import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Redirect } from 'react-router';
import { Table, Spin, Button, notification, Row, Col } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import UserAction from '../../redux/UserActions';

import TeacherSelectMySchoolForm from './TeacherSelectMySchoolForm';
import { SchoolController } from '../../api/SchoolController';
import { TeacherController } from '../../api/TeacherController';
import ErrorDTO from '../../models/common/ErrorDTO';
import { SimpleSchoolDTO } from '../../models/school/simpleSchoolDTO';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { UpdateTeacherDTO } from '../../models/teacher/updateTeacherDTO';
import { UpdateAccountDTO } from '../../models/account/updateAccountDTO';
import TeacherSelectMySchoolModal, { TeacherSelectMySchoolModalDataObject } from './TeacherSelectMySchoolModal';
import EnvironmentStaticContent from '../../constants/EnvironmentStaticContent';
import { SchoolSearchDTO } from '../../models/school/schoolSearchDTO';

interface TeacherSelectMySchoolProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    UpdateUserAccount: (request: UpdateAccountDTO) => void;
}

interface TeacherSelectMySchoolState {
    isSubmitting: boolean;
    isModalVisible: boolean;
}

class TeacherSelectMySchool extends React.Component<TeacherSelectMySchoolProps, TeacherSelectMySchoolState> {
    private notificationKey = "TeacherSelectMySchool";
    constructor(props: TeacherSelectMySchoolProps) {
        super(props);
        bindAllOfThis(this, TeacherSelectMySchool.prototype);

        this.state = {
            isSubmitting: false,
            isModalVisible: false
        };
    }

    handleOnSubmit(data: SchoolSearchDTO) {
        notification.info({
            key: this.notificationKey,
            message: "Joining school...",
            description: "Please wait while we add you to the selected school...",
            duration: 0
        });

        let request: UpdateTeacherDTO = {
            teacherId: this.props.User.id,
            email: this.props.User.email,
            firstName: this.props.User.firstName,
            lastName: this.props.User.lastName,
            phoneNumber: this.props.User.phoneNumber,
            schoolId: data.id,
        };

        this.setState({ isSubmitting: true });
        return TeacherController.PutUpdateTeacher(request).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "School Joined",
                description: "You have successfully joined the school!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.props.UpdateUserAccount({
                userId: result.data.id,
                email: result.data.email,
                firstName: result.data.firstName,
                lastName: result.data.lastName,
                phoneNumber: result.data.phoneNumber,
                schoolId: data.id,
            });
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Join School",
                description: ["There was an issue joining the school.", ...messages].map(x => <div>{x}</div>),
                duration: 10
            });
            this.setState({ isSubmitting: false });
        });
    }

    handleModalOnSubmit(data: TeacherSelectMySchoolModalDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving School",
            description: "Please wait while we save the school...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = SchoolController.PostCreateSchool({
            name: data.name,
            gradeStart: Number(data.gradeStart.key),
            gradeEnd: Number(data.gradeEnd.key),
            city: data.city,
            state: data.state,
            zip: data.zip,
            districtId: null //EnvironmentStaticContent.TeachAddSchoolDistrict.getIdForEnvironment(),
        });

        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Saved School",
                description: "Successfully saved the school!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isModalVisible: false });

            // Okay, now add the user to their school
            this.handleOnSubmit({
                id: result.data.id,
                name: result.data.name
            } as SchoolSearchDTO); // I should probably fix the input param but I don't care
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Save School",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });
        });
    }

    handleModalOnCancel() {
        this.setState({ isModalVisible: false });
    }

    newSchoolOnClick() {
        // Open the modal here
        this.setState({ isModalVisible: true });
    }

    render() {
        if (this.props.User.schoolId != null) {
            return <Redirect push to={Routes.TEACHER_MY_SCHOOL().toRoute} />;
        }

        return <div style={{ marginBottom: 44 }}>
            <h1>My School Selection</h1>
            <p>Select a state and city from the dropdowns below. Cities will populate based on the state selected. School provides additional filtering.</p>
            <p>Some schools require a "Join Code". To get a "Join Code" ask any Rock Digi enrolled teacher at your school.</p>
            <p>If you do not see your school in the list of schools, click "Add School".</p>
            {/* <p>If you do not see your school in the list of schools please email <strong>Admin@RockDigi.org</strong> with the <strong>school name</strong>, <strong>beginning grade level</strong>, <strong>ending grade level</strong> and <strong>district name</strong> then wait to hear back.</p> */}
            <TeacherSelectMySchoolForm
                onSubmit={this.handleOnSubmit}
                isSubmitting={this.state.isSubmitting}
            />
            <TeacherSelectMySchoolModal
                isVisible={this.state.isModalVisible}
                onSubmit={this.handleModalOnSubmit}
                onCancel={this.handleModalOnCancel}
            />
            <span>Can't find school? Add one here!</span>
            <Button type="primary" onClick={this.newSchoolOnClick} style={{ padding: "4px 10px 4px 8px", marginLeft: 8 }}>Add School</Button>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        UpdateUserAccount: (request: UpdateAccountDTO) => UserAction.UpdateUserAccount(dispatch, request)
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSelectMySchool);
