import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter, RouteComponentProps, Link } from 'react-router-dom';

import {
    BankOutlined,
    HomeOutlined,
    QuestionCircleOutlined,
    ReadOutlined,
    ShopOutlined,
    StarOutlined,
    TeamOutlined,
    DeploymentUnitOutlined
} from '@ant-design/icons';

import { Button, Layout, Menu, Tooltip } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UnregisterCallback } from 'history';
import DebugUtil from '../../utils/DebugUtil';
import { DocumentaryController } from '../../api/DocumentaryController';
import { DocumentaryDTO } from '../../models/documentary/documentaryDTO';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface TeacherSideNavProps extends RouteComponentProps<{ courseId: string, lessonId: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
}

interface TeacherSideNavState {
    selectedKey: string[];
}

class TeacherSideNav extends React.Component<TeacherSideNavProps, TeacherSideNavState> {
    unlisten: UnregisterCallback;
    private sideNavUrls: string[] = [
        Routes.GET.TEACHER_DASHBOARD,
        Routes.GET.TEACHER_MY_SCHOOL,
        Routes.GET.PRICING,
        Routes.GET.TEACHER_CLASSROOMS,
        Routes.GET.TEACHER_STUDENTS,
        Routes.GET.DEMO,
        Routes.GET.DOCUMENTARY_HOME,
        Routes.GET.TEACHER_EDIT_ACTIVE_SCHOOL
    ];
    constructor(props: TeacherSideNavProps) {
        super(props);
        bindAllOfThis(this, TeacherSideNav.prototype);

        // Find selected key
        let selectedKey = [this.props.match.url];

        // Forces rerender when URL changes since React doesn't always run render
        this.unlisten = props.history.listen((x) => {
            this.forceUpdate();
        });

        this.state = {
            selectedKey: selectedKey,
        };
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        this.setOpenKey(this.props);
    }

    componentWillReceiveProps(nextProps: TeacherSideNavProps) {
        if (this.props.match.url !== nextProps.match.url) {
            this.setOpenKey(nextProps);
        }
    }

    setOpenKey(props: TeacherSideNavProps) {
        // this is odd logic but it keeps the sidenav in sync with the page we are on
        let selectedMenuItems = this.sideNavUrls.filter(url => props.match.url.includes(url));
        let likelySelectedMenuItem = selectedMenuItems.length < 1 ? null : selectedMenuItems.pop();

        this.setState({
            selectedKey: [likelySelectedMenuItem],
        });
    }

    onInstructionsClick() {
        // Not used, but is a nice demonstration
        const url = "https://rock-digi-courses.s3.amazonaws.com/Teacher+Information+Packet.pdf";
        fetch(url)
            .then(response => response.blob()) // Get the response as a blob
            .then(response => {
                let blob = new Blob([response]);
                let blobUrl = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'Teacher Information Packet.pdf');
                document.body.appendChild(link);
                link.click();
        });
    }

    public render() {
        if (this.props.User.schoolId == null) {
            return (
                <Sider
                    width="250"
                    className="side-nav"
                    breakpoint="md"
                    collapsible
                >
                    <Menu
                        mode="inline"
                        subMenuOpenDelay={0}
                        subMenuCloseDelay={0.2}
                        selectedKeys={this.state.selectedKey}
                    >
                        <Menu.Item key={this.sideNavUrls[0]}>
                            <NavLink to={this.sideNavUrls[0]}>
                                <HomeOutlined /><span>Dashboard</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[1]}>
                            <NavLink to={this.sideNavUrls[1]}>
                                <BankOutlined /><span>Find School</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[2]}>
                            <NavLink to={this.sideNavUrls[2]}>
                                <ShopOutlined /><span>Shop</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[5]}>
                            <NavLink to={this.sideNavUrls[5]}>
                                <StarOutlined /><span>Free Trial Lessons</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={"teacher-instructions"}>
                            <a target="_blank" href={DebugUtil.GetCurrentEnvironment() === "development" ?
                                "https://rock-digi-courses-devl.s3.amazonaws.com/Teacher+Information+Packet.pdf" :
                                "https://rock-digi-courses.s3.amazonaws.com/Teacher+Information+Packet.pdf"}>
                                <QuestionCircleOutlined /><span>Need Instructions?</span>
                            </a>
                        </Menu.Item>
                    </Menu>
                </Sider>
            );
        } else {
            return (
                <Sider
                    width="250"
                    className="side-nav"
                    breakpoint="md"
                    collapsible
                >
                    <Menu
                        mode="inline"
                        subMenuOpenDelay={0}
                        subMenuCloseDelay={0.2}
                        selectedKeys={this.state.selectedKey}
                    >
                        <Menu.Item key={this.sideNavUrls[0]}>
                            <NavLink to={this.sideNavUrls[0]}>
                                <HomeOutlined /><span>Dashboard</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[1]}>
                            <NavLink to={this.sideNavUrls[1]}>
                                <BankOutlined /><span>My School's Courses</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[2]}>
                            <NavLink to={this.sideNavUrls[2]}>
                                <ShopOutlined /><span>Shop</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[3]}>
                            <NavLink to={this.sideNavUrls[3]}>
                                <ReadOutlined /><span>Classrooms</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[4]}>
                            <NavLink to={this.sideNavUrls[4]}>
                                <TeamOutlined /><span>Students</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[5]}>
                            <NavLink to={this.sideNavUrls[5]}>
                                <StarOutlined /><span>Free Trial Lessons</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={"teacher-instructions"}>
                            <a target="_blank" href={DebugUtil.GetCurrentEnvironment() === "development" ?
                                "https://rock-digi-courses-devl.s3.amazonaws.com/Teacher+Information+Packet.pdf" :
                                "https://rock-digi-courses.s3.amazonaws.com/Teacher+Information+Packet.pdf"}>
                                <QuestionCircleOutlined /><span>Need Instructions?</span>
                            </a>
                        </Menu.Item>
                        <Menu.Item key={this.sideNavUrls[7]}>
                            <NavLink to={this.sideNavUrls[7]}>
                                <DeploymentUnitOutlined /><span>Change Active School</span>
                            </NavLink>
                        </Menu.Item>
                    </Menu>
                    <div className='active-school'>
                        <span>Active School</span>
                        <p>{this.props.User.schoolName}</p>
                    </div>
                </Sider>
            );
        }
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps)(withRouter(TeacherSideNav));
