import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { Spin, Progress } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { Roles } from '../../constants/Roles';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import { LessonContentDTO } from '../../models/lessonContent/lessonContentDTO';
import { LessonContentController } from '../../api/LessonContentController';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';

interface WorkflowWhiteboardPageProps extends PageProps<{ courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowWhiteboardPageState {
    lesson: LessonDTO;
    lessonContent: LessonContentDTO;
    videoComplete: boolean;
    isLoading: boolean;
}

class WorkflowWhiteboardPage extends React.Component<WorkflowWhiteboardPageProps, WorkflowWhiteboardPageState> {
    private hasViewBeenCounted: boolean;

    constructor(props: WorkflowWhiteboardPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowWhiteboardPage.prototype);

        this.state = {
            lesson: null,
            lessonContent: null,
            videoComplete: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        let lesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        let lessonContent = (lesson.lessonContents || []).find(x => x.lessonContentTypeId === LessonContentTypes.WhiteBoardAnimations.id);
        this.setState({
            lesson: lesson,
            lessonContent: lessonContent,
            isLoading: false,
            videoComplete: lessonContent == null,
        }, this.figureOutOnNext);
    }

    figureOutOnNext() {
        // Teachers and above should not be blocked
        if (this.props.User.role === Roles.Student && !this.state.videoComplete) {
            return this.props.setButtonState("disabled", "visible");
        }
        return this.props.setButtonState("visible", "visible");
    }

    onComplete() {
        // Video complete!
        this.setState({
            videoComplete: true
        }, () => this.figureOutOnNext());
    }

    onProgress(playedPercent: Number) {
        if (this.hasViewBeenCounted || playedPercent < .5) return;
        this.hasViewBeenCounted = true;
        LessonContentController.IncrementVideoViewCount(this.state.lessonContent.id);
    }

    renderDebugInfo() {
        if (DebugUtil.isDebugEnabled() && this.state.lessonContent != null) {
            return <div>Content Url: {this.state.lessonContent.url}</div>;
        }
        return null;
    }

    renderVideo() {
        if (this.state.lessonContent == null) {
            return <h3>There was an error loading the video content</h3>;
        }
        let url = EncodeSpecialCharacters(this.state.lessonContent.url);

        return <ReactPlayer
            url={url}
            onEnded={this.onComplete}
            className="react-video-container"
            height={null} // WHY DOES THIS WORK
            width={null}
            controls={true}
            playing={false}
            onProgress={(e) => this.onProgress(e.played)}
        />;
    }

    renderDemoHelperText() {
        if (this.props.Workflow.isDemoMode) {
            return <p className="demo-helper-text">Whiteboard animations have been known to increase student retention by 15% as students are anticipating what is being drawn in the video.</p>;
        }
        return null;
    }

    renderHelperText() {
        if (this.props.User.role === Roles.Student) {
            return <p>Watch this short video to learn about life skills for success!</p>;
        }
        if (this.props.User.role === Roles.Parent) {
            return <p>Parent/guardian and student, together watch this short video to learn about life skills for success!</p>;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div className="whiteboard-scene">
            <h1>Whiteboard Animation</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderDemoHelperText()}
            {this.renderHelperText()}
            {this.renderDebugInfo()}
            {this.renderVideo()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowWhiteboardPage);
