import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Card } from 'antd';
import { ColProps } from 'antd/lib/col';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import "./SocialEmotionalLearningPage.scss";

interface SocialEmotionalLearningPageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
}

interface SocialEmotionalLearningPageState {
}

class SocialEmotionalLearningPage extends React.Component<SocialEmotionalLearningPageProps, SocialEmotionalLearningPageState> {
    constructor(props: SocialEmotionalLearningPageProps) {
        super(props);
        bindAllOfThis(this, SocialEmotionalLearningPage.prototype);

        this.state = {
        };
    }

    renderTheBestParts() {
        let makeItLarge: React.CSSProperties = {
            height: "54px",
            fontSize: "22px",
            width: "100%"
        };

        let colParams: ColProps = {
            xs: 24,
            lg: 12,
            style: { paddingBottom: "16px" }
        };

        if (this.props.User.state === "finished") {
            return <Row gutter={16}>
                <Col {...colParams}>
                    <Link key="demoLink" to={Routes.DEMO().toRoute}><Button type="primary" size="large" style={makeItLarge}>Free Trial Lessons</Button></Link>
                </Col>
                <Col {...colParams}>
                    <Link key="homeLink" to={Routes.HOME().toRoute}><Button type="primary" size="large" style={makeItLarge}>Go to My Dashboard</Button></Link>
                </Col>
            </Row>;
        }

        return <Row gutter={16}>
            <Col {...colParams}>
                <Link key="registerLink" to={Routes.REGISTER().toRoute}><Button type="primary" size="large" style={makeItLarge}>Register to Try a Sample Lesson</Button></Link>
            </Col>
            <Col {...colParams}>
                <Link key="loginLink" to={Routes.LOGIN().toRoute}><Button type="primary" size="large" style={makeItLarge}>Login</Button></Link>
            </Col>
        </Row>;
    }

    render() {
        const layoutSpans = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };
        const imageSpans = {
            xs: { span: 24 },
            sm: { span: 18 },
            md: { span: 16 },
            lg: { span: 12 },
        };

        return <div className="SocialEmotionalLearning-page">
            <Row  justify="center">
                <Col {...layoutSpans}>
                    {this.renderTheBestParts()}
                </Col>
            </Row>

            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center">Importance of SEL</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <span>SEL occurs all day, every day, and everywhere.</span><br />
                                <p>Social Emotional Learning helps to create safe, healthy and supportive environments that extend beyond the classroom – to the whole school, home, and
                                the community. SEL promotes equity and enables all learners to build on their unique skills and qualities.</p>
                                <p>Social Emotional Learning is the pie crust – composed of self-awareness,
                                social-awareness, self-management, relationship skills, and responsible decision
                                making - while all other academic subjects like math, reading, social studies,
                                science, etc. are on top and makes up the filling of the pie. Social Emotional
                                Learning holds and supports all other academic subjects. </p>
                                <p>This is why it is not only important to simply incorporate Social Emotional Learning
                                into what is being taught, but also why it is important to teach it using so many
                                different teaching methods so all students have the opportunity to fully understand
                                and be able to implement these skills into their lives. </p>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="center">
                            <Col {...imageSpans}>
                                <img className="center-img" src={require("../../assets/SEL-Diagram.jpg")}/>
                            </Col>
                        </Row>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <p>A child’s ability to learn is significantly diminished when they are in a school where
                                they do not feel safe and supported. The more comfortable a student feels in their
                                environment, the more they will be able to focus on what is important to them.</p>
                                <p>The overall health of a child and their mindset to embrace learning is critical. When
                                educators approach social emotional learning from a perspective of equity and
                                wanting to ensure that conditions of a school are appropriate for healthy learning,
                                students will be given the best chance to succeed in their education and in life. </p>
                                <p>Students can’t learn if they are struggling with social and emotional issues. It is a
                                basic need for all students. If students don’t have self-control, problem solving
                                skills, decision making skills, an understanding of what perseverance is, anger
                                management skills, and empathy for others, they are not equipped to learn.</p>
                                <p>Social emotional learning is the foundation for life. If students have healthy social
                                and emotional skills, they will have a firm foundation for a happy life. People who
                                have these skills are more resilient and able to deal with the ups and downs of life.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center">Research Findings</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <p>In a national sample of 148,189 sixth to twelfth graders,</p>
                                <ul>
                                    <li>only 29% to 45% of surveyed students reported that they had social
                                    competencies such as empathy, decision making, and conflict resolution skills; </li>
                                    <li>and only 29% indicated that their school provided a caring, encouraging
                                    environment (Benson, 2006).</li>
                                    <li>By high school as many as 40% to 60% of students become chronically
                                    disengaged from school (Klem & Connell, 2004).</li>
                                    <span className="small-text">(The Impact of Enhancing Students’ Social and Emotional Learning: A Meta-Analysis of School-Based Universal Interventions, 2011)</span>
                                </ul>
                                <p>97,000 students ranging from kindergarten to high school took part in a
                                meta-analysis that compared students that had participated in social emotional
                                learning programs to those that had not.</p>
                                <ul>
                                    <li>Researchers found that those students that participated in Social Emotional
                                    Learning programs had improved academic performance by 13 percentile points.</li>
                                    <li>Researchers also found that students that participated in Social Emotional
                                    Learning programs demonstrated a 6% increase in high school graduation rates,
                                    and an 11% increase in college graduation rates.</li>
                                    <span className="small-text">(“Promoting Positive Youth Development Through School-Based Social and Emotional Learning Interventions: A Meta-Analysis of
                                    Follow-Up Effects, 2017)</span>
                                </ul>
                                <p>Another study from 2015 that was published in the American Journal of Public
                                Health shows that there are significant associations between social emotional
                                skills at earlier ages and how that affects them as young adults in </p>
                                <ul>
                                    <li>Employment</li>
                                    <li>Academic Achievement</li>
                                    <li>Substance Abuse</li>
                                    <li>Criminal Activity</li>
                                    <li>Mental Health</li>
                                    <span className="small-text">(Early Social-Emotional Functioning and Public Health: The Relationship Between Kindergarten Social Competence and Future Wellness, 2015)</span>
                                </ul>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center">Blending SEL with other subjects</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <p>Although SEL is generally thought to be part of guidance or counseling curriculum,
                                SEL can be incorporated into many different other lessons for other subjects, and
                                help students to get a deeper understanding of both subjects.</p>
                                <p>Our Rock World Empathy Curriculum for grades 7-9 is an example of this by
                                blending SEL with Social Studies – Global Studies. It simultaneously meets ASCA
                                standards as well as Common Core standards for Social Studies</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(SocialEmotionalLearningPage);
