import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Row, Col, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { PageProps } from '../../../src/models/common/ComponentProps';
import { UserState } from '../../redux/UserReducer';
import { WorkflowState } from '../../redux/WorkflowReducer';
import WorkflowAction from '../../redux/WorkflowAction';
import ReduxStoreModel from '../../redux/ReduxModel';
import EnvironmentStaticContent, { StaticDemoContent } from '../../constants/EnvironmentStaticContent';
import "./DemoPage.scss";
import ReactPlayer from 'react-player';
import DebugUtil from '../../utils/DebugUtil';
import { DemoDTO } from '../../models/demo/DemoDTO';
import { DemoController } from '../../api/DemoController';
import ConfigureURLs from '../../core/ConfigureUrls';

interface DemoPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    SetDemoMode: (request: boolean) => void;
}

interface DemoPageState {
    demoInfo: DemoDTO[];
    isLoading: boolean;
}

class DemoPage extends React.Component<DemoPageProps, DemoPageState> {
    constructor(props: DemoPageProps) {
        super(props);
        bindAllOfThis(this, DemoPage.prototype);

        this.state = {
            demoInfo: null,
            isLoading: false,
        };
    }

    componentDidMount(){
        this.loadAll();
    }

    loadAll(){
        this.setState({
            isLoading: true
        });
        return DemoController.GetDemos().then(result => {
            this.setState({
                demoInfo: result.data,
                isLoading: false,
            });
        });
    }

    handleButtonClick() {
        this.props.SetDemoMode(true);
    }

    renderDemos() {
        if (this.state.demoInfo != null) {
            let items = [...this.state.demoInfo]
                .sort((a, b) => a.demoOrder > b.demoOrder ? 1 : -1)
                .map(demo => {
                    return <div key={demo.demoOrder}>
                        <Row>
                            <Col dangerouslySetInnerHTML={{
                                __html: demo.description
                            }}>
                            </Col>
                        </Row>
                        <Link to={Routes.WORKFLOW_COURSE_HOME(demo.id).toRoute}>
                            <Button type="primary" onClick={this.handleButtonClick}>
                                Enter Course
                            </Button>
                        </Link>
                        <br /><br />
                    </div>;
                });
            return items;
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div className="demo-page">
                <Spin className="spinner-centered very-large-spinner" />
            </div>;
        }
        return <div>
            <h1>Free Trial Lessons</h1>
            <p>Check out the example lessons provided below</p>

            <Row align="middle" style={{ padding: '16px 0', margin: '0px -16px 16px -16px', backgroundColor: '#0B3375', color: 'white', textAlign: 'center' }}>
                <Col xs={24} md={14}>
                    <ReactPlayer url={ConfigureURLs.GetAWSUrl("static", "freetrial-instructions.mp4")}
                        controls={true} width={null} height={null} playing={false} volume={0.5} />
                </Col>
                <Col xs={24} md={0}>
                    <p style={{ fontSize: 32, marginBottom: 0, padding: '0px 16px' }}>This video gives you an understanding of the format of the Rock Digi lessons.</p>
                </Col>
                <Col xs={0} md={10} lg={0}>
                    <p style={{ fontSize: 32, marginBottom: 0, padding: '0px 16px' }}>This video gives you an understanding of the format of the Rock Digi lessons.</p>
                </Col>
                <Col xs={0} lg={10} xxl={0}>
                    <p style={{ fontSize: 40, marginBottom: 0, padding: '0px 16px' }}>This video gives you an understanding of the format of the Rock Digi lessons.</p>
                </Col>
                <Col xs={0} xxl={10}>
                    <p style={{ fontSize: 48, marginBottom: 0, padding: '0px 16px' }}>This video gives you an understanding of the format of the Rock Digi lessons.</p>
                </Col>
            </Row>

            {this.renderDemos()}
            <p>We hope you enjoy your free trial lesson! If you have any questions, please contact us at admin@rockdigi.org</p>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        SetDemoMode: (request: boolean) => WorkflowAction.SetDemoMode(dispatch, request),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoPage);
