import * as React from 'react';
import { Table, Radio, Button, Popconfirm} from 'antd';
import { ColumnProps} from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';

import { SimpleLessonDTO } from '../../models/lesson/simpleLessonDTO';
import { ClassroomController } from '../../api/ClassroomController';
import { LessonController } from '../../api/LessonController';

interface TeacherMyClassroomLessonsProps  {
    isLoading?: boolean;
    isSendingEmail: boolean;
    tableData: SimpleLessonDTO[];
    handleToggleChange: (lessonId: string) => void;
    handleRateModalOpen: (lessonId: string, knowledgeUnderstandingRating: string, learningRating: string) => void;
    handleSendEmail: (lessonId: string) => void;
}

interface TeacherMyClassroomLessonsState {
    isSendingSHPEmail: boolean;
}

class TeacherMyClassroomLessons extends React.Component<TeacherMyClassroomLessonsProps, TeacherMyClassroomLessonsState> {
    tableColumns: ColumnProps<SimpleLessonDTO>[];

    constructor(props: TeacherMyClassroomLessonsProps) {
        super(props);
        bindAllOfThis(this, TeacherMyClassroomLessons.prototype);

        this.state = {
            isSendingSHPEmail: false
        };

        this.tableColumns = [{
            title: 'Lesson',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name)
        }, {
            title: 'Order',
            dataIndex: 'ordering',
            defaultSortOrder: 'ascend',
            key: 'ordering',
            sorter: (a, b) => a.ordering.toString().localeCompare(b.ordering.toString())
        }, {
            title: 'Lesson Availability',
            key: 'lessonAvailability',
            align: 'center',
            render: (text, record: SimpleLessonDTO) => {
                return (
                    <Radio.Group buttonStyle="solid" defaultValue={record.available} onChange={() => this.props.handleToggleChange(record.id)}>
                        <Radio.Button value={false}>Closed</Radio.Button>
                        <Radio.Button value={true}>Open</Radio.Button>
                    </Radio.Group>
                );
            }
        }, {
            title: 'School Home Partnership',
            key: 'schoolHomePartnershipEmail',
            align: 'center',
            render: (text, record: SimpleLessonDTO) => {
                return (<Popconfirm
                    title="Are you sure you want to send an email to all classroom students?"
                    // icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() => this.props.handleSendEmail && this.props.handleSendEmail(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type='primary'
                        disabled={!record.available}
                        loading={record.available && this.props.isSendingEmail}
                    >
                        Send Email
                    </Button>
                </Popconfirm>);
            }
        }, {
            key: 'rate',
            align: 'center',
            render: (text, record: SimpleLessonDTO) => {
                return [
                    <Button style={{ width: 125 }} disabled={!record.available} onClick={() => this.props.handleRateModalOpen(record.id, record.knowledgeUnderstandingRating, record.learningRating)}>
                        {record.available ?
                            (record.knowledgeUnderstandingRating == null && record.learningRating == null ?
                                <span style={{ color: "red" }}>Please Rate</span> :
                                <span>Update Rating</span>) :
                            <span>Open to Rate</span>
                        }
                    </Button>
                ];
            }
        }];
    }

    render() {
        return <Table
            size="middle"
            loading={this.props.isLoading}
            columns={this.tableColumns}
            dataSource={this.props.tableData}
            rowKey={record => record.id}
            pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                hideOnSinglePage: true,
                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
            }}
        />;
    }
}

export default TeacherMyClassroomLessons;
