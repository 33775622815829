import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Row, Col, Card } from 'antd';
import { ColProps } from 'antd/lib/col';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import "./MusicsEffectivenessPage.scss";

interface MusicsEffectivenessPageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
}

interface MusicsEffectivenessPageState {
}

class MusicsEffectivenessPage extends React.Component<MusicsEffectivenessPageProps, MusicsEffectivenessPageState> {
    constructor(props: MusicsEffectivenessPageProps) {
        super(props);
        bindAllOfThis(this, MusicsEffectivenessPage.prototype);

        this.state = {
        };
    }

    renderTheBestParts() {
        let makeItLarge: React.CSSProperties = {
            height: "54px",
            fontSize: "22px",
            width: "100%"
        };

        let colParams: ColProps = {
            xs: 24,
            lg: 12,
            style: { paddingBottom: "16px" }
        };

        if (this.props.User.state === "finished") {
            return <Row gutter={16}>
                <Col {...colParams}>
                    <Link key="demoLink" to={Routes.DEMO().toRoute}><Button type="primary" size="large" style={makeItLarge}>Free Trial Lessons</Button></Link>
                </Col>
                <Col {...colParams}>
                    <Link key="homeLink" to={Routes.HOME().toRoute}><Button type="primary" size="large" style={makeItLarge}>Go to My Dashboard</Button></Link>
                </Col>
            </Row>;
        }

        return <Row gutter={16}>
            <Col {...colParams}>
                <Link key="registerLink" to={Routes.REGISTER().toRoute}><Button type="primary" size="large" style={makeItLarge}>Register to Try a Sample Lesson</Button></Link>
            </Col>
            <Col {...colParams}>
                <Link key="loginLink" to={Routes.LOGIN().toRoute}><Button type="primary" size="large" style={makeItLarge}>Login</Button></Link>
            </Col>
        </Row>;
    }

    render() {
        const layoutSpans = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 22 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };

        return <div className="musics-effectiveness-page">
            <Row  justify="center">
                <Col {...layoutSpans}>
                    {this.renderTheBestParts()}
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center">Music's Effectiveness</h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                                <p>Music impacts our mood, feelings, and energy levels. This can provide us with
                                deeper insight into critical thinking and decision making.
                                </p>
                                <p>Music can be used to help us to remember key concepts and is a great way to
                                reach more learning styles.</p>
                                <p>Music helps us to make connections across different subjects, as well as look at
                                situations from multiple perspectives including different cultural perspectives.
                                </p>
                                <p>Music helps to give students the opportunity to share their experiences based
                                on their interpretation of the song. This also creates a space where students
                                want to share their experiences, what they are thinking, and why.
                                </p>
                                <div className="bold">How does music complement Social Emotional Learning?</div>
                                <ul>
                                    <li>It can be used as an emotional stimulus.</li>
                                    <li>It can be used to create an aesthetic experience or create a vibe.</li>
                                    <li>It can be used for relaxation and to stimulate imagination.</li>
                                    <li>It can be used as a form of self-expression.</li>
                                    <li>It can be used as a form of a group experience.</li>
                                </ul>
                                <div className="bold">Music stimulates multiple parts of the brain.</div>
                                <p>It activates three different centers of the brain at the same time: language,
                                hearing, and rhythmic motor control. Music can affect a child’s approach to
                                learning by increasing enthusiasm for and engagement in learning new skills.</p>
                                <div className="bold">Music improves retention.</div>
                                <p>A lot of people retain and remember information better when using music.
                                There has been research that shows that young children who had experienced
                                music-enriched curriculum had increased academic achievement. And different
                                music activities such as rhyming words, rhythmic patterns, steady beat, etc.
                                have a dramatic effect on a child’s reading ability and literacy. </p>
                                <p>A great example of this is the ABC song. This song still helps many adults to
                                remember the alphabet, and may even be hard for people to recite the
                                alphabet without singing the song</p>
                                <div className="bold">Music teaches emotional regulation.</div>
                                <p>Music experiences and interactions with peers provide children, who may have
                                difficulty initiating an interaction with another child, the motivation and context
                                for practice of this skill. </p>
                                <div className="bold">Music helps people connect with others.</div>
                                <p>Music is a universal language. Music can be used as a connector between
                                people as a cultural bridge. Music promotes diversity and unites individuals
                                through their love of music.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row  justify="center">
                <Col {...layoutSpans}>
                    <Card>
                        <h1 className="center"><i>“I know how powerful music can be in
                        the learning environment, but how do I use it in my classroom?"</i></h1>
                        <Row  align="middle" justify="space-between">
                            <Col span={24}>
                            <div className="bold">Different ways music can be incorporated</div>
                                <p>So many people think that they are not able to incorporate music into their
                                classroom because they are not musically inclined. This is definitely not true,
                                as there are many other ways you can use music without having to play or
                                sing yourself. Many activities also do not require you to play music well in
                                order to gain the emotional experience from it.</p>
                                <ul>
                                    <li>Playing instruments – as a group or individually</li>
                                    <li>Singing or vocal – as a group or individually</li>
                                    <li>Listening to other artists – live or recorded</li>
                                    <li>Creating songs – instrumental or lyrically</li>
                                    <li>Watching music videos</li>
                                    <li>Creating music videos</li>
                                </ul>
                                <p>Rock Digi lessons each contain a lyric music video where students are able to
                                visually see the lyrics of the song as they are being sung. This creates a better
                                understanding of the lyrics as well as creating a mood for the topic about to
                                be discussed. Lessons also give students the opportunity to share their
                                interpretation of the song, create their own music video, create new verses of
                                the songs, and much more.
                                </p>
                                <div className="bold">Different ways to use music in the classroom:</div>
                                <ul>
                                    <li>Start off the day/class</li>
                                    <li>Setting tone or mood</li>
                                    <li>Getting students moving</li>
                                    <li>Brain breaks</li>
                                    <li>Background music - focus</li>
                                    <li>Learning listening skills</li>
                                    <li>Learning self-control</li>
                                    <li>Memorizing facts</li>
                                    <li>Reflection</li>
                                    <li>Deeper thinking</li>
                                </ul>
                                <h2 className="center">All of the music used in Rock Digi, as well as more songs that have been used by Rock In Prevention in the past can be found on the following platforms.
                                </h2>
                                <h2 className="center"><Link target="_blank" to="//open.spotify.com/artist/4C6rCXXxcQmlpaZOQodUfy">Spotify</Link> – Pat McManus</h2>
                                <h2 className="center"><Link target="_blank" to="//music.apple.com/us/artist/pat-mcmanus/1449059275">Apple Music</Link> – Pat McManus</h2>
                                <h2 className="center"><Link target="_blank" to="//youtube.com/channel/UC2yrT5SFgVN2SVhY3r7TCjw">YouTube</Link> – Rock In Prevention Pat McManus</h2>
                                <h2 className="center"><Link target="_blank" to="//music.amazon.com/artists/B000V5X3XU?tab=CATALOG">Amazon Music</Link> – Pat McManus</h2>
                                <h2 className="center"><Link target="_blank" to="//play.google.com/store/music/artist/Pat_McManus?id=Alwkvh2t3wm2yabpn2mi47w5mgu&hl=en_US">Google Play</Link> – Pat McManus</h2>
                                <h2 className="center">And many more!</h2>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(MusicsEffectivenessPage);
