import * as React from 'react';
import bindAllOfThis from '../../../utils/BindThisHelper';
import { PageProps } from '../../../models/common/ComponentProps';
import { ConstantsDTO } from '../../../models/constant/constantsDTO';
import { ConstantController } from '../../../api/ConstantController';
import { CreditCardOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Card, Skeleton, Avatar, Button, Tooltip, Radio, notification } from 'antd';
import "./PricingPage.scss";
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Routes from '../../../core/Routes';
import { UserState } from '../../../redux/UserReducer';
import ReduxStoreModel from '../../../redux/ReduxModel';
import { connect } from 'react-redux';
import { CourseController } from '../../../api/CourseController';
import { SimpleCourseDTO } from '../../../models/course/simpleCourseDTO';
import Meta from 'antd/lib/card/Meta';
import { Roles } from '../../../constants/Roles';
import ConfigureURLs from '../../../core/ConfigureUrls';
import { DocumentaryDTO } from '../../../models/documentary/documentaryDTO';
import { DocumentaryController } from '../../../api/DocumentaryController';
import ErrorDTO from '../../../models/common/ErrorDTO';
import { CreateShoppingCartItemDTO } from '../../../models/shoppingCart/createShoppingCartItemDTO';
import { ShoppingCartController } from '../../../api/ShoppingCartController';

interface PricingPageProps extends RouteComponentProps<{}> {
    User: UserState;
}

interface PricingPageState {
    isLoading: boolean;
    constants: ConstantsDTO;
    courses: SimpleCourseDTO[];
    documentaries: DocumentaryDTO[];
    isThreeYear: boolean;
}

class PricingPage extends React.Component<PricingPageProps, PricingPageState> {
    constructor(props: PricingPageProps) {
        super(props);
        bindAllOfThis(this, PricingPage.prototype);

        this.state = {
            isLoading: true,
            constants: null,
            courses: [],
            documentaries: [],
            isThreeYear: false
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    async loadAll() {
        let constantsResult = await ConstantController.GetConstants();
        let courseResult = await CourseController.GetCourses();

        this.setState({
            constants: constantsResult.data,
            courses: courseResult.data.filter(course => course.availableForPurchase),
            documentaries: constantsResult.data.documentaries.filter(documentary => documentary.availableForPurchase),
            isLoading: false
        });
    }

    convertCentsToDollars(cents: number): string {
        return (cents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
    }

    handleYearOnChange(event: any) {
        this.setState({ isThreeYear: event.target.value });
    }

    renderFooter() {
        if (this.props.User.state !== "finished") {
            return <div>
                <Row justify="space-between" style={{ paddingTop: "18px" }}>
                    <Col>
                        <Link to={Routes.REGISTER().toRoute}><span style={{ display: "block" }}>Need to Register?</span></Link>
                    </Col>
                    <Col>
                        <Link to={Routes.LOGIN().toRoute}><span style={{ display: "block" }}>Have an account? Login here!</span></Link>
                    </Col>
                </Row>
            </div>;
        } else {
            return <div>
                <Row justify="space-between" align="middle" style={{ paddingTop: "18px" }}>
                    <Col span={24}>
                        <Link to={Routes.HOME().toRoute}><span style={{ display: "block" }}>Go Back Home</span></Link>
                    </Col>
                </Row>
            </div>;
        }
    }

    addDocumentaryToCart(documentaryId: string) {
        let notificationKey = "PricingPage";

        notification.info({
            key: notificationKey,
            message: "Adding to Cart",
            description: "Please wait while we finalize your adding to cart...",
            duration: 0
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        let startDate = yyyy + "-" + mm + "-" + dd;

        let request: CreateShoppingCartItemDTO = {
            courseId: null,
            documentaryId: documentaryId,
            teacherId: this.props.User.id,
            licenseId: null,
            startDate: startDate,
            licenseCount: 1,
            isTripleYearLicense: false
        };

        this.setState({ isLoading: true });
        return ShoppingCartController.PostAddShoppingCartItem(request).then(result => {
            notification.success({
                key: notificationKey,
                message: "Added to Cart",
                description: "Successfully added to cart!",
                duration: 5,
                onClick: () => notification.close(notificationKey)
            });

            this.setState({ isLoading: false });
            this.props.history.push(Routes.SHOPPING_CART().toRoute);
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: notificationKey,
                message: "Failed to Add to Cart",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        const span12 = {
            xs: { span: 24 },
            sm: { span: 12 },
        };
        return (
            <div className="pricing-page">
                <Row>
                    <Col
                        xs={{ span: 22, offset: 1 }}
                        md={{ span: 18, offset: 3 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                    >
                        <Card className="pricing-card" >
                            <h1>Pricing</h1>
                            <Radio.Group buttonStyle="solid" defaultValue={false} onChange={this.handleYearOnChange}>
                                <Radio.Button value={false}>1 Year License</Radio.Button>
                                <Radio.Button value={true}>3 Year License</Radio.Button>
                            </Radio.Group>
                            <br /><br />
                            {!this.state.isThreeYear && <div>
                                <Row gutter={16} justify="center">
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="1-5 students" >
                                            <h3>{this.convertCentsToDollars(10000)}/year</h3>
                                        </Card>
                                    </Col>
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="6-10 students" >
                                            <h3>{this.convertCentsToDollars(20000)}/year</h3>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="11-15 students" >
                                            <h3>{this.convertCentsToDollars(30000)}/year</h3>
                                        </Card>
                                    </Col>
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="16+ students" >
                                            <h3>{this.convertCentsToDollars(30000)}/year +$2.00 per Student</h3>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                </Row>
                            </div>}
                            {this.state.isThreeYear && <div>
                                <Row gutter={16} justify="center">
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="1-5 students" >
                                            <h3>{this.convertCentsToDollars(8500)}/year</h3>
                                        </Card>
                                    </Col>
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="6-10 students" >
                                            <h3>{this.convertCentsToDollars(17000)}/year</h3>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="center">
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="11-15 students" >
                                            <h3>{this.convertCentsToDollars(25000)}/year</h3>
                                        </Card>
                                    </Col>
                                    <Col {...span12} style={{ width: 400 }}>
                                        <Card className="pricing-tier" title="16+ students" >
                                            <h3>{this.convertCentsToDollars(25000)}/year +$2.00 per Student</h3>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>}
                            <p style={{marginTop: 8, marginBottom: 'unset'}}>This pricing model applies for each individual curriculum.</p>
                        </Card>
                        <Card style={{ backgroundColor: "#f2f2f2" }}>
                            <br />
                            <h1>Available Courses</h1>
                            {this.state.courses.sort((first, second) => first.gradeLevelStart - second.gradeLevelStart)
                                .map(course => {
                                    let link = this.props.User.role === Roles.None ?
                                        { pathname: Routes.REGISTER().toRoute } :
                                        { pathname: Routes.TEACHER_ADD_EDIT_MY_SCHOOL("").toRoute, search: `?courseId=${course.id}` };

                                    return (
                                        <Row gutter={16} justify="center">
                                            <Col xs={{ span: 22 }}
                                                md={{ span: 18 }}>
                                                <Card style={{ marginTop: 16 }}>
                                                    <Skeleton loading={false} active>
                                                        <Tooltip title={course.name} placement="top">
                                                            <Link to={link}>
                                                                <Button type="primary" icon={<CreditCardOutlined />} style={{ width: 130 }}>Purchase</Button>
                                                            </Link>
                                                        </Tooltip>
                                                        <br /><br />

                                                        <Meta title={course.name} description={
                                                            <div>
                                                                {course.description}
                                                                <br /><br />
                                                                <strong>Grades {course.gradeLevelStart} - {course.gradeLevelEnd}</strong>
                                                            </div>
                                                        } />

                                                        <br />
                                                        <Tooltip title={"View Standards and Objectives"} placement="top">
                                                            <a href={ConfigureURLs.GetAWSUrl("courses", course.name + "/" + course.objectivesAndStandards).substring(0)} target="_blank" download>
                                                                <Button type="primary" style={{ width: 300 }}>View Standards and Objectives</Button>
                                                            </a>
                                                        </Tooltip>
                                                    </Skeleton>
                                                </Card>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Card>
                        <Card style={{ backgroundColor: "#f2f2f2" }}>
                            <br />
                            <h1>Available Documentary Courses</h1>
                            {this.state.documentaries
                                .map(documentary => {

                                    return (
                                        <Row gutter={16} justify="center">
                                            <Col xs={{ span: 22 }}
                                                md={{ span: 18 }}>
                                                <Card style={{ marginTop: 16 }}>
                                                    <Skeleton loading={false} active>
                                                        <Tooltip title={documentary.name} placement="top">
                                                            <Button type="primary" onClick={() => this.addDocumentaryToCart(documentary.id)} icon={<CreditCardOutlined />} style={{ width: 130 }}>Purchase</Button>
                                                        </Tooltip>
                                                        <br /><br />

                                                        <Meta title={documentary.name} description={
                                                            <div>
                                                                {documentary.description}
                                                                <br /><br />
                                                                <strong>Grades 7 - 12</strong>
                                                            </div>
                                                        } />
                                                    </Skeleton>
                                                </Card>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            {this.renderFooter()}
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User
    };
}

export default connect(mapStateToProps)(withRouter(PricingPage));
