import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Checkbox, Spin, InputNumber } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';

export interface AdminQuestionFormDataObject {
    id?: string;
    text: string;
    ordering: number;
    lessonId: string;
    //questionType: boolean;
}

interface AdminQuestionFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: AdminQuestionFormDataObject) => void;
    onCancel: () => void;
    initialValues?: Partial<AdminQuestionFormDataObject>;
    isStudentQuestion: boolean;
    isLoading: boolean;
}

interface AdminQuestionFormState {
    isEdit: boolean;
}

class AdminQuestionForm extends React.Component<AdminQuestionFormProps, AdminQuestionFormState> {
    // Just need to initialize sub objects
    constructor(props: AdminQuestionFormProps) {
        super(props);
        bindAllOfThis(this, AdminQuestionForm.prototype);

        let isEdit = props.initialValues != null && props.initialValues.id != null;

        this.state = {
            isEdit: isEdit,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminQuestionFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            //values.studentQuestion = this.props.isStudentQuestion;
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Text"
                name={NameOf<AdminQuestionFormDataObject>("text")}
                initialValue={initialValues.text}
                rules={[{ required: true, message: "Text is required." }]}
            >
                <Input size="large" placeholder="Text" disabled={this.props.isLoading} {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Order"
                name={NameOf<AdminQuestionFormDataObject>("ordering")}
                initialValue={initialValues.ordering}
                rules={[{ required: true, message: "Order is required." }]}
            >
                <InputNumber size="large" placeholder="Order" disabled={this.props.isLoading} {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    Save Changes
                </Button>
                <Button type="default" className="login-form-button" size="large" onClick={this.props.onCancel} disabled={this.props.isLoading}>
                    Go Back
                </Button>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminQuestionFormProps>()(AdminQuestionForm);
