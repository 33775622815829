import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Layout} from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import AdminSideNav from "./AdminSideNav";
import GeneralHeader from '../GeneralHeader';
import GeneralFooter from '../GeneralFooter';
import { Roles } from '../../constants/Roles';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import Routes from '../../core/Routes';
import ReducedHeader from '../ReducedHeader';
import './AdminLayout.scss';

const { Content } = Layout;

interface AdminLayoutProps {
    // From Redux
    User: UserState;
}

interface AdminLayoutState {
    isLoading: boolean;
    isErrored: boolean;
}

class AdminLayout extends React.Component<AdminLayoutProps, AdminLayoutState> {
    constructor(props: AdminLayoutProps) {
        super(props);
        bindAllOfThis(this, AdminLayout.prototype);

        this.state = {
            isLoading: true,
            isErrored: false,
        };
    }

    componentDidMount() {
        // Check role
        if (this.props.User.role === Roles.Admin) {
            this.setState({ isLoading: false });
        } else {
            this.setState({ isErrored: true });
        }
    }

    public render() {
        if (this.state.isErrored) {
            return <Redirect push to={Routes.HOME().toRoute} />;
        }

        return <Layout className="admin-layout">
            <GeneralHeader />
            <Layout>
                <AdminSideNav />
                <Layout>
                    <Content>
                        {!this.state.isLoading && this.props.children}
                    </Content>
                    <GeneralFooter />
                </Layout>
            </Layout>
        </Layout>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow
    };
}

export default connect(mapStateToProps)(AdminLayout);
