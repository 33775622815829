import * as React from 'react';
import { Button, Row, Col, Card } from 'antd';

const redStyle: React.CSSProperties = { color: "red" };
const boldStyle: React.CSSProperties = { fontWeight: 700 };

class PrivacyPolicyConsentPage extends React.Component {
  render() {
    const layoutSpans = {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 22 },
      xl: { span: 18 },
      xxl: { span: 16 },
    };
    return <div>
      <Row  justify="center">
        <Col {...layoutSpans}>
          <h1>Privacy Policy</h1>
          <h3>Last Modified Date: 05/01/2023</h3>
          <h3><strong>PRIVACY STATEMENT</strong></h3>
          <p>
            <span>Use of Rock In Prevention, Inc. d/b/a Rock Digi (“Rock Digi”, “We”, and/or “Us”) websites, rockinprevention.org and/or rockdigi.org (“Websites”) and services Rock PLUS for Grades 3-4, Rock PLUS for Grades 4-5, Rock PLUS for Grades 5-6, Rock Digi (collectively “Services”) may require you to provide personal information so Rock Digi may contact you. Such personal information may be required to utilize Rock Digi’s Services, subscribe to email notifications, complete surveys, receive support or fill out other forms, such as the “contact us” form.</span>
          </p>
          <p>
            <span>This Privacy Policy describes how Rock Digi treats personal information Rock Digi collects from you on our Websites and through our Services. However, Rock Digi reserves the right to modify this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the Websites.</span>
          </p>

          <br /><hr /><br />
          <h3><strong>SECTION 1 – INFORMATION WE COLLECT</strong></h3>
          <p>
            <span>Rock Digi collects information from and about you when you utilize its Services.</span>
          </p>
          <p>
            <span>You may provide Rock Digi with personal information, including but not limited to, the following:</span>
          </p>
          <ol type="1">
            <li>Name, address, telephone number, email address, and/or your occupation or title.</li>
            <li>Login/password for your account.</li>
            <li>Payment information.</li>
            <li>Date of birth.</li>
            <li>Social media usernames.</li>
            <li>The identification number associated with your account.</li>
            <li>The details of your purchase or service.</li>
          </ol>
          <p>
            <span>Additionally, Rock Digi may collect analytic data to maintain the high quality and service of the Services using automated means such as cookies (as described in Section 9 below), server logs, web beacons, etc.  The collected analytic data may include, but is not limited to, the following:</span>
          </p>
          <ol type="1">
            <li>Your internet protocol (I.P.) address</li>
            <li>Your basic location information (group statistic), i.e. State/City you are located in.</li>
            <li>Cookie session IDs.</li>
            <li>The page(s) you visited.</li>
            <li>Your shopping cart(s).</li>
            <li>Features you used</li>
            <li>Keywords you used/searched for.</li>
            <li>What web browser you used during your visit to our Websites.</li>
            <li>What operating system you use.</li>
            <li>The link that referred/brought you to our Websites.</li>
          </ol>

          <h3><strong>SECTION 2 – WHAT WE DO WITH YOUR INFORMATION</strong></h3>
          <p>
            <span>When you make a purchase from our Websites, Rock Digi collects the personal information you give Rock Digi, as described above.</span>
          </p>
          <p>
            <span>Along with the automated information Rock Digi collects, Rock Digi uses this information for internal purposes such as evaluating, operating, and improving our company and Services, protecting against and preventing fraud, complying with legal requirements, etc. Rock Digi may also make use of and provide this information to third-parties in furtherance of its Services and for business purposes such as, but not limited to, maintaining your account, to conduct engagement audits, to conduct marketing of our services, to protect the security of our Website and Services. Additionally, Rock Digi may also use the collected information in conjunction with third-parties as described below. </span>
          </p>
          <p>
            <span><i>Email marketing</i></span>
            <span>: With your permission, Rock Digi may send you emails about our events, offerings, and other updates.  Emails sent to you will be from Rock In Prevention but may be distributed by either Rock Digi or by third party providers.</span>
          </p>

          <h3><strong>SECTION 3 - CONSENT</strong></h3>
          <p>
            <span><i><b>How do We get your consent?</b></i></span>
          </p>
          <p>
            <span>When you provide Rock Digi with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, you agree that you consent to our collecting it and using it for that specific reason only. You also agree to consent to the collection and use of personal information when you sign-up for or utilize Rock Digi’s Services.</span>
          </p>
          <p>
            <span>If Rock Digi asks for your personal information for a secondary reason, like marketing, Rock Digi will either ask you directly for your expressed consent, or provide you with an opportunity to say no.</span>
          </p>
          <p>
            <span><i><b>How do I withdraw my consent?</b></i></span>
          </p>
          <p>
            <span>If after you opt-in, you change your mind, you may withdraw your consent for Rock Digi to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting Rock Digi at Info@rockinprevention.org or by mail at Website administration C/O Rock In Prevention, 7628 Hickman Rd, Des Moines, IA 50324.</span>
          </p>

          <h3><strong>SECTION 4 - DISCLOSURE</strong></h3>
          <p>
            <span>Rock Digi may disclose your personal information if Rock Digi is required by law to do so or if you violate our Terms of Service.</span>
          </p>

          <h3><strong>SECTION 5 – THIRD PARTIES POLICIES</strong></h3>
          <p>
            <span>Rock Digi’s Websites are hosted on the GoDaddy, Inc. hosting service and maintained by QCI. Additionally, our store/ecommerce platform, which allows Rock Digi to sell our product(s) and/or Service(s) to you, is provided by Stripe Inc. and Shopify Inc. Our Rock Digi software and related Services are hosted on the Amazon Web Services, Inc. (“AWS”) platform. GoDaddy, Inc., Stripe Inc., Shopify Inc., Amazon Web Services, Inc., and are separate entities from Rock Digi and have their own privacy policies, terms of use and procedures which can be found at the respective link below. Rock Digi strongly recommends you review these additional policies and terms to fully understand how each of our vendors may use your information. Rock Digi has no specific information regarding the privacy policies of companies with which Rock Digi is not directly associated and encourages users of Rock Digi’s Websites and/or Services to contact the providers for additional details pertaining to privacy policies that may allow collection of information by any such entities. </span>
          </p>
          <ul style={{listStyle: 'none'}}>
            <li>
              <span>GoDaddy, Inc.:</span>
              <span><a target="_blank" href={"https://www.godaddy.com/agreements/privacy"}>https://www.godaddy.com/agreements/privacy</a></span>
            </li>
            <li>
              <span>QCI:</span>
              <span><a target="_blank" href={"https://www.qci.com/privacy-policy"}>https://www.qci.com/privacy-policy</a></span>
            </li>
            <li>
              <span>Stripe, Inc.:</span>
              <span><a target="_blank" href={"https://stripe.com/privacy"}>https://stripe.com/privacy</a></span>
            </li>
            <li>
              <span>Shopify Inc.:</span>
              <span><a target="_blank" href={"https://www.shopify.com/legal/privacy"}>https://www.shopify.com/legal/privacy</a></span>
            </li>
            <li>
              <span>Amazon Web Services, Inc.:</span>
              <span><a target="_blank" href={"https://aws.amazon.com/compliance/data-privacy-faq"}>https://aws.amazon.com/compliance/data-privacy-faq</a></span>
            </li>
          </ul>
          <p>
            <span>Your data is stored through GoDaddy’s data storage, databases and general applications. They store your data on a secure server behind a firewall.  GoDaddy uses 256-bit encryption to protect your data.</span>
          </p>

          <h3><strong>SECTION 6 - THIRD-PARTY SERVICES</strong></h3>
          <p>
            <span>In general, the third-party providers used by Rock Digi will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to Rock Digi.</span>
          </p>
          <p>
            <span>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies with respect to the information Rock Digi is required to provide to them for your purchase-related transactions.</span>
          </p>
          <p>
            <span>For these providers, Rock Digi recommends that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. Links to these privacy policies can be found above in Section 5 of this document.</span>
          </p>
          <p>
            <span>In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or Rock Digi. If you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</span>
          </p>
          <p>
            <span>As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.</span>
          </p>
          <p>
            <span>Once you leave Rock Digi’s Websites or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or Rock Digi’s Websites’ Terms of Service.</span>
          </p>
          <p>
            <span><i>Links</i></span>
            <span>: When you click on links on Rock Digi’s Websites or through the use of Rock Digi’s Services, they may direct you away from Rock Digi’s Websites. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</span>
          </p>
          <p>
            <span><i>Do Not Track Signals</i></span>
            <span>: Rock Digi does not process or respond to "Do Not Track" signals from your browser or other mechanisms that enable consumer choice regarding the collection of personal information about one's online activities over time and across third-party websites or online services.</span>
          </p>

          <h3><strong>SECTION 7 – NOTICE TO CALIFORNIA CUSTOMERS</strong></h3>
          <p>
            <span>Subject to the limitations under California Civil Code § 1798.83, if you are a California resident you may ask Rock Digi to provide you with a list of certain categories of personal information that we have disclosed to third parties for their direct marketing purposes during the immediately preceding calendar year, as well as the specific pieces of personal information collected. You may request the purposes for which Rock Digi uses the personal information, as well as the categories of information that Rock Digi discloses to third parties. You may also request the identity and category of certain third parties that received your personal information from Rock Digi for their direct marketing purposes during the immediately preceding calendar year. You may make a request as described hereinabove once a year.  </span>
          </p>
          <p>
            <span>Additionally, you may request to know what personal information of yours that Rock Digi has, to delete that information, and to request that your personal information not be sold. To make such a request, you can email Rock Digi or write to Rock Digi at the address below.  Please mention in your communication that you are making a "California Consumer Privacy Act" inquiry.  We will respond within thirty days.</span>
          </p>
          <p>
            <span>Furthermore, you have a right to non-discrimination for exercising your rights under the California Consumer Privacy Act. This means that Rock Digi cannot deny goods or services, charge you a different price, or provide a different level or quality of goods or services just because you exercised your rights under the CCPA. However, if you refuse to provide your personal information to Rock Digi or ask Rock Digi to delete your personal information, and that personal information is necessary for Rock Digi to provide you with goods or services, Rock Digi may not be able to complete that transaction. If you ask Rock Digi to delete your personal information, you may not be able to continue participating in any special deals Rock Digi may offer in exchange for personal information. </span>
          </p>

          <h3><strong>SECTION 8 – PROTECTING YOUR INFORMATION </strong></h3>
          <p>
            <span>To protect your personal information, Rock Digi takes reasonable precautions and follow industry best practices to ensure your information is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</span>
          </p>
          <p>
            <span>When you provide Rock Digi with your information, the information is encrypted using AES 256-bit encryption by our ecommerce provider, Stripe.  Although no method of transmission over the internet or electronic storage is 100% secure, our ecommerce provider Stripe is PCI-Level 1 certified and follows all such requirements and implements additional generally accepted industry standards. </span>
          </p>

          <h3><strong>SECTION 9 - COOKIES</strong></h3>
          <p>
            <span>Please be advised that Rock Digi uses cookies to collect general information about your visit which will be retained and used by Rock Digi, including but not limited to our parent and wholly-owned affiliate entities.  By using our Websites or Services you expressly consent to the use and collection by our company of this information.  To withdraw your consent after accepting the cookies, delete them from your browser.  For more information on cookies and deletion of cookies please visit:</span>
            <span><a target="_blank" href={"http://www.allaboutcookies.org"}>http://www.allaboutcookies.org</a></span>
          </p>
          <p>
            <span>You can stop cookies from being deployed on your computer by configuring your browser not to accept them.  Please refer to your browser's help section for more information.  Be aware that deleting certain cookies can disrupt normal website functionality.</span>
          </p>

          <h3><strong>SECTION 10 - AGE OF CONSENT</strong></h3>
          <p>
            <span>By using these Services, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence, and you have given Rock Digi your consent to allow any of your minor dependents to use this Website.</span>
          </p>

          <h3><strong>SECTION 11 - COMPLIANCE WITH THE CHILDREN’S ONLINE PRIVACY PROTECTION ACT</strong></h3>
          <p>
            <span>To the extent applicable, Rock Digi will comply with the requirements of the Children’s Online Privacy Protection Act. Rock Digi will not knowingly collect any personally identifiable information from children under 13 years of age without parental or guardian consent. Rock Digi will not collect any personally identifiable info from visitors who identify themselves as under age 13 without parent or guardian consent. </span>
          </p>
          <p>
            <span>Rock Digi will not require the disclosure of more information than is reasonably necessary to use its services. Parents and/or guardians may also request to review their child’s personal information and direct Rock Digi to delete it and refuse to allow any further collection or use of that information. Parents and/or guardians may additionally agree to the collection and use of their child’s information but disallow disclosure to third parties unless part of the service. Parents and/or guardians may use the email or mailing address in the Question and Contact Information section of this Policy to make such requests. Please note that the use of collected information may be necessary in order for Rock Digi to administer its services and requests to delete or discontinue the collection of information may result in limited functionality of said services or the discontinuation of those services entirely.</span>
          </p>

          <h3><strong>SECTION 12 - CHANGES TO THIS PRIVACY POLICY</strong></h3>
          <p>
            <span>Rock Digi reserves the right to modify this Privacy Policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the Websites. If Rock Digi makes material changes to this Privacy Policy, Rock Digi will notify you here that it has been updated, so that you are aware of what information Rock Digi collects, how Rock Digi uses it, and under what circumstances, if any, Rock Digi uses and/or discloses it.</span>
          </p>
          <p>
            <span>If Rock Digi is acquired or merged with another company, your information may be transferred to the new owners.</span>
          </p>

          <h3><strong>SECTION 13 – ONLINE POLICY ONLY</strong></h3>
          <p>
            <span>This online privacy policy applies only to information collected through Rock Digi’s Websites and Services and not to information collected offline.</span>
          </p>

          <h3><strong>SECTION 14 – SECURITY</strong></h3>
          <p>
            <span>Information emailed to Rock Digi via the Internet is not secure and could be intercepted by others. We recommend you do not use email to send private or sensitive data about yourself or others unless it is encoded or encrypted. Rock Digi uses usual and customary industry encryption and cybersecurity policies to protect your passwords, contact information, and other personal information.</span>
          </p>

          <h3><strong>QUESTIONS AND CONTACT INFORMATION</strong></h3>
          <p>
            <span>If you would like to: access, correct, amend or delete any personal information Rock Digi has about you, register a complaint, or simply want more information, contact Rock Digi at</span>
            <span> <a href = "mailto: info@rockinprevention.org">info@rockinprevention.org</a></span>
            <span> or by mail at</span>
          </p>
          <p style={{textAlign: 'center'}}>
            <span><b>Website administration C/O Rock In Prevention, 7628 Hickman Rd, Des Moines, IA 50324.</b></span>
          </p>
        </Col>
      </Row>
    </div>;
  }
}
export default PrivacyPolicyConsentPage;
