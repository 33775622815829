import { Dispatch, Action } from 'redux';
import BaseAction from '../../src/models/common/BaseAction';
import { Roles } from '../constants/Roles';
import { UserDTO } from '../models/account/userDTO';
import { LoadingStatusType } from '../models/common/LoadingStatusType';
import { CreateTeacherDTO } from '../models/teacher/createTeacherDTO';
import { AccountController } from '../api/AccountController';
import { LoginDTO } from '../models/account/loginDTO';
import { AxiosResponse, AxiosError } from 'axios';
import { AccountDTO } from '../models/account/accountDTO';
import ErrorDTO from '../models/common/ErrorDTO';
import ActionResultDTO from '../models/common/ActionResultDTO';
import WorkflowAction from './WorkflowAction';
import { StudentLoginDTO } from '../models/account/studentLoginDTO';
import { UpdateAccountDTO } from '../models/account/updateAccountDTO';
import ReduxStoreModel from './ReduxModel';

export interface RedirectAction extends BaseAction { type: 'LOGIN_REDIRECT'; data: string; }
export interface LoginUserAction extends BaseAction { type: 'LOGIN_USER'; data: UserDTO; }
export interface ClearLoginUserAction extends BaseAction { type: 'CLEAR_LOGIN_STATE'; }
export interface UpdateUserAction extends BaseAction { type: 'UPDATE_USER'; data: UserDTO; }
export interface UpdateUserStateAction extends BaseAction { type: 'UPDATE_USER_STATE'; data: LoadingStatusType; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
// JB: You have to have atleast 2 in here for everything to work. It's a typescript thing
export type KnownActions = LoginUserAction | ClearLoginUserAction | UpdateUserAction | UpdateUserStateAction | RedirectAction;

export default class UserAction {
    constructor() {
        // Dont be that guy
        throw new Error("NOOOO");
    }

    public static Login(dispatch: Dispatch<KnownActions>, request: LoginDTO): Promise<ActionResultDTO> {
        dispatch({ type: "UPDATE_USER_STATE", data: "loading" } as UpdateUserStateAction);

        return AccountController.PostLogin(request)
            .then(result => this.Login_OnSuccess(dispatch, result, false))
            .catch(error => this.Login_OnFailure(dispatch, error));
    }

    public static StudentLogin(dispatch: Dispatch<KnownActions>, request: StudentLoginDTO): Promise<ActionResultDTO> {
        dispatch({ type: "UPDATE_USER_STATE", data: "loading" } as UpdateUserStateAction);

        return AccountController.PostStudentLogin(request)
            .then(result => this.Login_OnSuccess(dispatch, result, false))
            .catch(error => this.Login_OnFailure(dispatch, error));
    }

    public static Register(dispatch: Dispatch<KnownActions>, request: CreateTeacherDTO): Promise<ActionResultDTO> {
        dispatch({ type: "UPDATE_USER_STATE", data: "loading" } as UpdateUserStateAction);

        return AccountController.PostRegister(request)
            .then(result => this.Login_OnSuccess(dispatch, result, true))
            .catch(error => this.Login_OnFailure(dispatch, error));
    }

    /**
     * Login using the browser cookie. This will run through the login process as if we had logged in
     *
     * Returns a `Promise<bool>` indicating if the login was a success
     *
     * @param {Dispatch<ReduxStoreModel>} dispatch The dispatch object passed in from `mapDispatchToProps()`
     */
    public static SoftLogin(dispatch: Dispatch<KnownActions>): Promise<ActionResultDTO> {
        dispatch({ type: "UPDATE_USER_STATE", data: "loading" } as UpdateUserStateAction);
        return AccountController.GetMe()
            .then(result => this.Login_OnSuccess(dispatch, result, false))
            .catch(error => this.Login_OnFailure(dispatch, error));
    }

    private static Login_OnSuccess(dispatch: Dispatch<KnownActions>, response: AxiosResponse<AccountDTO>, isBrandNew: boolean): ActionResultDTO {
        let role = Roles.FindById(response.data.roleId) || Roles.Teacher;

        let data: UserDTO = {
            id: response.data.id,
            email: response.data.email,
            schoolId: response.data.schoolId,
            schoolName: response.data.schoolName,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            phoneNumber: response.data.phoneNumber,
            isBrandNew: isBrandNew,
            role: role
        };

        dispatch({ type: "LOGIN_USER", data: data } as LoginUserAction);
        dispatch({ type: "UPDATE_USER_STATE", data: "finished" } as UpdateUserStateAction);

        // Load checkpoints for students
        if (role === Roles.Student) {
            WorkflowAction.LoadUserCheckpoint(dispatch);
        }
        return { isError: false };
    }

    private static Login_OnFailure(dispatch: Dispatch<KnownActions>, error: AxiosError): ActionResultDTO {
        dispatch({ type: "CLEAR_LOGIN_STATE" } as ClearLoginUserAction);
        dispatch({ type: "UPDATE_USER_STATE", data: "failed" } as UpdateUserStateAction);
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        return { isError: true, message: messages.join("\n") };
    }

    public static async Logout(dispatch: Dispatch<KnownActions>) {
        try {
            let result = await AccountController.Logout();

            dispatch({ type: "CLEAR_LOGIN_STATE" } as ClearLoginUserAction);
            dispatch({ type: "UPDATE_USER_STATE", data: "none" } as UpdateUserStateAction);

            return result;
        } catch (error) {
            dispatch({ type: "CLEAR_LOGIN_STATE" } as ClearLoginUserAction);
            dispatch({ type: "UPDATE_USER_STATE", data: "none" } as UpdateUserStateAction);
        }
    }

    public static UpdateUserAccount(dispatch: Dispatch<KnownActions> | any, request: UpdateAccountDTO) {
        // TODO: JB - Move all update logic into here. For now, this will do
        dispatch({ type: "UPDATE_USER_STATE", data: "loading" } as UpdateUserStateAction);

        return dispatch((innerDispatch: Dispatch<KnownActions>, reduxState: () => ReduxStoreModel) => {
            let state = reduxState();
            let userDTO: UserDTO = {
                id: request.userId,
                email: request.email,
                firstName: request.firstName,
                lastName: request.lastName,
                phoneNumber: request.phoneNumber,
                schoolId: request.schoolId,
                role: state.User.role,
                isBrandNew: false,
            };

            // Submit the change
            // Persist those changes in redux
            dispatch({ type: "UPDATE_USER", data: userDTO } as UpdateUserAction);
            // Stop loading
            dispatch({ type: "UPDATE_USER_STATE", data: "finished" } as UpdateUserStateAction);
        });
    }

    /**
     * Mostly a debug action right now
     */
    public static UpdateUser(dispatch: Dispatch<KnownActions>, request: UserDTO) {
        dispatch({ type: "UPDATE_USER", data: request } as UpdateUserAction);
    }

    public static RedirectUrl(dispatch: Dispatch<KnownActions>, input: string){
        dispatch({type: "LOGIN_REDIRECT", data: input} as RedirectAction);
    }
}
