import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, InputNumber } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import moment, { Moment } from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import Routes from '../../core/Routes';

export interface AdminLessonFormDataObject {
    id?: string;
    name: string;
    ordering: number;
}

interface AdminLessonFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: AdminLessonFormDataObject) => void;
    initialValues?: Partial<AdminLessonFormDataObject>;
    courseId: string;
    lessonId: string;
}

interface AdminLessonFormState {
    isEdit: boolean;
}

class AdminLessonForm extends React.Component<AdminLessonFormProps, AdminLessonFormState> {
    // Just need to initialize sub objects
    constructor(props: AdminLessonFormProps) {
        super(props);
        bindAllOfThis(this, AdminLessonForm.prototype);

        let isEdit = props.initialValues != null && props.initialValues.id != null;

        this.state = {
            isEdit: isEdit,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminLessonFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Name"
                name={NameOf<AdminLessonFormDataObject>("name")}
                initialValue={initialValues.name}
                rules={[{ required: true, message: "Name is required." }]}
            >
                <Input size="large" placeholder="Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Order"
                name={NameOf<AdminLessonFormDataObject>("ordering")}
                initialValue={initialValues.ordering}
                rules={[{ required: true, message: "Order is required." }]}
            >
                <InputNumber size="large" placeholder="Order" {...disableAutoCompleteTags()} style={{ width: '100%' }} />
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save Lesson" : "Add Lesson"}
                </Button>
                <Link to={Routes.ADMIN_COURSE_ADD_EDIT(this.props.courseId).toRoute}>
                    <Button type="default" className="login-form-button" size="large" >
                        Cancel
                    </Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminLessonFormProps>()(AdminLessonForm);
