import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Select, Empty } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { SchoolController } from '../../api/SchoolController';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { States } from '../../constants/States';

interface AdminSchoolsPageTableDataObject {
    id: string;
    name: string;
    gradeStart: number;
    gradeStartString: string;
    gradeEnd: number;
    gradeEndString: string;
    districtName: string;
    joinCode: string;
    city: string;
    state: string;
    zip: string;
}

interface AdminSchoolsPageProps extends PageProps<{}> {
}

interface AdminSchoolsPageState {
    isLoading: boolean;
    tableData: AdminSchoolsPageTableDataObject[];
    stateAbbrev: string;
}

class AdminSchoolsPage extends React.Component<AdminSchoolsPageProps, AdminSchoolsPageState> {
    tableColumns: ColumnProps<AdminSchoolsPageTableDataObject>[];
    private states = Object.keys(States);
    constructor(props: AdminSchoolsPageProps) {
        super(props);
        bindAllOfThis(this, AdminSchoolsPage.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("name", "Name"),
        }, {
            title: 'District',
            dataIndex: 'districtName',
            key: 'districtName',
            width: 200,
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("districtName", "District"),
        }, {
            title: 'Grade Start',
            dataIndex: 'gradeStartString',
            key: 'gradeStartString',
            width: 120,
            sorter: (a, b) => a.gradeStart - b.gradeStart,
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("gradeStartString", "Grade Start"),
        }, {
            title: 'Grade End',
            dataIndex: 'gradeEndString',
            key: 'gradeEndString',
            width: 120,
            sorter: (a, b) => a.gradeEnd - b.gradeEnd,
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("gradeEndString", "Grade End"),
        }, {
            title: 'Join Code',
            dataIndex: 'joinCode',
            key: 'joinCode',
            width: 120,
            sorter: (a, b) => a.joinCode.localeCompare(b.joinCode),
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("joinCode", "Join Code"),
        }, {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: 120,
            sorter: (a, b) => a.city.localeCompare(b.city),
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("city", "City"),
        }, {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: 60,
            sorter: (a, b) => a.state.localeCompare(b.state),
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("state", "State"),
        }, {
            title: 'Zip',
            dataIndex: 'zip',
            key: 'zip',
            width: 120,
            sorter: (a, b) => a.zip.localeCompare(b.zip),
            ...getColumnSearchProps<AdminSchoolsPageTableDataObject>("zip", "Zip"),
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit School" placement="top">
                        <Link to={Routes.ADMIN_SCHOOL_ADD_EDIT(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: false,
            tableData: null,
            stateAbbrev: null
        };
    }

    componentDidMount() {
        //this.loadTableData();
    }

    loadTableData(stateAbbrev: string) {
        this.setState({ isLoading: true });
        SchoolController.GetSchoolsByState(stateAbbrev).then(result => {
            let tableData = result.data.map<AdminSchoolsPageTableDataObject>(record => ({
                ...record,
                gradeStartString: record.gradeStart > 0 ? record.gradeStart.toString() : record.gradeStart === 0 ? "K" : "PK",
                gradeEndString: record.gradeEnd > 0 ? record.gradeEnd.toString() : record.gradeEnd === 0 ? "K" : "PK",
                joinCode: record.id.substring(0, 7)
            }));
            this.setState({
                tableData: tableData,
                isLoading: false,
            });
        });
    }

    onStateChange(state: string) {
        if (state != null && state.length > 0) {
            this.loadTableData(States[state]);
        }
    }

    render() {
        return (
            <div>
                <h1>Schools</h1>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a state"
                    onChange={this.onStateChange}>
                    {
                        this.states.map(state => <Select.Option key={state} value={state}>{state}</Select.Option>)
                    }
                </Select>
                <Table
                    title={(data) => {
                        return [
                            <Link key="table-link" to={Routes.ADMIN_SCHOOL_ADD_EDIT("").toRoute}>
                                <Button icon={<EditOutlined />}>Add School</Button>
                            </Link>,
                        ];
                    }}
                    rowKey={record => record.id}
                    size="middle"
                    columns={this.tableColumns}
                    dataSource={this.state.tableData}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        hideOnSinglePage: true,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                    }}
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Schools! Please select a state with schools." />  }}
                    loading={{ indicator: <Spin className="spinner-centered very-large-spinner" />, spinning: this.state.isLoading }}
                />
            </div>
        );
    }
}

export default AdminSchoolsPage;
