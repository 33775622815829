import * as React from 'react';
import { Spin, notification } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import ErrorDTO from '../../models/common/ErrorDTO';
import { TeacherController } from '../../api/TeacherController';
import AdminUserForm, { AdminUserFormDataObject } from './AdminUserForm';
import { UpdateTeacherDTO } from '../../models/teacher/updateTeacherDTO';
import { AdminController } from '../../api/AdminController';
import { UpdateAdminDTO } from '../../models/admin/updateAdminDTO';

interface AdminUserPageProps extends PageProps<{ userId?: string }> {
}

interface AdminUserPageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    userId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: Partial<AdminUserFormDataObject>;
}

class AdminUserPage extends React.Component<AdminUserPageProps, AdminUserPageState> {
    private notificationKey = "AdminUserPage";
    constructor(props: AdminUserPageProps) {
        super(props);
        bindAllOfThis(this, AdminUserPage.prototype);

        let isEdit = props.match.params.userId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            userId: props.match.params.userId,
            isLoading: true,
            formData: null,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    private handleOnSubmit(data: AdminUserFormDataObject) {
        // throw new Error("Not Implemented");
        notification.info({
            key: this.notificationKey,
            message: "Saving User",
            description: "Please wait while we save the user...",
            duration: 0
        });

        this.setState({ isSubmitting: true }, () => {
            let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);

            request.then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Saved User",
                    description: "Successfully saved the user!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({isSubmitting: false});
                this.props.history.push(Routes.ADMIN_USERS().toRoute);
            }).catch(error => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Save School",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({isSubmitting: false});
            });
        });
    }

    private handleOnSubmitAdd(data: AdminUserFormDataObject) {
        throw new Error("Not Implemented");
        // let request: CreateSchoolDTO = {
        //     name: data.name,
        //     gradeStart: data.gradeStart,
        //     gradeEnd: data.gradeEnd,
        //     districtId: data.district.key
        // };

        return TeacherController.PutUpdateTeacher(null);
    }

    private handleOnSubmitUpdate(data: AdminUserFormDataObject) {
        if (this.state.formData.isAdmin) {
            let request: UpdateAdminDTO = {
                adminId: data.id,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password == null || data.password.trim().length < 1 ? null : data.password
            };

            return AdminController.PutUpdateAdmin(request);
        }

        let request: UpdateTeacherDTO = {
            teacherId: data.id,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            schoolId: data.school != null ? data.school.id : null,
            password: data.password == null || data.password.trim().length < 1 ? null : data.password
        };

        return TeacherController.PutUpdateTeacher(request);
    }

    private async loadAll() {
        this.setState({ isLoading: true });

        try {
            const result = await TeacherController.GetTeacherById(this.state.userId);
            const formData: Partial<AdminUserFormDataObject> = {
                ...result.data,
                password: "",
                isAdmin: false
            };
            this.setState({ formData });
        } catch (error) {
            // Maybe it was an admin all along

            const result = await AdminController.GetAdminById(this.state.userId);
            const formData: Partial<AdminUserFormDataObject> = {
                ...result.data,
                allSchools: [],
                password: "",
                isAdmin: true
            };
            this.setState({ formData });
        }

        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <h1>{this.state.isEdit ? "Edit" : "Add"} User Account</h1>
            <h2>Updates should work, <b>adding does not.</b></h2>
            <AdminUserForm
                onSubmit={this.handleOnSubmit}
                onReload={this.loadAll}
                initialValues={this.state.formData}
                isSubmitting={this.state.isSubmitting}
            />
        </div>;
    }
}

export default AdminUserPage;
