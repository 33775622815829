import * as React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin, notification, Tabs, Button, Tooltip, Modal } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { LessonController } from '../../api/LessonController';
import { CreateLessonDTO } from '../../models/lesson/createLessonDTO';
import { UpdateLessonDTO } from '../../models/lesson/updateLessonDTO';
import AdminLessonForm, { AdminLessonFormDataObject } from './AdminLessonForm';
import AdminLessonContentTable, { AdminLessonContentTableDataObject } from './AdminLessonContentTable';
import ErrorDTO from '../../models/common/ErrorDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import AdminLessonQuestionTable, { AdminLessonQuestionTableDataObject } from './AdminLessonQuestionTable';
import { AcceptedParams } from '../../utils/ParamUtil';
import { QuestionController } from '../../api/QuestionController';
import { CourseController } from '../../api/CourseController';
import { QuestionDTO } from '../../models/question/questionDTO';
import { QuestionType } from './AdminQuestionPage';
import { DemoController } from '../../api/DemoController';
import { StudentResponseController } from '../../api/StudentResponseController';
import DebugUtil from '../../utils/DebugUtil';
import { StudentLessonController } from '../../api/StudentLessonController';

interface AdminLessonPageProps extends PageProps<{ courseId: string, lessonId?: string }> {
}

interface AdminLessonPageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    idFromUrl?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: AdminLessonFormDataObject;
    /** The data for the Lesson Content table */
    lessonContentTableData: AdminLessonContentTableDataObject[];

    /** The data for the student table */
    studentQuestionTableData: AdminLessonQuestionTableDataObject[];
    /** The data for the parent table */
    parentQuestionTableData: AdminLessonQuestionTableDataObject[];
    /** The data for the parent table */
    studentSelfAssessmentQuestionTableData: AdminLessonQuestionTableDataObject[];
    isDemo: boolean;
}

class AdminLessonPage extends React.Component<AdminLessonPageProps, AdminLessonPageState> {
    private notificationKey = "AdminLessonPage";
    constructor(props: AdminLessonPageProps) {
        super(props);
        bindAllOfThis(this, AdminLessonPage.prototype);

        let isEdit = props.match.params.lessonId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            idFromUrl: props.match.params.lessonId,
            isLoading: true,
            formData: null,
            lessonContentTableData: [],
            studentQuestionTableData: [],
            parentQuestionTableData: [],
            studentSelfAssessmentQuestionTableData: [],
            isDemo: false,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AdminLessonFormDataObject) {
        // These notifications do clutter up the function
        notification.info({
            key: this.notificationKey,
            message: "Saving Lesson",
            description: "Please wait while we save the lesson...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);
        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Saved Lesson",
                description: "Successfully saved the lesson!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isSubmitting: false });

            // Go back if editing, refresh if adding
            if (this.state.isEdit) {
                this.props.history.push(Routes.ADMIN_COURSE_ADD_EDIT(this.props.match.params.courseId).toRoute);
            } else {
                // This will also reload as react-router sees this as a new "page"
                this.props.history.push(Routes.ADMIN_LESSON_ADD_EDIT(this.props.match.params.courseId, result.data.id).toRoute);
            }
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Save Lesson",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleOnSubmitAdd(data: AdminLessonFormDataObject) {
        let request: CreateLessonDTO = {
            name: data.name,
            ordering: data.ordering,
            courseId: this.props.match.params.courseId
        };
        return LessonController.PostCreateLesson(request);
    }

    handleOnSubmitUpdate(data: AdminLessonFormDataObject) {
        let request: UpdateLessonDTO = {
            lessonId: data.id,
            name: data.name,
            ordering: data.ordering,
            courseId: this.props.match.params.courseId
        };

        return LessonController.PutUpdateLesson(request);
    }

    handleOnQuestionUpdate(record: AdminLessonQuestionTableDataObject, questionType: QuestionType) {
        this.setState({ isSubmitting: true });
        // Record typically null when deleting
        if (record == null) {
            return this.loadFormData().then(() => {
                return this.setState({ isSubmitting: false });
            });
        }

        // Update the question
        return QuestionController.PutUpdateQuestion({
            questionId: record.id,
            text: record.text,
            ordering: record.ordering,
            lessonId: this.props.match.params.lessonId,
            questionType: QuestionType[questionType],
        }).then(result => {
            return this.loadFormData().then(() => {
                return this.setState({ isSubmitting: false });
            });
        });
    }

    handleLessonDemoToggle(data: boolean) {
        this.setState({ isSubmitting: true });
        if (data === true) {
            return DemoController.PostSetDemoLesson(this.props.match.params.lessonId).then(result => {
                return this.loadFormData().then(() => {
                    return this.setState({ isSubmitting: false, isDemo: true });
                });
            });
        }
        else if (data === false) {
            return DemoController.PutRemoveLessonFromDemo(this.props.match.params.lessonId).then(result => {
                return this.loadFormData().then(() => {
                    return this.setState({ isSubmitting: false, isDemo: false });
                });
            });
        }
    }

    // Lots of copied code incoming!
    handleDeleteAssessmentResponses() {
        Modal.confirm({
            title: 'Deleting Student Self-Assessment Questions',
            className: 'danger-popup',
            content: <div>
                <p>This action will delete all student assessment responses for this lesson. This is useful if you need to update the lesson after the course has been purchase by a client.</p>
                <p>Simple changes to the assessment questions and answers are allowed! However, fully rewriting the question or changing the answers will invalidate the responses already received. In that case, removing them is the old recourse.</p>
                <p>This also should fix issues when deleting answers.</p>
                <p>Please note: This will delete the student responses. You cannot recover them once they are deleted; they are gone. No recovery is possible after you click 'Proceed'</p>
            </div>,
            okText: 'Proceed',
            okType: 'danger',
            cancelText: 'Nevermind',
            onOk: async () => {
                try {
                    const results = await StudentResponseController.DeleteAssessmentQuestionResponsesByLessonId(this.props.match.params.lessonId);

                    notification.success({
                        key: this.notificationKey,
                        message: "Student Self-Assessment Responses",
                        description: `The responses for lesson "${this.state.formData.name}" have been deleted`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                } catch (error) {
                    notification.error({
                        key: this.notificationKey,
                        message: "Student Self-Assessment Responses",
                        description: ["There was an issue removing the Lesson Content.", error].map(x => <div>{x}</div>),
                        duration: 10
                    });
                }
            }
        });
    }

    handleDeleteStudentResponses() {
        Modal.confirm({
            title: 'Deleting Student Responses',
            className: 'danger-popup',
            content: <div>
                <p>This action will delete all student responses for this lesson. This is useful if you need to update the lesson after the course has been purchase by a client.</p>
                <p>Simple changes to the student questions and answers are allowed! However, fully rewriting the question or changing the answers will invalidate the responses already received. In that case, removing them is the old recourse.</p>
                <p>This also should fix issues when deleting answers.</p>
                <p>Please note: This will delete the student responses. You cannot recover them once they are deleted; they are gone. No recovery is possible after you click 'Proceed'</p>
            </div>,
            okText: 'Proceed',
            okType: 'danger',
            cancelText: 'Nevermind',
            onOk: async () => {
                try {
                    const results = await StudentResponseController.DeleteStudentQuestionResponsesByLessonId(this.props.match.params.lessonId);

                    notification.success({
                        key: this.notificationKey,
                        message: "Student Responses",
                        description: `The responses for lesson "${this.state.formData.name}" have been deleted`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                } catch (error) {
                    notification.error({
                        key: this.notificationKey,
                        message: "Student Responses",
                        description: ["There was an issue removing the Lesson Content.", error].map(x => <div>{x}</div>),
                        duration: 10
                    });
                }
            }
        });
    }

    handleDeleteParentResponses() {
        Modal.confirm({
            title: 'Deleting Parent Responses',
            className: 'danger-popup',
            content: <div>
                <p>This action will delete all parent responses for this lesson. This is useful if you need to update the lesson after the course has been purchase by a client.</p>
                <p>Simple changes to the parent questions and answers are allowed! However, fully rewriting the question or changing the answers will invalidate the responses already received. In that case, removing them is the old recourse.</p>
                <p>This also should fix issues when deleting answers.</p>
                <p>Please note: This will delete the student responses. You cannot recover them once they are deleted; they are gone. No recovery is possible after you click 'Proceed'</p>
            </div>,
            okText: 'Proceed',
            okType: 'danger',
            cancelText: 'Nevermind',
            onOk: async () => {
                try {
                    const results = await StudentResponseController.DeleteParentQuestionResponsesByLessonId(this.props.match.params.lessonId);

                    notification.success({
                        key: this.notificationKey,
                        message: "Parent Responses",
                        description: `The responses for lesson "${this.state.formData.name}" have been deleted`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                } catch (error) {
                    notification.error({
                        key: this.notificationKey,
                        message: "Parent Responses",
                        description: ["There was an issue removing the Lesson Content.", error].map(x => <div>{x}</div>),
                        duration: 10
                    });
                }
            }
        });
    }

    handleDeleteStudentLessons() {
        Modal.confirm({
            title: 'Deleting Student Lessons',
            className: 'danger-popup',
            content: <div>
                <p>This action will delete all student lessons for this lesson. This is really only needed when removing the lesson.</p>
                <p>This also should fix issues when deleting the lesson.</p>
                <p>Please note: This will delete the student lessons. You cannot recover them once they are deleted; they are gone. No recovery is possible after you click 'Proceed'</p>
            </div>,
            okText: 'Proceed',
            okType: 'danger',
            cancelText: 'Nevermind',
            onOk: async () => {
                try {
                    const results = await StudentLessonController.DeleteStudentLessonsByLessonId(this.props.match.params.lessonId);

                    notification.success({
                        key: this.notificationKey,
                        message: "Parent Responses",
                        description: `The student lessons for "${this.state.formData.name}" have been deleted`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                } catch (error) {
                    notification.error({
                        key: this.notificationKey,
                        message: "Parent Responses",
                        description: ["There was an issue removing the Student Lessons.", error].map(x => <div>{x}</div>),
                        duration: 10
                    });
                }
            }
        });
    }

    loadAll() {
        this.setState({ isLoading: true });

        DemoController.GetDemoLessons().then(results => {
            results.data.forEach(lesson => {
                if (lesson.id === this.props.match.params.lessonId) {
                    this.setState({ isDemo: true });
                }
            });
        });
        return Promise.all([this.loadFormData()]).then(x => {
            // Skip api call if editing
            if (this.state.isEdit) {
                return this.setState({ isLoading: false });
            }

            // Sets the order to the next order from the list of questions
            return this.loadNextOrderValue().then(orderResult => {
                return this.setState(state => {
                    return {
                        formData: { ...state.formData, ordering: orderResult },
                        isLoading: false
                    };
                });
            });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            this.setState({
                formData: null
            });
            return Promise.resolve();
        }

        // Load the lesson here
        return LessonController.GetLesson(this.state.idFromUrl).then(result => {
            let data: AdminLessonFormDataObject = {
                id: result.data.id,
                name: result.data.name,
                ordering: result.data.ordering
            };

            /* Lesson Contents */
            let lessonContents = (result.data.lessonContents || []).map<AdminLessonContentTableDataObject>(x => ({
                id: x.id,
                fileName: x.name,
                url: x.url,
                lessonContentType: LessonContentTypes.FindById(x.lessonContentTypeId),
                isMissing: false
            }));
            let lessonContentTableData = LessonContentTypes.All.filter(x => x.hasUpload).flatMap<AdminLessonContentTableDataObject>(contentType => {
                let existing = lessonContents.filter(x => x.lessonContentType === contentType);
                if (existing != null && existing.length > 0) {
                    return existing;
                }
                return [{
                    id: `${Math.floor(Math.random() * 27548900)}`, // We are allowed to use random numbers as programmers
                    fileName: null,
                    url: null,
                    lessonContentType: contentType,
                    isMissing: true,
                }];
            });

            /* Table Data */
            let questionAnswerMapper = (record: QuestionDTO) => {
                // Find first item by order. If the index does not match our index, then it is a duplicate order
                let hasQuestionOrderError = (record.questionAnswers || []).some((value, index, arr) => {
                    return arr.findIndex(x => x.ordering === value.ordering) !== index;
                });
                // Check if every questionAnswer is false, which means that none of them have a correct response
                let hasMissingCorrectAnswerError = QuestionType[record.questionType] !== QuestionType.ASSESSMENT && (record.questionAnswers || []).every((value) => {
                    return !value.isCorrect;
                });

                return {
                    id: record.id,
                    text: record.text,
                    ordering: record.ordering,
                    questionAnswers: record.questionAnswers,
                    hasQuestionOrderError: hasQuestionOrderError,
                    hasMissingCorrectAnswerError: hasMissingCorrectAnswerError,
                };
            };
            let studentQuestionTableData = (result.data.studentQuestions || [])
                .sort((a, b) => a.id.localeCompare(b.id)) // Initial sort
                .map<AdminLessonQuestionTableDataObject>(questionAnswerMapper);
            let parentQuestionTableData = (result.data.parentQuestions || [])
                .sort((a, b) => a.id.localeCompare(b.id)) // Initial sort
                .map<AdminLessonQuestionTableDataObject>(questionAnswerMapper);
            let studentSelfAssessmentQuestionTableData = (result.data.studentSelfAssessmentQuestions || [])
                .sort((a, b) => a.id.localeCompare(b.id)) // Initial sort
                .map<AdminLessonQuestionTableDataObject>(questionAnswerMapper);

            this.setState({
                formData: data,
                lessonContentTableData: lessonContentTableData,
                studentQuestionTableData: studentQuestionTableData,
                parentQuestionTableData: parentQuestionTableData,
                studentSelfAssessmentQuestionTableData: studentSelfAssessmentQuestionTableData
            });
        });
    }

    loadNextOrderValue() {
        return CourseController.GetCourse(this.props.match.params.courseId).then(result => {
            // Find the next question order. Just get largest and add one
            let lessons = result.data.lessons;
            if (lessons == null || lessons.length < 1) {
                return 1;
            }
            let value = lessons
                .sort((a, b) => a.ordering - b.ordering)
                .reduce((prev, current) => { // Reduce to return the item with the largest 'ordering' value
                    return prev.ordering < current.ordering ? current : prev;
                });
            return value.ordering + 1;
        });
    }

    renderTabs() {
        let lessonContentHeader = this.state.lessonContentTableData.some(x => x.isMissing)
            ? <Tooltip title="There are missing Lesson Contents" mouseEnterDelay={1}>
                <span>Lesson Content <ExclamationCircleOutlined className="table-icon-error" /></span>
            </Tooltip>
            : <span>Lesson Content</span>;
        let studentHeader = this.state.studentQuestionTableData.some(x => x.hasMissingCorrectAnswerError || x.hasQuestionOrderError)
            ? <Tooltip title="There are Question errors" mouseEnterDelay={1}>
                <span>Student Questions <ExclamationCircleOutlined className="table-icon-error" /></span>
            </Tooltip>
            : <span>Student Questions</span>;
        let studentAssessmentHeader = this.state.studentQuestionTableData.some(x => x.hasMissingCorrectAnswerError || x.hasQuestionOrderError)
            ? <Tooltip title="There are Question errors" mouseEnterDelay={1}>
                <span>Student Self-Assessment Questions <ExclamationCircleOutlined className="table-icon-error" /></span>
            </Tooltip>
            : <span>Student Self-Assessment Questions</span>;
        let parentHeader = this.state.parentQuestionTableData.some(x => x.hasMissingCorrectAnswerError || x.hasQuestionOrderError)
            ? <Tooltip title="There are Question errors" mouseEnterDelay={1}>
                <span>Parent Questions <ExclamationCircleOutlined className="table-icon-error" /></span>
            </Tooltip>
            : <span>Parent Questions</span>;

        if (this.state.isEdit) {
            return (
                <Tabs type="card">
                    <Tabs.TabPane key="1" tab={lessonContentHeader}>
                        <AdminLessonContentTable
                            tableData={this.state.lessonContentTableData}
                            requestReload={this.loadAll}
                            courseId={this.props.match.params.courseId}
                            lessonId={this.props.match.params.lessonId}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="4" tab={studentAssessmentHeader}>
                        <Link
                            key="table-link"
                            to={{
                                pathname: Routes.ADMIN_QUESTION_ADD_EDIT(this.props.match.params.courseId, this.props.match.params.lessonId).toRoute,
                                search: AcceptedParams.IsAssessmentQuestion
                            }}
                        >
                            <Button icon={<EditOutlined />}>Add Student Self-Assessment Question</Button>
                        </Link>
                        {/* Enabled for devs only. Untested and client has not paid us for this feature */}
                        {DebugUtil.isDebugEnabled() &&
                            <Tooltip title='Click for more details'>
                                <Button onClick={this.handleDeleteAssessmentResponses}>Delete Assessments <span style={{ color: "red", fontWeight: 700, marginLeft: 8 }}>Debug Only</span></Button>
                            </Tooltip>
                        }
                        <AdminLessonQuestionTable
                            tableData={this.state.studentSelfAssessmentQuestionTableData}
                            courseId={this.props.match.params.courseId}
                            lessonId={this.props.match.params.lessonId}
                            isLoading={this.state.isLoading}
                            isSubmitting={this.state.isSubmitting}
                            onQuestionUpdate={e => this.handleOnQuestionUpdate(e, QuestionType.ASSESSMENT)}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="2" tab={studentHeader}>
                        <Link
                            key="table-link"
                            to={{
                                pathname: Routes.ADMIN_QUESTION_ADD_EDIT(this.props.match.params.courseId, this.props.match.params.lessonId).toRoute,
                                search: AcceptedParams.IsStudentQuestion
                            }}
                        >
                            <Button icon={<EditOutlined />}>Add Student Question</Button>
                        </Link>
                        {/* Enabled for devs only. Untested and client has not paid us for this feature */}
                        {DebugUtil.isDebugEnabled() &&
                            <Tooltip title='Click for more details'>
                                <Button onClick={this.handleDeleteStudentResponses}>Delete Responses <span style={{ color: "red", fontWeight: 700, marginLeft: 8 }}>Debug Only</span></Button>
                            </Tooltip>
                        }
                        <AdminLessonQuestionTable
                            tableData={this.state.studentQuestionTableData}
                            courseId={this.props.match.params.courseId}
                            lessonId={this.props.match.params.lessonId}
                            isLoading={this.state.isLoading}
                            isSubmitting={this.state.isSubmitting}
                            onQuestionUpdate={e => this.handleOnQuestionUpdate(e, QuestionType.STUDENT)}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="3" tab={parentHeader}>
                        <Link
                            key="table-link"
                            to={{
                                pathname: Routes.ADMIN_QUESTION_ADD_EDIT(this.props.match.params.courseId, this.props.match.params.lessonId).toRoute,
                                search: AcceptedParams.IsParentQuestion
                            }}
                        >
                            <Button icon={<EditOutlined />}>Add Parent Question</Button>
                        </Link>
                        {/* Enabled for devs only. Untested and client has not paid us for this feature */}
                        {DebugUtil.isDebugEnabled() &&
                            <Tooltip title='Click for more details'>
                                <Button onClick={this.handleDeleteParentResponses}>Delete Responses <span style={{ color: "red", fontWeight: 700, marginLeft: 8 }}>Debug Only</span></Button>
                            </Tooltip>
                        }
                        <AdminLessonQuestionTable
                            tableData={this.state.parentQuestionTableData}
                            courseId={this.props.match.params.courseId}
                            lessonId={this.props.match.params.lessonId}
                            isLoading={this.state.isLoading}
                            isSubmitting={this.state.isSubmitting}
                            onQuestionUpdate={e => this.handleOnQuestionUpdate(e, QuestionType.PARENT)}
                        />
                    </Tabs.TabPane>
                </Tabs>
            );
        }
        return null;
    }

    renderHeader() {
        if (this.state.isEdit) {
            return <h1>Edit Lesson</h1>;
        }
        return <h1>Add Lesson</h1>;
    }

    renderDemoToggle() {
        if (this.state.isEdit) {
            if (this.state.isDemo) {
                return <Tooltip title="Remove Lesson From Demo" placement="top">
                    <Button type="primary" className="login-form-button" size="large" onClick={() => this.handleLessonDemoToggle(false)} disabled={this.state.isSubmitting}>
                        Remove Lesson From Demo
                        </Button>
                </Tooltip>;
            }
            else {
                return <Tooltip title="Add Lesson To Demo" placement="top">
                    <Button type="primary" className="login-form-button" size="large" onClick={() => this.handleLessonDemoToggle(true)} disabled={this.state.isSubmitting}>
                        Add Lesson To Demo
                        </Button>
                </Tooltip>;
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            {this.renderHeader()}
            <AdminLessonForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
                courseId={this.props.match.params.courseId}
                lessonId={this.props.match.params.lessonId}
            />
            {this.renderDemoToggle()}

            {DebugUtil.isDebugEnabled() &&
                <Tooltip title='Click for more details'>
                    <Button size='large' onClick={this.handleDeleteStudentLessons}>Delete Student Lessons <span style={{ color: "red", fontWeight: 700, marginLeft: 8 }}>Debug Only</span></Button>
                </Tooltip>
            }

            <br /><br />
            {this.renderTabs()}
        </div>;
    }
}

export default AdminLessonPage;
