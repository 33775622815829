import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Spin, notification } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { DistrictController } from '../../api/DistrictController';
import { CreateDistrictDTO } from '../../models/district/createDistrictDTO';
import { UpdateDistrictDTO } from '../../models/district/updateDistrictDTO';
import AdminDistrictForm, { AdminDistrictFormDataObject } from './AdminDistrictForm';
import ErrorDTO from '../../models/common/ErrorDTO';

interface AdminDistrictPageProps extends PageProps<{ districtId?: string }> {
}

interface AdminDistrictPageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    districtId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: AdminDistrictFormDataObject;
}

class AdminDistrictPage extends React.Component<AdminDistrictPageProps, AdminDistrictPageState> {
    private notificationKey = "AdminDistrictPage";
    constructor(props: AdminDistrictPageProps) {
        super(props);
        bindAllOfThis(this, AdminDistrictPage.prototype);

        let isEdit = props.match.params.districtId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            districtId: props.match.params.districtId,
            isLoading: true,
            formData: null
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AdminDistrictFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving District",
            description: "Please wait while we save the district...",
            duration: 0
        });

        this.setState({ isSubmitting: true }, () => {
            let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);
            request.then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Saved District",
                    description: "Successfully saved the district!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({isSubmitting: false});
                this.props.history.push(Routes.ADMIN_DISTRICTS().toRoute);
            }).catch(error => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Save District",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({isSubmitting: false});
            });
        });
    }

    handleOnSubmitAdd(data: AdminDistrictFormDataObject) {
        let request: CreateDistrictDTO = {
            name: data.name,
            code: data.code,
        };
        return DistrictController.PostCreateDistrict(request);
    }

    handleOnSubmitUpdate(data: AdminDistrictFormDataObject) {
        let request: UpdateDistrictDTO = {
            districtId: data.id,
            name: data.name,
            code: data.code,
        };

        return DistrictController.PutUpdateDistrict(request);
    }

    loadAll() {
        return Promise.all([this.loadFormData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        // Load the user here
        return DistrictController.GetDistrict(this.state.districtId).then(result => {
            let data: AdminDistrictFormDataObject = {
                id: result.data.id,
                code: result.data.code,
                name: result.data.name,
            };
            this.setState({ formData: data });
        });
    }

    renderHeader() {
        if (this.state.isEdit) {
            return <h1>Edit District</h1>;
        }
        return <h1>Add District</h1>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            {this.renderHeader()}
            <AdminDistrictForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
            />
        </div>;
    }
}

export default AdminDistrictPage;
