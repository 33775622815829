/**
 * This provides a simple 1 to 1 map from an easy name to a less known param key
 */
export enum AcceptedParams {
    Something = "something",
    DemoUser = "demouser",
    LessonContentTypeId = "lessonContentTypeId",

    // These deal with the /passcode page for student login. Might be used elsewhere, but mainly the passcode system
    LessonId = "lessonId",
    CourseId = "courseId",
    DocumentaryId="documentaryId",
    ClassroomId = "classroomId",
    StudentPasscode = "code",
    IsParent = "parent",

    // Used for question handling. Just need to know they exist, not the values
    IsStudentQuestion = "studentQuestion",
    IsParentQuestion = "parentQuestion",
    IsAssessmentQuestion = "assessmentQuestion",

    Key = "key",
    LicenseType = "licenseType",
}

// The magic for this was learned from here: https://github.com/Microsoft/TypeScript/issues/5683
type KeyMapper<C extends object, T> = { [P in keyof C]: T };

/**
 * This is purely a helper function. It's primary function is to **limit** the number of options and provide easy access to param values
 *
 * If you need less restriction, use the URLSearchParams class that this is based on.
 *
 * Examples (react only)
 *
 *    let params = new ParamUtil(this.props.location.search);
 *    // Note: 'Something' is defined in the AcceptedParams enum
 *    params.has.Something; // Returns true of false if key exists or not
 *    params.get.Something; // Returns either a string, if found, or null, if not
 */
export class ParamUtil {
    private paramThing: URLSearchParams;
    constructor(urlParams: string | URLSearchParams) {
        this.paramThing = new URLSearchParams(urlParams);

        let easy = Object.keys(AcceptedParams)
            .map(x => ({ [x]: this.paramThing.get(this.retrieve(x)) }))
            .reduce((a, b) => ({ ...a, ...b })) as KeyMapper<typeof AcceptedParams, string>;

        let quick = Object.keys(AcceptedParams)
            .map(x => ({ [x]: this.paramThing.has(this.retrieve(x)) }))
            .reduce((a, b) => ({ ...a, ...b })) as KeyMapper<typeof AcceptedParams, boolean>;

        this.get = { ...easy };
        this.has = { ...quick };
    }

    private retrieve(key: string): string {
        let index = Object.keys(AcceptedParams).findIndex(x => x.toLowerCase() === key.toLowerCase());
        if (index < 0) {
            // no match
            return null;
        }
        return Object.values(AcceptedParams)[index];
    }

    /**
     * Provides easy 'get' access to the params
     */
    public get: KeyMapper<typeof AcceptedParams, string>;

    /**
     * Provides quick 'has' access to the params
     */
    public has: KeyMapper<typeof AcceptedParams, boolean>;

    /**
     * Returns all of the params found by the internal URLSearchParams object
     *
     * Limitations: Only returns the first object. Do you really need multiple duplicated params?
     *
     * Example of what i mean:
     *
     *    // There is no reason for this to be a thing
     *    let url = "example.com?some=yes&some=no";
     */
    public getAll(): { key: string, value: string }[] {
        return Array
            .from(this.paramThing.entries())
            .map(x => ({ key: x[0], value: x[1] }));
    }
}
