import * as React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';

interface WorkflowCourseAppendixProps extends PageProps<{ courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
}

interface WorkflowCourseAppendixState {
    isLoading: boolean;
}

class WorkflowCourseAppendix extends React.Component<WorkflowCourseAppendixProps, WorkflowCourseAppendixState> {
    constructor(props: WorkflowCourseAppendixProps) {
        super(props);
        bindAllOfThis(this, WorkflowCourseAppendix.prototype);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    renderDebugInfo() {
        let appendixUrl = this.props.Workflow.currentCourse.standardsAppendixUrl;
        if (DebugUtil.isDebugEnabled() && appendixUrl != null && appendixUrl.length > 0) {
            return <div>Content Url: {appendixUrl}</div>;
        }
        return null;
    }

    renderPDF() {
        let appendixUrl = this.props.Workflow.currentCourse.standardsAppendixUrl;
        if (appendixUrl == null || appendixUrl.length < 1) {
            return <h3>There does not appear to be a Course Appendix attached to this Course. Please report issues to <strong>admin@rockdigi.org</strong></h3>;
        }

        let url = encodeURI(EncodeSpecialCharacters(appendixUrl));
        

        return <div className="pdf-container">
            <iframe src={`https://docs.google.com/viewer?url=${url}&embedded=true`} frameBorder="0"></iframe>
        </div>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            <h1>Course Appendix</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderDebugInfo()}
            {this.renderPDF()}
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps)(WorkflowCourseAppendix);
