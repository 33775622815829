import axios from 'axios';

import { StudentDTO } from '../models/student/studentDTO';
import { CreateStudentDTO } from '../models/student/createStudentDTO';
import { UpdateStudentDTO } from '../models/student/updateStudentDTO';
import { ClassroomToStudentLessonDTO } from '../models/studentLesson/classroomToStudentLessonDTO';
import { ClassroomDTO } from '../models/classroom/classroomDTO';

export class StudentController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetStudentRoute = (studentId: string) => `students/${studentId}`;
    public static GetStudent(studentId: string) {
        return axios.get<StudentDTO>(this.GetStudentRoute(studentId));
    }

    public static PostCreateStudentRoute = () => `students`;
    public static PostCreateStudent(request: CreateStudentDTO) {
        return axios.post<StudentDTO>(this.PostCreateStudentRoute(), request);
    }

    public static PutUpdateStudentRoute = () => `students`;
    public static PutUpdateStudent(request: UpdateStudentDTO) {
        return axios.put<StudentDTO>(this.PutUpdateStudentRoute(), request);
    }

    public static PutChangePasscodeRoute = (studentId: string) => `students/${studentId}/changePasscode`;
    public static PutChangePasscode(studentId: string) {
        return axios.put<StudentDTO>(this.PutChangePasscodeRoute(studentId));
    }

    public static GetStudentLessonsByStudentRoute = (studentId: string) => `students/${studentId}/studentLessons`;
    public static GetStudentLessonsByStudent(studentId: string) {
        return axios.get<ClassroomToStudentLessonDTO[]>(this.GetStudentLessonsByStudentRoute(studentId));
    }

    public static GetClassroomsByStudentRoute = (studentId: string) => `students/${studentId}/classrooms`;
    public static GetClassroomsByStudent(studentId: string) {
        return axios.get<ClassroomDTO[]>(this.GetClassroomsByStudentRoute(studentId));
    }

    public static DeleteStudentRoute = (studentId: string) => `students/${studentId}`;
    public static DeleteStudent(studentId: string) {
        return axios.delete(this.DeleteStudentRoute(studentId));
    }
}
