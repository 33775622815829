import * as React from 'react';
import { RouteComponentProps} from 'react-router-dom';
import { UserState } from '../../redux/UserReducer';
import bindAllOfThis from '../../utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import { connect } from 'react-redux';
import { Row, Col, notification, Spin} from 'antd';
import { PageProps } from '../../models/common/ComponentProps';
import AdminDemoForm, {AdminDemoFormDataObject} from './AdminDemoForm';
import Routes from '../../core/Routes';
import ErrorDTO from '../../models/common/ErrorDTO';
import { DemoController } from '../../api/DemoController';
import { CreateDemoDTO } from '../../models/demo/CreateDemoDTO';
import { UpdateDemoDTO } from '../../models/demo/UpdateDemoDTO';
import AdminDemoLessonTable, { AdminDemoLessonTableDataObject } from './AdminDemoLessonTable';
import { CourseController } from '../../api/CourseController';

interface AdminDemoPageProps extends PageProps<{ courseId: string, demoId?: string}> {
}

interface AdminDemoPageState {
    isSubmitting: boolean;
    formData: AdminDemoFormDataObject;
    isEdit: boolean;
    courseId: string;
    demoId?: string;
    isLoading: boolean;
    tableData: AdminDemoLessonTableDataObject[];
}

class AdminDemoPage extends React.Component<AdminDemoPageProps, AdminDemoPageState>{
    private notificationKey = "AdminDemoPage";
    constructor(props: AdminDemoPageProps) {
        super(props);
        bindAllOfThis(this, AdminDemoPage.prototype);

        let isEdit = props.match.params.demoId != null;
        this.state = {
            isSubmitting: false,
            formData: null,
            isEdit: isEdit,
            courseId: props.match.params.courseId,
            demoId: props.match.params.demoId,
            isLoading: false,
            tableData: []
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AdminDemoFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving Demo",
            description: "Please wait while we save the demo...",
            duration: 0
        });
        this.setState({ isSubmitting: true });
        let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);
        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Saved Demo",
                description: "Successfully saved the Demo!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isSubmitting: false });
            // Go back if editing, refresh if adding
            if (this.state.isEdit) {
                this.props.history.push(Routes.ADMIN_COURSE_ADD_EDIT(this.state.courseId).toRoute);
            } else {
                this.props.history.push(Routes.ADMIN_DEMO(this.state.courseId, result.data.id).toRoute);
            }
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Save Demo",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleOnSubmitAdd(data: AdminDemoFormDataObject) {
        let request: CreateDemoDTO = {
            description: data.description,
            demoOrder: data.demoOrder,
            courseId: this.state.courseId
        };
        return DemoController.PostCreateDemo(request);
    }

    handleOnSubmitUpdate(data: AdminDemoFormDataObject) {
        let request: UpdateDemoDTO = {
            demoId: data.id,
            description: data.description,
            demoOrder: data.demoOrder
        };
        return DemoController.PutUpdateDemo(request);
    }

    handleDemoLesson(record: AdminDemoLessonTableDataObject) {
        this.setState({ isSubmitting: true });
        if (record.demo) {
            return DemoController.PostSetDemoLesson(record.id).then(result => {
                return this.loadFormData().then(() => {
                    return this.setState({ isSubmitting: false });
                });
            });
        }
        else {
            return DemoController.PutRemoveLessonFromDemo(record.id).then(result => {
                return this.loadFormData().then(() => {
                    return this.setState({ isSubmitting: false });
                });
            });
        }
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadFormData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            this.setState({
                formData: null
            });
            return Promise.resolve();
        }

         DemoController.GetDemo(this.state.demoId).then(result => {
            let data: AdminDemoFormDataObject = {
                id: result.data.id,
                description: result.data.description,
                demoOrder: result.data.demoOrder
            };
            this.setState({
                formData:data
            });
        });
        return CourseController.GetCourse(this.state.courseId).then(result => {
            let tableData = (result.data.lessons || [])
                .map<AdminDemoLessonTableDataObject>(record => {
                    return{
                        id: record.id,
                        name: record.name,
                        ordering: record.ordering,
                        demo: record.demo,
                    };
                });
            this.setState({
                tableData: tableData
            });
        });
    }

    renderTable() {
        if (this.state.isEdit) {
            return <div>
                <h1>Lessons</h1>
                <AdminDemoLessonTable
                    key="demo-lesson-table"
                    tableData={this.state.tableData}
                    isSubmitting={this.state.isSubmitting}
                    isLoading={this.state.isLoading}
                    onLessonUpdate={e => this.handleDemoLesson(e)}
                />
            </div>;
        }
    }

    render() {
        const formItemLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 20 },
            xl: { span: 16 },
        };

        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <h1>{this.state.isEdit ? "Edit" : "Add"} Demo Content</h1>
            <Row  justify="start">
                <Col {...formItemLayout}>
                    <AdminDemoForm
                        isSubmitting={this.state.isSubmitting}
                        onSubmit={this.handleOnSubmit}
                        initialValues={this.state.formData}
                    />
                </Col>
            </Row>

            {this.renderTable()}
        </div>;
    }

}

export default AdminDemoPage;
