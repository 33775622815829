import axios from 'axios';
import { DocumentaryDTO } from '../models/documentary/documentaryDTO';

export class DocumentaryController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetDocumentaryRoute = (documentaryId: string) => `documentaries/${documentaryId}`;
    public static GetDocumentary(documentaryId: string) {
        return axios.get<DocumentaryDTO[]>(this.GetDocumentaryRoute(documentaryId));
    }

    public static GetDocumentariesForSchoolRoute = (schoolId: string) => `documentaries/bySchool/${schoolId}`;
    public static GetDocumentariesForSchool(schoolId: string) {
        return axios.get<DocumentaryDTO[]>(this.GetDocumentariesForSchoolRoute(schoolId));
    }

    public static GetDocumentariesForTeacherRoute = (teacherId: string) => `documentaries/byTeacher/${teacherId}`;
    public static GetDocumentariesForTeacher(teacherId: string) {
        return axios.get<DocumentaryDTO[]>(this.GetDocumentariesForTeacherRoute(teacherId));
    }
}
