import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { Spin } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';
import { DocumentaryDTO } from '../../models/documentary/documentaryDTO';
import ConfigureURLs from '../../core/ConfigureUrls';
import { ConstantState } from '../../redux/ConstantsReducer';
import IframeGoogleDocs from '../../utils/IframeGoogleDocs';
import { RouteComponentProps } from 'react-router';

interface WorkflowDrillDownPageProps extends RouteComponentProps<{ documentaryId: string }> {
    // From Redux
    User: UserState;
    Constants: ConstantState;
}

interface WorkflowDrillDownPageState {
    documentary: DocumentaryDTO;
}

class DocumentaryPostVideoPage extends React.Component<WorkflowDrillDownPageProps, WorkflowDrillDownPageState> {
    constructor(props: WorkflowDrillDownPageProps) {
        super(props);
        bindAllOfThis(this, DocumentaryPostVideoPage.prototype);

        this.state = {
            documentary: this.props.Constants.documentaries[0]
        };
    }

    renderDebugInfo() {
        if (DebugUtil.isDebugEnabled() && this.state.documentary != null) {
            return <div>Content Url: {this.state.documentary.postVideoPDFUrl}</div>;
        }
        return null;
    }

    renderPDF() {
        if (this.state.documentary == null) {
            return <h3>There was an error loading the documentary content</h3>;
        }

        let url = encodeURI(EncodeSpecialCharacters(this.state.documentary.postVideoPDFUrl));

        return <IframeGoogleDocs srcUrl={url}></IframeGoogleDocs>;
    }

    render() {
        return <div>
            <h1>Post-Video</h1>
            <p>{this.state.documentary.description}</p>
            {this.renderDebugInfo()}
            {this.renderPDF()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Constants: state.Constants
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentaryPostVideoPage);
