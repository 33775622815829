import axios from 'axios';

import { StudentResponseDTO } from '../models/studentResponse/studentResponseDTO';
import { CreateStudentResponseDTO } from '../models/studentResponse/createStudentResponseDTO';
import { UpdateStudentResponseDTO } from '../models/studentResponse/updateStudentResponseDTO';

export class StudentResponseController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostCreateStudentResponseRoute = () => `studentResponses`;
    public static PostCreateStudentResponse(request: CreateStudentResponseDTO) {
        return axios.post<StudentResponseDTO>(this.PostCreateStudentResponseRoute(), request);
    }

    public static PutUpdateStudentResponseRoute = () => `studentResponses`;
    public static PutUpdateStudentResponse(request: UpdateStudentResponseDTO) {
        return axios.put<StudentResponseDTO>(this.PutUpdateStudentResponseRoute(), request);
    }

    public static GetStudentResponsesByLessonAndClassroomAndStudentRoute = (lessonId: string, classroomId: string, studentId: string) => `studentResponses/classrooms/${classroomId}/lessons/${lessonId}/student/${studentId}`;
    public static GetStudentResponsesByLessonAndClassroomAndStudent(lessonId: string, classroomId: string, studentId: string) {
        return axios.get<StudentResponseDTO[]>(this.GetStudentResponsesByLessonAndClassroomAndStudentRoute(lessonId, classroomId, studentId));
    }

    public static DeleteStudentResponseRoute = (studentResponseId: string) => `studentResponses/${studentResponseId}`;
    public static DeleteStudentResponse(studentResponseId: string) {
        return axios.delete(this.DeleteStudentResponseRoute(studentResponseId));
    }

    public static DeleteStudentQuestionResponsesByLessonIdRoute = (lessonId: string) => `studentResponses/lesson/${lessonId}/student`;
    public static DeleteStudentQuestionResponsesByLessonId(lessonId: string) {
        return axios.delete<void>(this.DeleteStudentQuestionResponsesByLessonIdRoute(lessonId));
    }

    public static DeleteParentQuestionResponsesByLessonIdRoute = (lessonId: string) => `studentResponses/lesson/${lessonId}/parent`;
    public static DeleteParentQuestionResponsesByLessonId(lessonId: string) {
        return axios.delete<void>(this.DeleteParentQuestionResponsesByLessonIdRoute(lessonId));
    }

    public static DeleteAssessmentQuestionResponsesByLessonIdRoute = (lessonId: string) => `studentResponses/lesson/${lessonId}/assessment`;
    public static DeleteAssessmentQuestionResponsesByLessonId(lessonId: string) {
        return axios.delete<void>(this.DeleteAssessmentQuestionResponsesByLessonIdRoute(lessonId));
    }
}
