import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Radio, Spin, Input, Button, Row, Col } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import AnswerDTO from '../../models/common/AnswerDTO';
import { QuestionDTO } from '../../models/question/questionDTO';
import { StudentResponseController } from '../../api/StudentResponseController';
import { StudentResponseDTO } from '../../models/studentResponse/studentResponseDTO';
import { Roles } from '../../constants/Roles';
import TextArea from 'antd/lib/input/TextArea';

interface WorkflowSchoolHomePartnershipPageProps extends PageProps<{ classroomId?: string, courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowSchoolHomePartnershipPageState {
    questions: QuestionDTO[];
    answers: AnswerDTO[];
    previousResponses: StudentResponseDTO[];
    isLoading: boolean;
    isSubmitting: boolean;
    /** Indicates that the page has completed */
    isComplete: boolean;
}

class WorkflowSchoolHomePartnershipPage extends React.Component<WorkflowSchoolHomePartnershipPageProps, WorkflowSchoolHomePartnershipPageState> {
    private isPreTest: boolean = true;
    constructor(props: WorkflowSchoolHomePartnershipPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowSchoolHomePartnershipPage.prototype);

        this.state = {
            questions: [],
            answers: [],
            previousResponses: [],
            isLoading: true,
            isSubmitting: false,
            isComplete: false,
        };
    }

    componentDidMount() {
        this.figureOutOnNext();
        this.loadPreviousResponses();
    }

    loadPreviousResponses() {
        let currentLesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        let parentQuestions = currentLesson.parentQuestions || [];

        if ([Roles.Teacher, Roles.Admin].includes(this.props.User.role)) {
            let answers = parentQuestions.map<AnswerDTO>(question => {
                return {
                    questionId: question.id,
                    studentResponseId: null,
                    answerId: null
                };
            });
            return this.setState({
                questions: parentQuestions,
                answers: answers,
                isLoading: false,
            }, () => this.figureOutOnNext());
        }

        if (this.props.User.role === Roles.Parent) {
            return StudentResponseController.GetStudentResponsesByLessonAndClassroomAndStudent(
                this.props.match.params.lessonId,
                this.props.match.params.classroomId,
                this.props.User.id).then(result => {

                    // Build the answer storage
                    // Setting answer to null means it's not been answered
                    let answers = parentQuestions.map<AnswerDTO>(question => {
                        let previousAnswer = result.data.find(x => x.preTest === this.isPreTest && x.questionId === question.id);
                        // This page is SPECIAL and we will always use the first and, hopefully, only answer
                        let questionAnswerId = question.questionAnswers == null || question.questionAnswers.length < 1 ? "" : question.questionAnswers[0].id;

                        return {
                            questionId: question.id,
                            answerId: questionAnswerId, // Names are hard
                            studentResponseId: previousAnswer != null ? previousAnswer.id : null,
                            text: previousAnswer != null ? previousAnswer.textResponse : null,
                        };
                    });
                    this.setState({
                        questions: parentQuestions,
                        answers: answers,
                        isLoading: false,
                        previousResponses: result.data
                    });
                });
        }
    }

    figureOutOnNext() {
        // this.props.setButtonState("disabled", "visible");
    }

    onAnswerChange(e: React.ChangeEvent<HTMLTextAreaElement>, questionId: string) {
        let answers = this.state.answers;
        let answer = answers.find(x => x.questionId === questionId);

        // Set answer
        answer.text = e.target.value;

        this.setState({
            answers: answers,
        });
    }

    /** Returns true if all questions have been answered */
    checkQuestionAnswers(): boolean {
        return !this.state.isLoading && !this.state.answers.some(x => x.text == null || x.text.trim().length < 1);
    }

    handleSubmitClick() {
        // Check if we are missing questions
        if (!this.checkQuestionAnswers()) {
            return;
        }

        // Save Answers
        this.setState({ isSubmitting: true });
        let promises = this.state.answers.map(answer => {
            let request = answer.studentResponseId != null
                ? StudentResponseController.PutUpdateStudentResponse({
                    preTest: this.isPreTest,
                    studentResponseId: answer.studentResponseId,
                    questionAnswerId: answer.answerId,
                    textResponse: answer.text,
                })
                : StudentResponseController.PostCreateStudentResponse({
                    preTest: this.isPreTest,
                    studentId: this.props.User.id,
                    classroomId: this.props.match.params.classroomId,
                    questionAnswerId: answer.answerId,
                    textResponse: answer.text,
                });
            return request;
        });

        Promise.all(promises).then(results => {
            this.setState({
                isSubmitting: false,
                isComplete: true
            });
        });
    }

    renderSubmitButton() {
        if (this.props.User.role === Roles.Parent) {
            return <Button type="primary" onClick={this.handleSubmitClick} disabled={!this.checkQuestionAnswers()} loading={this.state.isSubmitting}>Submit</Button>;
        }
        return null;
    }

    renderQuestions() {
        let questions = this.state.questions;

        if (questions.length < 1) {
            return <h3>Sadly, there are no questions for this lesson. Hit next to continue!</h3>;
        }

        return questions
            .sort((first, second) => first.ordering - second.ordering)
            .map(question => {
                return <div key={question.id}>
                    <h3>{question.ordering}. {question.text}</h3>
                    <TextArea
                        placeholder="Enter your answer here"
                        //autosize={{ minRows: 2, maxRows: 6 }} //autosize still exists, why is this error???
                        value={this.state.answers.find(x => x.questionId === question.id).text}
                        onChange={(e: any) => this.onAnswerChange(e, question.id)}
                    />
                </div>;
            });
    }

    renderTeacherHelperText() {
        if ([Roles.Teacher, Roles.Admin].includes(this.props.User.role)) {
            return <p className="teacher-helper-text">First, students will watch the Whiteboard Animation with their parent or guardian. After watching the Whiteboard Animation, they will then answer the following questions together.</p>;
        }
        return null;
    }

    renderDemoHelperText() {
        if (this.props.Workflow.isDemoMode) {
            return <p className="demo-helper-text">The School Home Partnership was created in a way that is short enough that anyone could complete it in a matter of minutes. This way, it's still sparking those important topics at home and connecting what students are learning at school and at home.</p>;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        if (this.state.isComplete) {
            return <div>
                <h1>School Home Partnership</h1>
                <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
                <p>Thank you for taking the time to respond to these questions! You can now close this page</p>
            </div>;
        }

        return <div>
            <h1>School Home Partnership</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderDemoHelperText()}
            {this.renderTeacherHelperText()}
            <p>After watching the Whiteboard Animation together, please answer the following questions together.</p>
            {this.renderQuestions()}
            <br />
            {this.renderSubmitButton()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSchoolHomePartnershipPage);
