export interface LessonContentType {
    id: string;
    name: string;
    displayName: string;
    hasUpload: boolean;
}

export class LessonContentTypes {
    public static readonly DiscussionQuestions: LessonContentType = { id: "1", name: "DiscussionQuestions", displayName: "Discussion Questions", hasUpload: true };
    public static readonly DrillDown: LessonContentType = { id: "2", name: "DrillDown", displayName: "Drill Down", hasUpload: true };
    public static readonly ExistingKnowledge: LessonContentType = { id: "3", name: "ExistingKnowledge", displayName: "Existing Knowledge", hasUpload: false };
    public static readonly ExitTicket: LessonContentType = { id: "4", name: "ExitTicket", displayName: "Exit Ticket", hasUpload: false };
    public static readonly LyricMusicVideo: LessonContentType = { id: "5", name: "LyricMusicVideo", displayName: "Lyric Music Video", hasUpload: true };
    public static readonly SchoolHomePartnership: LessonContentType = { id: "6", name: "SchoolHomePartnership", displayName: "School Home Partnership", hasUpload: false };
    public static readonly WhiteBoardAnimations: LessonContentType = { id: "7", name: "WhiteBoardAnimations", displayName: "White Board Animations", hasUpload: true };
    public static readonly LessonPlansWithStandards: LessonContentType = { id: "8", name: "LessonPlansWithStandards", displayName: "Lesson Plans With Standards", hasUpload: true };
    public static readonly OrientationAndInstructions: LessonContentType = { id: "9", name: "OrientationAndInstructions", displayName: "Orientation And Instructions", hasUpload: false };

    public static All: LessonContentType[] = [
        LessonContentTypes.DiscussionQuestions,
        LessonContentTypes.DrillDown,
        LessonContentTypes.ExistingKnowledge,
        LessonContentTypes.ExitTicket,
        LessonContentTypes.LyricMusicVideo,
        LessonContentTypes.SchoolHomePartnership,
        LessonContentTypes.WhiteBoardAnimations,
        LessonContentTypes.LessonPlansWithStandards,
        LessonContentTypes.OrientationAndInstructions,
    ];

    public static FindById(id: string) {
        let basic = id.toLowerCase();
        return this.All.find(x => x.id.toLowerCase() === basic);
    }
}
