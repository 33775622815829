import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import {
    BookOutlined,
    HomeOutlined,
    LoadingOutlined,
    RollbackOutlined,
    SettingOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Tooltip } from 'antd';
import { CollapseType } from 'antd/lib/layout/Sider';
import { UnregisterCallback } from 'history';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { WorkflowState } from '../../redux/WorkflowReducer';
import ReduxStoreModel from '../../redux/ReduxModel';
import { Checkpoints, Checkpoint } from '../../constants/Checkpoints';
import { Roles } from '../../constants/Roles';
import { UserState } from '../../redux/UserReducer';
import EnvironmentStaticContent from '../../constants/EnvironmentStaticContent';
import { FullLessonDTO } from '../../models/lesson/fullLessonDTO';
import WorkflowAction from '../../redux/WorkflowAction';
import { DemoController } from '../../api/DemoController';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface WorkflowSideNavProps extends RouteComponentProps<{ classroomId?: string, courseId: string, lessonId: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    ResetButtonState: () => void;
}

interface WorkflowSideNavState {
    selectedKey: string[];
    openKey: string[];
    isLoading: boolean;
    /** Set exclusively by the sider. DO NOT SET ANYWHERE ELSE */
    isSiderCollapsed: boolean;
}

class WorkflowSideNav extends React.Component<WorkflowSideNavProps, WorkflowSideNavState> {
    unlisten: UnregisterCallback;
    constructor(props: WorkflowSideNavProps) {
        super(props);
        bindAllOfThis(this, WorkflowSideNav.prototype);

        // Find selected key
        let selectedKey = [this.props.match.url];

        // Find open key
        let openKey = [""];

        // Forces rerender when URL changes since React doesn't always run render
        this.unlisten = props.history.listen((x) => {
            this.forceUpdate();
        });

        this.state = {
            selectedKey: selectedKey,
            openKey: openKey,
            isLoading: true,
            isSiderCollapsed: false,
        };
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        this.setOpenKey(this.props);
    }

    componentWillReceiveProps(nextProps: WorkflowSideNavProps) {
        if (this.props.match.url !== nextProps.match.url) {
            // Check open key
            this.setOpenKey(nextProps);
        }
    }

    setOpenKey(props: WorkflowSideNavProps) {
        let openKey = this.state.openKey;
        let lessonId = props.match.params.lessonId;
        let courseId = props.match.params.courseId;
        if (lessonId != null) {
            openKey = [`submenu${courseId}${lessonId}`];
        }
        this.setState({
            selectedKey: [props.match.url],
            openKey: openKey,
            isLoading: false,
        });
    }

    onSiderCollapse(collapsed: boolean, type: CollapseType) {
        this.setState({ isSiderCollapsed: collapsed });
    }

    subMenuOnClick(courseId: string, lessonId: string) {
        let openKey = `submenu${courseId}${lessonId}`;

        // If the current key is "open" then remove it, closing it instead
        if (this.state.openKey.length > 0 && this.state.openKey[0] === openKey) {
            return this.setState({
                openKey: []
            });
        }
        this.setState({
            openKey: [openKey]
        });
    }

    renderSubMenuItem(courseId: string, lessonId: string, checkpoint: Checkpoint, isClickable: boolean) {
        let route = [Roles.Student, Roles.Parent].find(x => x === this.props.User.role)
            ? Routes.STUDENT_WORKFLOW(this.props.match.params.classroomId, courseId, lessonId, checkpoint.name.toLowerCase()).toRoute
            : Routes.WORKFLOW(courseId, lessonId, checkpoint.name.toLowerCase()).toRoute;

        if (isClickable) {
            return (
                <Menu.Item key={route}>
                    <NavLink to={{
                        pathname: route,
                        search: this.props.User.role === Roles.Parent ? "?parent=true" : null
                    }}>
                        <SettingOutlined /><span>{checkpoint.display}</span>
                    </NavLink>
                </Menu.Item>
            );
        }
        return (
            <Menu.Item disabled={true} key={route}>
                <SettingOutlined /><span>{checkpoint.display}</span>
            </Menu.Item>
        );
    }

    renderLessonSubMenuForDynamicDemo(courseId: string, lesson:FullLessonDTO){
        let checkpoints = [...Checkpoints.Demo];
        let lessonId = lesson.id;
        let isLessonDemo = lesson.demo;

        if(!isLessonDemo){
            let subMenuTitle = "Lesson not available for this demo";
            return (
                <SubMenu key={`submenu${courseId}${lessonId}`}
                    title={[
                        <Tooltip key="lesson-icon-tooltip" placement="topRight" title={subMenuTitle} mouseEnterDelay={0.5}><TeamOutlined key={`title${courseId}${lessonId}`} /></Tooltip>,
                        <Tooltip key="lesson-text-tooltip" placement="topRight" title={subMenuTitle} mouseEnterDelay={0.5}><span key="lesson-text">{lesson.name}</span></Tooltip>,
                    ]}
                    disabled={true}
                ></SubMenu>
            );
        }

        return (
            <SubMenu key={`submenu${courseId}${lessonId}`}
            onTitleClick={() => this.subMenuOnClick(courseId, lessonId)}
            title={[
                <Tooltip key="lesson-icon-tooltip" placement="topRight" title={lesson.name} mouseEnterDelay={0.5}><TeamOutlined key={`title${courseId}${lessonId}`} /></Tooltip>,
                <span key="lesson-text">{lesson.name}</span>
            ]}
        >
            {checkpoints.map(checkpoint => this.renderSubMenuItem(courseId, lessonId, checkpoint, true))}
        </SubMenu>
        );
    }

    renderLessonSubMenu(courseId: string, lesson: FullLessonDTO, lessons: FullLessonDTO[]) {
        let menuItems: JSX.Element[] = [];
        let checkpoints = [...Checkpoints.GetCheckpointsByRole(this.props.User.role)];
        //only show start assessment on first lesson for a course (regardless of what's available currently)
        if (lesson.ordering !== 1) {checkpoints = checkpoints.filter(x => x !== Checkpoints.StartSelfAssessment);}
        //only show end assessment on last lesson for a course (regardless of what's available currently)
        if (lessons.indexOf(lesson) !== lessons.length - 1) {checkpoints = checkpoints.filter(x => x !== Checkpoints.EndSelfAssessment);}
        let lessonId = lesson.id;

        // Render the top level menu
        // Reduce options based on role
        if ([Roles.Student, Roles.Parent].find(x => x === this.props.User.role)) {
            // Get the current student checkpoint
            let studentCheckpoint = this.props.Workflow.studentCheckpoints
                .find(x => x.classroomId === this.props.match.params.classroomId && x.lessonId === lesson.id);
            let currentCheckpoint = studentCheckpoint != null
                ? Checkpoints.FindById(studentCheckpoint.checkpointId)
                : checkpoints[0];

            // Students are not allowed to click on next "checkpoints"
            menuItems = checkpoints.map(checkpoint => {
                if (checkpoint.order > currentCheckpoint.order) {
                    return this.renderSubMenuItem(courseId, lessonId, checkpoint, false);
                }
                return this.renderSubMenuItem(courseId, lessonId, checkpoint, true);
            });
        } else {
            menuItems = checkpoints.map(checkpoint => {
                return this.renderSubMenuItem(courseId, lessonId, checkpoint, true);
            });
        }

        // Render
        return (
            <SubMenu key={`submenu${courseId}${lessonId}`}
                onTitleClick={() => this.subMenuOnClick(courseId, lessonId)}
                title={[
                    <Tooltip key="lesson-icon-tooltip" placement="topRight" title={lesson.name} mouseEnterDelay={0.5}><TeamOutlined key={`title${courseId}${lessonId}`} /></Tooltip>,
                    <span key="lesson-text">{lesson.name}</span>
                ]}
            >
                {menuItems}
            </SubMenu>
        );
    }

    renderCourseAppendix() {
        if (![Roles.Student, Roles.Parent].includes(this.props.User.role)) {
            return (
                <Menu.Item key={Routes.GET.WORKFLOW_COURSE_APPENDIX}>
                    <NavLink to={Routes.WORKFLOW_COURSE_APPENDIX(this.props.match.params.courseId).toRoute}>
                        <BookOutlined />
                        <span>Course Appendix</span>
                    </NavLink>
                </Menu.Item>
            );
        }
    }

    renderClassroomLessons() {
        if (this.props.Workflow.courseState === "loading") {
            return (
                <Menu.Item key="Loading">
                    <LoadingOutlined />
                    <span>Loading Lessons...</span>
                </Menu.Item>
            );
        }
        if (this.props.Workflow.courseState !== "finished") {
            return null;
        }

        let courseId = this.props.match.params.courseId;
        let lessons = this.props.Workflow.currentCourse.lessons;

        // Demo mode needs a different renderer
        if(this.props.Workflow.isDemoMode){
            return lessons
                .sort((a, b) => a.ordering - b.ordering)
                .map(lesson => this.renderLessonSubMenuForDynamicDemo(courseId, lesson));
        }

        // Render those lessons
        return lessons
            .filter(x => x.available) // Reduce lessons on availability, which is set in the LoadCourse inside of the WorkflowAction file
            .sort((a, b) => a.ordering - b.ordering)
            .map(lesson => this.renderLessonSubMenu(courseId, lesson, lessons));
    }

    renderMenuItems() {
        let menuItems: JSX.Element[] = [];

        /* Home or Go Back */
        if (this.props.User.role === Roles.Teacher || this.props.User.role === Roles.Admin) {
            let route = this.props.Workflow.isDemoMode
                ? Routes.GET.DEMO
                : this.props.User.role === Roles.Teacher
                    ? Routes.GET.TEACHER_MY_SCHOOL
                    : Routes.GET.ADMIN_COURSES;
            menuItems.push(<Menu.Item key={route}>
                <NavLink to={route}>
                    <RollbackOutlined />
                    <span>Go Back</span>
                </NavLink>
            </Menu.Item>);
        } else if (this.props.User.role === Roles.Student) {
            // Parents get no home screen
            menuItems.push(<Menu.Item key={Routes.GET.STUDENT_HOME}>
                <NavLink to={Routes.GET.STUDENT_HOME}>
                    <HomeOutlined />
                    <span>Dashboard</span>
                </NavLink>
            </Menu.Item>);
        }

        return menuItems;
    }

    public render() {
        if (this.state.isLoading) {
            return null;
        }
        return <Sider
            width="250"
            className="side-nav"
            breakpoint="md"
            collapsible
            onCollapse={this.onSiderCollapse}
        >
            <Menu
                mode="inline"
                defaultOpenKeys={this.state.openKey}
                subMenuOpenDelay={0}
                subMenuCloseDelay={0.2}
                selectedKeys={this.state.selectedKey}
            >
                {this.renderMenuItems()}
                {this.renderClassroomLessons()}
                {this.renderCourseAppendix()}
            </Menu>
        </Sider>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        ResetButtonState: () => WorkflowAction.SetButtonState(dispatch, { next: "disabled", back: "disabled" }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WorkflowSideNav));
