import * as React from 'react';
import { Layout, Menu } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import GeneralHeader from '../GeneralHeader';
import GeneralFooter from '../GeneralFooter';

const { Content } = Layout;

interface PublicLayoutProps {
}

interface PublicLayoutState {
}

class PublicLayout extends React.Component<PublicLayoutProps, PublicLayoutState> {
    constructor(props: PublicLayoutProps) {
        super(props);
        bindAllOfThis(this, PublicLayout.prototype);

        this.state = {};
    }

    public render() {
        return <Layout className="public-layout">
            <GeneralHeader />
            <Content>
                {this.props.children}
            </Content>
            <GeneralFooter />
        </Layout>;
    }
}

export default PublicLayout;
