import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Checkbox, Radio, Modal } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import { StudentDTO } from '../../models/student/studentDTO';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import Routes from '../../core/Routes';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { SchoolYears, SchoolYear } from '../../constants/SchoolYears';

interface TeacherAddEditStudentFormProps extends FormComponentProps, RouteComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: StudentDTO) => void;
    initialValues?: Partial<StudentDTO>;
}

interface TeacherAddEditStudentFormState {
    isEdit: boolean;
    filteredSchoolYears: SchoolYear[];
    schoolYearFilter: string;
}

class TeacherAddEditStudentForm extends React.Component<TeacherAddEditStudentFormProps, TeacherAddEditStudentFormState> {
    // Just need to initialize sub objects
    constructor(props: TeacherAddEditStudentFormProps) {
        super(props);
        bindAllOfThis(this, TeacherAddEditStudentForm.prototype);

        let isEdit = props.initialValues != null;

        this.state = {
            isEdit: isEdit,
            filteredSchoolYears: SchoolYears.All,
            schoolYearFilter: ""
        };
    }

    async handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: StudentDTO): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;

            values.schoolYearId = this.state.isEdit ?
                this.props.initialValues.schoolYearId :
                ((values.schoolYearId as any) as KeyLabel).key;

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });

    }

    handleSchoolYearFilterChange(e: string) {
        let filter = (e || "").toString().toLowerCase();
        let schoolYears = SchoolYears.All.filter(x => x.startYear.toString().includes(filter));
        this.setState({
            schoolYearFilter: filter,
            filteredSchoolYears: schoolYears,
        });
    }

    ensureCheckboxChecked(rule: any, value: any, callback: any) {
        // const form = this.props.form;
        if (!value && !this.state.isEdit) {
            callback('The student must agree to the privacy policy.');
        }
        callback();
    }

    renderSchoolYearSelect() {
        return <Select
            placeholder={"Please select a school year"}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
            showSearch
            onChange={this.handleSchoolYearFilterChange}
            onSearch={this.handleSchoolYearFilterChange}
        >
            {this.state.filteredSchoolYears.map(schoolYear => <Select.Option key={`schoolYear${schoolYear.id}`} value={`${schoolYear.id}`}>{schoolYear.startYear} - {schoolYear.endYear}</Select.Option>)}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Email"
                name={NameOf<StudentDTO>("email")}
                initialValue={initialValues.email}
                rules={[{ required: true, message: "Email is required." }, { type: 'email', message: 'Not a valid Email.' }]}
            >
                <Input size="large" placeholder="Email"  {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Username"
                name={NameOf<StudentDTO>("lastName")}
                initialValue={initialValues.lastName}
                rules={[{ required: true, message: "Username is required." }]}
            >
                <Input size="large" placeholder="Username"  {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Nickname"
                name={NameOf<StudentDTO>("firstName")}
                initialValue={initialValues.firstName}
            >
                <Input size="large" placeholder="Nickname (Optional)"  {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Guardian Name"
                name={NameOf<StudentDTO>("guardianName")}
                initialValue={initialValues.guardianName}
                rules={[{ required: true, message: "Guardian Name is required." }]}
            >
                <Input size="large" placeholder="Guardian Name"  {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Guardian Email"
                name={NameOf<StudentDTO>("guardianEmail")}
                initialValue={initialValues.guardianEmail}
                rules={[{ required: true, message: "Guardian Email is required." }, { type: 'email', message: 'Not a valid Email.' }]}
            >
                <Input size="large" placeholder="Guardian Email"  {...disableAutoCompleteTags()} />
            </FieldDecorator>

            {!this.state.isEdit ?
                <FieldDecorator
                    {...formItemLayout}
                    form={this.props.form}
                    label="School Year"
                    name={NameOf<StudentDTO>("schoolYearId")}
                    rules={[{ required: true, message: 'School Year is required.' }]}
                >
                    {this.renderSchoolYearSelect()}
                </FieldDecorator> : null
            }

            <FieldDecorator
                form={this.props.form}
                name={"acceptPrivacyPolicy"}
                valuePropName="checked" // Needed for checkboxes
                rules={[{ validator: this.ensureCheckboxChecked }]}
            >
                <Checkbox disabled={!this.state.isEdit ? false : true}>The student and/or parent/guardian agrees to the
                    <Link target='#' to={Routes.PRIVACY_POLICY().toRoute}> Privacy Policy</Link>
                </Checkbox>
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save Student" : "Add Student"}
                </Button>

                <Link to={Routes.GET.TEACHER_STUDENTS}>
                    <Button className="login-form-button" size="large">
                        Go Back
                    </Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default withRouter(Form.create<TeacherAddEditStudentFormProps>()(TeacherAddEditStudentForm));
