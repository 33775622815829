import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Redirect } from 'react-router-dom';
import { Row, Col, Card, notification } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import Routes from '../../../core/Routes';
import RegistrationForm from './RegistrationForm';
import UserAction from '../../../redux/UserActions';
import ReduxStoreModel from '../../../redux/ReduxModel';
import { UserState } from '../../../redux/UserReducer';
import { CreateTeacherDTO } from '../../../models/teacher/createTeacherDTO';
import { PageProps } from '../../../models/common/ComponentProps';
import ActionResultDTO from '../../../models/common/ActionResultDTO';


interface RegistrationPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    Register: (request: CreateTeacherDTO) => Promise<ActionResultDTO>;
}

interface RegistrationPageState {
    errorMessage?: string;
    isLoading: boolean;
    isSubmitting: boolean;
}

class RegistrationPage extends React.Component<RegistrationPageProps, RegistrationPageState> {
    private notificationKey = "RegistrationPage";
    constructor(props: RegistrationPageProps) {
        super(props);
        bindAllOfThis(this, RegistrationPage.prototype);

        this.state = {
            isLoading: true,
            isSubmitting: false,
            errorMessage: "",
        };
    }

    handleRegisterClick(data: CreateTeacherDTO) {
        // Don't crush the login endpoint
        if (this.state.isSubmitting) {
            return;
        }

        notification.info({
            key: this.notificationKey,
            message: "Register",
            description: "Logging in to the site...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        this.props.Register(data).then(result => {
            if (!result.isError) {
                notification.success({
                    key: this.notificationKey,
                    message: "Register",
                    description: "Registration successful!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });
            } else {
                notification.error({
                    key: this.notificationKey,
                    message: "Register",
                    description: ["Register returned an error.", result.message].map(x => <div>{x}</div>),
                    duration: 0
                });
            }
            this.setState({
                isSubmitting: false
            });
        });
    }

    render() {
        if (this.props.User.state === "finished") {
            if (this.props.User.isBrandNew) {
                return <Redirect to={Routes.DEMO().toRoute} push />;
            }
            return <Redirect to={Routes.HOME().toRoute} push />;
        }

        return <div className="registration-page">
            <Row>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 18, offset: 3 }}
                    lg={{ span: 16, offset: 4 }}
                    xl={{ span: 14, offset: 5 }}
                >
                    <Card style={{backgroundColor: "#f2f2f2"}}>
                        <h1>Registration Page</h1>
                        <RegistrationForm onSubmit={this.handleRegisterClick} isSubmitting={this.state.isSubmitting} />
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        Register: (request: CreateTeacherDTO) => UserAction.Register(dispatch, request),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);

