import axios from 'axios';
import { LessonDTO } from '../models/lesson/lessonDTO';
import { CreateLessonDTO } from '../models/lesson/createLessonDTO';
import { UpdateLessonDTO } from '../models/lesson/updateLessonDTO';
import { SurveyQuestionDTO } from '../models/surveyQuestion/surveyQuestionDTO';
import { RateLessonClassroomDTO } from '../models/lessonClassroom/rateLessonClassroomDTO';

export class LessonController {
    constructor() {
        throw new Error("Nooo");
    }

    public static PostCreateLessonRoute = () => `lessons`;
    public static PostCreateLesson(request: CreateLessonDTO) {
        return axios.post<LessonDTO>(this.PostCreateLessonRoute(), request);
    }

    public static PutUpdateLessonRoute = () => `lessons`;
    public static PutUpdateLesson(request: UpdateLessonDTO) {
        return axios.put<LessonDTO>(this.PutUpdateLessonRoute(), request);
    }

    public static PutUpdateLessonClassroomRatingRoute = () => `lessons/classrooms/rateLessonClassroom`;
    public static PutUpdateLessonClassroomRating(request: RateLessonClassroomDTO) {
        return axios.put<void>(this.PutUpdateLessonClassroomRatingRoute(), request);
    }

    public static DeleteLessonRoute = (lessonId: string) => `lessons/${lessonId}`;
    public static DeleteLesson(lessonId: string) {
        return axios.delete(this.DeleteLessonRoute(lessonId));
    }

    public static GetLessonRoute = (lessonId: string) => `lessons/${lessonId}`;
    public static GetLesson(lessonId: string) {
        return axios.get<LessonDTO>(this.GetLessonRoute(lessonId));
    }

    public static PutChangeAvailabilityRoute = (lessonId: string, classroomId: string, licenseId: string) => `lessons/${lessonId}/classrooms/${classroomId}/licenses/${licenseId}/changeAvailability`;
    public static PutChangeAvailability(lessonId: string, classroomId: string, licenseId: string) {
        return axios.put<void>(this.PutChangeAvailabilityRoute(lessonId, classroomId, licenseId));
    }

    public static GetSendStudentHomePartnershipEmailRoute = (lessonId: string, classroomId: string, licenseId: string) => `lessons/${lessonId}/classrooms/${classroomId}/licenses/${licenseId}/sendStudentHomePartnershipEmail`;
    public static GetSendStudentHomePartnershipEmail(lessonId: string, classroomId: string, licenseId: string) {
        return axios.get<void>(this.GetSendStudentHomePartnershipEmailRoute(lessonId, classroomId, licenseId));
    }

    public static GetSurveyQuestionsByLessonRoute = (lessonId: string) => `lessons/${lessonId}/surveyQuestions`;
    public static GetSurveyQuestionsByLesson(lessonId: string) {
        return axios.get<SurveyQuestionDTO[]>(this.GetSurveyQuestionsByLessonRoute(lessonId));
    }
}
