export interface PricingTier{
    id: string;
    oneYearCost: number;
    threeYearCost: number;
    numberOfSeats: number;
}

export class PricingTiers{
    public static readonly None: PricingTier = { id: "0", oneYearCost: 0, threeYearCost: 0, numberOfSeats:  0};
    public static readonly One: PricingTier = { id: "1",  oneYearCost: 10000, threeYearCost: 8500, numberOfSeats:  5};
    public static readonly Two: PricingTier = { id: "2",  oneYearCost: 20000, threeYearCost: 17000, numberOfSeats:  10};
    public static readonly Three: PricingTier = { id: "3",oneYearCost: 30000, threeYearCost: 25000, numberOfSeats:   15};
    public static readonly Four: PricingTier = { id: "4",oneYearCost: 30000, threeYearCost: 25000, numberOfSeats:   15};

    public static All: PricingTier[] = [
        PricingTiers.None,
        PricingTiers.One,
        PricingTiers.Two,
        PricingTiers.Three,
        PricingTiers.Four
    ];

    public static FindById(id:string){
        return this.All.find(x => x.id === id);
    }

    public static FindBySeatCount(count: number){
        if (count <= 5) {
            return this.One;
        } else if (count <= 10) {
            return this.Two;
        } else if (count <= 15) {
            return this.Three;
        }
        return this.Four;
    }
}
