import axios from 'axios';
import { ClassroomDTO } from '../models/classroom/classroomDTO';
import { CreateClassroomDTO } from '../models/classroom/createClassroomDTO';
import { UpdateClassroomDTO } from '../models/classroom/updateClassroomDTO';
import { AssignStudentsToClassroomDTO } from '../models/classroom/assignStudentsToClassroomDTO';
import { RemoveStudentsFromClassroomDTO } from '../models/classroom/removeStudentsFromClassroomDTO';
import { StudentDTO } from '../models/student/studentDTO';
import { CourseDTO } from '../models/course/courseDTO';
import { LicenseDTO } from '../models/license/licenseDTO';
import { SimpleLessonDTO } from '../models/lesson/simpleLessonDTO';
import { SimpleCourseDTO } from '../models/course/simpleCourseDTO';


export class ClassroomController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetGetClassroomRoute = (classroomId: string) => `classrooms/${classroomId}`;
    public static GetGetClassroom(classroomId: string) {
        return axios.get<ClassroomDTO>(this.GetGetClassroomRoute(classroomId));
    }

    public static PostCreateClassroomRoute = () => `classrooms`;
    public static PostCreateClassroom(request: CreateClassroomDTO) {
        return axios.post<ClassroomDTO>(this.PostCreateClassroomRoute(), request);
    }

    public static PutUpdateClassroomRoute = () => `classrooms`;
    public static PutUpdateClassroom(request: UpdateClassroomDTO) {
        return axios.put<ClassroomDTO>(this.PutUpdateClassroomRoute(), request);
    }

    public static PostAssignStudentsRoute = () => `classrooms/assignStudents`;
    public static PostAssignStudents(request: AssignStudentsToClassroomDTO) {
        return axios.post<void>(this.PostAssignStudentsRoute(), request);
    }

    public static PostRemoveStudentsRoute = () => `classrooms/removeStudents`;
    public static PostRemoveStudents(request: RemoveStudentsFromClassroomDTO) {
        return axios.post<string[]>(this.PostRemoveStudentsRoute(), request);
    }

    public static DeleteDeleteClassroomRoute = (classroomId: string) => `classrooms/${classroomId}`;
    public static DeleteDeleteClassroom(classroomId: string) {
        return axios.delete(this.DeleteDeleteClassroomRoute(classroomId));
    }

    public static GetEmailStudentLoginsByClassroomAndLicenseRoute = (classroomId: string, licenseId: string) => `classrooms/${classroomId}/licenses/${licenseId}/emailStudentLogins`;
    public static GetEmailStudentLoginsByClassroomAndLicense(classroomId: string, licenseId: string) {
        return axios.get<void>(this.GetEmailStudentLoginsByClassroomAndLicenseRoute(classroomId, licenseId));
    }

    public static GetGetStudentsByClassroomRoute = (classroomId: string, courseId: string) => `classrooms/${classroomId}/courses/${courseId}/students`;
    public static GetGetStudentsByClassroom(classroomId: string, courseId: string) {
        return axios.get<StudentDTO[]>(this.GetGetStudentsByClassroomRoute(classroomId, courseId));
    }

    public static GetLessonsByClassroomAndCourseRoute = (classroomId: string, courseId: string) => `classrooms/${classroomId}/courses/${courseId}/lessons`;
    public static GetLessonsByClassroomAndCourse(classroomId: string, courseId: string) {
        return axios.get<SimpleLessonDTO[]>(this.GetLessonsByClassroomAndCourseRoute(classroomId, courseId));
    }

    public static GetCoursesByClassroomRoute = (classroomId: string) => `classrooms/${classroomId}/courses`;
    public static GetCoursesByClassroom(classroomId: string) {
        return axios.get<SimpleCourseDTO[]>(this.GetCoursesByClassroomRoute(classroomId));
    }

    public static GetStudentsByClassroomAndLicenseRoute = (classroomId: string, licenseId: string) => `classrooms/${classroomId}/licenses/${licenseId}/students`;
    public static GetStudentsByClassroomAndLicense(classroomId: string, licenseId: string) {
        return axios.get<StudentDTO[]>(this.GetStudentsByClassroomAndLicenseRoute(classroomId, licenseId));
    }

    public static GetLicensesByClassroomRoute = (classroomId: string) => `classrooms/${classroomId}/licenses`;
    public static GetLicensesByClassroom(classroomId: string) {
        return axios.get<LicenseDTO[]>(this.GetLicensesByClassroomRoute(classroomId));
    }

    public static AddCourseToClassroomByLicenseRoute = (classroomId: string, licenseId: string) => `classrooms/${classroomId}/licenses/${licenseId}/addCourseToClassroom`;
    public static AddCourseToClassroomByLicense(classroomId: string, licenseId: string) {
        return axios.post(this.AddCourseToClassroomByLicenseRoute(classroomId, licenseId));
    }
} 
