import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Tree } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import WorkflowAction from '../../redux/WorkflowAction';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { ClassroomController } from '../../api/ClassroomController';
import { StudentController } from '../../api/StudentController';
import { Checkpoints } from '../../constants/Checkpoints';
const { TreeNode } = Tree;

interface StudentHomePageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    ClearCourse: () => void;
}

interface AvailableClassroom {
    id: string;
    name: string;
    courses: Array<{
        id: string,
        name: string,
        lessons: Array<{
            id: string,
            name: string
        }>
    }>;
}

interface StudentHomePageState {
    areClassroomsLoading: boolean;
    availableClassrooms: AvailableClassroom[];
}

class StudentHomePage extends React.Component<StudentHomePageProps, StudentHomePageState> {
    private notificationKey = "StudentHomePage";
    constructor(props: StudentHomePageProps) {
        super(props);
        bindAllOfThis(this, StudentHomePage.prototype);

        this.state = {
            areClassroomsLoading: true,
            availableClassrooms: []
        };
    }

    componentDidMount() {
        this.props.ClearCourse();
        this.loadAll();
    }

    async loadAll() {
        this.setState({
            areClassroomsLoading: true
        });
        let classroomResponse = (await StudentController.GetClassroomsByStudent(this.props.User.id));
        let availableClassrooms = await Promise.all(classroomResponse.data.map(async x => ({
            id: x.id,
            name: x.name,
            courses: (await ClassroomController.GetCoursesByClassroom(x.id)).data.sort((a, b) => a.id.localeCompare(b.id)).map(y => ({
                id: y.id,
                name: y.name,
                lessons: y.lessons.filter(z => z.available).sort((a, b) => a.ordering - b.ordering).map(z => ({
                    id: z.id,
                    name: z.name
                }))
            }))
        })));

        this.setState({
            availableClassrooms: availableClassrooms.sort((a, b) => a.id.localeCompare(b.id)),
            areClassroomsLoading: false
        });
    }

    render() {
        if (this.state.areClassroomsLoading) {
            return <div>
                <h1>Home</h1>
                <p>Loading...</p>
            </div>;
        }
        if (!this.state.areClassroomsLoading && this.state.availableClassrooms.length < 1) {
            return <div>
                <h1>Home</h1>
                <p>Sorry, you do not appear to be in any classrooms! Ask your teacher to add you to one first.</p>
            </div>;
        }

        return <React.Fragment>
            <h1>Home</h1>
            <Tree showLine defaultExpandAll={true} >
                {
                    this.state.availableClassrooms.map(classroom => (
                        <TreeNode title={classroom.name} key={classroom.id} selectable={false}>
                            {
                                classroom.courses.map(course => (
                                    <TreeNode title={<Link className="container" to={Routes.STUDENT_COURSE_HOME(classroom.id, course.id).toRoute}>{course.name}</Link>} key={course.id} >
                                        {
                                            course.lessons.map(lesson => (
                                                <TreeNode title={<Link className="container" to={Routes.STUDENT_WORKFLOW(classroom.id, course.id, lesson.id, Checkpoints.Home.name.toLowerCase()).toRoute}>{lesson.name}</Link>} key={lesson.id} />
                                            ))
                                        }
                                    </TreeNode>
                                ))
                            }
                        </TreeNode>
                    ))
                }
            </Tree>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        ClearCourse: () => WorkflowAction.ClearCourse(dispatch),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentHomePage);
