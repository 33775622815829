import axios from 'axios';
import { AccountDTO } from '../models/account/accountDTO';
import { AdminDTO } from '../models/admin/adminDTO';
import { UpdateAdminDTO } from '../models/admin/updateAdminDTO';

export class AdminController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetAllAdminsRoute = () => `admin`;
    public static GetAllAdmins() {
        return axios.get<AdminDTO[]>(this.GetAllAdminsRoute());
    }

    public static PutUpdateAdminRoute = () => `admin`;
    public static PutUpdateAdmin(request: UpdateAdminDTO) {
        return axios.put<AdminDTO>(this.PutUpdateAdminRoute(), request);
    }

    public static PutAdminAccountRoute = () => `admin`;
    public static PutAdminAccount(request: UpdateAdminDTO) {
        throw new Error("Yeah, this doesn't work");
        return axios.put<AccountDTO>(this.PutAdminAccountRoute(), request);
    }

    public static GetAdminByIdRoute = (adminId: string) => `admin/byId/${adminId}`;
    public static GetAdminById(adminId: string) {
        return axios.get<AdminDTO>(this.GetAdminByIdRoute(adminId));
    }

    public static PutAdminHelpInstructionsRoute = () => `/admin/helpInstructions`;
    public static PutAdminHelpInstructions(formData: FormData) {
        let headers = { 'content-type': `multipart/form-data; boundary=${(formData as any)._boundary}` };
        return axios.put<void>(this.PutAdminHelpInstructionsRoute(), formData, {headers: headers});
    }
}
