import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Select, DatePicker, Checkbox, AutoComplete } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Moment } from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import { FieldDecorator } from '../../components/formHelpers';
import { NameOf } from '../../utils/NameOf';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { SimpleSchoolDTO } from '../../models/school/simpleSchoolDTO';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { SelectValue } from 'antd/lib/select';

export interface AdminLicenseCourseFormDataObject {
    id?: string;
    school: KeyLabel;
    course: KeyLabel;
    startDate: Moment;
    endDate: Moment;
    licenseCount: number;
}

interface AdminLicenseCourseFormProps extends FormComponentProps {
    onSubmit: (data: AdminLicenseCourseFormDataObject) => void;
    onSchoolSearch: (name: string) => PromiseLike<SimpleSchoolDTO[]>;
    initialValues?: Partial<AdminLicenseCourseFormDataObject>;

    isLoadingSchools: boolean;

    // schools: SimpleSchoolDTO[];
    courses: SimpleCourseDTO[];
}

interface AdminLicenseCourseFormState {
    isEdit: boolean;

    // School Filtering
    filteredSchools: SimpleSchoolDTO[];

    // Course Filtering
    filteredCourses: SimpleCourseDTO[];

    selectedSchool: KeyLabel;
    selectedCourse: KeyLabel;
}

class AdminLicenseCourseForm extends React.Component<AdminLicenseCourseFormProps, AdminLicenseCourseFormState> {
    // Just need to initialize sub objects
    constructor(props: AdminLicenseCourseFormProps) {
        super(props);
        bindAllOfThis(this, AdminLicenseCourseForm.prototype);

        let isEdit = props.initialValues != null && props.initialValues.id != null;
        let selectedSchool = props.initialValues != null
            ? props.initialValues.school
            : null;
        let selectedCourse = props.initialValues != null
            ? props.initialValues.course
            : null;

        this.state = {
            isEdit: isEdit,
            filteredSchools: [],
            filteredCourses: props.courses,
            selectedSchool: selectedSchool,
            selectedCourse: selectedCourse,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminLicenseCourseFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;

            // Entering the 'SO HACKY' area
            values.course = this.state.selectedCourse;
            values.school = this.state.selectedSchool;

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    handleFilterSchools(value: SelectValue) {
        if (this.props.isLoadingSchools) {
            return;
        }
        let filter = (value || "").toString().toLowerCase();

        // Filter the schools if input is 3+ chars OR if the number of schools is small, like 50 or so
        if (filter.length > 3) {
            this.props.onSchoolSearch(filter).then(x => {
                this.setState({
                    filteredSchools: x,
                });
            });
        }
    }

    handleFilterCourses(value?: SelectValue) {
        let filter = (value || "").toString().toLowerCase();

        // Filter the clients if input is 3+ chars OR if the number of schools is small, like 50 or so
        if (filter.length > 2 || this.props.courses.length < 50) {
            let filteredCourses = this.props.courses
                .filter(x => x.name.toLowerCase().indexOf(filter) >= 0);
            this.setState({ filteredCourses: filteredCourses });
            return;
        }
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || { school: { label: "" }, course: { label: "" } } as Partial<AdminLicenseCourseFormDataObject>;

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark>
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="School"
                name={NameOf<AdminLicenseCourseFormDataObject>("school")}
                initialValue={initialValues.school.label}
                rules={[{ required: true, message: "School is required." }]}
            >
                <AutoComplete
                    allowClear
                    onSearch={this.handleFilterSchools}
                    onSelect={(e: SelectValue) => {
                        let school = this.state.filteredSchools.find(x => x.id === e.toString());
                        this.setState({ selectedSchool: { key: school.id, label: school.name } });
                    }}
                    placeholder="Type 4 or more letters to search Schools"
                    notFoundContent={this.props.isLoadingSchools ? "Loading Results..." : "No results"}
                    >
                        {this.state.filteredSchools.map(school => <AutoComplete.Option key={`school${school.id}`} value={`${school.id}`}>{school.name}</AutoComplete.Option>)}
                </AutoComplete>
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Course"
                name={NameOf<AdminLicenseCourseFormDataObject>("course")}
                initialValue={initialValues.course.label}
                rules={[{ required: true, message: "Course is required." }]}
            >
                <AutoComplete
                    allowClear
                    onSearch={this.handleFilterCourses}
                    onFocus={() => this.handleFilterCourses}
                    onSelect={(e: SelectValue) => {
                        let course = this.props.courses.find(x => x.id === e.toString());
                        this.setState({ selectedCourse: { key: course.id, label: course.name } });
                    }}
                >
                    {this.state.filteredCourses.map(course => <AutoComplete.Option key={`course${course.id}`} value={`${course.id}`}>{course.name}</AutoComplete.Option>)}
                </AutoComplete>
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Start Date"
                name={NameOf<AdminLicenseCourseFormDataObject>("startDate")}
                initialValue={initialValues.startDate}
                rules={[{ required: true, message: "Start Date is required." }]}
            >
                <DatePicker size="large" placeholder="Start Date" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="End Date"
                name={NameOf<AdminLicenseCourseFormDataObject>("endDate")}
                initialValue={initialValues.endDate}
                rules={[{ required: true, message: "End Date is required." }]}
            >
                <DatePicker size="large" placeholder="End Date" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="License Count"
                name={NameOf<AdminLicenseCourseFormDataObject>("licenseCount")}
                initialValue={initialValues.licenseCount}
                rules={[{ required: true, message: "License Count is required." }]}
            >
                <Input size="large" placeholder="License Count" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                    {this.state.isEdit ? "Save License" : "Add License"}
                </Button>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminLicenseCourseFormProps>()(AdminLicenseCourseForm);
