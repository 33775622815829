import * as React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tooltip, Button, notification, Modal } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { getColumnSearchProps, getColumnSearchPropsHard } from '../../components/tableHelpers/getColumnSearchProps';
import { LessonContentType } from '../../constants/LessonContentTypes';
import { LessonContentController } from '../../api/LessonContentController';
import ErrorDTO from '../../models/common/ErrorDTO';
import AdminLessonContentTableModal, { AdminLessonContentTableModalDataObject } from './AdminLessonContentTableModal';

export interface AdminLessonContentTableDataObject {
    id: string;
    fileName: string; // This is the file name, WHY?!
    url: string;

    // Generated
    lessonContentType: LessonContentType;
    isMissing: boolean;
}

interface AdminLessonContentTableProps {
    tableData: AdminLessonContentTableDataObject[];
    isLoading?: boolean;
    courseId: string;
    lessonId: string;
    requestReload: () => void;
}

interface AdminLessonContentTableState {
    isModalVisible: boolean;
    modalData: AdminLessonContentTableModalDataObject;
}

class AdminLessonContentTable extends React.Component<AdminLessonContentTableProps, AdminLessonContentTableState> {
    private notificationKey = "AdminLessonContentTable";
    private tableColumns: ColumnProps<AdminLessonContentTableDataObject>[];
    constructor(props: AdminLessonContentTableProps) {
        super(props);
        bindAllOfThis(this, AdminLessonContentTable.prototype);

        this.tableColumns = [{
            title: 'Content Type',
            dataIndex: 'lessonContentType.displayName',
            key: 'name',
            sorter: (a, b) => a.lessonContentType.displayName.localeCompare(b.lessonContentType.displayName),
            ...getColumnSearchPropsHard("lessonContentType.displayName", "Name"),
            render: (text, record) => {
                return record.lessonContentType.displayName;
            }
        }, {
            title: 'File Name',
            dataIndex: 'fileName',
            key: 'fileName',
            sorter: (a, b) => a.fileName.localeCompare(b.fileName),
            ...getColumnSearchProps<AdminLessonContentTableDataObject>("fileName", "File Name"),
            render: (text, record) => {
                if (record.isMissing) {
                    return <Button type="link" onClick={() => this.handleEditModal(record)}>Add Media</Button>;
                }
                return record.fileName;
            }
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                if (record.isMissing) {
                    return null;
                }
                return (
                    <Tooltip title="Edit Content" placement="top">
                        <Button shape="circle" icon={<EditOutlined />} onClick={() => this.handleEditModal(record)} />
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                if (record.isMissing) {
                    return null;
                }
                return (
                    <Tooltip title="Delete Content" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleOnRemove(record)} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isModalVisible: false,
            modalData: null
        };
    }

    handleModalComplete() {
        this.setState({
            isModalVisible: false,
            modalData: null
        });
        this.props.requestReload();
    }

    handleModalClose() {
        this.setState({
            isModalVisible: false,
            modalData: null
        });
    }

    handleEditModal(data: AdminLessonContentTableDataObject) {
        this.setState({
            isModalVisible: true,
            modalData: {
                id: data.isMissing ? null : data.id,
                lessonContentType: data.lessonContentType,
                lessonId: this.props.lessonId,
                url: data.url
            }
        });
    }

    handleOnRemove(record: AdminLessonContentTableDataObject) {
        // Ask if they are sure
        Modal.confirm({
            title: "Are you sure?",
            content: `Are you sure you want to delete this lesson content? "${record.fileName}"`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                LessonContentController.DeleteLessonContent(record.id).then(result => {
                    notification.success({
                        key: this.notificationKey,
                        message: "Lesson Content Removal",
                        description: `The Lesson Content "${record.lessonContentType.displayName}" has been removed`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                    this.props.requestReload();
                }).catch(error => {
                    let messages = error != null && error.response != null && error.response.data.messages != null
                        ? (error.response.data as ErrorDTO).messages
                        : ["Critical Error"];
                    notification.error({
                        key: this.notificationKey,
                        message: "Lesson Content Removal",
                        description: ["There was an issue removing the Lesson Content.", ...messages].map(x => <div>{x}</div>),
                        duration: 10
                    });
                    this.props.requestReload();
                });
            }
        });
    }

    render() {
        // TODO: JB - Consider moving the modal up a level to avoid weird reload all's. Also, constancy
        return <div>
            <AdminLessonContentTableModal
                isVisible={this.state.isModalVisible}
                onComplete={this.handleModalComplete}
                onClose={this.handleModalClose}
                formValues={this.state.modalData} />
            <Table
                loading={this.props.isLoading}
                rowKey={record => record.id}
                size="small"
                columns={this.tableColumns}
                dataSource={this.props.tableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default AdminLessonContentTable;
