import * as React from 'react';
import { CreditCardOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Spin, Tabs, Modal, Button, notification, Select, Popconfirm, Radio, Rate } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import { PageProps } from '../../models/common/ComponentProps';
import { Link } from 'react-router-dom';
import Routes from '../../core/Routes';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';

import TeacherMyClassroomOverview from './TeacherMyClassroomOverview';
import TeacherMyClassroomLessons from './TeacherMyClassroomLessons';
import TeacherMyClassroomStudents from './TeacherMyClassroomStudents';
import { LessonController } from '../../api/LessonController';
import { ClassroomController } from '../../api/ClassroomController';
import { SchoolController } from '../../api/SchoolController';
import { StudentController } from '../../api/StudentController';
import { ReportController } from '../../api/ReportController';
import { ClassroomDTO } from '../../models/classroom/classroomDTO';
import { StudentDTO } from '../../models/student/studentDTO';
import { LicenseDTO } from '../../models/license/licenseDTO';
import { SimpleLessonDTO } from '../../models/lesson/simpleLessonDTO';
import { AssignStudentsToClassroomDTO } from '../../models/classroom/assignStudentsToClassroomDTO';
import { RemoveStudentsFromClassroomDTO } from '../../models/classroom/removeStudentsFromClassroomDTO';
import ErrorDTO from '../../models/common/ErrorDTO';
import { ClassroomOverviewDTO } from '../../models/report/classroomOverviewDTO';
import moment from 'moment';
import { RadioChangeEvent } from 'antd/lib/radio';
import { RateLessonClassroomDTO } from '../../models/lessonClassroom/rateLessonClassroomDTO';
import './TeacherMyClassroom.scss';

interface TeacherMyClassroomProps extends PageProps<{ classroomId?: string }> {
}

interface TeacherMyClassroomState {
    idFromUrl?: string;

    isLoading: boolean;
    isLoadingOverview: boolean;
    isLoadingLessons: boolean;
    isLoadingStudents: boolean;
    isLoadingCourses: boolean;
    isSendingStudentLogins: boolean;
    isSendingEmail: boolean;
    isDownloadingGuardianSurveyResponses: boolean;
    isDownloadingDiscussionQuestionResponses: boolean;
    isDownloadingStudentAssessmentResults: boolean;
    isRatingLessonClassroom: boolean;

    classroom: ClassroomDTO;

    // License Dropdown
    classroomLicenses: LicenseDTO[];
    schoolLicenses: LicenseDTO[];
    selectedLicense: LicenseDTO;
    isAddingCourseToClassroom: boolean;

    // Students Tab
    licenseStudents: StudentDTO[];
    selectedLicenseStudentRowKeys: React.ReactText[];
    allSchoolStudents: StudentDTO[];
    filteredSchoolStudents: StudentDTO[];
    selectedSchoolStudentRowKeys: React.ReactText[];

    // Lessons Tab
    lessons: SimpleLessonDTO[];
    ratedLessonId: string;
    knowledgeUnderstandingRating: string;
    learningRating: number;

    // Overview Tab
    classroomOverview: ClassroomOverviewDTO;
    selectedLesson: SimpleLessonDTO;

    // Modals
    addStudentsModalVisible: boolean;
    rateLessonClassroomModalVisible: boolean;

    removalErrors: string[];
}

class TeacherMyClassroom extends React.Component<TeacherMyClassroomProps, TeacherMyClassroomState> {
    private notificationKey = "TeacherMyClassroom";

    constructor(props: TeacherMyClassroomProps) {
        super(props);
        bindAllOfThis(this, TeacherMyClassroom.prototype);

        this.state = {
            idFromUrl: props.match.params.classroomId,
            isLoading: true,
            isLoadingOverview: false,
            isLoadingLessons: false,
            isLoadingStudents: false,
            isLoadingCourses: false,
            isSendingStudentLogins: false,
            isSendingEmail: false,
            isDownloadingGuardianSurveyResponses: false,
            isDownloadingDiscussionQuestionResponses: false,
            isDownloadingStudentAssessmentResults: false,
            isRatingLessonClassroom: false,
            isAddingCourseToClassroom: false,

            classroom: null,
            classroomLicenses: [],
            schoolLicenses: [],
            licenseStudents: [],
            allSchoolStudents: [],
            filteredSchoolStudents: [],
            lessons: [],
            ratedLessonId: null,
            knowledgeUnderstandingRating: null,
            learningRating: null,
            selectedLesson: null,
            classroomOverview: null,
            selectedLicense: null,
            selectedLicenseStudentRowKeys: [],
            selectedSchoolStudentRowKeys: [],
            addStudentsModalVisible: false,
            rateLessonClassroomModalVisible: false,
            removalErrors: []
        };
    }

    async componentDidMount() {
        this.loadAll();
    }

    async loadAll() {
        let classroom = (await ClassroomController.GetGetClassroom(this.state.idFromUrl)).data;

        let schoolStudents = (await SchoolController.GetStudentsBySchool(classroom.schoolId)).data;
        let classroomLicenses = (await ClassroomController.GetLicensesByClassroom(this.state.idFromUrl)).data;
        let schoolLicenses = (await SchoolController.GetLicensesBySchool(classroom.schoolId)).data;

        this.setState({
            classroom: classroom,
            classroomLicenses: classroomLicenses,
            schoolLicenses: this.filterSchoolLicenses(schoolLicenses, classroomLicenses, classroom),
            allSchoolStudents: schoolStudents.filter(student => student.schoolYearId === classroom.schoolYearId),
            isLoading: false,
            isAddingCourseToClassroom: classroomLicenses.length === 0
        }, () => {
            if (classroomLicenses.length === 1) {
                let selectedLicense = this.state.classroomLicenses[0];
                this.setState({
                    selectedLicense: selectedLicense,
                    selectedLesson: null,
                    isAddingCourseToClassroom: false
                }, () => {
                    this.loadLessonData();
                    this.loadLicenseStudentData();
                });
            }
        });
    }

    filterSchoolLicenses(schoolLicenses: LicenseDTO[], classroomLicenses: LicenseDTO[], classroom: ClassroomDTO) {
        return schoolLicenses.filter(license => license.documentaryId == null && !classroomLicenses.map(l => l.id).includes(license.id) &&  // Filter out licenses that classroom is already part of
            // We could do some complicated checks to make sure the license is valid. However, it just needs to be valid for today, so we can cheat a bit
            moment().isBetween(moment(license.startDate), moment(license.endDate))
        );
    }

    // Lessons tab button change
    handleToggleChange(lessonId: string) {
        notification.info({
            key: this.notificationKey,
            message: "Changing Lesson Availability",
            description: "Please wait while we change the lesson availability...",
            duration: 0
        });

        LessonController.PutChangeAvailability(lessonId, this.state.idFromUrl, this.state.selectedLicense.id).then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Changed Lesson Availability",
                description: "Successfully changed lesson availability!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.loadLessonData();
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Change Lesson Availability",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.loadLessonData();
        });
    }

    async handleSendSchoolHomePartnershipEmail(lessonId: string) {
        this.setState({ isSendingEmail: true });

        try {
            await LessonController.GetSendStudentHomePartnershipEmail(lessonId, this.state.idFromUrl, this.state.selectedLicense.id);
            notification.success({
                key: this.notificationKey,
                message: "School Home Partnership Email",
                description: "Successfully sent emails!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isSendingEmail: false });
        } catch (error) {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to send School Home Partnership Email",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSendingEmail: false });
        }
    }

    // Students tab selection change
    handleLicenseStudentSelectChange(selectedRowKeys: React.ReactText[], selectedRows: StudentDTO[]) {
        this.setState({ selectedLicenseStudentRowKeys: selectedRowKeys });
    }

    // Students modal selection change
    handleSchoolStudentSelectChange(selectedRowKeys: React.ReactText[], selectedRows: StudentDTO[]) {
        this.setState({ selectedSchoolStudentRowKeys: selectedRowKeys });
    }

    handleRemoveSelectedStudents() {
        notification.info({
            key: this.notificationKey,
            message: "Removing Students",
            description: "Please wait while we remove the students from the classroom...",
            duration: 0
        });

        this.setState({ isLoadingStudents: true, isLoadingCourses: true }, async () => {
            let removeStudentsFromClassroom: RemoveStudentsFromClassroomDTO = {
                classroomId: this.state.idFromUrl,
                licenseId: this.state.selectedLicense.id,
                studentIds: this.state.selectedLicenseStudentRowKeys as string[]
            };

            try {
                let result = await ClassroomController.PostRemoveStudents(removeStudentsFromClassroom);
                let unableToRemove = result.data.length > 0;

                if (unableToRemove) {
                    notification.error({
                        key: this.notificationKey,
                        message: "Unable to Remove Specific Students",
                        duration: 10
                    });
                } else {
                    notification.success({
                        key: this.notificationKey,
                        message: "Removed Students",
                        description: "Successfully removed the students from the classroom!",
                        duration: 5,
                        onClick: () => notification.close(this.notificationKey)
                    });
                }

                this.loadCourseData();
                this.loadLicenseStudentData(result.data);
                this.loadOverviewData();
            } catch (error) {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Remove Students",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.loadCourseData();
                this.loadLicenseStudentData();
                this.loadOverviewData();
            }
        });
    }

    handleRateLessonClassroomModalOk(e: any) {
        notification.info({
            key: this.notificationKey,
            message: "Submitting Lesson Rating",
            description: "Please wait while we submit the lesson rating...",
            duration: 0
        });

        this.setState({ isRatingLessonClassroom: true }, async () => {
            let rateLessonClassroomDTO: RateLessonClassroomDTO = {
                lessonId: this.state.ratedLessonId,
                classroomId: this.state.idFromUrl,
                knowledgeUnderstandingRating: this.state.knowledgeUnderstandingRating,
                learningRating: this.state.learningRating.toString()
            };

            try {
                await LessonController.PutUpdateLessonClassroomRating(rateLessonClassroomDTO);
                notification.success({
                    key: this.notificationKey,
                    message: "Submitted Lesson Rating",
                    description: "Successfully submitted lesson rating!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.loadLessonData();
                this.setState({ rateLessonClassroomModalVisible: false, isRatingLessonClassroom: false });
            } catch (error) {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Submit Lesson Rating",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.loadLessonData();
                this.setState({ rateLessonClassroomModalVisible: false, isRatingLessonClassroom: false });
            }
        });
    }

    handleAddStudentsModalOk(e: any) {
        notification.info({
            key: this.notificationKey,
            message: "Assigning Students",
            description: "Please wait while we assign the students to the classroom...",
            duration: 0
        });

        this.setState({ isLoadingStudents: true, isLoadingCourses: true }, async () => {
            // This is really assigning students to a classroom and license
            let assignStudentsToClassroom: AssignStudentsToClassroomDTO = {
                classroomId: this.state.idFromUrl,
                licenseId: this.state.selectedLicense.id,
                studentIds: this.state.selectedSchoolStudentRowKeys as string[]
            };

            try {
                await ClassroomController.PostAssignStudents(assignStudentsToClassroom);
                notification.success({
                    key: this.notificationKey,
                    message: "Assigned Students",
                    description: "Successfully assigned the students to the classroom!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.loadCourseData();
                this.loadLicenseStudentData();
                this.loadOverviewData();
                this.setState({ addStudentsModalVisible: false });
            } catch (error) {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Assign Students",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.loadCourseData();
                this.loadLicenseStudentData();
                this.loadOverviewData();
                this.setState({ addStudentsModalVisible: false });
            }
        });
    }

    async handleLicenseChange(value: any) {
        if (value == null) {
            this.setState({ selectedLicense: null });
        } else {
            let selectedLicense = this.state.classroomLicenses.concat(this.state.schoolLicenses).find(x => x.id === value.key);
            this.setState({ selectedLicense: selectedLicense, selectedLesson: null }, () => {
                this.loadLessonData();
                this.loadLicenseStudentData();
            });
        }
    }

    async handleLessonChange(value: any) { //Need to check what is actually being passed back from Select
        if (value == null) {
            this.setState({ selectedLesson: null });
        } else {
            let selectedLesson = this.state.lessons.find(x => x.id === value.key);
            this.setState({ selectedLesson: selectedLesson, isLoadingOverview: true }, () => {
                this.loadOverviewData();
            });
        }
    }

    handleChangePasscode(studentId: string) {
        notification.info({
            key: this.notificationKey,
            message: "Changing Passcode",
            description: "Please wait while we change the passcode...",
            duration: 0
        });

        this.setState({ isLoadingStudents: true }, async () => {
            try {
                let result = await StudentController.PutChangePasscode(studentId);

                notification.success({
                    key: this.notificationKey,
                    message: `Passcode Changed to: ${result.data.passcode}`,
                    description: "Successfully changed the passcode!",
                    duration: 5,
                });

                this.loadLicenseStudentData();
            } catch (error) {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Change Passcode",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.loadLicenseStudentData();
            }
        });
    }

    handleEmailStudentLoginsByClassroom() {
        notification.info({
            key: this.notificationKey,
            message: "Emailing Student Logins...",
            description: "Please wait while we email the student logins...",
            duration: 0
        });

        this.setState({ isSendingStudentLogins: true }, async () => {
            try {
                await ClassroomController.GetEmailStudentLoginsByClassroomAndLicense(this.state.idFromUrl, this.state.selectedLicense.id);
                notification.success({
                    key: this.notificationKey,
                    message: "Student Logins Emailed",
                    description: "Successfully emailed the student logins!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isSendingStudentLogins: false });
            } catch (error) {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Email Student Logins",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isSendingStudentLogins: false });
            }
        });
    }

    handleDownloadGuardianSurveyResponses() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the guardian survey responses ...",
            duration: 0
        });

        this.setState({isDownloadingGuardianSurveyResponses: true}, async () => {
            ReportController.DownloadGuardianSurveyResponses(this.state.idFromUrl, this.state.selectedLicense.id, this.state.selectedLesson.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'GuardianSurveyResponses.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded guardian survey responses!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({isDownloadingGuardianSurveyResponses: false});
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download guardian Survey Responses.",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({isDownloadingGuardianSurveyResponses: false});
            });
        });
    }

    handleDownloadDiscussionQuestionResponses() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the discussion question responses ...",
            duration: 0
        });

        this.setState({ isDownloadingDiscussionQuestionResponses: true }, async () => {
            ReportController.DownloadDiscussionQuestionResponses(this.state.idFromUrl, this.state.selectedLicense.id, this.state.selectedLesson.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'DiscussionQuestionResponses.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded discussion question responses!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingDiscussionQuestionResponses: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download discussion question Responses.",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingDiscussionQuestionResponses: false });
            });
        });
    }

    handleDownloadStudentAssessmentResults() {
        notification.info({
            key: this.notificationKey,
            message: "Downloading",
            description: "Please wait while we download the student assessment results ...",
            duration: 0
        });

        this.setState({ isDownloadingStudentAssessmentResults: true }, async () => {
            //todo add new route/report
            ReportController.DownloadStudentAssessmentResults(this.state.idFromUrl, this.state.selectedLicense.id, this.state.selectedLesson.id).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'StudentAssessmentResults.xlsx');
                document.body.appendChild(link);
                link.click();

                notification.success({
                    key: this.notificationKey,
                    message: "Downloaded",
                    description: "Successfully downloaded student assessment results!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isDownloadingStudentAssessmentResults: false });
            }).catch((error) => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Download student assessment results.",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isDownloadingStudentAssessmentResults: false });
            });
        });
    }

    async loadCourseData(){
        this.setState({isLoadingCourses: true}, async () => {
            // Get data for course select again
            let classroomLicenses = (await ClassroomController.GetLicensesByClassroom(this.state.idFromUrl)).data;
            let schoolLicenses = (await SchoolController.GetLicensesBySchool(this.state.classroom.schoolId)).data;

            // Update the selected license (mainly to update the license counts on the object)
            let selectedLicense = classroomLicenses.concat(schoolLicenses).find(x => x.id === this.state.selectedLicense.id);

            this.setState({
                selectedLicense: selectedLicense,
                classroomLicenses: classroomLicenses,
                schoolLicenses: this.filterSchoolLicenses(schoolLicenses, classroomLicenses, this.state.classroom),
                isLoadingCourses: false
            });
        });
    }

    async loadOverviewData() {
        this.setState({ isLoadingOverview: true }, async () => {
            let classroomOverview = this.state.selectedLesson === null ? null :
                (await ReportController.GetClassroomOverview(this.state.idFromUrl, this.state.selectedLicense.id, this.state.selectedLesson.id)).data;

            this.setState({
                classroomOverview: classroomOverview,
                isLoadingOverview: false
            });
        });
    }

    async loadLessonData() {
        this.setState({ lessons: [], isLoadingLessons: true }, async () => {
            let lessonsPromise = ClassroomController.GetLessonsByClassroomAndCourse(this.state.idFromUrl, this.state.selectedLicense.courseId);

            this.setState({
                lessons: (await lessonsPromise).data,
                isLoadingLessons: false
            });
        });
    }

    async loadLicenseStudentData(removalErrors: string[] = []) {
        this.setState({ licenseStudents: [], isLoadingStudents: true, selectedLicenseStudentRowKeys: [], selectedSchoolStudentRowKeys: [] }, async () => {
            let licenseStudents = (await ClassroomController.GetStudentsByClassroomAndLicense(this.state.idFromUrl, this.state.selectedLicense.id)).data;

            this.setState({
                // Filter out license students (students in the class on this license) from our list of all students
                filteredSchoolStudents: this.state.allSchoolStudents.filter(schoolStudent => !licenseStudents.map(l => l.id).includes(schoolStudent.id)),
                licenseStudents: licenseStudents,
                isLoadingStudents: false,
                removalErrors: removalErrors
            });
        });
    }

    async addCourseToClassroom() {
        await (ClassroomController.AddCourseToClassroomByLicense(this.state.idFromUrl, this.state.selectedLicense.id));
        this.setState({
            isAddingCourseToClassroom: false
        }, () => this.loadCourseData());
    }

    setSelectedLicense(value: any) {
        if (value == null) {
            this.setState({ selectedLicense: null });
        } else {
            let selectedLicense = this.state.classroomLicenses.concat(this.state.schoolLicenses).find(x => x.id === value.key);
            this.setState({ selectedLicense: selectedLicense});
        }
    }

    showSchoolCoursesForAddingToClassroom(){
        this.setState({
            isAddingCourseToClassroom : this.state.isAddingCourseToClassroom ? false : true
        });
        this.renderCourseSelection();
    }

    renderRemovalErrors() {
        return this.state.removalErrors.map((error, index) => <div key={index} style={{ color: "#FF0000" }}>{error}</div>);
    }

    renderStudentsTab() {
        const classroomStudentsSelected = this.state.selectedLicenseStudentRowKeys.length > 0;

        return <Tabs.TabPane tab="Students" key="3">
            {this.state.isLoadingStudents ? <Spin className="spinner-centered" size="large" /> :
                this.state.selectedLicense === null ? <h3>Please Select a Course</h3> :
                    <div>
                        <Button danger onClick={this.handleRemoveSelectedStudents} disabled={!classroomStudentsSelected}>Remove Selected Students</Button>
                        <Button type="primary" onClick={() => this.setState({ addStudentsModalVisible: true })}>Add Students</Button>
                        <br />
                        <br />
                        {this.renderRemovalErrors()}
                        <br />
                        <TeacherMyClassroomStudents
                            tableData={this.state.licenseStudents}
                            size="middle"
                            showPasscode={true}
                            selectedRowKeys={this.state.selectedLicenseStudentRowKeys}
                            handleSelectionChange={this.handleLicenseStudentSelectChange}
                            handleChangePasscode={this.handleChangePasscode}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                hideOnSinglePage: true,
                                showTotal: (total: any, range: any) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                            }}
                        />
                    </div>
            }
        </Tabs.TabPane>;
    }

    renderLessonsTab(notTiedToClassroom: boolean) {
        return <Tabs.TabPane tab="Lessons" key="2">
            {this.state.isLoadingLessons ? <Spin className="spinner-centered" size="large" /> :
                this.state.selectedLicense === null ? <h3>Please Select a Course</h3> :
                    notTiedToClassroom ? <h3>Please add students</h3> :
                        <TeacherMyClassroomLessons
                            tableData={this.state.lessons}
                            isSendingEmail={this.state.isSendingEmail}
                            handleToggleChange={this.handleToggleChange}
                            handleRateModalOpen={(lessonId: string, knowledgeUnderstandingRating: string, learningRating: string) => this.setState({
                                rateLessonClassroomModalVisible: true,
                                ratedLessonId: lessonId,
                                learningRating: learningRating == null ? 5 : +learningRating,
                                knowledgeUnderstandingRating: knowledgeUnderstandingRating == null ? "Strongly Agree" : knowledgeUnderstandingRating
                            })}
                            handleSendEmail={this.handleSendSchoolHomePartnershipEmail}
                        />
            }
        </Tabs.TabPane>;

    }

    renderOverviewTab(notTiedToClassroom: boolean) {
        return (
            <Tabs.TabPane tab="Student Evaluation Data" key="1">
                {this.state.isLoadingLessons ? <Spin className="spinner-centered" size="large" /> :
                    this.state.selectedLicense === null ? <h3>Please Select a Course</h3> :
                        notTiedToClassroom ? <h3>Please add students</h3> :
                            <div>
                                <br />
                                <Select
                                    value={{ value: (this.state.selectedLesson || { id: "" }).id, label: "Please select a lesson" } as any}
                                    loading={this.state.isLoadingLessons}
                                    style={{ width: 200 }}
                                    filterOption={false}
                                    notFoundContent={null}
                                    labelInValue
                                    allowClear
                                    showSearch
                                    onChange={this.handleLessonChange}>
                                    {this.state.lessons
                                        .sort((first, second) => first.ordering - second.ordering)
                                        .map(lesson => <Select.Option value={lesson.id} key={lesson.id}>{lesson.name}</Select.Option>)}
                                </Select>

                                {this.state.isLoadingOverview ? <Spin className="spinner-centered" size="large" /> :
                                    this.state.selectedLesson === null ? null :
                                        <div>
                                            <br/>
                                            <br/>
                                            <h4>Pre-Test Average: {this.state.classroomOverview.preTestAverageScore == null ?
                                                "N/A" : this.state.classroomOverview.preTestAverageScore.toFixed(0) + "%"}</h4>

                                            <h4>Post-Test Average: {this.state.classroomOverview.postTestAverageScore == null ?
                                                "N/A" : this.state.classroomOverview.postTestAverageScore.toFixed(0) + "%"}</h4>

                                            <Button style={{ marginRight: "6px" }} icon={<LegacyIcon
                                                type={this.state.isDownloadingGuardianSurveyResponses ? "loading" : "download"} />} disabled={this.state.isDownloadingGuardianSurveyResponses}  onClick={() => this.handleDownloadGuardianSurveyResponses()}>
                                                Download Guardian Surveys
                                            </Button>

                                            <Button style={{ marginRight: "6px" }} icon={<LegacyIcon
                                                type={this.state.isDownloadingDiscussionQuestionResponses ? "loading" : "download"} />} disabled={this.state.isDownloadingDiscussionQuestionResponses} onClick={() => this.handleDownloadDiscussionQuestionResponses()}>
                                                Download Discussion Question Responses
                                            </Button>

                                            <Button icon={<LegacyIcon
                                                type={this.state.isDownloadingStudentAssessmentResults ? "loading" : "download"} />} disabled={this.state.isDownloadingStudentAssessmentResults} onClick={() => this.handleDownloadStudentAssessmentResults()}>
                                                Download Student Assessment Results
                                            </Button>

                                            <TeacherMyClassroomOverview
                                                tableData={this.state.classroomOverview}
                                                pagination={{
                                                    showSizeChanger: true,
                                                    showQuickJumper: true,
                                                    hideOnSinglePage: true,
                                                    showTotal: (total: any, range: any) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                                                }}
                                            />
                                        </div>
                                }
                            </div>
                }
            </Tabs.TabPane>
        );
    }

    renderCourseSelection() {
        var defaultSelect;
        if (this.state.classroomLicenses.length === 1) {
            defaultSelect = { value: this.state.classroomLicenses[0].id, key: this.state.classroomLicenses[0].id, label: this.state.classroomLicenses[0].courseDocumentaryName };
        }
        return <div>
            <h1>Courses</h1>
            {this.state.isAddingCourseToClassroom &&
                <span>
                    <p>If you would like to add a course, use this first dropdown menu to choose which of your school's courses you would like to add this class to.</p>
                    <div>
                        <h4>Don't see a course?</h4>
                        <Link to={Routes.TEACHER_ADD_EDIT_MY_SCHOOL("").toRoute}>
                            <Button icon={<CreditCardOutlined />}>Purchase License</Button>
                        </Link>
                    </div>
                    <br />
                    <div className={"input-group"}>
                        <Select
                            loading={this.state.isLoadingCourses}
                            style={{ width: 550 }}
                            placeholder={"Please select a course"}
                            filterOption={false}
                            notFoundContent={null}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.setSelectedLicense}
                        >
                            <Select.OptGroup label="School Courses">
                                {this.state.schoolLicenses
                                    .sort((first, second) => first.courseDocumentaryName.localeCompare(second.courseDocumentaryName))
                                    .map(license => <Select.Option value={license.id} key={license.id}>{license.courseDocumentaryName}</Select.Option>)}
                            </Select.OptGroup>
                        </Select>
                        <Button
                            type='primary'
                            onClick={this.addCourseToClassroom}
                            style={{ marginLeft: 12 }}
                        >
                            Add Selected Course to Classroom
                        </Button>
                    </div>
                </span>}
            {this.state.classroomLicenses.length > 0 &&
                <span>
                    <p> Use this dropdown to select which of your classes you would like to manage. You are able to add students to courses, manage responses to questions by the students and the parents/guardians, and open and close student access to lessons.</p>
                    <div className={"input-group"}>
                        <Select
                            loading={this.state.isLoadingCourses}
                            style={{ width: 550 }}
                            placeholder={"Please select a course"}
                            filterOption={false}
                            notFoundContent={null}
                            defaultValue={defaultSelect}
                            labelInValue
                            allowClear
                            showSearch
                            onChange={this.handleLicenseChange}
                        >
                            <Select.OptGroup label="Classroom Courses">
                                {this.state.classroomLicenses
                                    .sort((first, second) => first.courseDocumentaryName.localeCompare(second.courseDocumentaryName))
                                    .map(license => <Select.Option default value={license.id} key={license.id}>{license.courseDocumentaryName}</Select.Option>)}
                            </Select.OptGroup>
                        </Select>
                        <Button
                            type='primary'
                            onClick={this.showSchoolCoursesForAddingToClassroom}
                            style={{ marginLeft: 12 }}
                        >
                            Add an Additional Course
                        </Button>
                    </div>
                </span>
            }
        </div>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        const notTiedToClassroom = this.state.licenseStudents.length === 0;

        return (
            <div className='teacher-my-classroom'>
                <h1>My Classroom - {this.state.classroom.name}  ({this.state.classroom.schoolYear.startYear} - {this.state.classroom.schoolYear.endYear})</h1>
                <br />

                {this.renderCourseSelection()}
                <br />
                {this.state.selectedLicense === null ? null :
                    <div>
                        <h4>License Start : {moment(this.state.selectedLicense.startDate).format("DD-MMM-YYYY")}</h4>
                        <h4>License End : {moment(this.state.selectedLicense.endDate).format("DD-MMM-YYYY")}</h4>
                    </div>}
                <br />
                {this.state.classroomLicenses.length > 0 &&
                    <span>
                        <p>Overview: You are able to see and download student and parent/guardian responses.</p>
                        <p>Lessons: You are able to open and close lessons for students to access. Students will receive an email immediately that will contain a link that will take them to the lesson.</p>
                        <p>Students: You are able to choose from the students to this class that you added through the “Students” tab on the blue toolbar on the left portion of your screen.</p>
                        <br />
                        <div>
                            <Popconfirm
                                title="Are you sure you want to send an email to all classroom students?"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={this.handleEmailStudentLoginsByClassroom}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button icon={<LegacyIcon type={this.state.isSendingStudentLogins ? "loading" : "mail"} />} disabled={this.state.isSendingStudentLogins || this.state.selectedLicense == null}>
                                    Email Student Logins
                                </Button>
                            </Popconfirm>
                        </div>
                        <br />

                        <Tabs className='classroom-tabs' type="card">
                            {this.renderOverviewTab(notTiedToClassroom)}
                            {this.renderLessonsTab(notTiedToClassroom)}
                            {this.renderStudentsTab()}
                        </Tabs>

                        {/* Add Students Modal  */}
                        <Modal
                            title={`Add ${this.state.classroom.schoolYear.startYear} Students`}
                            visible={this.state.addStudentsModalVisible}
                            onOk={this.handleAddStudentsModalOk}
                            okButtonProps={{ loading: this.state.isLoadingStudents, disabled: this.state.selectedSchoolStudentRowKeys.length === 0 }}
                            okText="Add"
                            onCancel={() => this.setState({ addStudentsModalVisible: false, selectedSchoolStudentRowKeys: [] })}>

                            {this.state.selectedLicense === null ? null :
                                <h4>Remaining Seats for License: {this.state.selectedLicense.licenseCount - this.state.selectedLicense.claimedLicenseCount}</h4>}

                            <h4>Number of Students Selected: {this.state.selectedSchoolStudentRowKeys.length}</h4>

                            <TeacherMyClassroomStudents
                                isLoading={this.state.isLoading}
                                tableData={this.state.filteredSchoolStudents}
                                size="small"
                                showPasscode={false}
                                selectedRowKeys={this.state.selectedSchoolStudentRowKeys}
                                handleSelectionChange={this.handleSchoolStudentSelectChange}
                                pagination={{ hideOnSinglePage: true }}
                            />
                        </Modal>

                        {/* Rate LessonClasroom Modal*/}
                        <Modal
                            title={`Please Rate Lesson`}
                            visible={this.state.rateLessonClassroomModalVisible}
                            onOk={this.handleRateLessonClassroomModalOk}
                            okButtonProps={{ loading: this.state.isRatingLessonClassroom }}
                            okText="Submit"
                            onCancel={() => this.setState({ rateLessonClassroomModalVisible: false })}>

                            <p>Do you believe your student's knowledge and understanding of the lesson's objective increased?</p>
                            <Radio.Group buttonStyle="solid" value={this.state.knowledgeUnderstandingRating} onChange={(e: RadioChangeEvent) => this.setState({ knowledgeUnderstandingRating: e.target.value })}>
                                <Radio.Button value="Strongly Disagree">Strongly Disagree</Radio.Button>
                                <Radio.Button value="Disagree">Disagree</Radio.Button>
                                <Radio.Button value="Agree">Agree</Radio.Button>
                                <Radio.Button value="Strongly Agree">Strongly Agree</Radio.Button>
                            </Radio.Group>

                            <br /><br /><br />
                            <p>Please rate your student's learning on the lesson objective:</p>
                            <Rate value={this.state.learningRating} onChange={(value: number) => this.setState({ learningRating: value })} allowClear={false} />
                        </Modal>
                    </span>}
            </div>
        );
    }
}

export default TeacherMyClassroom;
