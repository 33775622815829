import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Layout, Menu } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import { WorkflowState } from '../../redux/WorkflowReducer';
import ReduxStoreModel from '../../redux/ReduxModel';
import WorkflowSideNav from "./DocumentarySideNav";
import WorkflowFooter from './DocumentaryFooter';
import GeneralHeader from '../GeneralHeader';
import WorkflowAction from '../../redux/WorkflowAction';
import EnvironmentStaticContent from '../../constants/EnvironmentStaticContent';
import { ParamUtil } from '../../utils/ParamUtil';
import { UserDTO } from '../../models/account/userDTO';
import UserAction from '../../redux/UserActions';
import { UserState } from '../../redux/UserReducer';
import { Roles } from '../../constants/Roles';

import './DocumentaryLayout.scss';
import ReducedHeader from '../ReducedHeader';
import { DemoController } from '../../api/DemoController';

const { Content } = Layout;

interface DocumentaryLayoutProps extends RouteComponentProps<{ classroomId?: string, courseId: string, lessonId: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    LoadCourse: (courseId: string, classroomId?: string) => void;
    SetDemoMode: (request: boolean) => void;
    UpdateUser: (request: UserDTO) => void;
}

interface DocumentaryLayoutState {
    isLoading: boolean;
}

class DocumentaryLayout extends React.Component<DocumentaryLayoutProps, DocumentaryLayoutState> {
    constructor(props: DocumentaryLayoutProps) {
        super(props);
        bindAllOfThis(this, DocumentaryLayout.prototype);

        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {
        this.updateRole(this.props);
        this.loadCourse(this.props);
    }

    updateRole(props: DocumentaryLayoutProps) {
        let params = new ParamUtil(props.location.search);

        // Check if we are a parent
        if (params.has.IsParent && props.User.role !== Roles.Parent) {
            props.UpdateUser({
                ...props.User,
                role: Roles.Parent
            });
        }
    }

    loadCourse(nextProps: DocumentaryLayoutProps){
        nextProps.LoadCourse(nextProps.match.params.courseId);
    }

    componentWillReceiveProps(nextProps: DocumentaryLayoutProps) {
        if (this.props.match.url !== nextProps.match.url) {
            let workflow = this.props.Workflow;
            //if demoId is there, then check if current course we are trying to load is demo
            //load course if its not a demo
            if (workflow.demoId != null && workflow.demoId !== this.props.match.params.courseId) {
                nextProps.LoadCourse(nextProps.match.params.courseId, nextProps.match.params.classroomId);
            }
            if (workflow.courseId !== this.props.match.params.courseId) {
                nextProps.LoadCourse(nextProps.match.params.courseId, nextProps.match.params.classroomId);
            }
        }
    }

    renderContent() {
        // If course loading is finished OR if the courseID does not exist, which it can for a few pages that do not require it**?
        if (this.props.Workflow.courseState === "finished" || this.props.match.params.courseId == null) {
            return this.props.children;
        }
        return null;
    }

    public render() {
        return <Layout className="workflow-layout">
            <ReducedHeader />
            <Layout>
                <WorkflowSideNav />
                <Layout>
                    <Content>
                        {this.renderContent()}
                    </Content>
                    <WorkflowFooter />
                </Layout>
            </Layout>
        </Layout>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        LoadCourse: (courseId: string, classroomId?: string) => WorkflowAction.LoadCourse(dispatch, courseId, classroomId),
        SetDemoMode: (request: boolean) => WorkflowAction.SetDemoMode(dispatch, request),
        UpdateUser: (request: UserDTO) => UserAction.UpdateUser(dispatch, request),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentaryLayout));
