import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Radio, Spin } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import AnswerDTO from '../../models/common/AnswerDTO';
import { QuestionDTO } from '../../models/question/questionDTO';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Roles } from '../../constants/Roles';
import { StudentResponseController } from '../../api/StudentResponseController';
import { StudentResponseDTO } from '../../models/studentResponse/studentResponseDTO';

const RadioGroup = Radio.Group;

interface WorkflowEntranceTestPageProps extends PageProps<{ classroomId?: string, courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowEntranceTestPageState {
    questions: QuestionDTO[];
    answers: AnswerDTO[];
    previousResponses: StudentResponseDTO[];
    isLoading: boolean;

    submittingQuestions: string[];
}

class WorkflowEntranceTestPage extends React.Component<WorkflowEntranceTestPageProps, WorkflowEntranceTestPageState> {
    private isPreTest: boolean = true;
    constructor(props: WorkflowEntranceTestPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowEntranceTestPage.prototype);

        this.state = {
            questions: [],
            answers: [],
            previousResponses: [],
            submittingQuestions: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.loadPreviousResponses();
    }

    loadPreviousResponses() {
        // Sometimes it makes sense to split long lines, other times it doesn't -- Some British Guy™ 2019
        let currentLesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        let questions = currentLesson.studentQuestions || [];

        // Admins and Teachers get no answers
        if ([Roles.Teacher, Roles.Admin].includes(this.props.User.role)) {
            let answers = questions.map<AnswerDTO>(question => {
                return {
                    questionId: question.id,
                    studentResponseId: null,
                    answerId: null
                };
            });
            return this.setState({
                questions: questions,
                answers: answers,
                isLoading: false,
            }, () => this.figureOutOnNext());
        }

        if (this.props.User.role === Roles.Student) {
            return StudentResponseController.GetStudentResponsesByLessonAndClassroomAndStudent(
                this.props.match.params.lessonId,
                this.props.match.params.classroomId,
                this.props.User.id).then(result => {
                    // Build the answer storage
                    // Setting answer to null means it's not been answered
                    let answers = questions.map<AnswerDTO>(question => {
                        let previousAnswer = result.data.find(x => x.preTest === this.isPreTest && x.questionId === question.id);
                        return {
                            questionId: question.id,
                            studentResponseId: previousAnswer != null ? previousAnswer.id : null,
                            answerId: previousAnswer != null ? previousAnswer.questionAnswerId : null
                        };
                    });
                    this.setState({
                        questions: questions,
                        answers: answers,
                        isLoading: false,
                        previousResponses: result.data
                    }, () => this.figureOutOnNext());
                });
        }
    }

    figureOutOnNext() {
        // Teachers and above should not be blocked
        if (this.props.User.role === Roles.Student) {
            if (this.state.answers.some(x => x.answerId == null)) {
                return this.props.setButtonState("disabled", "visible");
            }
            if (this.state.submittingQuestions.length > 0) {
                return this.props.setButtonState("loading", "visible");
            }
        }
        return this.props.setButtonState("visible", "visible");
    }

    onAnswerSelect(e: RadioChangeEvent, questionId: string) {
        // TODO: JB - Make this a bit more refined. It's doing a bit too much work
        let answers = this.state.answers;
        let answer = answers.find(x => x.questionId === questionId);

        // Set answer
        answer.answerId = e.target.value;

        // Save the answer
        if (this.props.User.role === Roles.Student) {
            // Set submission variable
            this.setState(state => ({ submittingQuestions: [...state.submittingQuestions, questionId] }));

            let request = answer.studentResponseId != null
                ? StudentResponseController.PutUpdateStudentResponse({
                    preTest: this.isPreTest,
                    studentResponseId: answer.studentResponseId,
                    questionAnswerId: answer.answerId,
                    textResponse: null,
                })
                : StudentResponseController.PostCreateStudentResponse({
                    preTest: this.isPreTest,
                    studentId: this.props.User.id,
                    questionAnswerId: answer.answerId,
                    classroomId: this.props.match.params.classroomId,
                    textResponse: null,
                });
            request.then(x => {
                // Update the answers with a new ID
                this.setState(newerState => {
                    let newerAnswers = newerState.answers;
                    let newerAnswer = newerAnswers.find(x => x.questionId === questionId);
                    newerAnswer.studentResponseId = x.data.id;

                    return {
                        answers: newerAnswers,
                        submittingQuestions: [...newerState.submittingQuestions.filter(x => x !== questionId)]
                    };
                }, () => this.figureOutOnNext());
            });
        }

        this.setState({
            answers: answers,
        }, () => this.figureOutOnNext());
    }

    renderQuestions() {
        let questions = (this.state.questions || [])
            .sort((first, second) => first.ordering - second.ordering)
            .filter(x => x.questionAnswers != null && x.questionAnswers.length > 0)
            .map(question => {
                return <div key={question.id}>
                    <h3>{question.ordering}. {question.text}</h3>
                    <RadioGroup onChange={e => this.onAnswerSelect(e, question.id)} className="vertical left-padding" value={this.state.answers.find(x => x.questionId === question.id).answerId} >
                        {question.questionAnswers
                            .sort((first, second) => first.ordering - second.ordering)
                            .map(answer => <Radio key={answer.id} value={answer.id}>{answer.text}</Radio>)}
                    </RadioGroup>
                </div>;
            });
        if (questions.length < 1) {
            return <h3>Sadly, there are no questions for this lesson. Hit next to continue!</h3>;
        }
        return questions;
    }

    renderDemoHelperText() {
        if (this.props.Workflow.isDemoMode) {
            return <p className="demo-helper-text">These questions are for the students to answer before completing any other sections of the lesson. This will track student learning when compared with their answers in the Exit Ticket.</p>;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <h1>Existing Knowledge</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderDemoHelperText()}
            <p>What do you already know? Show us what you've got!</p>
            {this.renderQuestions()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowEntranceTestPage);
