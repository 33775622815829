import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { Redirect } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import Routes from '../../../core/Routes';
import UserAction from '../../../redux/UserActions';
import ReduxStoreModel from '../../../redux/ReduxModel';
import { UserState } from '../../../redux/UserReducer';
import { PageProps } from '../../../models/common/ComponentProps';


interface ConferenceRegistrationPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    Logout: () => Promise<any>;
}

interface ConferenceRegistrationPageState {
    redirect: boolean;
    countdown: number;
}

class ConferenceRegistrationPage extends React.Component<ConferenceRegistrationPageProps, ConferenceRegistrationPageState> {
    constructor(props: ConferenceRegistrationPageProps) {
        super(props);
        bindAllOfThis(this, ConferenceRegistrationPage.prototype);

        this.state = {
            redirect: false,
            countdown: 5
        };
    }

    componentDidMount() {
        // Start the counter to redirect them out
        let q: NodeJS.Timeout = null;
        q = setInterval(() => {
            let countdown = this.state.countdown - 1;
            if (countdown < 0) {
                clearInterval(q);
                this.setState({ redirect: true });
            } else {
                this.setState({ countdown: countdown });
            }
        }, 1111);
        this.props.Logout();
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={Routes.REGISTER_CONFERENCE_EDITION().toRoute} push />;
        }

        return <div className="registration-page">
            <Row>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    md={{ span: 18, offset: 3 }}
                    lg={{ span: 16, offset: 4 }}
                    xl={{ span: 14, offset: 5 }}
                >
                    <Card style={{ backgroundColor: "#f2f2f2" }}>
                        <h1>Registration Successfully</h1>
                        <p>Thank you for registering for Rock Digi! You should receive an email from <b>admin@rockdigi.org</b> with your login information.</p>
                        <p>You will be redirected in {this.state.countdown} {this.state.countdown === 1 ? "second" : "seconds"}</p>
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        Logout: () => UserAction.Logout(dispatch),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceRegistrationPage);

