import { Reducer } from 'redux';
import { KnownActions, ButtonTriState } from './WorkflowAction';
import { LoadingStatusType } from '../models/common/LoadingStatusType';
import { FullCourseDTO } from '../models/course/fullCourseDTO';
import { FullStudentCheckpointDTO } from '../models/studentLesson/fullStudentCheckpointDTO';

export type WorkflowState = {
    nextButtonState: ButtonTriState;
    backButtonState: ButtonTriState;

    /** Called by the WorkflowFooter to indicate to the page that next has been selected. Return a true to allow the next, false to reject it */
    onNextCallback: () => Promise<string>;

    currentCourse: FullCourseDTO;
    studentCheckpoints: FullStudentCheckpointDTO[];

    studentResponseState: LoadingStatusType;
    courseState: LoadingStatusType;

    isDemoMode: boolean;
    courseId: string;
    demoId?: string;
};

let DefaultUserState: WorkflowState = {
    nextButtonState: "disabled",
    backButtonState: "disabled",
    onNextCallback: null,
    currentCourse: null,
    studentCheckpoints: [],
    studentResponseState: "none",
    courseState: "none",
    isDemoMode: false,
    courseId: null,
    demoId: null,
};

export const WorkflowReducer: Reducer<WorkflowState, KnownActions> = (state: WorkflowState = DefaultUserState, action: KnownActions) => {
    switch (action.type) {
        case "SET_BUTTON_STATE_WORKFLOW":
            return {
                ...state,
                nextButtonState: action.data.next,
                backButtonState: action.data.back,
            };
        case "SET_COURSE_STATE_WORKFLOW":
            return {
                ...state,
                courseState: action.data,
            };
        case "SET_STUDENT_RESPONSE_STATE_WORKFLOW":
            return {
                ...state,
                studentResponseState: action.data,
            };
        case "SET_COURSE_WORKFLOW":
            return {
                ...state,
                currentCourse: action.data,
            };
        case "SET_LESSON_CHECKPOINTS_WORKFLOW":
            return {
                ...state,
                studentCheckpoints: action.data,
            };
        case "SET_DEMO_MODE_WORKFLOW":
            return {
                ...state,
                isDemoMode: action.data,
            };
        case "UPDATE_LESSON_CHECKPOINT_WORKFLOW":
            let studentCheckpoints = [...state.studentCheckpoints];
            let index = studentCheckpoints.findIndex(x => x.classroomId === action.data.classroomId && x.lessonId === action.data.lessonId);
            if (index >= 0) {
                studentCheckpoints.splice(index, 1, action.data);
            } else {
                studentCheckpoints.push(action.data);
            }

            return {
                ...state,
                studentCheckpoints: studentCheckpoints,
            };
        case "SET_ON_NEXT_CALLBACK_WORKFLOW":
            return {
                ...state,
                onNextCallback: action.data,
            };
        case "SET_DEMO_ID":
            return{
                ...state,
                demoId: action.data,
            };
        case "SET_COURSE_ID":
            return{
                ...state,
                courseId: action.data,
            }    ;    
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // JB - Clarification: The following line is magic
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    // (or default initial state if none was supplied)
    return state;
};
