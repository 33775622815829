import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Spin, Button, Tooltip, notification } from 'antd';
import { Elements } from 'react-stripe-elements';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';

import InjectedTeacherAddEditMySchoolForm, { TeacherAddEditMySchoolFormDataObject } from './TeacherAddEditMySchoolForm';
import { LicenseController } from '../../api/LicenseController';
import { CourseController } from '../../api/CourseController';
import { ShoppingCartController } from '../../api/ShoppingCartController';
import { AccountController } from '../../api/AccountController';
import { ConstantController } from '../../api/ConstantController';
import ErrorDTO from '../../models/common/ErrorDTO';
import { CreateShoppingCartItemDTO } from '../../models/shoppingCart/createShoppingCartItemDTO';
import { EditShoppingCartItemDTO } from '../../models/shoppingCart/editShoppingCartItemDTO';
import { LicenseDTO } from '../../models/license/licenseDTO';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';
import { ConstantsDTO } from '../../models/constant/constantsDTO';
import { PricingTiers, PricingTier } from '../../constants/PricingTiers';

interface TeacherAddEditMySchoolProps extends PageProps<{ licenseId?: string, shoppingCartItemId?: string }> {
    // From Redux
    User: UserState;
}

interface TeacherAddEditMySchoolState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** Indicates that the page is in edit shopping cart mode */
    isCartEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    idFromUrl?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: TeacherAddEditMySchoolFormDataObject;

    updateShoppingCartItemData: EditShoppingCartItemDTO;

    /** Additional Data */
    courses: SimpleCourseDTO[];
    constants: ConstantsDTO;
}

class TeacherAddEditMySchool extends React.Component<TeacherAddEditMySchoolProps, TeacherAddEditMySchoolState> {
    private notificationKey = "TeacherAddEditMySchool";
    constructor(props: TeacherAddEditMySchoolProps) {
        super(props);
        bindAllOfThis(this, TeacherAddEditMySchool.prototype);

        let isEdit = props.match.params.licenseId != null && props.match.params.licenseId !== "null";
        let isCartEdit = props.match.params.shoppingCartItemId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            isCartEdit: isCartEdit,
            idFromUrl: props.match.params.licenseId,
            isLoading: true,
            formData: null,
            updateShoppingCartItemData: null,
            courses: [],
            constants: null
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: TeacherAddEditMySchoolFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: this.state.isEdit ? "Adding Additional Seats to Cart" : "Adding to Cart",
            description: "Please wait while we finalize your adding to cart...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = null;
        if(this.state.isEdit && this.state.isCartEdit) {
            request = this.handleOnSubmitEditUpdate(data);
        }
        else if(this.state.isEdit) {
            request = this.handleOnSubmitUpdate(data);
        }
        else if(this.state.isCartEdit) {
            request = this.handleOnSubmitEdit(data);
        }
        else {
            request = this.handleOnSubmitAdd(data);
        }

        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: this.state.isEdit ? "Added Additional Seats to Cart" : "Added to Cart",
                description: "Successfully added to cart!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isSubmitting: false });
            this.props.history.push(Routes.SHOPPING_CART().toRoute);
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: this.state.isEdit ? "Failed to Add Additional Seats to Cart" : "Failed to Add to Cart",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleOnSubmitAdd(data: TeacherAddEditMySchoolFormDataObject) {
        let course = data.course;
        let tier = data.pricingTier;
        let licenseCount = 0;
        let tierNum = 0;
        if (tier === PricingTiers.One) {
            licenseCount = 5;
            tierNum = 1;
        }
        if (tier === PricingTiers.Two) {
            licenseCount = 10;
            tierNum = 2;
        }
        if (tier === PricingTiers.Three) {
            licenseCount = 15;
            tierNum = 3;
        }
        if (tier === PricingTiers.Four) {
            if (data.additionalLicenseCount != null) {
                licenseCount = 15 + data.additionalLicenseCount;
                tierNum = 4;
            }
            else {
                licenseCount = 15;
                tierNum = 3;
            }
        }

        let request: CreateShoppingCartItemDTO = {
            courseId: course.key,
            documentaryId: null,
            teacherId: this.props.User.id,
            licenseId: data.licenseId,
            startDate: data.startDate,
            licenseCount: licenseCount,
            isTripleYearLicense: data.tripleYearLicense
        };

        return ShoppingCartController.PostAddShoppingCartItem(request);
    }

    handleOnSubmitUpdate(data: TeacherAddEditMySchoolFormDataObject) {
        let updatedLicenseCount: number = 0;
        if(data.pricingTier === PricingTiers.Two){
            updatedLicenseCount = 10;
        }
        if(data.pricingTier === PricingTiers.Three){
            updatedLicenseCount = 15;
        }
        if(data.pricingTier === PricingTiers.Four){
            updatedLicenseCount = data.licenseCount;
        }

        let request: CreateShoppingCartItemDTO = {
            courseId: null,
            documentaryId: null,
            teacherId: this.props.User.id,
            startDate: data.startDate,
            isTripleYearLicense: data.tripleYearLicense,
            licenseId: data.id,
            licenseCount: updatedLicenseCount
        };

        return ShoppingCartController.PostAddShoppingCartItem(request);
    }

    handleOnSubmitEditUpdate(data: TeacherAddEditMySchoolFormDataObject) {
        let updatedLicenseCount: number = 0;
        if(data.pricingTier === PricingTiers.Two){
            updatedLicenseCount = 10;
        }
        if(data.pricingTier === PricingTiers.Three){
            updatedLicenseCount = 15;
        }
        if(data.pricingTier === PricingTiers.Four){
            updatedLicenseCount = data.licenseCount;
        }

        let request: EditShoppingCartItemDTO = {
            id: data.id,
            courseId: data.course.key,
            documentaryId: null,
            startDate: data.startDate,
            licenseCount: updatedLicenseCount,
            isTripleYearLicense: data.tripleYearLicense
        };

        return ShoppingCartController.PostEditShoppingCartItem(request);
    }

    handleOnSubmitEdit(data: TeacherAddEditMySchoolFormDataObject) {
        let course = data.course;
        let tier = data.pricingTier;
        let licenseCount = 0;
        let tierNum = 0;
        if (tier === PricingTiers.One) {
            licenseCount = 5;
            tierNum = 1;
        }
        if (tier === PricingTiers.Two) {
            licenseCount = 10;
            tierNum = 2;
        }
        if (tier === PricingTiers.Three) {
            licenseCount = 15;
            tierNum = 3;
        }
        if (tier === PricingTiers.Four) {
            if (data.additionalLicenseCount != null) {
                licenseCount = 15 + data.additionalLicenseCount;
                tierNum = 4;
            }
            else {
                licenseCount = 15;
                tierNum = 3;
            }
        }

        let request: EditShoppingCartItemDTO = {
            id: data.id,
            courseId: course.key,
            documentaryId: null,
            startDate: data.startDate,
            licenseCount: licenseCount,
            isTripleYearLicense: data.tripleYearLicense
        };

        return ShoppingCartController.PostEditShoppingCartItem(request);
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadFormData(), this.loadShoppingCartItemData(), this.loadCourses(), this.loadConstants()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        // Load the license here
        return LicenseController.GetLicense(this.state.idFromUrl).then(result => {
            let tier = PricingTiers.FindBySeatCount(result.data.licenseCount);
            let license = {
                id: result.data.id,
                startDate: result.data.startDate,
                endDate: result.data.endDate,
                pricingTier: tier,
                additionalLicenseCount: result.data.claimedLicenseCount - tier.numberOfSeats,
                course: { key: result.data.courseId, label: result.data.courseDocumentaryName },
                paymentAmountInCents: result.data.paymentAmountInCents,
                schoolYearId: result.data.schoolYearId,
                tripleYearLicense: result.data.tripleYearLicense,
                couponId: result.data.couponId,
                licenseId: this.props.match.params.licenseId
            } as TeacherAddEditMySchoolFormDataObject;

            this.setState({ formData: license });

            let data = result.data;
            if(data!=null){
            let tier = PricingTiers.FindBySeatCount(result.data.licenseCount);
                let formData: TeacherAddEditMySchoolFormDataObject = {
                    id : data.id,
                    startDate : data.startDate,
                    endDate : data.endDate,
                    licenseCount : data.licenseCount,
                    courseId : data.courseId,
                    schoolYearId : data.schoolYearId,
                    tripleYearLicense : data.tripleYearLicense,
                    paymentAmountInCents : data.paymentAmountInCents,
                    additionalLicenseCount : data.additionalLicenseCount,
                    couponId :  data.couponId != null ? data.couponId : null,
                    pricingTier: tier,
                    course: { key: data.courseId, label: data.courseDocumentaryName },
                    licenseId: this.props.match.params.licenseId
                };
            this.setState({ formData: formData });
            }

        });
    }

    loadShoppingCartItemData() {
        if (!this.state.isCartEdit) {
            return this.setState({
                updateShoppingCartItemData: null
            });
        }

        return ShoppingCartController.GetShoppingCartItem(this.props.match.params.shoppingCartItemId).then(result => {
            let shoppingCartItemData: EditShoppingCartItemDTO = {
                id : result.data.id,
                startDate : result.data.startDate,
                licenseCount : result.data.licenseCount,
                courseId : result.data.courseId,
                documentaryId: null,
                isTripleYearLicense : result.data.isTripleYearLicense
            };
            this.setState({ updateShoppingCartItemData: shoppingCartItemData });
        });
    }

    loadCourses() {
        if (this.state.isEdit) {
            return this.setState({
                courses: []
            });
        }

        // Load the simple course list here
        return CourseController.GetCourses().then(result => {
            let availableCourses = result.data.filter(course => course.availableForPurchase);
            this.setState({ courses: availableCourses });
        });
    }

    loadConstants() {
        // todo: pull constants from redux instead
        return ConstantController.GetConstants().then(constantsResult => {
            this.setState({ constants: constantsResult.data });
        });
    }

    renderHeader() {
        if (this.state.isEdit) {
            return <h1>Add Additional Seats to Cart</h1>;
        }
        return <h1>Add License to Cart</h1>;
    }

    render() {
        if (this.props.User.schoolId == null) {
            return <Redirect to={Routes.TEACHER_SELECT_MY_SCHOOL().toRoute} />;
        }

        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            {this.renderHeader()}
            <Elements>
                <InjectedTeacherAddEditMySchoolForm
                    isSubmitting={this.state.isSubmitting}
                    onSubmit={this.handleOnSubmit}
                    initialValues={this.state.formData}
                    shoppingCartItemValues={this.state.updateShoppingCartItemData}
                    courses={this.state.courses}
                    constants={this.state.constants}
                    isEdit={this.state.isEdit}
                />
            </Elements>
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherAddEditMySchool);
