import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { LocationDescriptor } from 'history';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, Redirect, withRouter } from 'react-router-dom';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { PageProps } from '../../../src/models/common/ComponentProps';
import { UserState } from '../../redux/UserReducer';
import { WorkflowState } from '../../redux/WorkflowReducer';
import UserAction from '../../redux/UserActions';
import ReduxStoreModel from '../../redux/ReduxModel';
import DebugUtil from '../../utils/DebugUtil';
import { Roles } from '../../constants/Roles';
import { UserDTO } from '../../models/account/userDTO';
import { ParamUtil } from '../../utils/ParamUtil';
import ActionResultDTO from '../../models/common/ActionResultDTO';
import { ClassroomController } from '../../api/ClassroomController';

interface UrlPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    UpdateUser: (request: UserDTO) => void;
    StudentLogin: (passcode: string) => Promise<ActionResultDTO>;
}

interface UrlPageState {
    /** Indicates that the page has loaded properly once and gone through the componentDidMount() process */
    isInitialized: boolean;

    isLoading: boolean;
    nextLocation: LocationDescriptor;
    urlParams: ParamUtil;
}

class UrlPage extends React.Component<UrlPageProps, UrlPageState> {
    constructor(props: UrlPageProps) {
        super(props);
        bindAllOfThis(this, UrlPage.prototype);

        this.state = {
            isInitialized: false,
            isLoading: true,
            nextLocation: null,
            urlParams: null,
        };
    }

    componentDidMount() {
        // Run through e available URL params
        let params = new ParamUtil(this.props.location.search);

        /* Scenario 1 - Student Passcode Login */
        if (params.has.StudentPasscode) {
            return this.props.StudentLogin(params.get.StudentPasscode).then(result => {
                console.warn("Logged in as student"); // JB - Remove this console.warn
                // TODO: JB - Error handling
                if (result.isError) {
                    // result.message
                    return;
                }

                // Check if we are a parent
                if (params.has.IsParent) {
                    console.warn("Changing to parent"); // JB - Remove this console.warn
                    this.props.UpdateUser({
                        ...this.props.User,
                        role: Roles.Parent
                    });
                }

                // OKAY, we are logged in (hopefully) and need to be put somewhere
                if (params.has.LessonId && params.has.ClassroomId) {
                    console.warn("Starting the classroom loader"); // JB - Remove this console.warn
                    return ClassroomController.GetCoursesByClassroom(params.get.ClassroomId).then(courseResult => {
                        let myCourse = courseResult.data.find(course => course.lessons.find(lesson => lesson.id === params.get.LessonId) != null);
                        console.warn("Setting nextLocation"); // JB - Remove this console.warn
                        return this.setState({
                            nextLocation: {
                                pathname: Routes.STUDENT_WORKFLOW_HOME(params.get.ClassroomId, myCourse.id, params.get.LessonId).toRoute,
                                search: params.has.IsParent ? "parent=true" : ""
                            }
                        });
                    }).catch(error => {
                        // SOMETHING IS VERY WRONG
                        return this.setState({ nextLocation: Routes.HOME().toRoute });
                    });
                }
                return this.setState({ nextLocation: Routes.HOME().toRoute });
            });
        }

        // Uh, send to student login
        return this.setState({
            nextLocation: { pathname: Routes.STUDENT_LOGIN().toRoute }
        });
    }

    render() {
        if (this.state.nextLocation != null) {
            return <Redirect to={this.state.nextLocation} />;
        }

        return (
            <div>
                <h1>Welcome</h1>
                {/* <p>Please wait while you are being redirected</p> */}
                <p>Please wait <LoadingOutlined /></p>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return ({
        UpdateUser: (request: UserDTO) => UserAction.UpdateUser(dispatch, request),
        StudentLogin: (passcode: string) => UserAction.StudentLogin(dispatch, { passcode }), // Variable with with no ':' is shorthand. It is the variable name AND value
    });
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UrlPage));
