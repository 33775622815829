import axios from 'axios';
import DebugUtil from '../utils/DebugUtil';

/**
 * Configure Axios with defaults such as baseurl and headers. Also has interceptors for failed authentication requests
 *
 */
function ConfigureAxios() {
    // Internal function to avoid adding more to the global scope :3
    let getAPIEnvironment = (): string => {
        // When running local with local intelliJ, use this
        // return "http://localhost:5000";

        // Dev url will have 'devl' in the URL, in case you get these mixed up
        switch (DebugUtil.GetCurrentEnvironment()) {
           case "production":
               return `${window.location.protocol}//api.rockdigi.org`;
           case "development":
           default:
               return `${window.location.protocol}//api.devl.rockdigi.org`;
        }
    };
    axios.defaults.baseURL = getAPIEnvironment();

    // Allows cookies to be sent on all API requests
    axios.defaults.withCredentials = true;
}

export default ConfigureAxios;
