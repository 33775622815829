import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import DebugUtil from './utils/DebugUtil';
import History from '../src/core/ConfigureHistory'; // Generates a default history state
import Store from '../src/core/ConfigureRedux'; // Redux in all of it's glory
import ConfigureAxios from '../src/core/ConfigureAPI'; // API Defaults
import App from './App'; // Literally everything else
import moment from 'moment';

// TODO: JB - Should this really go here? Or should we put it into app
// Configures the api to do things
ConfigureAxios();

// BEGIN HACK
/**
 * For whatever reason, async-validator shows up alot in the console because the validator package that just does that. Why? Because it hates you.
 * This is stupid and completely unnecessary. Why console.warn? There is no way to turn it off if you aren't in prod (it looks for a node env var)
 * I would like to point out that this practice is stupid and the fact that i need to even do this next part is also stupid
 * </rant>
 * Code Summary: Essentially, we are taking the console.warn function and intercepting it to look for a specific string.
 * If found, return
 * Else, console.warn like normal
 */

// Code from here
// https://github.com/yiminghe/async-validator/issues/92#issuecomment-463207689
// const warnFunction = console.warn;
// console.warn = (...args: any[]) => {
//     let q = args[0];
//     if (typeof q === 'string' && q.startsWith('async-validator')) {
//         return;
//     }
//     warnFunction(...args);
// };
// END HACK

if (DebugUtil.isDebugEnabled()) {
    let buildDate = moment(process.env.REACT_APP_BUILD_DATE);
    let today = moment();
    let yesterday = moment().add(-1, 'days');

    let buildString = buildDate.isSameOrAfter(today, "day")
        // Build was made today
        ? `Build created Today at ${buildDate.format("LT")}`
        : buildDate.isSameOrAfter(yesterday, "day")
            // Build was made yesterday
            ? `Build created Yesterday at ${buildDate.format("LT")}`
            // Build was much longer ago
            : `Build created on (${buildDate.format("LL")}) at ${buildDate.format("LT")}`;

    console.error("Debug Enabled");
    console.warn(`Environment: ${process.env.REACT_APP_ENVIRONMENT}`);
    console.warn(buildString);
}

let stripeApiKey = DebugUtil.GetCurrentEnvironment() === "development" ? "pk_test_IQvEQQKmBTKPg8O3f4Gh1xpO00i3FBxaXB" : "pk_live_w73ZGFTwfmjJQO39LDStIJzb00zFeiUirQ";

ReactDOM.render(
        <Provider store={Store}>
            <Router history={History}>
                <StripeProvider apiKey={stripeApiKey}>
                    <App />
                </StripeProvider>
            </Router>
        </Provider>,
    document.getElementById('root') as HTMLElement
);
