import * as React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Spin, notification, Button } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { CourseController } from '../../api/CourseController';
import { CreateCourseDTO } from '../../models/course/createCourseDTO';
import { UpdateCourseDTO } from '../../models/course/updateCourseDTO';
import AdminCourseForm, { AdminCourseFormDataObject } from './AdminCourseForm';
import ErrorDTO from '../../models/common/ErrorDTO';
import AdminCourseLessonTable, { AdminCourseLessonTableDataObject } from './AdminCourseLessonTable';
import { LessonController } from '../../api/LessonController';
import { QuestionDTO } from '../../models/question/questionDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import AdminCourseAppendixModal, { AdminCourseAppendixModalDataObject } from './AdminCourseAppendixModal';
import { DemoController } from '../../api/DemoController';

interface AdminCoursePageProps extends PageProps<{ courseId?: string }> {
}

interface AdminCoursePageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    courseId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: AdminCourseFormDataObject;
    /** The data for the table */
    tableData: AdminCourseLessonTableDataObject[];

    isModalVisible: boolean;
    modalData: AdminCourseAppendixModalDataObject;
    demoId: string;
}

class AdminCoursePage extends React.Component<AdminCoursePageProps, AdminCoursePageState> {
    private notificationKey = "AdminCoursePage";
    constructor(props: AdminCoursePageProps) {
        super(props);
        bindAllOfThis(this, AdminCoursePage.prototype);

        let isEdit = props.match.params.courseId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            courseId: props.match.params.courseId,
            isLoading: true,
            formData: null,
            tableData: [],
            isModalVisible: false,
            modalData: null,
            demoId: null
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleModalComplete() {
        this.setState({
            isModalVisible: false,
        });
        this.loadAll();
    }

    handleModalClose() {
        this.setState({
            isModalVisible: false,
        });
    }

    handleEditModal() {
        this.setState({
            isModalVisible: true,
        });
    }

    handleOnSubmit(data: AdminCourseFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving Course",
            description: "Please wait while we save the course...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);
        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Saved Course",
                description: "Successfully saved the course!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isSubmitting: false });
            // Go back if editing, refresh if adding
            if (this.state.isEdit) {
                this.props.history.push(Routes.ADMIN_COURSES().toRoute);
            } else {
                this.props.history.push(Routes.ADMIN_COURSE_ADD_EDIT(result.data.id).toRoute);
            }
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Save Course",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleOnSubmitAdd(data: AdminCourseFormDataObject) {
        let request: CreateCourseDTO = {
            name: data.name,
            description: data.description,
            gradeLevelStart: data.gradeLevelStart,
            gradeLevelEnd: data.gradeLevelEnd,
            availableForPurchase: data.availableForPurchase
        };
        return CourseController.PostCreateCourse(request);
    }

    handleOnSubmitUpdate(data: AdminCourseFormDataObject) {
        let request: UpdateCourseDTO = {
            courseId: data.id,
            name: data.name,
            description: data.description,
            gradeLevelStart: data.gradeLevelStart,
            gradeLevelEnd: data.gradeLevelEnd,
            availableForPurchase: data.availableForPurchase
        };

        return CourseController.PutUpdateCourse(request);
    }

    handleOnLessonUpdate(record: AdminCourseLessonTableDataObject) {
        this.setState({ isSubmitting: true });
        // Record typically null when deleting
        if (record == null) {
            return this.loadFormData().then(() => {
                return this.setState({ isSubmitting: false });
            });
        }

        // Update the lesson
        return LessonController.PutUpdateLesson({
            lessonId: record.id,
            name: record.name,
            ordering: record.ordering,
            courseId: this.props.match.params.courseId,
        }).then(result => {
            return this.loadFormData().then(() => {
                return this.setState({ isSubmitting: false });
            });
        });
    }

    handleRemoveDemo(){
        notification.info({
            key: this.notificationKey,
            message: "Removing Course From Demo",
            description: "Please wait while we remove the course...",
            duration: 0
        });
        this.setState({ isSubmitting: true });
        DemoController.DeleteRemoveDemo(this.state.demoId).then(() => {
            notification.success({
                key: this.notificationKey,
                message: "Removed Course",
                description: "Successfully removed the course!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
            this.setState({ isSubmitting: false });
            this.props.history.push(Routes.ADMIN_COURSE_ADD_EDIT(this.state.courseId).toRoute);
        });
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadFormData()]).then(x => {
            DemoController.GetDemoCourse(this.state.courseId).then(result => {
                if(result == null){
                    this.setState({
                        demoId: null,
                    });
                }
                else{
                let demoData = result.data;
                    this.setState({
                        demoId: demoData.id,
                    });
                }
            });
            this.setState({ isLoading: false });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            this.setState({
                formData: null
            });
            return Promise.resolve();
        }

        // Load the user here
        return CourseController.GetCourse(this.state.courseId).then(result => {
            let data: AdminCourseFormDataObject = {
                id: result.data.id,
                name: result.data.name,
                description: result.data.description,
                gradeLevelStart: result.data.gradeLevelStart,
                gradeLevelEnd: result.data.gradeLevelEnd,
                availableForPurchase: result.data.availableForPurchase
            };
            let tableData = (result.data.lessons || [])
                .sort((a, b) => a.id.localeCompare(b.id)) // Initial sort
                .map<AdminCourseLessonTableDataObject>(record => {
                    // Checks
                    let questionCheckerFunction = (question: QuestionDTO): boolean => {
                        // Find first item by order. If the index does not match our index, then it is a duplicate order
                        let hasQuestionOrderError = (question.questionAnswers || []).some((value, index, arr) => {
                            return arr.findIndex(x => x.ordering === value.ordering) !== index;
                        });
                        // Check if every questionAnswer is false, which means that none of them have a correct response
                        let hasMissingCorrectAnswerError = (question.questionAnswers || []).every((value) => {
                            return !value.isCorrect;
                        });
                        return hasQuestionOrderError || hasMissingCorrectAnswerError;
                    };
                    let hasStudentQuestionsError = (record.studentQuestions || []).some(questionCheckerFunction);
                    let hasParentQuestionsError = (record.parentQuestions || []).some(questionCheckerFunction);
                    let hasLessonContentsError = LessonContentTypes.All.filter(x => x.hasUpload).some(something => {
                        return (record.lessonContents || []).find(x => x.lessonContentTypeId === something.id) == null;
                    });

                    return {
                        id: record.id,
                        name: record.name,
                        ordering: record.ordering,
                        studentQuestions: record.studentQuestions,
                        parentQuestions: record.parentQuestions,
                        lessonContents: record.lessonContents,
                        hasQuestionsError: hasStudentQuestionsError || hasParentQuestionsError,
                        hasLessonContentsError: hasLessonContentsError,
                    };
                });
            this.setState({
                formData: data,
                tableData: tableData,
                modalData: {
                    courseId: result.data.id,
                    url: result.data.standardsAppendixUrl
                }
            });
        });
    }

    renderTable() {
        if (this.state.isEdit) {
            return (
                <React.Fragment>
                    <h1 key="lessons-header">Lessons</h1>
                    <Link key="table-link" to={Routes.ADMIN_LESSON_ADD_EDIT(this.state.courseId).toRoute}>
                        <Button icon={<EditOutlined />}>New Lesson</Button>
                    </Link>
                    <Button
                        icon={<EditOutlined />}
                        style={{ marginLeft: "6px" }}
                        onClick={() => this.handleEditModal()}
                    >
                        {this.state.modalData.url != null && this.state.modalData.url.length > 0 ? "Edit" : "Add"} Course Appendix
                    </Button>
                    <AdminCourseLessonTable
                        key="lessons-table"
                        tableData={this.state.tableData}
                        courseId={this.state.courseId}
                        isSubmitting={this.state.isSubmitting}
                        isLoading={this.state.isLoading}
                        onLessonUpdate={e => this.handleOnLessonUpdate(e)}
                    />
                </React.Fragment>
            );
        }
        return null;
    }

    renderDemoButtons() {
        if (!this.state.isLoading) {
            if (this.state.demoId == null && this.state.courseId != null) {
                return <Link to={Routes.ADMIN_DEMO(this.state.courseId).toRoute}>
                    <Button type="primary" className="login-form-button" size="large">
                        Add Course To Demo
                    </Button>
                </Link>;
            }
            else if (this.state.demoId !== null) {
                return <div>
                    <Link to={Routes.ADMIN_DEMO(this.state.courseId, this.state.demoId).toRoute}>
                        <Button type="primary" className="login-form-button" size="large">
                            Update Course Demo Description and Order
                        </Button>
                    </Link>
                    <br></br>
                    <br></br>
                    <Link to={Routes.ADMIN_COURSES().toRoute}>
                        <Button  className="login-form-button" danger size="large" onClick={() => this.handleRemoveDemo()}>
                            Remove Course From Demo
                        </Button>
                    </Link>
                </div>;
            }
        }
       
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <AdminCourseAppendixModal
                isVisible={this.state.isModalVisible}
                onComplete={this.handleModalComplete}
                onClose={this.handleModalClose}
                formValues={this.state.modalData} />
            <h1>{this.state.isEdit ? "Edit" : "Add"} Course</h1>
            <AdminCourseForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
            />
            {this.renderDemoButtons()}
            {this.renderTable()}
        </div>;
    }
}

export default AdminCoursePage;
