import axios from 'axios';
import { SimpleSchoolDTO } from '../models/school/simpleSchoolDTO';
import { CreateSchoolDTO } from '../models/school/createSchoolDTO';
import { UpdateSchoolDTO } from '../models/school/updateSchoolDTO';
import { SchoolDTO } from '../models/school/schoolDTO';
import { ClassroomDTO } from '../models/classroom/classroomDTO';
import { CourseDTO } from '../models/course/courseDTO';
import { StudentDTO } from '../models/student/studentDTO';
import { LicenseDTO } from '../models/license/licenseDTO';
import { TeacherDTO } from '../models/teacher/teacherDTO';
import { SchoolSearchRequestDTO } from '../models/school/schoolSearchRequestDTO';
import { SchoolSearchDTO } from '../models/school/schoolSearchDTO';

export class SchoolController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetSchoolsRoute = () => `schools`;
    public static GetSchools() {
        return axios.get<SchoolDTO[]>(this.GetSchoolsRoute());
    }

    public static PostCreateSchoolRoute = () => `schools`;
    public static PostCreateSchool(request: CreateSchoolDTO) {
        return axios.post<SchoolDTO>(this.PostCreateSchoolRoute(), request);
    }

    public static PutUpdateSchoolRoute = () => `schools`;
    public static PutUpdateSchool(request: UpdateSchoolDTO) {
        return axios.put<SchoolDTO>(this.PutUpdateSchoolRoute(), request);
    }

    public static GetMassStudentUploadTemplateRoute = () => `schools/massStudentUpload`;
    public static GetMassStudentUploadTemplate() {
        return axios.get<any>(this.GetMassStudentUploadTemplateRoute(), {responseType: 'blob'});
    }

    public static PostMassStudentUploadRoute = (schoolId: string) => `schools/${schoolId}/massStudentUpload`;
    public static PostMassStudentUpload(schoolId: string, formData: FormData) {
        let headers = { 'content-type': `multipart/form-data; boundary=${(formData as any)._boundary}` };
        return axios.post<string[]>(this.PostMassStudentUploadRoute(schoolId), formData, {headers: headers});
    }

    public static GetSimpleSchoolsRoute = () => `schools/simple`;
    public static GetSimpleSchools() {
        return axios.get<SimpleSchoolDTO[]>(this.GetSimpleSchoolsRoute());
    }

    public static DeleteSchoolRoute = (schoolId: string) => `schools/${schoolId}`;
    public static DeleteSchool(schoolId: string) {
        return axios.delete(this.DeleteSchoolRoute(schoolId));
    }

    public static GetClassroomsBySchoolRoute = (schoolId: string) => `schools/${schoolId}/classrooms`;
    public static GetClassroomsBySchool(schoolId: string) {
        return axios.get<ClassroomDTO[]>(this.GetClassroomsBySchoolRoute(schoolId));
    }

    public static GetLicensesBySchoolRoute = (schoolId: string) => `schools/${schoolId}/licenses`;
    public static GetLicensesBySchool(schoolId: string) {
        return axios.get<LicenseDTO[]>(this.GetLicensesBySchoolRoute(schoolId));
    }

    public static GetStudentsBySchoolRoute = (schoolId: string) => `schools/${schoolId}/students`;
    public static GetStudentsBySchool(schoolId: string) {
        return axios.get<StudentDTO[]>(this.GetStudentsBySchoolRoute(schoolId));
    }

    public static GetTeachersBySchoolRoute = (schoolId: string) => `schools/${schoolId}/teachers`;
    public static GetTeachersBySchool(schoolId: string) {
        return axios.get<TeacherDTO[]>(this.GetTeachersBySchoolRoute(schoolId));
    }

    public static GetSchoolRoute = (schoolId: string) => `schools/${schoolId}`;
    public static GetSchool(schoolId: string) {
        return axios.get<SchoolDTO>(this.GetSchoolRoute(schoolId));
    }

    public static GetSchoolsByNameRoute = (str: string) => `schools/byName/${str}`;
    public static GetSchoolsByName(str: string) {
        return axios.get<SimpleSchoolDTO[]>(this.GetSchoolsByNameRoute(str));
    }

    public static GetSchoolsByStateRoute = (str: string) => `schools/byState/${str}`;
    public static GetSchoolsByState(str: string) {
        return axios.get<SchoolDTO[]>(this.GetSchoolsByStateRoute(str));
    }

    public static GetStatesRoute = () => `schools/getStates`;
    public static GetStates() {
        return axios.get<string[]>(this.GetStatesRoute());
    }
    public static GetFindCitiesByStateRoute = (searchString: string) => `schools/findCitiesByState/${searchString}`;
    public static GetFindCitiesByState(request: string) {
        return axios.get<string[]>(this.GetFindCitiesByStateRoute(request));
    }
    public static PostFindSchoolsByDTORoute = () => `schools/findSchoolsByDTO`;
    public static PostFindSchoolsByDTO(request: SchoolSearchRequestDTO) {
        return axios.post<SchoolSearchDTO[]>(this.PostFindSchoolsByDTORoute(), request);
    }
}
