import * as React from 'react';
import { Spin, notification } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { DistrictController } from '../../api/DistrictController';
import { SchoolController } from '../../api/SchoolController';
import AdminSchoolForm, { AdminSchoolFormDataObject } from './AdminSchoolForm';
import { DistrictDTO } from '../../models/district/districtDTO';
import { CreateSchoolDTO } from '../../models/school/createSchoolDTO';
import { UpdateSchoolDTO } from '../../models/school/updateSchoolDTO';
import ErrorDTO from '../../models/common/ErrorDTO';

interface AdminSchoolPageProps extends PageProps<{ schoolId?: string }> {
}

interface AdminSchoolPageState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    schoolId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: AdminSchoolFormDataObject;

    // Extra data
    districts: DistrictDTO[];
}

class AdminSchoolPage extends React.Component<AdminSchoolPageProps, AdminSchoolPageState> {
    private notificationKey = "AdminSchoolPage";
    constructor(props: AdminSchoolPageProps) {
        super(props);
        bindAllOfThis(this, AdminSchoolPage.prototype);

        let isEdit = props.match.params.schoolId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            schoolId: props.match.params.schoolId,
            isLoading: true,
            formData: null,
            districts: [],
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AdminSchoolFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving School",
            description: "Please wait while we save the school...",
            duration: 0
        });

        this.setState({ isSubmitting: true }, () => {
            let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);

            request.then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Saved School",
                    description: "Successfully saved the school!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({isSubmitting: false});
                this.props.history.push(Routes.ADMIN_SCHOOLS().toRoute);
            }).catch(error => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Save School",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({isSubmitting: false});
            });
        });
    }

    handleOnSubmitAdd(data: AdminSchoolFormDataObject) {
        let request: CreateSchoolDTO = {
            name: data.name,
            gradeStart: data.gradeStart,
            gradeEnd: data.gradeEnd,
            districtId: (data.district && data.district.key) || null,
            city: data.city,
            state: data.state,
            zip: data.zip
        };

        return SchoolController.PostCreateSchool(request);
    }

    handleOnSubmitUpdate(data: AdminSchoolFormDataObject) {
        let request: UpdateSchoolDTO = {
            schoolId: data.id,
            name: data.name,
            gradeStart: data.gradeStart,
            gradeEnd: data.gradeEnd,
            districtId: data.district.key,
            city: data.city,
            state: data.state,
            zip: data.zip,
        };

        return SchoolController.PutUpdateSchool(request);
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadDistricts(), this.loadFormData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadDistricts() {
        return DistrictController.GetDistricts().then(results => {
            return this.setState({ districts: results.data });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        // Load the school here
        return SchoolController.GetSchool(this.state.schoolId).then(result => {
            let data: AdminSchoolFormDataObject = {
                id: result.data.id,
                name: result.data.name,
                gradeStart: result.data.gradeStart,
                gradeEnd: result.data.gradeEnd,
                district: { key: result.data.districtId || '', label: result.data.districtName } as KeyLabel,
                city: result.data.city,
                state: result.data.state,
                zip: result.data.zip
            };
            this.setState({ formData: data });
        });
    }

    renderHeader() {
        if (this.state.isEdit) {
            return <h1>Edit School</h1>;
        }
        return <h1>Add School</h1>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            {this.renderHeader()}
            <AdminSchoolForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
                districts={this.state.districts}
            />
        </div>;
    }
}

export default AdminSchoolPage;
