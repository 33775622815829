import * as React from 'react';
import { Input, InputNumber } from 'antd';
import bindAllOfThis from '../utils/BindThisHelper';

interface InputWithExtraProps {
    value?: number;
    disabled?: boolean;
    defaultValue?: number;
    rightSide?: React.ReactNode;

    onChange?: (value: number) => void;
}

class InputWithExtra extends React.PureComponent<InputWithExtraProps> {
    private defaults: Partial<InputWithExtraProps> = {
        disabled: false
    };

    constructor(props: InputWithExtraProps) {
        super(props);
        bindAllOfThis(this, InputWithExtra.prototype);
    }

    private getProps(props: InputWithExtraProps = this.props) {
        return {...this.defaults, ...props};
    }

    private handleOnChange(value: string | number) {
        if (this.props.onChange != null) {
            this.props.onChange(Number(value));
        }
    }

    render() {
        let { value, defaultValue, disabled, rightSide } = this.getProps();

        return (
            <div className="letter-range-selector">
                <Input.Group compact>
                    <InputNumber
                        precision={0}
                        min={1}
                        size="large"
                        defaultValue={defaultValue}
                        value={value}
                        disabled={disabled}
                        onChange={this.handleOnChange} />

                    {rightSide}
                </Input.Group>
            </div>);
    }
}

export default InputWithExtra;
