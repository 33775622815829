import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import { PageProps } from '../../models/common/ComponentProps';
import { Button, Card, Col, Input, Modal, notification, Row, Spin, Table } from 'antd';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import { NameOf } from '../../utils/NameOf';
import { SchoolDTO } from '../../models/school/schoolDTO';
import { ColumnProps } from 'antd/lib/table';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { TeacherController } from '../../api/TeacherController';
import { UpdateTeacherDTO } from '../../models/teacher/updateTeacherDTO';
import { UpdateAccountDTO } from '../../models/account/updateAccountDTO';
import UserAction from '../../redux/UserActions';
import SchoolSearch from '../../components/SchoolSearch';
import DebugUtil from '../../utils/DebugUtil';
import { SchoolSearchDTO } from '../../models/school/schoolSearchDTO';
import FalseFormItem from '../../components/FalseFormItem';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import TeacherSelectMySchoolModal, { TeacherSelectMySchoolModalDataObject } from './TeacherSelectMySchoolModal';
import { SchoolController } from '../../api/SchoolController';
import ErrorDTO from '../../models/common/ErrorDTO';

interface TeacherEditActiveSchoolProps extends PageProps {
    User: UserState;
    UpdateUserAccount: (request: UpdateAccountDTO) => void;
}

interface TeacherEditActiveSchoolState {
    isLoading: boolean;
    isAddSchoolVisible: boolean;
    isRemoveSchoolVisible: boolean;
    isCreateSchoolVisible: boolean;
    joinCode: string;
    removeCountDown: number;
    tableData: SchoolDTO[];

    /** The school on the teacher record if any */
    primarySchool: SchoolDTO;

    /** The school to be added from the modal */
    selectedSchool: SchoolSearchDTO;

    selectedSchoolForRemoval: SchoolDTO;
}

class TeacherEditActiveSchool extends React.Component<TeacherEditActiveSchoolProps, TeacherEditActiveSchoolState> {
    private notificationKey = "TeacherEditActiveSchoolPage";
    private tableColumns: ColumnProps<SchoolDTO>[];
    private removeWarningTimer: NodeJS.Timeout;

    constructor(props: TeacherEditActiveSchoolProps) {
        super(props);
        bindAllOfThis(this, TeacherEditActiveSchool.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: NameOf<SchoolDTO>("name"),
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: 'descend',
            ...getColumnSearchProps<SchoolDTO>("name", "Name"),
        }, {
            title: 'City',
            dataIndex: NameOf<SchoolDTO>("city"),
            sorter: (a, b) => a.city.localeCompare(b.city),
            ...getColumnSearchProps<SchoolDTO>("city", "City"),
        }, {
            title: 'State',
            dataIndex: NameOf<SchoolDTO>("state"),
            sorter: (a, b) => a.state.localeCompare(b.state),
            ...getColumnSearchProps<SchoolDTO>("state", "State"),
        }, {
            title: 'Zip',
            dataIndex: NameOf<SchoolDTO>("zip"),
            sorter: (a, b) => a.zip.localeCompare(b.zip),
            ...getColumnSearchProps<SchoolDTO>("zip", "Zip"),
        }, {
            title: '',
            key: 'select',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="link" onClick={() => this.handleSelectSchool(record)}>Select</Button>
                );
            }
        }, {
            title: '',
            key: 'remove',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="link" onClick={() => this.handleRemoveSchool(record)}>Remove</Button>
                );
            }
        }];

        this.state = {
            isLoading: true,
            isAddSchoolVisible: false,
            isRemoveSchoolVisible: false,
            isCreateSchoolVisible: false,
            joinCode: "",
            removeCountDown: 0,
            tableData: [],
            primarySchool: null,
            selectedSchool: null,
            selectedSchoolForRemoval: null
        };
    }

    componentWillUnmount() {
        clearInterval(this.removeWarningTimer);
    }

    componentDidMount() {
        this.loadTeacher();
    }

    async loadTeacher() {
        this.setState({ isLoading: true });
        try {
            let result = await TeacherController.GetTeacherById(this.props.User.id);
            this.setState({
                tableData: result.data.allSchools,
                primarySchool: result.data.allSchools.find(x => x.id === this.props.User.schoolId),
                selectedSchool: null,
                isLoading: false,
                isAddSchoolVisible: false,
                isRemoveSchoolVisible: false
            });
        } catch (error) {
            notification.error({
                key: this.notificationKey,
                message: "Error!",
                description: "Failed to load schools, please refresh the page and try again",
                duration: 0
            });
        }
    }

    private async handleSelectSchool(selected: SchoolDTO) {
        // This will submit the teacher object
        const { User } = this.props;

        let request: UpdateTeacherDTO = {
            teacherId: User.id,
            email: User.email,
            firstName: User.firstName,
            lastName: User.lastName,
            phoneNumber: User.phoneNumber,
            schoolId: selected.id
        };

        try {
            notification.info({
                key: this.notificationKey,
                message: "Update Active School",
                description: "Please wait...",
                duration: 0
            });
            let result = await TeacherController.PutUpdateTeacher(request);
            this.props.UpdateUserAccount({
                ...result.data,
                // Spread operator takes care of most. userId is not and SchoolId is missing entirely
                userId: result.data.id,
                schoolId: result.data.school.id,
            });
            notification.success({
                key: this.notificationKey,
                message: "Update Active School",
                description: "Updated active school",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });
        } catch (error) {
            notification.error({
                key: this.notificationKey,
                message: "Update Active School",
                description: `Error while adding: ${error}`,
                duration: 5
            });
        }
    }

    private async handleAddSchool() {
        if (this.state.selectedSchool == null) {
            notification.warn({
                key: this.notificationKey,
                message: "Adding School",
                description: "You must actually select a school first",
                duration: 7
            });
            return;
        }
        try {
            notification.info({
                key: this.notificationKey,
                message: "Adding School",
                description: "Please wait...",
                duration: 0
            });
            await TeacherController.GetAddTeacherToSchool(this.props.User.id, this.state.selectedSchool.id);
            notification.success({
                key: this.notificationKey,
                message: "Adding School",
                description: "Added School to Teacher",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ selectedSchool: null });
            this.loadTeacher();
        } catch (error) {
            notification.error({
                key: this.notificationKey,
                message: "Adding School",
                description: `Error while adding: ${error}`,
                duration: 5
            });
        }
    }

    private async handleRemoveSchool(e: SchoolDTO) {
        // Do some sanity checking for the users sake
        if (this.state.tableData.length === 1) {
            // Don't let them remove their only school. Only admins can do that
            notification.error({
                key: this.notificationKey,
                message: "Removing School",
                description: "You cannot remove your only school!",
                duration: 10,
                onClick: () => notification.close(this.notificationKey)
            });
            return;
        }

        if (this.state.primarySchool.id === e.id) {
            // Another warning, this time because it is the primary
            notification.error({
                key: this.notificationKey,
                message: "Removing School",
                description: "You cannot remove your primary school. Please set another school as the primary first",
                duration: 10,
                onClick: () => notification.close(this.notificationKey)
            });
            return;
        }

        // Start the timer and show the warning
        this.setState({
            removeCountDown: 3,
            isRemoveSchoolVisible: true,
            selectedSchoolForRemoval: e
        }, () => {
            // Run the timer only after the state has been updated
            this.removeWarningTimer = setInterval(() => {
                let { removeCountDown } = this.state;
                this.setState({ removeCountDown: removeCountDown - 1 });

                if (removeCountDown < 1) {
                    clearInterval(this.removeWarningTimer);
                }
            }, 1000);
        });
    }

    private async handleRemoveSchoolPart2() {
        const { selectedSchoolForRemoval } = this.state;
        try {
            notification.info({
                key: this.notificationKey,
                message: "Removing School",
                description: "Please wait...",
                duration: 0
            });
            await TeacherController.GetRemoveTeacherFromSchool(this.props.User.id, selectedSchoolForRemoval.id);
            notification.success({
                key: this.notificationKey,
                message: "Removing School",
                description: "Removed School from Teacher",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.loadTeacher();
        } catch (error) {
            notification.error({
                key: this.notificationKey,
                message: "Removing School",
                description: `Error while removing: ${error}`,
                duration: 5
            });
        }
    }

    private handleModalOnSubmit(data: TeacherSelectMySchoolModalDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "Saving School",
            description: "Please wait while we save the school...",
            duration: 0
        });

        // this.setState({ isSubmitting: true });
        let request = SchoolController.PostCreateSchool({
            name: data.name,
            gradeStart: Number(data.gradeStart.key),
            gradeEnd: Number(data.gradeEnd.key),
            city: data.city,
            state: data.state,
            zip: data.zip,
            districtId: null
        });

        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Saved School",
                description: "Successfully saved the school!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isCreateSchoolVisible: false });

            // Okay, now add the user to their school
            return this.handleSelectSchool({
                id: result.data.id,
                name: result.data.name
            } as SchoolSearchDTO); // I should probably fix the input param but I don't care
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Save School",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });
        });
    }

    private handleModalOnCancel() {
        this.setState({ isCreateSchoolVisible: false });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        let { joinCode, selectedSchool } = this.state;

        const tableItemLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 18 },
            xxl: { span: 16 },
        };

        const modalItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                xl: { span: 8 },
                xxl: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 16 },
                xl: { span: 16 },
                xxl: { span: 16 }
            },
        };
        const modalTableLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 24 },
        };

        let isJoinCodeRequired = selectedSchool != null && selectedSchool.joinCodeRequired;
        let isJoinCodeAccepted = selectedSchool != null && joinCode != null && joinCode.toLowerCase() === selectedSchool.id.substring(0, 7).toLowerCase();

        return <div>
            <h1>Update Active School</h1>
            <p>Your current schools are shown in the table below. The highlighted row is your 'primary' or 'current' school. Click the 'Select' button on a school to change your 'primary' school.</p>
            <p>You can add a school from the 'Add School' button and remove them from the table with the 'Remove' button. You will not be able to remove your 'Primary' school without changing it, nor can you remove your last school.</p>

            <Row>
                <Col {...tableItemLayout}>
                    <h2>Schools</h2>
                    <Button onClick={() => this.setState({ isAddSchoolVisible: true })}>Add School</Button>
                    <Table
                        rowKey={record => record.id}
                        bordered
                        rowSelection={{
                            type: "radio",
                            selectedRowKeys: this.state.primarySchool != null ? [this.state.primarySchool.id] : [],
                            // Dev Note: Idk which to use
                            // onSelect: (selected) => this.handleSelect(selected),
                            onChange: (_, records) => this.handleSelectSchool(records[0])
                        }}
                        size="middle"
                        columns={this.tableColumns}
                        dataSource={this.state.tableData}
                        pagination={{
                            showSizeChanger: true,
                            showQuickJumper: true,
                            hideOnSinglePage: true,
                            pageSizeOptions: ["5", "10", "25", "50", "100"],
                            defaultPageSize: 5,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                        }}
                    />
                </Col>
            </Row>

            <Modal
                title="Add School"
                visible={this.state.isAddSchoolVisible}
                okText="Join School"
                cancelText="Cancel"
                okButtonProps={{
                    disabled: isJoinCodeRequired && !isJoinCodeAccepted
                }}
                onOk={this.handleAddSchool}
                onCancel={() => this.setState({ isAddSchoolVisible: false })}
                closable={false}
                width={650}
            >
                <SchoolSearch
                    onSelected={(e) => this.setState({ selectedSchool: e })}
                    formItemLayout={modalItemLayout}
                    tableItemLayout={modalTableLayout}
                />

                {this.state.selectedSchool != null &&
                    <div>
                        <Row style={{ marginBottom: 24 }}>
                            <Col {...modalItemLayout}>
                                <h2>Selected School</h2>
                                <Row justify="center">
                                    <Col span={20}>
                                        <Card title={null} >
                                            <Row>
                                                <Col span={8} style={{ textAlign: "right", paddingRight: 8 }}>
                                                    <p>School:</p>
                                                    <p>Address:</p>
                                                    <p>Teachers:</p>
                                                    {DebugUtil.isDebugEnabled() && <p>Join Code (Debug Only):</p>}
                                                </Col>
                                                <Col span={16}>
                                                    <p>{this.state.selectedSchool.name}</p>
                                                    <p>{this.state.selectedSchool.city}, {this.state.selectedSchool.state} {this.state.selectedSchool.zip}</p>
                                                    <p>{this.state.selectedSchool.teacherCount}</p>
                                                    {DebugUtil.isDebugEnabled() && <p>{this.state.selectedSchool.id.substring(0, 7)}</p>}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {isJoinCodeRequired &&
                            <div>
                                <span>Enter the Join Code provided by a teacher already at this school to join.</span>
                                <FalseFormItem
                                    {...modalItemLayout}
                                    label="Join Code"
                                >
                                    <Input
                                        placeholder="Join Code (7 characters, not case-sensitive)"
                                        onChange={(e) => this.setState({ joinCode: e.target.value })}
                                        maxLength={7}
                                        {...disableAutoCompleteTags()}
                                    />
                                </FalseFormItem>
                            </div>}
                    </div>
                }

                <p>If you do not see your school in the list of schools, click "Create School".</p>
                <Button type="primary" onClick={() => this.setState({ isCreateSchoolVisible: true })} style={{ padding: "4px 10px 4px 8px", marginLeft: 8 }}>Create School</Button>

            </Modal>
            <Modal
                title="Warning! You are about to remove a school"
                visible={this.state.isRemoveSchoolVisible}
                okText={this.state.removeCountDown > 0 ? `${this.state.removeCountDown}` : "Remove"}
                okButtonProps={{
                    disabled: this.state.removeCountDown > 0,
                    danger: true
                }}
                cancelText="Cancel"
                onOk={this.handleRemoveSchoolPart2}
                onCancel={() => this.setState({ isRemoveSchoolVisible: false })}
                closable={false}
                width={650}
            >
                <p>Removing a school will remove your access to that school. This cannot be undone. However, you will be able to rejoin a school if there are no other teacher or using the Join Code.</p>
            </Modal>

            <TeacherSelectMySchoolModal
                isVisible={this.state.isCreateSchoolVisible}
                onSubmit={this.handleModalOnSubmit}
                onCancel={this.handleModalOnCancel}
            />
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        UpdateUserAccount: (request: UpdateAccountDTO) => UserAction.UpdateUserAccount(dispatch, request)
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherEditActiveSchool);
