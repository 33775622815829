import { KeyMapper, Omit, StringNum } from "../models/common/TheBestTypes";

/* Types used to make life easy */
// Remove keys that I do not want on the new type
type T4 = Omit<typeof Routes, "prototype" | "GET">;
// Take those keys and make them all return a string
type T6 = KeyMapper<T4, string>;
type T7 = KeyMapper<Omit<typeof Routes, "prototype" | "GET">, string>;
// TODO: JB - Change type names

/**
 * The route containment class
 */
export class RouteUrl {
    /**
     * Returns the original URL provided before adding in the params
     */
    public readonly originalUrl: string;
    /**
     * Returns the original Params as an object
     */
    public readonly originalParams: { [index: string]: string };

    /**
     * Returns the route generated from the URL and Params
     */
    public readonly toRoute: string;

    /**
     * Creates an instance of RouteUrl
     * @example
     * // Simple
     * RouteUrl("/path/:id", { id });
     *
     * // Complicated. Notice the matching keys and url params
     * RouteUrl("/path/:foo/:bar/:baz?", { foo, bar: id, baz: "something" });
     *
     * @remarks
     * If params are in the URL and **not** passed into the params param, then the route is not parsed and will equal the url passed in
     *
     * @param {string} url The url as a React Router url string, see examples
     * @param {{}} [params] The params for the url as an object. Items must be keyed corresponding to their name in the url
     */
    constructor(url: string, params?: {}) {
        // Remove spaces from url
        this.originalUrl = url.replace(/(\r\n|\n|\r| )/gm, "");
        this.originalParams = params;
        this.toRoute = this.generateRoute();
        // Rick: We might be able to use react-router's 'generatePath()' instead
        // import { generatePath } from "react-router"
    }

    private generateRoute(): string {
        // If we have no params, skip parsing.
        if (this.originalParams == null || this.originalParams === {}) {
            return this.originalUrl;
        }

        let paramKeys = Object.keys(this.originalParams);

        // Purely for readability on the return statement below
        let mapFunction = (split: string) => {
            let isOptional = false;

            // Check if variable or just string
            if (split[0] !== ":") {
                return split;
            }

            // Remove the ':' from the start of the key
            split = split.slice(1);

            // Check if required
            if (split.slice(-1) === "?") {
                isOptional = true;
                split = split.slice(0, -1);
            }

            // Check if params match. If not, optional params just get let through
            let key = paramKeys.find(y => y === split);
            if (key == null && isOptional) {
                return "";
            }

            return this.originalParams[key];
        };

        return "/" + this.originalUrl
            .split("/") // Split into chunks
            .map(mapFunction) // Map params to variables
            .filter(x => x) // Remove empty
            .join("/"); // Join everything back together again
    }
}

export default class Routes {
    /** A transformed list containing all of the routes as strings, rather than functions. Useful for quick access */
    public static GET: T6 = null;

    /* General */
    public static BASE_ROUTE() { return new RouteUrl("/"); }
    public static HOME() { return new RouteUrl("/home"); }
    public static LOGIN() { return new RouteUrl("/login"); }
    public static STUDENT_LOGIN() { return new RouteUrl("/studentLogin"); }
    public static LOGOUT() { return new RouteUrl("/logout"); }
    public static FORGOT_PASSWORD() { return new RouteUrl("/forgot-password"); }
    public static RESET_PASSWORD() { return new RouteUrl("/forgot-password/reset"); }
    public static REGISTER() { return new RouteUrl("/register"); }
    public static REGISTER_CONFERENCE_EDITION() { return new RouteUrl("/register/conference"); }
    public static REGISTER_CONFERENCE_EDITION_CONFIRMATION() { return new RouteUrl("/register/conference/confirmation"); }
    public static ACCOUNT() { return new RouteUrl("/account"); }
    public static EULA() { return new RouteUrl("/end-user-agreement"); }
    public static BETA_USER_AGREEMENT() { return new RouteUrl("/beta-user-agreement"); }
    public static PRIVACY_POLICY() { return new RouteUrl("/privacy-policy"); }
    public static DEMO() { return new RouteUrl("/demo"); }
    public static COURSES() { return new RouteUrl("/courses"); }
    public static COURSE(courseId: StringNum) { return new RouteUrl("/courses/:courseId", { courseId }); }
    public static URL_MASTER() { return new RouteUrl("/url"); }
    public static PASSCODE() { return new RouteUrl("/passcode"); }
    public static PRICING() { return new RouteUrl("/pricing"); }
    public static ABOUT_US() { return new RouteUrl("/about-us"); }

    public static SOCIAL_EMOTIONAL_LEARNING() { return new RouteUrl("/social-emotional-learning"); }
    public static BENEFIT_ME() { return new RouteUrl("/benefit-me"); }
    public static MUSICS_EFFECTIVENESS() { return new RouteUrl("/musics-effectiveness"); }
    /* Admin */
    public static ADMIN_DASHBOARD() { return new RouteUrl("/admin"); }
    public static ADMIN_LICENSES() { return new RouteUrl("/admin/licenses"); }
    public static ADMIN_LICENSE_ADD_EDIT(licenseId?: StringNum) { return new RouteUrl("/admin/license/:licenseId?", { licenseId }); }
    public static ADMIN_DISTRICTS() { return new RouteUrl("/admin/districts"); }
    public static ADMIN_DISTRICT_ADD_EDIT(districtId?: StringNum) { return new RouteUrl("/admin/district/:districtId?", { districtId }); }
    public static ADMIN_SCHOOLS() { return new RouteUrl("/admin/schools"); }
    public static ADMIN_SCHOOL_ADD_EDIT(schoolId?: StringNum) { return new RouteUrl("/admin/school/:schoolId?", { schoolId }); }
    public static ADMIN_COURSES() { return new RouteUrl("/admin/courses"); }
    public static ADMIN_COURSE_ADD_EDIT(courseId?: StringNum) { return new RouteUrl("/admin/course/:courseId?", { courseId }); }
    public static ADMIN_LESSON_ADD_EDIT(courseId: StringNum, lessonId?: StringNum) {
        return new RouteUrl("/admin/course/:courseId/lesson/:lessonId?", { courseId, lessonId });
    }
    public static ADMIN_LESSON_CONTENT_ADD_EDIT(courseId: StringNum, lessonId: StringNum, lessonContentId?: StringNum) {
        return new RouteUrl("/admin/course/:courseId/lesson/:lessonId?/content/:lessonContentId?", { courseId, lessonId, lessonContentId });
    }
    public static ADMIN_QUESTION_ADD_EDIT(courseId: StringNum, lessonId: StringNum, questionId?: StringNum) {
        return new RouteUrl("/admin/course/:courseId/lesson/:lessonId?/questions/:questionId?", { courseId, lessonId, questionId });
    }
    public static ADMIN_QUESTION_ANSWER_ADD_EDIT(courseId: StringNum, lessonId: StringNum, questionId: StringNum, answerId?: StringNum) {
        return new RouteUrl("/admin/course/:courseId/lesson/:lessonId?/questions/:questionId/:answerId?", { courseId, lessonId, questionId, answerId });
    }
    public static ADMIN_USERS() { return new RouteUrl("/admin/users"); }
    public static ADMIN_USER_ADD_EDIT(userId?: StringNum) { return new RouteUrl("/admin/user/:userId?", { userId }); }
    public static ADMIN_COUPONS() { return new RouteUrl("/admin/coupons"); }
    public static ADMIN_COUPON_ADD_EDIT(couponId?: StringNum) { return new RouteUrl("/admin/coupon/:couponId?", { couponId }); }
    public static ADMIN_DEMO(courseId: StringNum, demoId?: StringNum){return new RouteUrl("/admin/demo/:courseId/:demoId?", {courseId, demoId});}

    /* Student */
    public static STUDENT_HOME() { return new RouteUrl("/student"); }
    public static STUDENT_COURSE_HOME(classroomId: StringNum, courseId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/", { classroomId, courseId });
    }

    /* Teacher */
    public static TEACHER_DASHBOARD() { return new RouteUrl("/teacher"); }
    public static TEACHER_STUDENTS() { return new RouteUrl("/teacher/students"); }
    public static TEACHER_CLASSROOMS() { return new RouteUrl("/teacher/classrooms"); }
    public static TEACHER_MY_CLASSROOM(classroomId: StringNum) { return new RouteUrl("/teacher/classrooms/:classroomId/myclassroom", { classroomId }); }

    public static TEACHER_MY_SCHOOL() { return new RouteUrl("/teacher/myschool"); }
    public static TEACHER_SELECT_MY_SCHOOL() { return new RouteUrl("/teacher/myschool/select"); }
    public static TEACHER_ADD_EDIT_MY_SCHOOL(licenseId?: StringNum, shoppingCartItemId?: StringNum) { return new RouteUrl("/teacher/myschool/edit/:licenseId?/:shoppingCartItemId?", { licenseId, shoppingCartItemId }); }

    public static TEACHER_COURSES() { return new RouteUrl("/teacher/courses"); }

    public static TEACHER_ADD_EDIT_STUDENT(studentId?: StringNum) { return new RouteUrl("/teacher/students/edit/:studentId?", { studentId }); }
    public static TEACHER_ADD_EDIT_CLASSROOM(classroomId?: StringNum) { return new RouteUrl("/teacher/classrooms/edit/:classroomId?", { classroomId }); }
    public static TEACHER_EDIT_ACTIVE_SCHOOL() { return new RouteUrl("/teacher/activeSchool"); }


    /* Workflow */
    public static WORKFLOW(courseId: StringNum, lessonId: StringNum, checkpoint?: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/:checkpoint?", { courseId, lessonId, checkpoint });
    }
    public static WORKFLOW_COURSE_APPENDIX(courseId: StringNum) {
        return new RouteUrl("/workflow/:courseId/courseAppendix", { courseId });
    }

    public static WORKFLOW_COURSE_HOME(courseId: StringNum) {
        return new RouteUrl("/workflow/:courseId/", { courseId });
    }
    public static WORKFLOW_HOME(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/home", { courseId, lessonId });
    }
    public static WORKFLOW_LESSON_PLAN(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/lessonplan", { courseId, lessonId });
    }
    public static WORKFLOW_ENTRANCE_TEST(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/entrancetest", { courseId, lessonId });
    }
    public static WORKFLOW_WHITEBOARD(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/whiteboard", { courseId, lessonId });
    }
    public static WORKFLOW_VIDEO(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/video", { courseId, lessonId });
    }
    public static WORKFLOW_DISCUSSION(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/discussion", { courseId, lessonId });
    }
    public static WORKFLOW_DRILL_DOWN(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/drilldown", { courseId, lessonId });
    }
    public static WORKFLOW_EXIT_TEST(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/exittest", { courseId, lessonId });
    }
    public static WORKFLOW_RESULTS(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/results", { courseId, lessonId });
    }
    public static WORKFLOW_SURVEY(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/survey", { courseId, lessonId });
    }
    public static WORKFLOW_SCHOOL_HOME_PARTNERSHIP(courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/schoolhomepartnership", { courseId, lessonId });
    }
    public static WORKFLOW_START_ASSESSMENT(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/startselfassessment", { classroomId, courseId, lessonId });
    }
    public static WORKFLOW_END_ASSESSMENT(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/workflow/:courseId/:lessonId/endselfassessment", { classroomId, courseId, lessonId });
    }

    /* Student Workflow */
    public static STUDENT_WORKFLOW(classroomId: StringNum, courseId: StringNum, lessonId: StringNum, checkpoint?: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/:checkpoint?", { courseId, classroomId, lessonId, checkpoint });
    }

    public static STUDENT_WORKFLOW_HOME(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/home", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_LESSON_PLAN(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/lessonplan", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_START_ASSESSMENT(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/startselfassessment", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_END_ASSESSMENT(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/endselfassessment", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_ENTRANCE_TEST(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/entrancetest", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_WHITEBOARD(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/whiteboard", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_VIDEO(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/video", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_DISCUSSION(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/discussion", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_DRILL_DOWN(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/drilldown", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_EXIT_TEST(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/exittest", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_RESULTS(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/results", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_SURVEY(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/survey", { classroomId, courseId, lessonId });
    }
    public static STUDENT_WORKFLOW_SCHOOL_HOME_PARTNERSHIP(classroomId: StringNum, courseId: StringNum, lessonId: StringNum) {
        return new RouteUrl("/student/:classroomId/:courseId/:lessonId/schoolhomepartnership", { classroomId, courseId, lessonId });
    }

    /* Documentary Workflow */
    public static DOCUMENTARY_HOME(documentaryId: StringNum) {
        return new RouteUrl("/documentary/:documentaryId/", { documentaryId });
    }
    public static DOCUMENTARY_VIDEO(documentaryId: StringNum) {
        return new RouteUrl("/documentary/:documentaryId/video", { documentaryId });
    }
    public static DOCUMENTARY_POST_VIDEO(documentaryId: StringNum) {
        return new RouteUrl("/documentary/:documentaryId/post-video", { documentaryId });
    }

    /* Shopping Cart */
    public static SHOPPING_CART() { return new RouteUrl("/cart"); }

    /* Error Handling */
    public static ERROR_PAGE() { return new RouteUrl("/the-handler"); }
    public static PROD_ERROR_PAGE() { return new RouteUrl("/errors"); }
    public static PAGE_NOT_FOUND() { return new RouteUrl("/404"); }

    /**
     * Static constructor for the static Routes class
     *
     * This **Must** be last. **DO NOT MOVE**
     */
    public static construct = (() => {
        let retrieve = function (key: string): string {
            let RoutesNoAny = Routes as any;
            // If function, run it and assume RouteUrl as a return type. Else, check for string and return its result
            if (typeof RoutesNoAny[key] === 'function') {
                let obj: RouteUrl = RoutesNoAny[key]({});
                if (obj != null) {
                    return obj.originalUrl;
                }
            } else if (typeof RoutesNoAny[key] === 'string') {
                return RoutesNoAny[key];
            } else {
                // Uh
                return "";
            }
        };

        // Keys to be removed. Object functions, prototype and where we are storing the rest
        let spareKeys = ["name", "length", "construct", "prototype", "GET"];

        Routes.GET = Object.getOwnPropertyNames(Routes) // Get all properties including functions
            .filter(x => spareKeys.find(key => key === x) == null) // Remove some of those functions
            .map(x => ({ [x]: retrieve(x) })) // Get the string representation
            .reduce((a, b) => ({ ...a, ...b })) as T6; // Combine array into object
    })();
}
