import * as React from 'react';
import { RouteComponentProps, withRouter, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { AccountController } from '../../api/AccountController';
import { Spin } from 'antd';
import UserAction from '../../redux/UserActions';
import ReduxStoreModel from '../../redux/ReduxModel';
import { AxiosResponse } from 'axios';
import { UserState } from '../../redux/UserReducer';

interface LogoutPageProps extends RouteComponentProps<{}> {
    // From Redux
    User: UserState;
    Logout: () => Promise<AxiosResponse<any>>;
}

interface LogoutPageState {
    isLoading: boolean;
}

class LogoutPage extends React.Component<LogoutPageProps, LogoutPageState> {
    constructor(props: LogoutPageProps) {
        super(props);
        bindAllOfThis(this, LogoutPage.prototype);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        if (this.props.User.state === "finished") {
            this.props.Logout();
        }
    }

    render() {
        if (this.props.User.state === "none") {
            return <Redirect to={Routes.BASE_ROUTE().toRoute} push/>;
        }
        return <div>
            <h1>Logout</h1>
            <p>Please wait while you are being logged out</p>
            <Spin className="spinner-centered very-large-spinner" />
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        Logout: () => UserAction.Logout(dispatch),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
