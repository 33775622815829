import * as React from 'react';
import { Spin } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import bindAllOfThis from "../src/utils/BindThisHelper";
import RouteLoader from '../src/core/ConfigureRouter';
import Routes from './core/Routes';

import 'antd/dist/antd.css';
import './App.scss';

interface AppProps extends RouteComponentProps {
}

interface AppState {
    /**
     * Used for loading data that the user MUST wait for.
     *
     * Examples: Multilingual data, Translations, Roles, Assets, etc.
     */
    isLoading: boolean;
}

/** This class is VERY special. It handles loading react, the routes and any 'on startup' logic */
class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        bindAllOfThis(this, App.prototype);

        // App state
        this.state = {
            isLoading: false,
        };

        // Here you can listen to changes in the route and respond accordingly
        props.history.listen(location => {
            console.log('Route has changed', location);
        });
    }

    // componentDidCatch(e: Error) {
    //     // Errors are stupid and wont stringify properly
    //     var error: string = JSON.stringify({
    //         name: e.name,
    //         message: e.message,
    //         stack: e.stack
    //     });
    //     this.props.history.push({
    //         pathname: Routes.ERROR_PAGE().toRoute,
    //         state: { errors: error }
    //     });
    // }

    render() {
        // Check if loading, render a spinner. You can disable this and remove it entirely for fast load times
        if (this.state.isLoading) {
            return <div className="App">
                <Spin className="spinner-centered very-large-spinner" />
            </div>;
        }

        // Render the app by loading the router. This includes layouts and other such logic
        return <div className="App">
            {RouteLoader}
        </div>;
    }
}

export default withRouter(App);
