import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip, Button, Modal, notification, Row, Col } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import ErrorDTO from '../../models/common/ErrorDTO';
import { LessonController } from '../../api/LessonController';
import { QuestionDTO } from '../../models/question/questionDTO';
import { LessonContentDTO } from '../../models/lessonContent/lessonContentDTO';

export interface AdminCourseLessonTableDataObject {
    id: string;
    name: string;
    ordering: number;
    studentQuestions: QuestionDTO[];
    parentQuestions: QuestionDTO[];
    lessonContents: LessonContentDTO[];

    // Manages Error handling
    hasQuestionsError: boolean;
    hasLessonContentsError: boolean;
}

interface AdminCourseLessonTableProps {
    tableData: AdminCourseLessonTableDataObject[];
    isLoading: boolean;
    isSubmitting: boolean;
    courseId: string;
    onLessonUpdate: (record: AdminCourseLessonTableDataObject) => void;
}

interface AdminCourseLessonTableState {
}

class AdminCourseLessonTable extends React.Component<AdminCourseLessonTableProps, AdminCourseLessonTableState> {
    private notificationKey = "AdminCourseLessonTable";
    private tableColumns: ColumnProps<AdminCourseLessonTableDataObject>[];
    constructor(props: AdminCourseLessonTableProps) {
        super(props);
        bindAllOfThis(this, AdminCourseLessonTable.prototype);

        this.tableColumns = [{
            title: 'Name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<AdminCourseLessonTableDataObject>("name", "Name"),
            render: (text, record) => {
                let message = [
                    record.hasLessonContentsError ? "Missing Lesson Content" : null,
                    record.hasQuestionsError ? "Question Errors" : null
                ].filter(x => x).join(", ");

                return <Row  justify="space-between">
                    <Col>{record.name}</Col>
                    <Col>{message.length > 0 ? <span className="table-row-error">{message}</span> : null}</Col>
                </Row>;
            }
        }, {
            title: 'Media Count',
            dataIndex: 'lessonContents.length',
            key: 'lessonContents',
            width: 110,
            sorter: (a, b) => a.ordering - b.ordering,
        }, {
            title: 'Student Questions',
            dataIndex: 'studentQuestions.length',
            key: 'studentQuestions',
            width: 110,
            sorter: (a, b) => a.ordering - b.ordering,
        }, {
            title: 'Parent Questions',
            dataIndex: 'parentQuestions.length',
            key: 'parentQuestions',
            width: 110,
            sorter: (a, b) => a.ordering - b.ordering,
        }, {
            title: 'Order',
            dataIndex: 'ordering',
            defaultSortOrder: 'ascend',
            key: 'ordering',
            width: 80,
            sorter: (a, b) => a.ordering - b.ordering,
        }, {
            title: 'Change Order',
            key: 'swap',
            width: 120,
            render: (text, record) => {
                let up = <Button key="swap-up" shape="circle" icon={<UpOutlined />} onClick={() => this.handleMoveUp(record)} disabled={this.props.isSubmitting} />;
                let down = <Button key="swap-down" shape="circle" icon={<DownOutlined />} onClick={() => this.handleMoveDown(record)} disabled={this.props.isSubmitting} />;
                let empty = <span key="swap-empty" style={{ width: "32px", display: "inline-block" }}>&nbsp;</span>;
                if (record.ordering === 1) {
                    return [empty, down];
                }
                return [up, down];
            }
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit Lesson" placement="top">
                        <Link to={Routes.ADMIN_LESSON_ADD_EDIT(this.props.courseId, record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Delete Content" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleOnRemove(record)} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
        };
    }

    handleMoveUp(record: AdminCourseLessonTableDataObject) {
        this.props.onLessonUpdate({
            ...record,
            ordering: record.ordering - 1
        });
    }

    handleMoveDown(record: AdminCourseLessonTableDataObject) {
        this.props.onLessonUpdate({
            ...record,
            ordering: record.ordering + 1
        });
    }

    handleOnRemove(record: AdminCourseLessonTableDataObject) {
        // Ask if they are sure
        Modal.confirm({
            title: "Are you sure?",
            content: `Are you sure you want to delete this lesson? "${record.name}"`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                LessonController.DeleteLesson(record.id).then(result => {
                    notification.success({
                        key: this.notificationKey,
                        message: "Lesson Removal",
                        description: `The Lesson "${record.name}" has been removed`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });

                    this.props.onLessonUpdate(null);
                }).catch(error => {
                    let messages = error != null && error.response != null && error.response.data.messages != null
                        ? (error.response.data as ErrorDTO).messages
                        : ["Critical Error"];
                    notification.error({
                        key: this.notificationKey,
                        message: "Lesson Content Removal",
                        description: ["There was an issue removing the Lesson Content.", ...messages].map(x => <div>{x}</div>),
                        duration: 10
                    });
                });
            }
        });
    }

    render() {
        return <div>
            <Table
                loading={this.props.isLoading}
                rowKey={record => record.id}
                size="small"
                columns={this.tableColumns}
                dataSource={this.props.tableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: false,
                    defaultPageSize: 20,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default AdminCourseLessonTable;
