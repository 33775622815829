import * as React from 'react';
import { Button, Col, Row, Select, Spin, Table } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../utils/BindThisHelper';
import { NameOf } from '../utils/NameOf';
import { getColumnSearchProps } from '../components/tableHelpers/getColumnSearchProps';
import { ColumnProps } from 'antd/lib/table';

import { SchoolController } from '../api/SchoolController';
import { SchoolSearchDTO } from '../models/school/schoolSearchDTO';
import { SchoolSearchRequestDTO } from '../models/school/schoolSearchRequestDTO';
import { StatesAsList } from '../constants/States';
import { LabeledValue } from 'antd/lib/select';
import { IsNullOrEmpty, ToTitleCase, TrimExtraSpaces } from '../utils/StringUtils';
import FalseFormItem from './FalseFormItem';
import { ColProps, ColSize } from 'antd/lib/col';
import ObjectUtil from '../utils/ObjectUtil';
import DebugUtil from '../utils/DebugUtil';

interface SchoolSearchProps {
    /** Called when the school search is triggered, populating the table */
    onSearch?: (data: SchoolSearchDTO[]) => void;
    onSelected?: (data: SchoolSearchDTO) => void;
    showTable?: boolean;
    initialValue?: SchoolSearchDTO;

    formItemLayout?: {
        labelCol?: ColProps,
        wrapperCol?: ColProps
    };
    tableItemLayout?: ColProps;
}

interface SchoolSearchState {
    selectedSchool?: SchoolSearchDTO;
    tableData: SchoolSearchDTO[];
    hasSearched: boolean;

    // Text that the user has entered. Temporary and are cleared when one option is selected
    stateSearch: string;
    citySearch: string;
    schoolSearch: string;

    // These are for the text that is selected, not just the searched string
    stateSelectedText: string;
    citySelectedText: string;
    schoolSelectedText: string;

    isLoadingStateData: boolean;
    isLoadingCityData: boolean;
    isLoadingSchoolData: boolean;
    isLoadingTableData: boolean;

    stateData: LabeledValue[];
    cityData: LabeledValue[];
}

class SchoolSearch extends React.Component<SchoolSearchProps, SchoolSearchState> {
    private tableColumns: ColumnProps<SchoolSearchDTO>[];
    private defaults: Partial<SchoolSearchProps> = {
        showTable: true,
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                lg: { span: 6 },
                xl: { span: 6 },
                xxl: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 14 },
                lg: { span: 13 },
                xl: { span: 12 },
                xxl: { span: 8 }
            },
        },
        tableItemLayout: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 18 },
            xxl: { span: 16 },
        }
    };

    constructor(props: SchoolSearchProps) {
        super(props);
        bindAllOfThis(this, SchoolSearch.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: NameOf<SchoolSearchDTO>("name"),
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<SchoolSearchDTO>("name", "Name"),
        }, {
            title: 'Address',
            dataIndex: NameOf<SchoolSearchDTO>("address"),
            sorter: (a, b) => a.address.localeCompare(b.address),
            ...getColumnSearchProps<SchoolSearchDTO>("address", "Address"),
        }, {
            title: 'Teachers',
            key: NameOf<SchoolSearchDTO>("teacherCount"),
            width: 120,
            sorter: (a, b) => a.teacherCount - b.teacherCount,
            ...getColumnSearchProps<SchoolSearchDTO>("teacherCount", "Teacher Count"),
            render: (text: any, record: SchoolSearchDTO) => {
                if (record.teacherCount != null && record.teacherCount > 0) {
                    return <span>{record.teacherCount}</span>;
                }
                return null;
            }
        }, {
            title: '',
            key: 'select',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Button type="link" onClick={() => this.handleSelect(record)}>Select</Button>
                );
            }
        }];
        if (DebugUtil.isDebugEnabled()) {
            this.tableColumns.push({
                title: <span>Id (<b style={{ color: 'red' }}>Debug</b>)</span>,
                dataIndex: NameOf<SchoolSearchDTO>("id"),
            });
        }

        this.state = {
            selectedSchool: null,
            tableData: [],
            hasSearched: false,
            stateSearch: "",
            citySearch: "",
            schoolSearch: "",
            stateSelectedText: "",
            citySelectedText: "",
            schoolSelectedText: "",
            isLoadingStateData: false,
            isLoadingCityData: false,
            isLoadingSchoolData: false,
            isLoadingTableData: false,
            stateData: [],
            cityData: [],
        };
    }

    componentDidMount() {
        this.fetchStateData();

        // SO HACKY 😂😂😂
        if (!!this.props.initialValue) {
            this.setState({
                selectedSchool: this.props.initialValue,
                citySelectedText: this.props.initialValue.city,
                schoolSelectedText: this.props.initialValue.name
            });
            this.handleSearch();
        }
    }

    async fetchStateData() {
        // BL: States have always been... complicated. We stored them as abbreviations in the db. Idk why, but we did
        // So, when we send a request off to the db, we need to make sure that we send the abbreviation if it matches as state
        // However, we need to load user entered countries and territories, so that will be exciting

        this.setState({ isLoadingStateData: true });

        try {
            let response = await SchoolController.GetStates();

            // Add the state list we already have, matching against what is pulled back
            let stateData = response.data
                .sort()
                .map<LabeledValue>(result => {
                    let match = StatesAsList.find((value) => value.id.toUpperCase() === result.toUpperCase());
                    return match != null
                        ? { key: match.id, label: match.label, value: match.id }
                        : { key: result, label: result, value: result };
                });

            this.setState({
                stateData,
                isLoadingStateData: false
            });
        } catch (error) {
            this.setState({
                isLoadingStateData: false
            });
        }
    }

    async fetchCityData(e: LabeledValue) {
        this.setState({ isLoadingCityData: true });

        try {
            let response = await SchoolController.GetFindCitiesByState(e.value.toString());

            // Ensure the data is unique and in proper form
            let cityData = Array.from(new Set(response.data.map(TrimExtraSpaces).map(ToTitleCase)))
                .sort()
                .map<LabeledValue>(result => ({ key: result, label: result, value: result }));

            this.setState({
                cityData,
                isLoadingCityData: false
            });
        } catch (error) {
            this.setState({
                isLoadingCityData: false
            });
        }
    }

    async handleSearch() {
        this.setState({ isLoadingTableData: true });
        let { stateSelectedText, citySelectedText, schoolSelectedText } = this.state;
        let { onSearch } = this.props;

        let request: SchoolSearchRequestDTO = {
            state: stateSelectedText,
            city: citySelectedText,
            school: schoolSelectedText
        };

        try {
            let response = await SchoolController.PostFindSchoolsByDTO(request);

            // Dev Note: There is no address in the DB. Instead, we are going to hack it for searching purposes
            let tableData = response.data.map(record => ({ ...record, address: `${record.city}, ${record.state} ${record.zip}` }));
            this.setState({
                tableData,
                isLoadingTableData: false,
                selectedSchool: null,
                hasSearched: true
            });
            onSearch && onSearch(tableData);
        } catch (error) {
            this.setState({
                isLoadingTableData: false
            });
        }
    }

    handleSelect(selected: SchoolSearchDTO) {
        let { onSelected } = this.props;
        this.setState({ selectedSchool: selected });
        onSelected && onSelected(selected);
    }

    render() {
        let { stateSearch, citySearch, schoolSearch, stateData, cityData, stateSelectedText, citySelectedText, schoolSelectedText } = this.state;
        let { formItemLayout, tableItemLayout } = ObjectUtil.DeepMergeObjects(this.defaults, this.props);

        // Dev Note: Typecasting is frustrating sometimes
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: (formItemLayout.wrapperCol.xs as ColSize).span, offset: (formItemLayout.labelCol.xs as ColSize).span as number % 24 },
                sm: { span: (formItemLayout.wrapperCol.sm as ColSize).span, offset: (formItemLayout.labelCol.sm as ColSize).span as number % 24 },
                md: { span: (formItemLayout.wrapperCol.md as ColSize).span, offset: (formItemLayout.labelCol.md as ColSize).span as number % 24 },
                lg: { span: (formItemLayout.wrapperCol.lg as ColSize).span, offset: (formItemLayout.labelCol.lg as ColSize).span as number % 24 },
                xl: { span: (formItemLayout.wrapperCol.xl as ColSize).span, offset: (formItemLayout.labelCol.xl as ColSize).span as number % 24 },
                xxl: { span: (formItemLayout.wrapperCol.xxl as ColSize).span, offset: (formItemLayout.labelCol.xxl as ColSize).span as number % 24 },
            },
        };

        let isSearchDisabled = IsNullOrEmpty(stateSelectedText) || (IsNullOrEmpty(citySelectedText) && IsNullOrEmpty(schoolSelectedText));

        let stateOptions = [...stateData];
        if (!IsNullOrEmpty(stateSearch)) {
            // Check if the state matches, either abbreviation or startsWith, putting it first
            // This is a bit more complicated since the states are complicated, see the fetchStates() for more
            let foundIndex = -1;

            // Search for abbreviation if we have 2 characters
            if (stateSearch.length === 2) {
                foundIndex = stateOptions.findIndex(x => x.label.toString().indexOf(`(${stateSearch.toUpperCase()})`) >= 0);
            }

            // Not found? Search collection for startsWith
            if (foundIndex < 0) {
                foundIndex = stateOptions.findIndex(x => x.label.toString().toLowerCase().indexOf(stateSearch.toLowerCase()) === 0);
            }

            // If anything found above, move to start. Otherwise, add search text to top
            if (foundIndex >= 0) {
                let foundItems = stateOptions.splice(foundIndex, 1);
                stateOptions = [...foundItems, { label: stateSearch, value: stateSearch }, ...stateOptions];
            } else {
                stateOptions = [{ label: stateSearch, value: stateSearch }, ...stateOptions];
            }
        }

        let cityOptions = [...cityData];
        if (!IsNullOrEmpty(citySearch)) {
            let foundIndex = cityOptions.findIndex(x => x.label.toString().toLowerCase().indexOf(citySearch.toLowerCase()) === 0);
            if (foundIndex >= 0) {
                let foundItems = cityOptions.splice(foundIndex, 1);
                cityOptions = [...foundItems, { label: citySearch, value: citySearch }, ...cityOptions];
            } else {
                cityOptions = [{ label: citySearch, value: citySearch }, ...cityOptions];
            }
        }

        let schoolOptions: LabeledValue[] = [];
        if (!IsNullOrEmpty(schoolSearch)) {
            schoolOptions.push({ label: schoolSearch, value: schoolSearch });
        }

        return <div>
            <FalseFormItem
                {...formItemLayout}
                label="State/Province/Country"
            >
                <Select
                    labelInValue
                    placeholder="Select State"
                    notFoundContent={this.state.isLoadingStateData ? <Spin size="small" /> : null}
                    allowClear
                    showSearch
                    onSearch={(e) => this.setState({ stateSearch: e })}
                    onChange={(e: LabeledValue) => {
                        let selectedText = e != null ? e.value.toString() : "";
                        this.setState({ stateSearch: "", stateSelectedText: selectedText });
                        if (e == null) {
                            // We cleared the input, so refreshing is not needed
                            return;
                        }
                        this.fetchCityData(e);
                    }}
                    filterOption={(input, option) =>
                        option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    loading={this.state.isLoadingStateData}
                    style={{ width: '100%' }}
                    options={stateOptions}
                />
            </FalseFormItem>

            <FalseFormItem
                {...formItemLayout}
                label="City"
            >
                <Select
                    labelInValue
                    placeholder="Select City"
                    notFoundContent={this.state.isLoadingCityData ? <Spin size="small" /> : null}
                    allowClear
                    showSearch
                    onSearch={(e) => this.setState({ citySearch: e })}
                    onChange={(e: LabeledValue) => {
                        let selectedText = e != null ? e.value.toString() : "";
                        this.setState({ citySearch: "", citySelectedText: selectedText });
                    }}
                    filterOption={(input, option) =>
                        option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    loading={this.state.isLoadingCityData}
                    style={{ width: '100%' }}
                    options={cityOptions}
                />
            </FalseFormItem>

            <FalseFormItem
                {...formItemLayout}
                label="School"
            >
                <Select
                    labelInValue
                    placeholder="Select School"
                    notFoundContent={this.state.isLoadingSchoolData ? <Spin size="small" /> : null}
                    allowClear
                    showSearch
                    onSearch={(e) => this.setState({ schoolSearch: e })}
                    onChange={(e: LabeledValue) => {
                        let selectedText = e != null ? e.value.toString() : "";
                        this.setState({ schoolSearch: "", schoolSelectedText: selectedText });
                    }}
                    filterOption={(input, option) =>
                        option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    loading={this.state.isLoadingSchoolData}
                    style={{ width: '100%' }}
                    options={schoolOptions}
                />
            </FalseFormItem>

            <FalseFormItem
                {...tailFormItemLayout}
            >
                <Row justify="end">
                    <Col>
                        <Button type="primary" className="login-form-button" size="large" disabled={isSearchDisabled} loading={this.state.isLoadingTableData} onClick={this.handleSearch}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </FalseFormItem>

            {this.state.hasSearched &&
                <Row>
                    <Col {...tableItemLayout}>
                        <h2>Schools</h2>
                        <Table
                            rowKey={record => record.id}
                            bordered
                            rowSelection={{
                                type: "radio",
                                selectedRowKeys: this.state.selectedSchool != null ? [this.state.selectedSchool.id] : [],
                                onSelect: (selected) => this.handleSelect(selected),
                                onChange: (_, records) => this.handleSelect(records[0])
                            }}
                            size="middle"
                            loading={this.state.isLoadingTableData}
                            columns={this.tableColumns}
                            dataSource={this.state.tableData}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                hideOnSinglePage: true,
                                pageSizeOptions: ["5", "10", "25", "50", "100"],
                                defaultPageSize: 5,
                                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                            }}
                        />
                    </Col>
                </Row>
            }
        </div>;
    }
}

export default SchoolSearch;
