import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip, Button, notification, Modal, Row, Col } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { QuestionController } from '../../api/QuestionController';
import ErrorDTO from '../../models/common/ErrorDTO';
import { QuestionAnswerDTO } from '../../models/questionAnswer/questionAnswerDTO';

export interface AdminLessonQuestionTableDataObject {
    id: string;
    text: string;
    ordering: number;
    questionAnswers: QuestionAnswerDTO[];

    // Manages Error handling
    hasQuestionOrderError: boolean;
    hasMissingCorrectAnswerError: boolean;
}

interface AdminLessonQuestionTableProps {
    tableData: AdminLessonQuestionTableDataObject[];
    isLoading: boolean;
    isSubmitting: boolean;
    courseId: string;
    lessonId: string;
    onQuestionUpdate: (record: AdminLessonQuestionTableDataObject) => void;
}

interface AdminLessonQuestionTableState {
}

class AdminLessonQuestionTable extends React.Component<AdminLessonQuestionTableProps, AdminLessonQuestionTableState> {
    private notificationKey = "AdminLessonQuestionTable";
    private tableColumns: ColumnProps<AdminLessonQuestionTableDataObject>[];
    constructor(props: AdminLessonQuestionTableProps) {
        super(props);
        bindAllOfThis(this, AdminLessonQuestionTable.prototype);

        this.tableColumns = [{
            title: 'Question Text',
            key: 'text',
            sorter: (a, b) => a.text.localeCompare(b.text),
            ...getColumnSearchProps<AdminLessonQuestionTableDataObject>("text", "Text"),
            render: (text, record) => {
                let message = [
                    record.hasMissingCorrectAnswerError ? "No Correct Answer" : null,
                    record.hasQuestionOrderError ? "Duplicate Order" : null
                ].filter(x => x).join(", ");

                return <Row  justify="space-between">
                    <Col>{record.text}</Col>
                    <Col>{message.length > 0 ? <span className="table-row-error">{message}</span> : null}</Col>
                </Row>;
            }
        }, {
            title: 'Order',
            dataIndex: 'ordering',
            defaultSortOrder: 'ascend',
            key: 'ordering',
            width: 80,
            sorter: (a, b) => a.ordering - b.ordering,
        }, {
            title: 'Change Order',
            key: 'swap',
            width: 120,
            render: (text, record) => {
                let up = <Button key="swap-up" shape="circle" icon={<UpOutlined />} onClick={() => this.handleMoveUp(record)} disabled={this.props.isSubmitting} />;
                let down = <Button key="swap-down" shape="circle" icon={<DownOutlined />} onClick={() => this.handleMoveDown(record)} disabled={this.props.isSubmitting} />;
                let empty = <span key="swap-empty" style={{ width: "32px", display: "inline-block" }}>&nbsp;</span>;
                if (record.ordering === 1) {
                    return [empty, down];
                }
                return [up, down];
            }
        }, {
            title: 'Edit',
            key: 'edit',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Edit Question" placement="top">
                        <Link to={Routes.ADMIN_QUESTION_ADD_EDIT(this.props.courseId, this.props.lessonId, record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }, {
            title: 'Delete',
            key: 'delete',
            width: 60,
            align: 'center',
            render: (text, record) => {
                return (
                    <Tooltip title="Delete Question" placement="top">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleOnRemove(record)} />
                    </Tooltip>
                );
            }
        }];

        this.state = {
        };
    }

    handleMoveUp(record: AdminLessonQuestionTableDataObject) {
        this.props.onQuestionUpdate({
            ...record,
            ordering: record.ordering - 1
        });
    }

    handleMoveDown(record: AdminLessonQuestionTableDataObject) {
        this.props.onQuestionUpdate({
            ...record,
            ordering: record.ordering + 1
        });
    }

    handleOnRemove(record: AdminLessonQuestionTableDataObject) {
        // Ask if they are sure
        Modal.confirm({
            title: "Are you sure?",
            content: `Are you sure you want to delete this question? "${record.text}"`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                return QuestionController.DeleteQuestion(record.id).then(result => {
                    notification.success({
                        key: this.notificationKey,
                        message: "Question Removal",
                        description: `The Question "${record.text}" has been removed`,
                        duration: 10,
                        onClick: () => notification.close(this.notificationKey)
                    });
                    this.props.onQuestionUpdate(null);
                }).catch(error => {
                    let messages = error != null && error.response != null && error.response.data.messages != null
                        ? (error.response.data as ErrorDTO).messages
                        : ["Critical Error"];
                    notification.error({
                        key: this.notificationKey,
                        message: "Question Removal",
                        description: ["There was an issue removing the Lesson Content.", ...messages].map(x => <div>{x}</div>),
                        duration: 10
                    });
                    this.props.onQuestionUpdate(null);
                });
            }
        });
    }

    render() {
        return <div>
            <Table
                loading={this.props.isLoading}
                rowKey={record => record.id}
                size="small"
                columns={this.tableColumns}
                dataSource={this.props.tableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default AdminLessonQuestionTable;
