import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Radio, Table, Spin, Input } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import { LessonContentDTO } from '../../models/lessonContent/lessonContentDTO';
import { Roles } from '../../constants/Roles';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';
import IframeGoogleDocs from '../../utils/IframeGoogleDocs';

interface WorkflowDiscussionPageProps extends PageProps<{ classroomId?: string, courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    SetButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
    SetOnNextCallback: (func: () => Promise<string>) => void;
}

interface WorkflowDiscussionPageState {
    lesson: LessonDTO;
    lessonContent: LessonContentDTO;
    discussionResponse: string;
    isLoading: boolean;
}

class WorkflowDiscussionPage extends React.Component<WorkflowDiscussionPageProps, WorkflowDiscussionPageState> {
    constructor(props: WorkflowDiscussionPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowDiscussionPage.prototype);

        this.state = {
            lesson: null,
            lessonContent: null,
            discussionResponse: "",
            isLoading: true,
        };
    }

    componentDidMount() {
        let lesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        let lessonContent = (lesson.lessonContents || []).find(x => x.lessonContentTypeId === LessonContentTypes.DiscussionQuestions.id);

        // Discussion Response™ is hidden on the Student Checkpoint
        let studentCheckpoint = this.props.match.params.classroomId != null
            ? this.props.Workflow.studentCheckpoints.find(x => x.classroomId === this.props.match.params.classroomId && x.lessonId === lesson.id)
            : null;
        let discussionResponse = studentCheckpoint != null ? studentCheckpoint.discussionQuestionResponse : null;

        this.setState({
            lesson: lesson,
            lessonContent: lessonContent,
            discussionResponse: discussionResponse,
            isLoading: false
        });
        this.figureOutOnNext();
        this.props.SetOnNextCallback(this.onNextCallback);
    }

    onNextCallback(): Promise<string> {
        return Promise.resolve(this.state.discussionResponse);
    }

    figureOutOnNext() {
        this.props.SetButtonState("visible", "visible");
    }

    onResponseChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ discussionResponse: e.target.value });
    }

    renderDebugInfo() {
        if (DebugUtil.isDebugEnabled() && this.state.lessonContent != null) {
            return <div>Content Url: {this.state.lessonContent.url}</div>;
        }
        return null;
    }

    renderPDF() {
        if (this.state.lessonContent == null) {
            return <h3>There was an error loading the lesson content</h3>;
        }

        let url = encodeURI(EncodeSpecialCharacters(this.state.lessonContent.url));    

        // Work derived from several places including this one: https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be
        // https://docs.google.com/viewer
        // <iframe src="https://docs.google.com/gview?url={magical url that works}"></iframe>
        // <iframe src="https://docs.google.com/viewer?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true" style="width:600px; height:500px;" frameborder="0"></iframe>
        // <iframe src="https://drive.google.com/viewerng/viewer?url=http://docs.google.com/fileview?id=0B5ImRpiNhCfGZDVhMGEyYmUtZTdmMy00YWEyLWEyMTQtN2E2YzM3MDg3MTZh&hl=en&pid=explorer&efh=false&a=v&chrome=false&embedded=true" frameborder="0"></iframe>       

        return <IframeGoogleDocs srcUrl={url}></IframeGoogleDocs>;
       
    }

    renderDiscussionAnswerBox() {
        if (this.state.lessonContent == null) {
            return;
        }

        return <React.Fragment>
            <h3>Discussion Question Answers</h3>
            <Input.TextArea
                placeholder="Enter your answer here"
                // autosize={{ minRows: 3, maxRows: 6 }}
                value={this.state.discussionResponse}
                onChange={this.onResponseChange}
            />
        </React.Fragment>;
    }

    renderTeacherHelperText() {
        if ([Roles.Teacher, Roles.Admin].includes(this.props.User.role)) {
            // BUSINESS NOTE: This is the only place in the app as of 7/24/19 that does any logic with gradeStart. This is intentional
            if (this.props.Workflow.currentCourse.gradeLevelStart <= 5) {
                return <p className="teacher-helper-text">Ask the class each individual discussion question. Call on multiple students to answer each question to get multiple points of view on each of the questions. There is an answers box at the bottom of the page if students would like to write any answers.</p>;
            }
            return <p className="teacher-helper-text">Split your class into small groups and assign each group a question to discuss. Once students have been given ample time to discuss, have each group share with the class their answers for their question and have the large group discuss further each question.</p>;
        }
        return null;
    }

    renderStudentHelperText() {
        if ([Roles.Student, Roles.Parent].includes(this.props.User.role)) {
            // BUSINESS NOTE: This is the only place in the app as of 7/24/19 that does any logic with gradeStart. This is intentional
            if (this.props.Workflow.currentCourse.gradeLevelStart <= 5) {
                return <p className="student-helper-text">Discuss the questions below with your classmates. There is a box below for you to write your answers in.</p>;
            }
            return <p className="student-helper-text">Discuss the questions below with your small group. After you have had time to discuss with your small group, you will discuss further with the large group.</p>;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            <h1>Discussion Questions</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderTeacherHelperText()}
            {this.renderStudentHelperText()}
            {this.renderDebugInfo()}
            {this.renderPDF()}
            {this.renderDiscussionAnswerBox()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        SetButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
        SetOnNextCallback: (func: () => Promise<string>) => WorkflowAction.SetOnNextCallback(dispatch, func),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDiscussionPage);
