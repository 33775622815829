import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import bindAllOfThis from '../../../utils/BindThisHelper';
import { NameOf } from '../../../utils/NameOf';
import { FieldDecorator } from '../../../components/formHelpers';
import { StudentLoginDTO } from '../../../models/account/studentLoginDTO';
import { disableAutoCompleteTags } from '../../../utils/AutoComplete';

export interface StudentLoginFormDataObject {
    passcode: string;
}

interface StudentLoginFormProps extends FormComponentProps {
    onSubmit: (data: StudentLoginDTO) => void;
    isSubmitting: boolean;
}

interface StudentLoginFormState {
}

class StudentLoginForm extends React.Component<StudentLoginFormProps, StudentLoginFormState> {
    constructor(props: StudentLoginFormProps) {
        super(props);
        bindAllOfThis(this, StudentLoginForm.prototype);

        this.state = {
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: StudentLoginDTO): void => {
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        return <Form layout="vertical" onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <FieldDecorator
                form={this.props.form}
                name={NameOf<StudentLoginFormDataObject>("passcode")}
                rules={[{ required: true, message: "Passcode is required." }]}
            >
                <Input size="large" placeholder="Passcode" {...disableAutoCompleteTags()} />
            </FieldDecorator>
            <Row>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    Login
                </Button>
            </Row>
        </Form>;
    }
}

export default Form.create<StudentLoginFormProps>()(StudentLoginForm);
