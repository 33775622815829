import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Select, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Moment } from 'moment';
import bindAllOfThis from '../../utils/BindThisHelper';
import { FieldDecorator } from '../../components/formHelpers';
import { NameOf } from '../../utils/NameOf';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import { SimpleSchoolDTO } from '../../models/school/simpleSchoolDTO';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { DocumentaryDTO } from '../../models/documentary/documentaryDTO';
import SchoolSearch from '../../components/SchoolSearch';
import { SchoolSearchDTO } from '../../models/school/schoolSearchDTO';
import { connect } from 'react-redux';
import ReduxStoreModel from '../../redux/ReduxModel';
import { ConstantState } from '../../redux/ConstantsReducer';
import { LabeledValue } from 'antd/lib/select';

export interface AdminLicenseDocumentaryFormDataObject {
    id?: string;
    school: KeyLabel;
    documentary: KeyLabel;
    startDate: Moment;
}

interface AdminLicenseDocumentaryFormProps extends FormComponentProps {
    onSubmit: (data: AdminLicenseDocumentaryFormDataObject) => void;
    initialValues?: Partial<AdminLicenseDocumentaryFormDataObject>;

    // From Redux
    Constants: ConstantState;
}

interface AdminLicenseDocumentaryFormState {
    isEdit: boolean;

    // School Filtering
    filteredSchools: SimpleSchoolDTO[];

    // Course Filtering
    filteredDocumentaries: DocumentaryDTO[];

    selectedSchool: SchoolSearchDTO;
    selectedDocumentary: DocumentaryDTO;

    documentaries: DocumentaryDTO[];

    isLoadingSchools: boolean;
}

class AdminLicenseDocumentaryForm extends React.Component<AdminLicenseDocumentaryFormProps, AdminLicenseDocumentaryFormState> {
    // Just need to initialize sub objects
    constructor(props: AdminLicenseDocumentaryFormProps) {
        super(props);
        bindAllOfThis(this, AdminLicenseDocumentaryForm.prototype);

        let isEdit = props.initialValues != null && props.initialValues.id != null;
        let selectedSchool = !!props.initialValues && !!props.initialValues.school
            ? { id: props.initialValues.school.key, name: props.initialValues.school.label } as SchoolSearchDTO
            : null;
        let selectedDocumentary = props.initialValues != null
            ? { id: props.initialValues.documentary.key, name: props.initialValues.documentary.label } as DocumentaryDTO
            : null;

        this.state = {
            isEdit: isEdit,
            filteredSchools: [],
            filteredDocumentaries: [],
            selectedSchool: selectedSchool,
            selectedDocumentary,
            documentaries: props.Constants.documentaries,
            isLoadingSchools: true
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminLicenseDocumentaryFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;

            // Entering the 'SO HACKY' area
            values.documentary = { key: this.state.selectedDocumentary.id, label: this.state.selectedDocumentary.name };
            values.school = { key: this.state.selectedSchool.id, label: this.state.selectedSchool.name };

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                xl: { span: 3 },
                xxl: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                xl: { span: 10 },
                xxl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
                xxl: { span: formItemLayout.wrapperCol.xxl.span, offset: formItemLayout.labelCol.xxl.span % 24 },
            },
        };

        const { initialValues } = this.props;

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark>
            <SchoolSearch
                onSelected={(e) => this.setState({ selectedSchool: e })}
                formItemLayout={formItemLayout}
                tableItemLayout={tailFormItemLayout.wrapperCol}
                initialValue={initialValues && initialValues.school ? { id: initialValues.school.key, name: initialValues.school.label } as SchoolSearchDTO : undefined}
            />

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Documentary"
                name={NameOf<AdminLicenseDocumentaryFormDataObject>("documentary")}
                initialValue={initialValues && initialValues.documentary ? initialValues.documentary.label : undefined}
                rules={[{ required: true, message: "Documentary is required." }]}
            >
                <Select
                    labelInValue
                    placeholder="Select Documentary"
                    allowClear
                    showSearch
                    onChange={(e: LabeledValue) => {
                        this.setState({ selectedDocumentary: this.state.documentaries.find(x => x.id === e.key) });
                    }}
                    filterOption={(input, option) =>
                        option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    options={this.state.documentaries.map(x => ({ key: x.id, label: x.name, value: x.id } as LabeledValue))}
                />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Start Date"
                name={NameOf<AdminLicenseDocumentaryFormDataObject>("startDate")}
                initialValue={initialValues ? initialValues.startDate : undefined}
                rules={[{ required: true, message: "Start Date is required." }]}
            >
                <DatePicker size="large" placeholder="Start Date" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                    {this.state.isEdit ? "Save License" : "Add License"}
                </Button>
            </Form.Item>
        </Form>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Constants: state.Constants,
    };
}

export default connect(mapStateToProps)(Form.create<AdminLicenseDocumentaryFormProps>()(AdminLicenseDocumentaryForm));
