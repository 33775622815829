import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import ReduxStoreModel from '../redux/ReduxModel';
import { Form } from '@ant-design/compatible';
import { Button, Input, Modal } from 'antd';
import Routes from '../core/Routes';
import { disableAutoCompleteTags } from '../utils/AutoComplete';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { ConstantsDTO } from '../models/constant/constantsDTO';
import { CouponDTO } from '../models/coupon/couponDTO';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { FieldDecorator } from '../components/formHelpers';
import { ShoppingCartItemDTO } from '../models/shoppingCart/shoppingCartItemDTO';
import { convertCentsToDollars } from './ShoppingCartItem';
import bindAllOfThis from '../utils/BindThisHelper';
import { UserState } from '../redux/UserReducer';

export interface ShoppingCartFormDataObject {
  paymentAmountInCents: number;
  shoppingCartItems: ShoppingCartItemDTO[];
  couponId?: string;
}

interface ShoppingCartFormProps extends FormComponentProps, ReactStripeElements.InjectedStripeProps {
  User: UserState;
  total: number;
  isSubmitting: boolean;
  onCouponSubmit: (couponCode: string) => void;
  onSubmit: (data: ShoppingCartFormDataObject, stripeToken: string) => void;
}

interface ShoppingCartFormState {
  total: number;
  constants: ConstantsDTO;
  couponCode: string;
  coupon: CouponDTO;
  couponErrorMessage: string;
  stripeErrorMessage: string;
  stripeValid: boolean;
  visible: boolean;
}

class ShoppingCartForm extends React.Component<ShoppingCartFormProps, ShoppingCartFormState> {
  constructor(props: ShoppingCartFormProps) {
      super(props);
      bindAllOfThis(this, ShoppingCartForm.prototype);

      this.state = {
        total: this.props.total,
        constants: null,
        couponCode: null,
        coupon: null,
        couponErrorMessage: null,
        stripeErrorMessage: null,
        stripeValid: false,
        visible: false
      };
  }

  handleApplyCodeOnClick() {
    this.props.onCouponSubmit(this.state.couponCode);
  }

  handleCouponCodeOnChange(event: any) {
      this.setState({ couponCode: event.target.value });
  }

  handleStripeOnChange(event: ReactStripeElements.ElementChangeResponse) {
    this.setState({
        stripeErrorMessage: event.error ? event.error.message : null, stripeValid: event.error ? false : true
    });
  }

  async handleFormSubmit(e: React.FormEvent) {
    // Stop the button from reloading the page
    e.preventDefault();
    // This prevents enter from submitting the form before the confirmation modal is visible
    if (this.state.visible) {
      // Form needs to be validated
      this.props.form.validateFields((errors: any, values: ShoppingCartFormDataObject): void => {
          values.paymentAmountInCents =  this.props.total;
          if (errors == null) {
              // Get stripe token here then pass back to parent
              this.props.stripe.createToken({ name: this.props.User.email }).then(stripeTokenResult => {

                  if (stripeTokenResult.error === undefined) {
                      this.props.onSubmit(values, stripeTokenResult.token.id);
                  } else {
                      this.setState({ visible: false });
                  }
              });
          } else {
              this.setState({ visible: false });
          }
      });
  } else {
      // Don't show modal unless form is valid
      this.props.form.validateFields((errors: any, values: ShoppingCartFormDataObject): void => {
          if (errors == null) {
              this.setState({ visible: true });
          }
      });
  }
  }

  handleCancel(e: any) {
    this.setState({ visible: false });
  }

  render() {
      return <Form id="shoppingCartForm" onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
        <div>
            <div style={{ marginTop: 15 }}>
              <FieldDecorator
                form={this.props.form}
                label="Coupon Code"
                name="CouponCode"
                wrapperCol={{
                  xs: { span: 18 },
                  sm: { span: 8 },
                  md: { span: 7 },
                  lg: { span: 6 },
                  xl: { span: 6 }
                }}
              >
                  <Input size="large" placeholder="Code" onChange={this.handleCouponCodeOnChange} {...disableAutoCompleteTags()} />
              </FieldDecorator>
              <p style={{ color: "red" }}>{this.state.couponErrorMessage}</p>
              <Button size="large" onClick={this.handleApplyCodeOnClick}>Apply Code</Button>
            </div>
            <div style={{ marginTop: 16 }}>
              <h2>Total: {convertCentsToDollars(this.props.total)}</h2>
            </div>
            <div>
              <FieldDecorator
                form={this.props.form}
                label="Credit Card"
                name="CreditCard"
                rules={[{ required: true, message: 'Credit Card is required.' }]}
                wrapperCol={{
                  xs: { span: 22 },
                  sm: { span: 22 },
                  md: { span: 7 },
                  lg: { span: 10 },
                  xl: { span: 10 }
                }}
              >
                <CardElement onChange={this.handleStripeOnChange} />
              </FieldDecorator>
              <p style={{ color: "red" }}>{this.state.stripeErrorMessage}</p>
            </div>
            <div style={{ marginTop: 15, textAlign: "center" }}>
              <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                  Purchase
              </Button>

              <Link to={Routes.GET.TEACHER_MY_SCHOOL} style={{ marginLeft: '6px' }}>
                  <Button className="login-form-button" size="large">
                      Go Back
                  </Button>
              </Link>
            </div>
          </div>

          <Modal
            title="Purchase Confirmation"
            visible={this.state.visible}
            closable={false}
            footer={[
                <Button form="shoppingCartForm" type="primary" htmlType="submit" loading={this.props.isSubmitting}>
                    Confirm
                </Button>,
                <Button danger onClick={this.handleCancel}>
                    Cancel
                </Button>
              ]}>
            <h3>Confirm payment of {convertCentsToDollars(this.props.total)}?</h3>
          </Modal>
        </Form>;
  }
}

function mapStateToProps(state: ReduxStoreModel) {
  return {
      User: state.User,
  };
}

export default connect(mapStateToProps)(injectStripe(Form.create<ShoppingCartFormProps>()(ShoppingCartForm)));
