import * as React from 'react';
import { Row, Col, notification } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import { PageProps } from '../../../models/common/ComponentProps';

import "./ForgotPassword.scss";
import { AccountController } from '../../../api/AccountController';
import ErrorDTO from '../../../models/common/ErrorDTO';
import { ResetPasswordDTO } from '../../../models/account/resetPasswordDTO';
import ResetPasswordForm from './ResetPasswordForm';
import Routes from '../../../core/Routes';
import { ParamUtil } from '../../../utils/ParamUtil';

interface ResetPasswordPageProps extends PageProps<{ key: string }> {
}

interface ResetPasswordPageState {
    isSubmitting: boolean;
    formData: ResetPasswordDTO;
    resetPasswordToken: string;
}

class ResetPasswordPage extends React.Component<ResetPasswordPageProps, ResetPasswordPageState> {
    private notificationKey = "ResetPasswordPage";
    constructor(props: ResetPasswordPageProps) {
        super(props);
        bindAllOfThis(this, ResetPasswordPage.prototype);

        let resetPasswordToken = new ParamUtil(this.props.location.search).get.Key;

        this.state = {
            isSubmitting: false,
            formData: null,
            resetPasswordToken: resetPasswordToken
        };
    }

    handleResetPasswordClick(data: ResetPasswordDTO) {
        notification.info({
            key: this.notificationKey,
            message: "Updating Password",
            description: "Please wait while we update your password...",
            duration: 0
        });

        this.setState({ isSubmitting: true }, () => {
            data.resetPasswordToken = this.state.resetPasswordToken;

            AccountController.PostResetPassword(data).then(result => {
                notification.success({
                    key: this.notificationKey,
                    message: "Updated Password",
                    description: "Successfully updated your password!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });

                this.setState({ isSubmitting: false });
                this.props.history.push(Routes.LOGIN().toRoute);
            }).catch(error => {
                let messages = error != null && error.response != null && error.response.data.messages != null
                    ? (error.response.data as ErrorDTO).messages
                    : ["Critical Error"];
                notification.error({
                    key: this.notificationKey,
                    message: "Failed to Update Password",
                    description: messages.map(x => <div>{x}</div>),
                    duration: 10
                });

                this.setState({ isSubmitting: false });
            });
        });
    }

    render() {
        return <div className="forgot-password-page">
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 10, offset: 7 }}
                    xl={{ span: 8, offset: 8 }}
                >
                    <h2>Reset Password</h2>
                    <p>Passwords must have at least one upper case letter</p>
                    <ResetPasswordForm onSubmit={this.handleResetPasswordClick} isSubmitting={this.state.isSubmitting} />
                </Col>
            </Row>
        </div>;
    }
}

export default ResetPasswordPage;
