import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { RouteComponentProps, withRouter, Link, Redirect } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, notification } from 'antd';
import bindAllOfThis from '../../../utils/BindThisHelper';
import Routes from '../../../core/Routes';
import LoginForm from './LoginForm';
import UserAction from '../../../redux/UserActions';
import ReduxStoreModel from '../../../redux/ReduxModel';
import { UserState } from '../../../redux/UserReducer';
import { StudentLoginDTO } from '../../../models/account/studentLoginDTO';
import { PageProps } from '../../../models/common/ComponentProps';
import ActionResultDTO from '../../../models/common/ActionResultDTO';

import "./Login.scss";
import StudentLoginForm from './StudentLoginForm';

interface StudentLoginPageProps extends PageProps<{}> {
    // From Redux
    User: UserState;
    Login: (data: StudentLoginDTO) => Promise<ActionResultDTO>;
}

interface StudentLoginPageState {
    isLoggedIn: boolean;
    isSubmitting: boolean;
}

class StudentLoginPage extends React.Component<StudentLoginPageProps, StudentLoginPageState> {
    private notificationKey = "StudentLoginPage";
    constructor(props: StudentLoginPageProps) {
        super(props);
        bindAllOfThis(this, StudentLoginPage.prototype);

        this.state = {
            isLoggedIn: false,
            isSubmitting: false,
        };
    }

    handleLoginClick(data: StudentLoginDTO) {
        // Don't crush the login endpoint
        if (this.state.isSubmitting) {
            return;
        }

        notification.info({
            key: this.notificationKey,
            message: "Login",
            description: "Logging in to the site...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        this.props.Login(data).then(result => {
            if (!result.isError) {
                notification.success({
                    key: this.notificationKey,
                    message: "Login",
                    description: "Login success!",
                    duration: 5,
                    onClick: () => notification.close(this.notificationKey)
                });
            } else {
                notification.error({
                    key: this.notificationKey,
                    message: "Login",
                    description: ["Login returned an error.", result.message].map(x => <div>{x}</div>),
                    duration: 10
                });
                this.setState({ isSubmitting: false });
            }
        }).catch(() => {
            notification.error({
                key: this.notificationKey,
                message: "Login",
                description: ["Login returned an critical error. Please refresh your browser window"].map(x => <div>{x}</div>),
                duration: 0
            });
            this.setState({ isSubmitting: false });
        });
    }

    render() {
        if (this.props.User.state === "finished") {
            return <Redirect to={Routes.HOME().toRoute} push />;
        }

        return <div className="login-page">
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 10, offset: 7 }}
                    xl={{ span: 8, offset: 8 }}
                >
                    <h2>Student Login</h2>
                    <StudentLoginForm onSubmit={this.handleLoginClick} isSubmitting={this.state.isSubmitting} />
                    <Row  justify="center" style={{ paddingTop: "18px" }}>
                        <Col>
                            <Link to={Routes.LOGIN().toRoute}><span style={{ display: "block" }}>Oops, I am not a student!</span></Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        Login: (data: StudentLoginDTO) => UserAction.StudentLogin(dispatch, data),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentLoginPage);
