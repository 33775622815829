import * as React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Tooltip, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { getColumnSearchProps } from '../../components/tableHelpers/getColumnSearchProps';
import { Role, Roles } from '../../constants/Roles';
import { TeacherController } from '../../api/TeacherController';
import { AdminController } from '../../api/AdminController';
import { NameOf } from '../../utils/NameOf';

interface AdminUsersPageTableDataObject {
    id: string;
    name: string;
    email: string;
    roleName: string;
    schoolCount: number;
    schoolCountDisplay: string;
    hasPrimarySchool: boolean;
    phoneNumber: string;
}

interface AdminUsersPageProps extends PageProps<{}> {
}

interface AdminUsersPageState {
    isLoading: boolean;
    userTableData: AdminUsersPageTableDataObject[];
    adminTableData: AdminUsersPageTableDataObject[];
}

class AdminUsersPage extends React.Component<AdminUsersPageProps, AdminUsersPageState> {
    tableColumns: ColumnProps<AdminUsersPageTableDataObject>[];
    constructor(props: AdminUsersPageProps) {
        super(props);
        bindAllOfThis(this, AdminUsersPage.prototype);

        this.tableColumns = [{
            title: 'Name',
            dataIndex: NameOf<AdminUsersPageTableDataObject>("name"),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps<AdminUsersPageTableDataObject>("name", "Name"),
        }, {
            title: 'Email',
            dataIndex: NameOf<AdminUsersPageTableDataObject>("email"),
            width: 250,
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchProps<AdminUsersPageTableDataObject>("email", "Email"),
        }, {
            title: 'Role',
            dataIndex: NameOf<AdminUsersPageTableDataObject>("roleName"),
            width: 120,
            sorter: (a, b) => a.roleName.localeCompare(b.roleName),
            ...getColumnSearchProps<AdminUsersPageTableDataObject>("roleName", "Role"),
        }, {
            title: 'School Count',
            dataIndex: NameOf<AdminUsersPageTableDataObject>("schoolCountDisplay"),
            width: 120,
            sorter: (a, b) => a.schoolCountDisplay.localeCompare(b.schoolCountDisplay),
            ...getColumnSearchProps<AdminUsersPageTableDataObject>("schoolCountDisplay", "School Count"),
        }, {
            title: 'Primary School?',
            key: NameOf<AdminUsersPageTableDataObject>("hasPrimarySchool"),
            width: 120,
            sorter: (a, b) => Number(a.hasPrimarySchool) - Number(b.hasPrimarySchool),
            render: (_, record) => record.hasPrimarySchool ? "Yes" : record.schoolCount > 0 ? <span style={{ color: "red" }}>No</span> : "No",
            ...getColumnSearchProps<AdminUsersPageTableDataObject>("hasPrimarySchool", "Primary School?"),
        }, {
            title: 'Edit',
            key: 'edit',
            align: 'center',
            width: 60,
            render: (text, record) => {
                return (
                    <Tooltip title="Edit User" placement="top">
                        <Link to={Routes.ADMIN_USER_ADD_EDIT(record.id).toRoute}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    </Tooltip>
                );
            }
        }];

        this.state = {
            isLoading: true,
            userTableData: [],
            adminTableData: []
        };
    }

    componentDidMount() {
        this.loadTableData();
    }

    loadTableData() {
        TeacherController.GetAllTeachers().then(result => {
            let tableData = result.data.map<AdminUsersPageTableDataObject>(x => {
                let userRole = Roles.FindById(x.roleId);
                return {
                    id: x.id,
                    name: `${x.lastName}, ${x.firstName}`,
                    email: x.email,
                    roleName: userRole.name,
                    hasPrimarySchool: x.school != null,
                    schoolCount: x.allSchools.length,
                    schoolCountDisplay: x.allSchools.length > 0 ? x.allSchools.length.toString() : "",
                    phoneNumber: x.phoneNumber
                };
            });
            this.setState({
                userTableData: tableData,
                isLoading: false
            });
        });
        AdminController.GetAllAdmins().then(result => {
            let tableData = result.data.map<AdminUsersPageTableDataObject>(x => {
                return {
                    id: x.id,
                    name: `${x.lastName}, ${x.firstName}`,
                    email: x.email,
                    roleName: 'Admin',
                    hasPrimarySchool: false,
                    schoolCount: 0,
                    schoolCountDisplay: "n/a",
                    phoneNumber: 'n/a'
                };
            });
            this.setState({
                adminTableData: tableData,
                isLoading: false
            });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }
        return <div>
            <h1>Users</h1>
            <h2>Hey look, we can now support admins!</h2>
            <Table
                rowKey={record => record.id}
                size="middle"
                columns={this.tableColumns}
                dataSource={this.state.adminTableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    defaultPageSize: 20,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
            <br />
            <h2>Regular Users</h2>
            <Table
                // title={(data) => {
                //     return [
                //         <Link key="table-link" to={Routes.ADMIN_COURSE_ADD_EDIT("").toRoute}>
                //             <Button icon="edit">Add User</Button>
                //         </Link>,
                //     ];
                // }}
                rowKey={record => record.id}
                size="middle"
                columns={this.tableColumns}
                dataSource={this.state.userTableData}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    defaultPageSize: 10,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }}
            />
        </div>;
    }
}

export default AdminUsersPage;
