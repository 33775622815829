import * as React from 'react';

interface IframeGoogleDocsProps {    
    srcUrl: String;
}

interface IframeGoogleDocsState {    
    srcUrl: String;
    iframeKey: React.Key;
}

export default class IframeGoogleDocs extends React.Component<IframeGoogleDocsProps, IframeGoogleDocsState> {
    private iframeTimeoutId: NodeJS.Timeout;
    constructor(props: IframeGoogleDocsProps) {
        super(props);
        this.bindActions();

        this.state = {
            srcUrl: null,
            iframeKey: Math.random(),           
        };
    }
    bindActions() {
        this.updateIframeSrc = this.updateIframeSrc.bind(this);
        this.iframeLoaded = this.iframeLoaded.bind(this);
    }
    iframeLoaded() {
        clearInterval(this.iframeTimeoutId);
    }
    getIframeLink() {
        return `https://docs.google.com/viewer?url=${this.props.srcUrl}&embedded=true`; // no need for this if thats not dynamic
    }
    updateIframeSrc() {
        let srcUrl = this.getIframeLink();
        this.setState({
            srcUrl : srcUrl,
            iframeKey: Math.random(),
        });        
        console.log("setting url");
    }
    componentDidMount() {
       let iframeTimeoutId = setInterval(
            this.updateIframeSrc, 1000,            
        );
         this.iframeTimeoutId = iframeTimeoutId;
    }
    render() {
        console.log("loading");
        return ( <div className="pdf-container">
            <iframe key={this.state.iframeKey} onLoad={this.iframeLoaded} onError={this.updateIframeSrc} src={this.getIframeLink()} frameBorder="0"></iframe>
            </div>
        );
    }
}
