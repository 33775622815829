import axios from 'axios';
import { CourseDTO } from '../models/course/courseDTO';
import { SimpleCourseDTO } from '../models/course/simpleCourseDTO';
import { CreateCourseDTO } from '../models/course/createCourseDTO';
import { UpdateCourseDTO } from '../models/course/updateCourseDTO';


export class CourseController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetCoursesRoute = () => `courses`;
    public static GetCourses() {
        return axios.get<SimpleCourseDTO[]>(this.GetCoursesRoute());
    }

    public static PostCreateCourseRoute = () => `courses`;
    public static PostCreateCourse(request: CreateCourseDTO) {
        return axios.post<CourseDTO>(this.PostCreateCourseRoute(), request);
    }

    public static PutUpdateCourseRoute = () => `courses`;
    public static PutUpdateCourse(request: UpdateCourseDTO) {
        return axios.put<CourseDTO>(this.PutUpdateCourseRoute(), request);
    }

    public static PostSaveStandardsAppendixRoute = (courseId: string) => `courses/${courseId}/saveStandardsAppendix`;
    public static PostSaveStandardsAppendix(courseId: string, formData: FormData, progressCallback?: (data: any) => void) {
        let route = this.PostSaveStandardsAppendixRoute(courseId);
        let headers = { 'content-type': `multipart/form-data; boundary=${(formData as any)._boundary}` };
        return axios.post<CourseDTO>(route, formData, { headers: headers, onUploadProgress: progressCallback });
    }

    public static DeleteCourseRoute = (courseId: string) => `courses/${courseId}`;
    public static DeleteCourse(courseId: string) {
        return axios.delete(this.DeleteCourseRoute(courseId));
    }

    public static GetCourseRoute = (courseId: string) => `courses/${courseId}`;
    public static GetCourse(courseId: string) {
        return axios.get<CourseDTO>(this.GetCourseRoute(courseId));
    }

    public static GetCourseFromDemoRoute = (demoId: string) => `courses/demo/${demoId}`;
    public static GetCourseFromDemo(demoId: string){
        return axios.get<CourseDTO>(this.GetCourseFromDemoRoute(demoId));
    }
}

