import * as React from 'react';
import { Switch } from 'react-router-dom';
import Routes from './Routes';
import LayoutComponent from './LayoutComponent';

/* Layouts */
import WorkflowLayout from '../../src/layouts/workflow/WorkflowLayout';
import PublicLayout from '../../src/layouts/public/PublicLayout';
import AdminLayout from '../../src/layouts/admin/AdminLayout';
import TeacherLayout from '../../src/layouts/teacher/TeacherLayout';
import DocumentaryLayout from '../../src/layouts/documentary/DocumentaryLayout';

/* Pages */
import PricingPage from '../pages/public/pricing/PricingPage';
import LoginPage from '../pages/account/login/LoginPage';
import StudentLoginPage from '../pages/account/login/StudentLoginPage';
import LogoutPage from '../pages/account/LogoutPage';
import ForgotPasswordPage from '../pages/account/forgotPassword/ForgotPassword';
import ResetPasswordPage from '../pages/account/forgotPassword/ResetPassword';
import AccountPage from '../pages/account/account/AccountPage';
import HomePage from '../pages/HomePage';
import ErrorPage from '../pages/public/ErrorPage';
import PageNotFound from '../pages/public/PageNotFound';
import DemoPage from '../pages/public/DemoPage';
import UrlPage from '../pages/public/UrlPage';
import RoleRedirectPage from '../pages/public/RoleRedirectPage';
import UserCoursePage from '../pages/user/UserCoursePage';
import UserCoursesPage from '../pages/user/UserCoursesPage';
import RegistrationPage from '../pages/account/registration/RegistrationPage';
import ConferenceRegistrationPage from '../pages/account/conferenceRegistration/ConferenceRegistrationPage';
import ConferenceRegistrationConfirmationPage from '../pages/account/conferenceRegistration/ConferenceRegistrationConfirmationPage';
import AboutUsPage from '../pages/public/AboutUsPage';
import SocialEmotionalLearningPage from '../pages/public/SocialEmotionalLearningPage';
import BenefitMePage from '../pages/public/BenefitMePage';
import MusicsEffectivenessPage from '../pages/public/MusicsEffectivenessPage';
import BetaUserAgreementPage from '../pages/account/BetaUserAgreementPage';
import PrivacyPolicyPage from '../pages/account/PrivacyPolicyPage';

import AdminHomePage from '../pages/admin/AdminHome';
import AdminCoursesPage from '../pages/admin/AdminCoursesPage';
import AdminLicensesPage from '../pages/admin/AdminLicensesPage';
import AdminLicensePage from '../pages/admin/AdminLicensePage';
import AdminDistrictsPage from '../pages/admin/AdminDistrictsPage';
import AdminDistrictPage from '../pages/admin/AdminDistrictPage';
import AdminSchoolsPage from '../pages/admin/AdminSchoolsPage';
import AdminSchoolPage from '../pages/admin/AdminSchoolPage';
import AdminCoursePage from '../pages/admin/AdminCoursePage';
import AdminUsersPage from '../pages/admin/AdminUsersPage';
import AdminUserPage from '../pages/admin/AdminUserPage';
import AdminCouponsPage from '../pages/admin/AdminCouponsPage';
import AdminCouponPage from '../pages/admin/AdminCouponPage';

import TeacherHomePage from '../pages/teacher/TeacherHome';
import TeacherStudents from '../pages/teacher/TeacherStudents';
import TeacherClassrooms from '../pages/teacher/TeacherClassrooms';
import TeacherAddEditStudent from '../pages/teacher/TeacherAddEditStudent';
import TeacherAddEditClassroom from '../pages/teacher/TeacherAddEditClassroom';
import TeacherMyClassroom from '../pages/teacher/TeacherMyClassroom';
import TeacherMySchoolPage from '../pages/teacher/TeacherMySchool';
import TeacherSelectMySchoolPage from '../pages/teacher/TeacherSelectMySchool';
import TeacherAddEditMySchoolPage from '../pages/teacher/TeacherAddEditMySchool';

import WorkflowHomePage from '../pages/workflow/WorkflowHomePage';
import WorkflowLessonPlanPage from '../pages/workflow/WorkflowLessonPlanPage';
import WorkflowEntranceTestPage from '../pages/workflow/WorkflowEntranceTestPage';
import WorkflowWhiteboardPage from '../pages/workflow/WorkflowWhiteboardPage';
import WorkflowVideoPage from '../pages/workflow/WorkflowVideoPage';
import WorkflowDiscussionPage from '../pages/workflow/WorkflowDiscussionPage';
import WorkflowDrillDownPage from '../pages/workflow/WorkflowDrillDownPage';
import WorkflowExitTestPage from '../pages/workflow/WorkflowExitTestPage';
import WorkflowResultsPage from '../pages/workflow/WorkflowResultsPage';
import WorkflowSurveyPage from '../pages/workflow/WorkflowSurveyPage';
import WorkflowSchoolHomePartnershipPage from '../pages/workflow/WorkflowSchoolHomePartnershipPage';
import WorkflowCourseHomePage from '../pages/workflow/WorkflowCourseHomePage';
import WorkflowCourseAppendix from '../pages/workflow/WorkflowCourseAppendix';
import TeacherCoursesPage from '../pages/teacher/TeacherCoursesPage';
import AdminLessonPage from '../pages/admin/AdminLessonPage';
import AdminQuestionPage from '../pages/admin/AdminQuestionPage';
import StudentHomePage from '../pages/student/StudentHomePage';
import StudentCourseHomePage from '../pages/student/StudentCourseHomePage';
import WorkflowStartAssessmentPage from '../pages/workflow/WorkflowStartAssessmentPage';
import WorkflowEndAssessmentPage from '../pages/workflow/WorkflowEndAssessmentPage';
import AdminDemoPage from '../pages/admin/AdminDemoPage';
import ShoppingCartPage from '../pages/ShoppingCart';
import DocumentaryHomePage from '../pages/documentary/DocumentaryHomePage';
import DocumentaryVideoPage from '../pages/documentary/DocumentaryVideoPage';
import DocumentaryPostVideoPage from '../pages/documentary/DocumentaryPostVideoPage';
import TeacherEditActiveSchool from '../pages/teacher/TeacherEditActiveSchool';


const RouteLoader = <Switch>
    {/* Public */}
    <LayoutComponent exact path={Routes.GET.BASE_ROUTE} component={HomePage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.LOGIN} component={LoginPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.STUDENT_LOGIN} component={StudentLoginPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.LOGOUT} component={LogoutPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.REGISTER} component={RegistrationPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.REGISTER_CONFERENCE_EDITION} component={ConferenceRegistrationPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.REGISTER_CONFERENCE_EDITION_CONFIRMATION} component={ConferenceRegistrationConfirmationPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.DEMO} component={DemoPage} layout={PublicLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.URL_MASTER} component={UrlPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.PASSCODE} component={UrlPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.ACCOUNT} component={AccountPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.FORGOT_PASSWORD} component={ForgotPasswordPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.RESET_PASSWORD} component={ResetPasswordPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.PRICING} component={PricingPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.ABOUT_US} component={AboutUsPage} layout={PublicLayout}/>
    <LayoutComponent exact path={Routes.GET.BETA_USER_AGREEMENT} component={BetaUserAgreementPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.PRIVACY_POLICY} component={PrivacyPolicyPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.BENEFIT_ME} component={BenefitMePage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.MUSICS_EFFECTIVENESS} component={MusicsEffectivenessPage} layout={PublicLayout} />
    <LayoutComponent exact path={Routes.GET.SOCIAL_EMOTIONAL_LEARNING} component={SocialEmotionalLearningPage} layout={PublicLayout}/>
    {/* Admin/Teacher Workflow */}
    <LayoutComponent exact path={Routes.GET.COURSE} component={UserCoursePage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.COURSES} component={UserCoursesPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_COURSE_APPENDIX} component={WorkflowCourseAppendix} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_COURSE_HOME} component={WorkflowCourseHomePage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_HOME} component={WorkflowHomePage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_LESSON_PLAN} component={WorkflowLessonPlanPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_ENTRANCE_TEST} component={WorkflowEntranceTestPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_WHITEBOARD} component={WorkflowWhiteboardPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_VIDEO} component={WorkflowVideoPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_DISCUSSION} component={WorkflowDiscussionPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_DRILL_DOWN} component={WorkflowDrillDownPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_EXIT_TEST} component={WorkflowExitTestPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_RESULTS} component={WorkflowResultsPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_SURVEY} component={WorkflowSurveyPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_SCHOOL_HOME_PARTNERSHIP} component={WorkflowSchoolHomePartnershipPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_START_ASSESSMENT} component={WorkflowStartAssessmentPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_END_ASSESSMENT} component={WorkflowEndAssessmentPage} layout={WorkflowLayout} requireLogin={true} />

    {/* Student Workflow */}
    <LayoutComponent exact path={Routes.GET.STUDENT_HOME} component={StudentHomePage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_COURSE_HOME} component={StudentCourseHomePage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_HOME} component={WorkflowHomePage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_LESSON_PLAN} component={WorkflowLessonPlanPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_START_ASSESSMENT} component={WorkflowStartAssessmentPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_END_ASSESSMENT} component={WorkflowEndAssessmentPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_ENTRANCE_TEST} component={WorkflowEntranceTestPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_WHITEBOARD} component={WorkflowWhiteboardPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_VIDEO} component={WorkflowVideoPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_DISCUSSION} component={WorkflowDiscussionPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_DRILL_DOWN} component={WorkflowDrillDownPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_EXIT_TEST} component={WorkflowExitTestPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_RESULTS} component={WorkflowResultsPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_SURVEY} component={WorkflowSurveyPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.STUDENT_WORKFLOW_SCHOOL_HOME_PARTNERSHIP} component={WorkflowSchoolHomePartnershipPage} layout={WorkflowLayout} requireLogin={true} />

    {/* Teacher Documentary */}
    <LayoutComponent exact path={Routes.GET.DOCUMENTARY_HOME} component={DocumentaryHomePage} layout={DocumentaryLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.DOCUMENTARY_VIDEO} component={DocumentaryVideoPage} layout={DocumentaryLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.DOCUMENTARY_POST_VIDEO} component={DocumentaryPostVideoPage} layout={DocumentaryLayout} requireLogin={true} />


    {/* <LayoutComponent exact path={Routes.GET.WORKFLOW_SURVEY} component={WorkflowSurveyPage} layout={WorkflowLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.WORKFLOW_SCHOOL_HOME_PARTNERSHIP} component={WorkflowSchoolHomePartnershipPage} layout={WorkflowLayout} requireLogin={true} /> */}

    {/* Special */}
    <LayoutComponent exact path={Routes.GET.HOME} component={RoleRedirectPage} layout={PublicLayout} requireLogin={false} />

    {/* Admin */}
    <LayoutComponent exact path={Routes.GET.ADMIN_DASHBOARD} component={AdminHomePage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_LICENSES} component={AdminLicensesPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_LICENSE_ADD_EDIT} component={AdminLicensePage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_DISTRICTS} component={AdminDistrictsPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_DISTRICT_ADD_EDIT} component={AdminDistrictPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_SCHOOLS} component={AdminSchoolsPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_SCHOOL_ADD_EDIT} component={AdminSchoolPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_COURSES} component={AdminCoursesPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_COURSE_ADD_EDIT} component={AdminCoursePage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_LESSON_ADD_EDIT} component={AdminLessonPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_QUESTION_ADD_EDIT} component={AdminQuestionPage} layout={AdminLayout} requireLogin={true} />

    <LayoutComponent exact path={Routes.GET.ADMIN_USERS} component={AdminUsersPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_USER_ADD_EDIT} component={AdminUserPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_COUPONS} component={AdminCouponsPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_COUPON_ADD_EDIT} component={AdminCouponPage} layout={AdminLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.ADMIN_DEMO} component={AdminDemoPage} layout={AdminLayout} requireLogin={true} />

    {/* Teacher */}
    <LayoutComponent exact path={Routes.GET.TEACHER_DASHBOARD} component={TeacherHomePage} layout={TeacherLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.TEACHER_STUDENTS} component={TeacherStudents} layout={TeacherLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.TEACHER_CLASSROOMS} component={TeacherClassrooms} layout={TeacherLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.TEACHER_MY_CLASSROOM} component={TeacherMyClassroom} layout={TeacherLayout} requireLogin={true} />

    <LayoutComponent exact path={Routes.GET.TEACHER_COURSES} component={TeacherCoursesPage} layout={TeacherLayout} requireLogin={true} />

    <LayoutComponent exact path={Routes.GET.TEACHER_MY_SCHOOL} component={TeacherMySchoolPage} layout={TeacherLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.TEACHER_SELECT_MY_SCHOOL} component={TeacherSelectMySchoolPage} layout={TeacherLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.TEACHER_ADD_EDIT_MY_SCHOOL} component={TeacherAddEditMySchoolPage} layout={TeacherLayout} requireLogin={true} />

    <LayoutComponent exact path={Routes.GET.TEACHER_ADD_EDIT_STUDENT} component={TeacherAddEditStudent} layout={TeacherLayout} requireLogin={true} />
    <LayoutComponent exact path={Routes.GET.TEACHER_ADD_EDIT_CLASSROOM} component={TeacherAddEditClassroom} layout={TeacherLayout} requireLogin={true} />

    <LayoutComponent exact path={Routes.GET.TEACHER_EDIT_ACTIVE_SCHOOL} component={TeacherEditActiveSchool} layout={TeacherLayout} requireLogin={true} />

    {/* Shopping Cart */}
    <LayoutComponent exact path={Routes.GET.SHOPPING_CART} component={ShoppingCartPage} layout={TeacherLayout} requireLogin={true} />

    {/* Error Handling */}
    <LayoutComponent exact path={Routes.GET.ERROR_PAGE} component={ErrorPage} layout={PublicLayout} requireLogin={false} />
    <LayoutComponent exact path={Routes.GET.PAGE_NOT_FOUND} component={PageNotFound} layout={PublicLayout} requireLogin={false} />
    {/* This needs to be the last item. Path also needs to be undefined */}
    <LayoutComponent path={undefined} component={PageNotFound} layout={PublicLayout} />
</Switch>;

export default RouteLoader;
