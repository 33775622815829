import * as React from 'react';
import { connect } from 'react-redux';
import { Spin, notification } from 'antd';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import bindAllOfThis from '../../utils/BindThisHelper';
import { PageProps } from '../../models/common/ComponentProps';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';
import AdminLicenseCourseForm, { AdminLicenseCourseFormDataObject } from './AdminLicenseCourseForm';
import { LicenseController } from '../../api/LicenseController';
import { SimpleSchoolDTO } from '../../models/school/simpleSchoolDTO';
import { CourseController } from '../../api/CourseController';
import { SchoolController } from '../../api/SchoolController';
import { SimpleCourseDTO } from '../../models/course/simpleCourseDTO';
import { LicenseDTO } from '../../models/license/licenseDTO';
import ErrorDTO from '../../models/common/ErrorDTO';
import { UserState } from '../../redux/UserReducer';
import Routes from '../../core/Routes';
import AdminLicenseDocumentaryForm, { AdminLicenseDocumentaryFormDataObject } from './AdminLicenseDocumentaryForm';
import { ParamUtil } from '../../utils/ParamUtil';
import ReduxStoreModel from '../../redux/ReduxModel';

interface AdminLicensePageProps extends PageProps<{ licenseId?: string }> {
    User: UserState;
}

interface AdminLicensePageState {
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    licenseType: 'course' | 'documentary';
    /** The ID retrieved from the URL. UserId, LicenseId, etc. They are all IDs */
    licenseId?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    courseFormData: AdminLicenseCourseFormDataObject;
    documentaryFormData: AdminLicenseDocumentaryFormDataObject;

    isSubmitting: boolean;

    isLoadingSchools: boolean;

    // Extra data
    schools: SimpleSchoolDTO[];
    courses: SimpleCourseDTO[];
}

class AdminLicensePage extends React.Component<AdminLicensePageProps, AdminLicensePageState> {
    private notificationKey = "AdminLicensePage";
    constructor(props: AdminLicensePageProps) {
        super(props);
        bindAllOfThis(this, AdminLicensePage.prototype);

        let isEdit = props.match.params.licenseId != null;
        let params = new ParamUtil(props.location.search);

        this.state = {
            isEdit: isEdit,
            licenseType: params.has.LicenseType && params.get.LicenseType.toLowerCase() === 'documentary' ? 'documentary' : 'course',
            licenseId: props.match.params.licenseId,
            isLoading: true,
            isLoadingSchools: false,
            isSubmitting: false,
            courseFormData: null,
            documentaryFormData: null,
            courses: [],
            schools: [],
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    handleOnSubmit(data: AdminLicenseCourseFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "License Save",
            description: "Saving the License...",
            duration: 0
        });
        this.setState({ isSubmitting: true });
        let request = this.state.isEdit
            ? LicenseController.PutAdminUpdateLicense({
                licenseId: data.id,
                licenseCount: data.licenseCount,
                courseId: data.course.key,
                schoolId: data.school.key,
                startDate: data.startDate.format('YYYY-MM-DD'),
                endDate: data.endDate.format('YYYY-MM-DD'),
                stripeToken: `Manually added by - ${this.props.User.email}`,
                paymentAmountInCents: 0,
            })
            : LicenseController.PostAdminCreateLicense({
                licenseCount: data.licenseCount,
                courseId: data.course.key,
                schoolId: data.school.key,
                startDate: data.startDate.format('YYYY-MM-DD'),
                endDate: data.endDate.format('YYYY-MM-DD'),
                tripleYearLicense: false,
                stripeToken: `Manually added by - ${this.props.User.email}`,
                paymentAmountInCents: 0,
                pricingTierId: 999,
            });
        return request
            .then(this.handleOnSubmit_OnSuccess)
            .catch(this.handleOnSubmit_OnFailure);

    }

    handleDocumentaryOnSubmit(data: AdminLicenseDocumentaryFormDataObject) {
        notification.info({
            key: this.notificationKey,
            message: "License Save",
            description: "Saving the License...",
            duration: 0
        });
        this.setState({ isSubmitting: true });
        let request = this.state.isEdit
            ? LicenseController.PutAdminUpdateLicense({
                licenseId: data.id,
                licenseCount: 1,
                documentaryId: data.documentary.key,
                schoolId: data.school.key,
                startDate: data.startDate.format('YYYY-MM-DD'),
                stripeToken: `Manually added by - ${this.props.User.email}`,
                paymentAmountInCents: 0,
            })
            : LicenseController.PostAdminCreateLicense({
                licenseCount: 1,
                documentaryId: data.documentary.key,
                schoolId: data.school.key,
                startDate: data.startDate.format('YYYY-MM-DD'),
                tripleYearLicense: false,
                stripeToken: `Manually added by - ${this.props.User.email}`,
                paymentAmountInCents: 0,
                pricingTierId: 999,
            });
        return request
            .then(this.handleOnSubmit_OnSuccess)
            .catch(this.handleOnSubmit_OnFailure);

    }

    handleOnSubmit_OnSuccess(result: AxiosResponse<LicenseDTO>) {
        notification.success({
            key: this.notificationKey,
            message: "License Save",
            description: "The Lesson Content has been saved successfully!",
            duration: 5,
            onClick: () => notification.close(this.notificationKey)
        });
        this.props.history.push(Routes.GET.ADMIN_LICENSES);
    }

    handleOnSubmit_OnFailure(error: any) {
        let messages = error != null && error.response != null && error.response.data.messages != null
            ? (error.response.data as ErrorDTO).messages
            : ["Critical Error"];
        notification.error({
            key: this.notificationKey,
            message: "License Save",
            description: ["There was an issue saving the License.", ...messages].map(x => <div>{x}</div>),
            duration: 0
        });
        this.setState({ isSubmitting: false });
    }

    handleSchoolSearch(name: string) {
        if (this.state.isLoadingSchools) {
            return;
        }

        this.setState({ isLoadingSchools: true });
        return SchoolController.GetSchoolsByName(name).then(x => {
            this.setState({ isLoadingSchools: false });
            return x.data;
        });
    }

    loadAll() {
        this.setState({ isLoading: true });
        return Promise.all([this.loadCourses(), this.loadFormData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadCourses() {
        return CourseController.GetCourses().then(results => {
            return this.setState({ courses: results.data });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            this.setState({
                courseFormData: null
            });
            return Promise.resolve();
        }

        // Load the license here
        return LicenseController.GetLicense(this.state.licenseId).then(result => {
            if (result.data.courseId != null) {
                let courseFormData: AdminLicenseCourseFormDataObject = {
                    id: result.data.id,
                    school: { key: result.data.schoolId, label: result.data.schoolName },
                    course: { key: result.data.courseId, label: result.data.courseDocumentaryName },
                    startDate: moment(result.data.startDate),
                    endDate: moment(result.data.endDate),
                    licenseCount: result.data.licenseCount,
                };
                this.setState({ courseFormData });
            } else {
                let documentaryFormData: AdminLicenseDocumentaryFormDataObject = {
                    id: result.data.id,
                    school: { key: result.data.schoolId, label: result.data.schoolName },
                    documentary: { key: result.data.documentaryId, label: result.data.courseDocumentaryName },
                    startDate: moment(result.data.startDate)
                };
                this.setState({ documentaryFormData });
            }
        });
    }

    renderForm() {
        if (!!this.state.courseFormData || this.state.licenseType === 'course') {
            return <AdminLicenseCourseForm
                onSubmit={this.handleOnSubmit}
                onSchoolSearch={this.handleSchoolSearch}
                initialValues={this.state.courseFormData}
                // schools={this.state.schools}
                courses={this.state.courses}
                isLoadingSchools={this.state.isLoadingSchools}
            />;
        }
        if (!!this.state.documentaryFormData || this.state.licenseType === 'documentary') {
            return <AdminLicenseDocumentaryForm
                onSubmit={this.handleDocumentaryOnSubmit}
                initialValues={this.state.courseFormData}
            />;
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        // Title is messy, sorry
        return <div>
            <h1>{this.state.isEdit ? "Edit" : "Add"} {this.state.licenseType[0].toUpperCase() + (this.state.licenseType as string).slice(1)} License</h1>
            {this.renderForm()}
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps)(AdminLicensePage);
