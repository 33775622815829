import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Input, Radio, Select, Spin, notification } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../utils/BindThisHelper';
import { NameOf } from '../../utils/NameOf';
import { disableAutoCompleteTags } from '../../utils/AutoComplete';
import { FieldDecorator } from '../../components/formHelpers';
import { Link } from 'react-router-dom';
import Routes from '../../core/Routes';
import { KeyLabel } from '../../models/common/KeyValueAndSimilar';

export interface AdminCourseFormDataObject {
    id?: string;
    name: string;
    description: string;
    gradeLevelStart: number;
    gradeLevelEnd: number;
    availableForPurchase: boolean;
}

interface AdminCourseFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: AdminCourseFormDataObject) => void;
    initialValues?: Partial<AdminCourseFormDataObject>;
}

interface AdminCourseFormState {
    isEdit: boolean;
}

class AdminCourseForm extends React.Component<AdminCourseFormProps, AdminCourseFormState> {
    private gradeLevels: KeyLabel[] = [
        { key: "-1", label: "PK" },
        { key: "0", label: "K" },
        { key: "1", label: "1st" },
        { key: "2", label: "2nd" },
        { key: "3", label: "3rd" },
        { key: "4", label: "4th" },
        { key: "5", label: "5th" },
        { key: "6", label: "6th" },
        { key: "7", label: "7th" },
        { key: "8", label: "8th" },
        { key: "9", label: "9th" },
        { key: "10", label: "10th" },
        { key: "11", label: "11th" },
        { key: "12", label: "12th" },
    ];

    // Just need to initialize sub objects
    constructor(props: AdminCourseFormProps) {
        super(props);
        bindAllOfThis(this, AdminCourseForm.prototype);

        let isEdit = props.initialValues != null;

        this.state = {
            isEdit: isEdit,
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: AdminCourseFormDataObject): void => {
            values.id = this.props.initialValues != null ? this.props.initialValues.id : null;

            // The select form items return a key label not ids
            // so for now we are doing this to get the id
            values.gradeLevelStart = +((values.gradeLevelStart as any) as KeyLabel).key;
            values.gradeLevelEnd = +((values.gradeLevelEnd as any) as KeyLabel).key;

            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    renderGradeSelect() {
        return <Select
            placeholder={"Please select a grade"}
            filterOption={false}
            notFoundContent={null}
            labelInValue
            allowClear
        >
            {this.gradeLevels.map(gradeLevel => <Select.Option key={gradeLevel.key} value={gradeLevel.key}>{gradeLevel.label}</Select.Option>)}
        </Select>;
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 },
                lg: { span: 5 },
                xl: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                lg: { span: 12 },
                xl: { span: 10 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: formItemLayout.wrapperCol.xs.span, offset: formItemLayout.labelCol.xs.span % 24 },
                sm: { span: formItemLayout.wrapperCol.sm.span, offset: formItemLayout.labelCol.sm.span % 24 },
                md: { span: formItemLayout.wrapperCol.md.span, offset: formItemLayout.labelCol.md.span % 24 },
                lg: { span: formItemLayout.wrapperCol.lg.span, offset: formItemLayout.labelCol.lg.span % 24 },
                xl: { span: formItemLayout.wrapperCol.xl.span, offset: formItemLayout.labelCol.xl.span % 24 },
            },
        };

        let initialValues = this.props.initialValues || {};

        let initialGradeLevelStartValue = this.state.isEdit ?
            this.gradeLevels.find(x => x.key === initialValues.gradeLevelStart.toString()) :
            null;

        let initialGradeLevelEndValue = this.state.isEdit ?
            this.gradeLevels.find(x => x.key === initialValues.gradeLevelEnd.toString()) :
            null;

        return <Form onSubmit={this.handleFormSubmit} hideRequiredMark autoComplete="new-password">
            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Name"
                name={NameOf<AdminCourseFormDataObject>("name")}
                initialValue={initialValues.name}
                rules={[{ required: true, message: "Name is required." }]}
            >
                <Input size="large" placeholder="Name" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Description"
                name={NameOf<AdminCourseFormDataObject>("description")}
                initialValue={initialValues.description}
                rules={[{ required: true, message: "Description is required." }]}
            >
                <Input size="large" placeholder="Description" {...disableAutoCompleteTags()} />
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Start Grade"
                name={NameOf<AdminCourseFormDataObject>("gradeLevelStart")}
                initialValue={initialGradeLevelStartValue}
                rules={[{ required: true, message: "Start Grade is required." }]}
            >
                {this.renderGradeSelect()}
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="End Grade"
                name={NameOf<AdminCourseFormDataObject>("gradeLevelEnd")}
                initialValue={initialGradeLevelEndValue}
                rules={[{ required: true, message: "End Grade is required." }]}
            >
                {this.renderGradeSelect()}
            </FieldDecorator>

            <FieldDecorator
                {...formItemLayout}
                form={this.props.form}
                label="Available For Purchase"
                name={NameOf<AdminCourseFormDataObject>("availableForPurchase")}
                initialValue={initialValues.availableForPurchase == null ? false : initialValues.availableForPurchase}
                rules={[{ required: true }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={false}>No</Radio.Button>
                    <Radio.Button value={true}>Yes</Radio.Button>
                </Radio.Group>
            </FieldDecorator>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    {this.state.isEdit ? "Save Course" : "Add Course"}
                </Button>
                <Link to={Routes.ADMIN_COURSES().toRoute}>
                    <Button type="default" className="login-form-button" size="large" >
                        Cancel
                    </Button>
                </Link>
            </Form.Item>
        </Form>;
    }
}

export default Form.create<AdminCourseFormProps>()(AdminCourseForm);
