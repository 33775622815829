import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import WorkflowAction from '../../redux/WorkflowAction';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';
import { DocumentaryDTO } from '../../models/documentary/documentaryDTO';
import { Button } from 'antd';
import { ConstantState } from '../../redux/ConstantsReducer';

interface DocumentaryHomePageProps extends RouteComponentProps<{ documentaryId: string }> {
    // From Redux
    User: UserState;
    Constants: ConstantState;
}

interface DocumentaryHomePageState {
    documentary: DocumentaryDTO;
}

class DocumentaryHomePage extends React.Component<DocumentaryHomePageProps, DocumentaryHomePageState> {
    private notificationKey = "DocumentaryHomePage";
    constructor(props: DocumentaryHomePageProps) {
        super(props);
        bindAllOfThis(this, DocumentaryHomePage.prototype);

        this.state = {
            documentary: this.props.Constants.documentaries[0]
        };
    }

    render() {
        return <React.Fragment>
            <h1>Documentary</h1>
            <p>Welcome to the documentary! Please check the button below to start watching the video</p>
            <Link to={Routes.DOCUMENTARY_VIDEO(this.props.match.params.documentaryId).toRoute}>
                <Button type="primary">Start Watching!</Button>
            </Link>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        ClearCourse: () => WorkflowAction.ClearCourse(dispatch),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
        Constants: state.Constants
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentaryHomePage);
