import { Dispatch, Action } from 'redux';
import BaseAction from '../../src/models/common/BaseAction';
import { ConstantController } from '../api/ConstantController';
import { LoadingStatusType } from '../models/common/LoadingStatusType';
import { AxiosResponse, AxiosError } from 'axios';
import { ConstantsDTO } from '../models/constant/constantsDTO';
import { DocumentaryDTO } from '../models/documentary/documentaryDTO';

export interface UpdateRolesAction extends BaseAction { type: 'UPDATE_ROLES_CONSTANTS'; data: any[]; }
export interface UpdateCheckpointsAction extends BaseAction { type: 'UPDATE_CHECKPOINTS_CONSTANTS'; data: any[]; }
export interface UpdateLessonContentTypesAction extends BaseAction { type: 'UPDATE_LESSON_CONTENT_TYPES_CONSTANTS'; data: any[]; }
export interface UpdateSchoolYearsAction extends BaseAction { type: 'UPDATE_SCHOOL_YEARS_CONSTANTS'; data: any[]; }
export interface UpdateConstantStateAction extends BaseAction { type: 'UPDATE_STATE_CONSTANTS'; data: LoadingStatusType; }
export interface UpdatePricingTiersAction extends BaseAction { type: 'UPDATE_PRICING_TIERS_CONSTANTS'; data: any[]; }
export interface UpdateDocumentariesAction extends BaseAction { type: 'UPDATE_DOCUMENTARIES_CONSTANTS'; data: DocumentaryDTO[]; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
// JB: You have to have atleast 2 in here for everything to work. It must be a typescript thing
export type KnownActions = UpdateRolesAction | UpdateCheckpointsAction | UpdateLessonContentTypesAction | UpdateSchoolYearsAction | UpdateConstantStateAction | UpdatePricingTiersAction | UpdateDocumentariesAction;

export default class ConstantsAction {
    constructor() {
        // Dont be that guy
        throw new Error("NOOOO");
    }

    public static LoadConstants(dispatch: Dispatch<KnownActions>) {
        dispatch({ type: "UPDATE_STATE_CONSTANTS", data: "loading" } as UpdateConstantStateAction);

        return ConstantController.GetConstants()
            .then(result => this.Load_OnSuccess(dispatch, result))
            .catch(error => this.Load_OnFailure(dispatch, error));
    }

    private static Load_OnSuccess(dispatch: Dispatch<KnownActions>, response: AxiosResponse<ConstantsDTO>) {
        dispatch({ type: "UPDATE_ROLES_CONSTANTS", data: response.data.roles } as UpdateRolesAction);
        dispatch({ type: "UPDATE_CHECKPOINTS_CONSTANTS", data: response.data.checkpoints } as UpdateCheckpointsAction);
        dispatch({ type: "UPDATE_LESSON_CONTENT_TYPES_CONSTANTS", data: response.data.lessonContentTypes } as UpdateLessonContentTypesAction);
        dispatch({ type: "UPDATE_SCHOOL_YEARS_CONSTANTS", data: response.data.schoolYears } as UpdateSchoolYearsAction);
        dispatch({ type: "UPDATE_PRICING_TIERS_CONSTANTS", data: response.data.licenseCostsInCents} as UpdatePricingTiersAction);
        dispatch({ type: "UPDATE_DOCUMENTARIES_CONSTANTS", data: response.data.documentaries} as UpdateDocumentariesAction);
        dispatch({ type: "UPDATE_STATE_CONSTANTS", data: "finished" } as UpdateConstantStateAction);

        // Need to give redux a chance to process the constant value updates
        return new Promise<boolean>((res, rej) => {
            setTimeout(() => {
                res(true);
            }, 100);
        });
    }

    private static Load_OnFailure(dispatch: Dispatch<KnownActions>, response: AxiosError): boolean {
        dispatch({ type: "UPDATE_STATE_CONSTANTS", data: "failed" } as UpdateConstantStateAction);
        return false;
    }
}
