import axios from 'axios';
import { LicenseDTO } from '../models/license/licenseDTO';
import { CreateLicenseDTO } from '../models/license/createLicenseDTO';
import { UpdateLicenseDTO } from '../models/license/updateLicenseDTO';

export class LicenseController {
    constructor() {
        throw new Error("Nooo");
    }

    public static GetLicensesRoute = () => `licenses`;
    public static GetLicenses() {
        return axios.get<LicenseDTO[]>(this.GetLicensesRoute());
    }

    public static PutUpdateLicenseRoute = () => `licenses`;
    public static PutUpdateLicense(request: UpdateLicenseDTO) {
        return axios.put<LicenseDTO>(this.PutUpdateLicenseRoute(), request);
    }

    public static PostAdminCreateLicenseRoute = () => `licenses/admin`;
    public static PostAdminCreateLicense(request: CreateLicenseDTO) {
        return axios.post<LicenseDTO>(this.PostAdminCreateLicenseRoute(), request);
    }

    public static PutAdminUpdateLicenseRoute = () => `licenses/admin`;
    public static PutAdminUpdateLicense(request: UpdateLicenseDTO) {
        return axios.put<LicenseDTO>(this.PutAdminUpdateLicenseRoute(), request);
    }

    public static DeleteLicenseRoute = (licenseId: string) => `licenses/${licenseId}`;
    public static DeleteLicense(licenseId: string) {
        return axios.delete(this.DeleteLicenseRoute(licenseId));
    }

    public static GetLicenseRoute = (licenseId: string) => `licenses/${licenseId}`;
    public static GetLicense(licenseId: string) {
        return axios.get<LicenseDTO>(this.GetLicenseRoute(licenseId));
    }
}
