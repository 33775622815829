import * as React from 'react';
import { Link } from 'react-router-dom';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { PageProps } from '../../../src/models/common/ComponentProps';

interface PageNotFoundProps extends PageProps<{}> {
}

class PageNotFound extends React.Component<PageNotFoundProps> {
    constructor(props: PageNotFoundProps) {
        super(props);
        bindAllOfThis(this, PageNotFound.prototype);
    }

    render() {
        return <div style={{ backgroundColor: "white" }}>
            <h1>404</h1>
            <p>Woops! Nothing here.</p>
            <p>Go back <Link to={Routes.BASE_ROUTE().toRoute}>Home</Link></p>
        </div>;
    }
}

export default PageNotFound;
