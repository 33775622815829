import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import { LessonContentDTO } from '../../models/lessonContent/lessonContentDTO';
import { Roles } from '../../constants/Roles';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';
import IframeGoogleDocs from '../../utils/IframeGoogleDocs';

interface WorkflowDrillDownPageProps extends PageProps<{ courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

interface WorkflowDrillDownPageState {
    lesson: LessonDTO;
    lessonContent: LessonContentDTO;
    isLoading: boolean;
}

class WorkflowDrillDownPage extends React.Component<WorkflowDrillDownPageProps, WorkflowDrillDownPageState> {
    constructor(props: WorkflowDrillDownPageProps) {
        super(props);
        bindAllOfThis(this, WorkflowDrillDownPage.prototype);

        this.state = {
            lesson: null,
            lessonContent: null,
            isLoading: true,
        };
    }

    componentDidMount() {
        let lesson = this.props.Workflow.currentCourse.lessons.find(x => x.id === this.props.match.params.lessonId);
        let lessonContent = (lesson.lessonContents || []).find(x => x.lessonContentTypeId === LessonContentTypes.DrillDown.id);
        this.setState({
            lesson: lesson,
            lessonContent: lessonContent,
            isLoading: false
        });
        this.figureOutOnNext();
    }

    figureOutOnNext() {
        this.props.setButtonState("visible", "visible");
    }

    renderDebugInfo() {
        if (DebugUtil.isDebugEnabled() && this.state.lessonContent != null) {
            return <div>Content Url: {this.state.lessonContent.url}</div>;
        }
        return null;
    }

    renderPDF() {
        if (this.state.lessonContent == null) {
            return <h3>There was an error loading the lesson content</h3>;
        }
               
        let url = encodeURI(EncodeSpecialCharacters(this.state.lessonContent.url));

        return <IframeGoogleDocs srcUrl={url}></IframeGoogleDocs>;
       
    }

    renderTeacherHelperText() {
        if ([Roles.Teacher, Roles.Admin].includes(this.props.User.role)) {
            return <p className="teacher-helper-text">Educators may choose activities based on how their students learn the best. Educators may also use students’ answers from the Existing Knowledge to get a better understanding of what areas the educator would like students to work on more. Educators may choose to do as many Drill Down Activities as they would like.</p>;
        }
        return null;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            <h1>Drill Down Activities</h1>
            <p className="course-name">{this.props.Workflow.currentCourse.name}</p>
            {this.renderTeacherHelperText()}
            {this.renderDebugInfo()}
            {this.renderPDF()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDrillDownPage);
