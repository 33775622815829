import * as React from 'react';
import { Row, Col } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';

class DisplayColumnSpans extends React.Component<{}> {
    constructor(props: {}) {
        super(props);
        bindAllOfThis(this, DisplayColumnSpans.prototype);
    }

    render() {
        const leftStyle = {
            color: "cornflowerblue",
            // background: "linear-gradient(to right, red, orange, yellow, green, cyan, blue, violet)",
            // WebkitBackgroundClip: "text",
            // WebkitTextFillColor: "transparent"
        } as React.CSSProperties;
        const rightStyle = {
            color: "palevioletred",
        };
        return <div>
            <Row><Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}><h1 style={leftStyle}>Span: <span style={rightStyle}>XS</span></h1></Col></Row>
            <Row><Col xs={0} sm={24} md={0} lg={0} xl={0} xxl={0}><h1 style={leftStyle}>Span: <span style={rightStyle}>SM</span></h1></Col></Row>
            <Row><Col xs={0} sm={0} md={24} lg={0} xl={0} xxl={0}><h1 style={leftStyle}>Span: <span style={rightStyle}>MD</span></h1></Col></Row>
            <Row><Col xs={0} sm={0} md={0} lg={24} xl={0} xxl={0}><h1 style={leftStyle}>Span: <span style={rightStyle}>LG</span></h1></Col></Row>
            <Row><Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={0}><h1 style={leftStyle}>Span: <span style={rightStyle}>XL</span></h1></Col></Row>
            <Row><Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={24}><h1 style={leftStyle}>Span: <span style={rightStyle}>XXL</span></h1></Col></Row>
        </div>;
    }
}

export default DisplayColumnSpans;
