import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { Button } from 'antd';
import WorkflowAction from '../../redux/WorkflowAction';
import ReduxStoreModel from '../../redux/ReduxModel';
import { WorkflowState } from '../../redux/WorkflowReducer';

interface UserCoursePageProps extends RouteComponentProps<{}> {
    // From Redux
    Workflow: WorkflowState;
    // setCurrentScene: (scene: Scene) => void;
}

interface UserCoursePageState {
}

class UserCoursePage extends React.Component<UserCoursePageProps, UserCoursePageState> {
    constructor(props: UserCoursePageProps) {
        super(props);
        bindAllOfThis(this, UserCoursePage.prototype);

        this.state = {
        };
    }

    onClick() {
        // Set the redux object and change pages
        // this.props.history.push(Routes.getRoute(TeacherSceneManager.Home.route, 1));
    }

    onClickDemo(id: number) {
        // TODO: JB - HACKS! For demo...
        // this.props.history.push(Routes.getRoute(TeacherSceneManager.Home.route, id));
    }

    render() {
        return <div>
            <h1>User Course Home</h1>
            <p>Testing. Wanted to see how the text looks</p>
            <ol>
                <li>First</li>
                <li>Second</li>
                <li>Third</li>
            </ol>
            <div><Button type="primary" onClick={() => this.onClickDemo(242)}>Begin Course 1 Lesson 2</Button></div>
            <br />
            <div><Button type="primary" onClick={() => this.onClickDemo(564)}>Begin Course 2 Lesson 4</Button></div>
            <br />
            <div><Button type="primary" onClick={() => this.onClickDemo(791)}>Begin Course 3 Lesson 1</Button></div>
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        // setCurrentScene: (scene: Scene) => WorkflowAction.SetCurrentScene(dispatch, scene),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCoursePage);
