import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import bindAllOfThis from '../../../utils/BindThisHelper';
import { FieldDecorator } from '../../../components/formHelpers';
import { ResetPasswordDTO } from '../../../models/account/resetPasswordDTO';
import { NameOf } from '../../../utils/NameOf';

interface ResetPasswordFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: ResetPasswordDTO) => void;
}

interface ResetPasswordFormState {
    errorMessage: string;
    confirmDirty: boolean;
}

class ResetPasswordForm extends React.Component<ResetPasswordFormProps, ResetPasswordFormState> {
    constructor(props: ResetPasswordFormProps) {
        super(props);
        bindAllOfThis(this, ResetPasswordForm.prototype);

        this.state = {
            confirmDirty: false,
            errorMessage: null
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: ResetPasswordDTO): void => {
            this.setState({errorMessage: ""});
            let passwordsMatch = values.newPassword === values.confirmNewPassword;

            if (errors == null && !passwordsMatch){
                this.setState({errorMessage: "Passwords must match."});
            } else if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    /** Confirm Password Logic */
    handleConfirmBlur(e: any) {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    /** Confirm Password Logic */
    compareToFirstPassword(rule: any, value: any, callback: any) {
        const form = this.props.form;
        if (value && value !== form.getFieldValue(NameOf<ResetPasswordDTO>("newPassword"))) {
            callback('The passwords do not match!');
        } else {
            callback();
        }
    }

    /** Confirm Password Logic */
    validateToNextPassword(rule: any, value: any, callback: any) {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields([NameOf<ResetPasswordDTO>("confirmNewPassword")], { force: true });
        }
        callback();
    }

    render() {
        return <Form onSubmit={this.handleFormSubmit}>
            <FieldDecorator
                form={this.props.form}
                name={NameOf<ResetPasswordDTO>("newPassword")}
                rules={[{ required: true, message: 'Password is required.' }, { validator: this.validateToNextPassword }]}
            >
                <Input type="password" size="large" placeholder="Password" />
            </FieldDecorator>

            <FieldDecorator
                form={this.props.form}
                name={NameOf<ResetPasswordDTO>("confirmNewPassword")}
                // rules={[{ required: true, message: 'Please confirm your password.' }]}
                rules={[{ validator: this.compareToFirstPassword }]}
            >
                <Input type="password" size="large" placeholder="Confirm Password" />
            </FieldDecorator>

            {this.state.errorMessage != null ?
                <div style={{ color: "red", fontSize: "16px"}}>{this.state.errorMessage}</div> : null}

            <Row>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    Reset Password
                </Button>
            </Row>
        </Form>;
    }
}

export default Form.create<ResetPasswordFormProps>()(ResetPasswordForm);
