import * as React from 'react';
import { Link } from 'react-router-dom';
import equal from 'fast-deep-equal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Row, Col, Card, Tooltip, Modal } from 'antd';
import { PricingTiers, PricingTier } from '../constants/PricingTiers';
import { ConstantsDTO } from '../models/constant/constantsDTO';
import { KeyLabel } from '../models/common/KeyValueAndSimilar';
import Routes from '../core/Routes';
import { CouponDTO } from '../models/coupon/couponDTO';
import { ShoppingCartItemInfo } from './ShoppingCart';
import bindAllOfThis from '../utils/BindThisHelper';

interface ShoppingCartItemProps {
    initialValues: ShoppingCartItemInfo;
    constants: ConstantsDTO;
    coupon: CouponDTO;
    couponErrorMessage: string;
    onDelete: (shoppingCartItemId: string) => void;
}

interface ShoppingCartItemState {
    visible: boolean;
}

export function convertCentsToDollars(cents: number): string {
    return (cents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

class ShoppingCartItem extends React.Component<ShoppingCartItemProps, ShoppingCartItemState> {
    constructor(props: ShoppingCartItemProps) {
        super(props);
        bindAllOfThis(this, ShoppingCartItem.prototype);

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        this.renderCostCalculation();
    }

    componentDidUpdate(prevProps: any) {
        if(!equal(this.props.initialValues, prevProps.initialValues))
        {
          this.renderCostCalculation();
        }
    }

    renderCostCalculation(): JSX.Element {
        let perStudentLicenseCost = convertCentsToDollars(this.props.constants.studentLicenseCostInCents);

        if (this.props.initialValues.shoppingCartItems.licenseId != null) {
            // Updating Existing License
            let additionalLicenseCountCost = convertCentsToDollars(this.props.initialValues.paymentAmountInCents);
            let perYearPrice = (this.props.initialValues.paymentAmountInCents - (this.props.constants.studentLicenseCostInCents * this.props.initialValues.additionalLicenseCount) * 3) / 3;
            if (this.props.initialValues.licenseTier === PricingTiers.Four) {
                if (this.props.initialValues.shoppingCartItems.licenseDTO.licenseCount <= 15) {
                    additionalLicenseCountCost = this.props.initialValues.shoppingCartItems.isTripleYearLicense ?
                        convertCentsToDollars((this.props.constants.studentLicenseCostInCents * this.props.initialValues.additionalLicenseCount * 3)) :
                        convertCentsToDollars((this.props.constants.studentLicenseCostInCents * this.props.initialValues.additionalLicenseCount));
                    return this.props.initialValues.shoppingCartItems.isTripleYearLicense ?
                        <h5>
                            + {additionalLicenseCountCost} ({this.props.initialValues.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost x 3 Years)
                        + Tier Upgrade Cost: {convertCentsToDollars(perYearPrice)} x 3 Years
                </h5>
                        :
                        <h5>
                            + {additionalLicenseCountCost} ({this.props.initialValues.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost)
                        + Tier Upgrade Cost: {convertCentsToDollars(this.props.initialValues.paymentAmountInCents - (this.props.constants.studentLicenseCostInCents * this.props.initialValues.additionalLicenseCount))}
                        </h5>;
                }
                return this.props.initialValues.shoppingCartItems.isTripleYearLicense ?
                    <h5>
                        + {additionalLicenseCountCost} ({this.props.initialValues.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost x 3 Years)
                </h5>
                    :
                    <h5>
                        + {additionalLicenseCountCost} ({this.props.initialValues.additionalLicenseCount} Additional Students x {perStudentLicenseCost} Per Student Cost)
                </h5>;
            }
            else {
                return !this.props.initialValues.shoppingCartItems.isTripleYearLicense ? <h5>
                    Tier Upgrade Cost: {convertCentsToDollars(this.props.initialValues.paymentAmountInCents)}
                </h5> :
                <h5>
                    Tier Upgrade Cost: {convertCentsToDollars(perYearPrice)} x 3 Years
            </h5>;
            }

        } else {

            // New License
            let totalLicenseCostInCents = convertCentsToDollars(this.props.initialValues.totalLicenseCostInCents);
            let baseLicenseCost = convertCentsToDollars(this.props.initialValues.baseLicenseCostInCents);
            let studentCost = convertCentsToDollars(this.props.initialValues.licenseCountCostInCents);
            let discountCost = this.props.initialValues.discountInCents != null ? convertCentsToDollars(this.props.initialValues.discountInCents) : null;
            return this.props.initialValues.shoppingCartItems.isTripleYearLicense ?
                <h5>
                    +  {totalLicenseCostInCents} ({baseLicenseCost} Base License Cost x 3 Years) <br />
                    {this.props.initialValues.licenseTier === PricingTiers.Four && <div> + {studentCost} ({this.props.initialValues.additionalLicenseCount} Students x {perStudentLicenseCost} Per Student Cost x 3 Years) <br /> </div>}
                    {discountCost != null ? <div>- <strong>{discountCost} Discount</strong></div> : null}
                </h5>
                :
                <h5>
                    + {baseLicenseCost} Base License Cost <br />
                    {this.props.initialValues.licenseTier === PricingTiers.Four && <div> + {studentCost} ({this.props.initialValues.additionalLicenseCount} Students x {perStudentLicenseCost} Per Student Cost) <br /> </div>}
                    {discountCost != null ? <div>- <strong>{discountCost} Discount</strong></div> : null}
                </h5>;
        }
    }

    renderPricingTierLabel() {
        if (this.props.initialValues == null || this.props.initialValues.licenseTier == null) {
            return "0 students";
        }

        let { licenseTier } = this.props.initialValues;
        if (licenseTier === PricingTiers.One) {
            return "1-5 students";
        }
        else if (licenseTier === PricingTiers.Two) {
            return "6-10 students";
        }
        else if (licenseTier === PricingTiers.Three) {
            return "11-15 students";
        }
        else if (licenseTier === PricingTiers.Four) {
            return "16+ students";
        }
    }

    renderTotalSeats() {
        if (this.props.initialValues.licenseTier === PricingTiers.Four) {
            if (this.props.initialValues.shoppingCartItems.licenseCount <= 15) {
                return <h3>
                    Previous total students: {this.props.initialValues.shoppingCartItems.licenseCount}
                    <br />
                New total students: {15 + this.props.initialValues.additionalLicenseCount}
                </h3>;
            }
            return <h3>
                Previous total students: {this.props.initialValues.shoppingCartItems.licenseCount}
                <br />
                New total students: {this.props.initialValues.shoppingCartItems.licenseCount + this.props.initialValues.additionalLicenseCount}
            </h3>;
        }
    }

    handleCancel(e: any) {
        this.setState({ visible: false });
    }

    handleDelete(id: string) {
        if(this.state.visible) {
            this.props.onDelete(id);
        }
        else {
            this.setState({ visible: true });
        }
    }

    render() {
        return <Row>
            <Col xs={24} xl={16}>
                <Card style={{ marginTop: 16 }}>
                    <div>{this.props.initialValues.shoppingCartItems.courseDocumentaryName}</div>
                    <div>Description: {this.props.initialValues.shoppingCartItems.courseDocumentaryDescription}</div>
                    <Row gutter={16} style={{ marginTop: 10 }} justify="space-between">
                        <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                            <div>
                                {this.props.initialValues.shoppingCartItems.documentaryId == null &&
                                    <div>
                                        Number of Students: {this.renderPricingTierLabel()}
                                    </div>
                                }
                                <div>
                                    Start Date: {this.props.initialValues.shoppingCartItems.startDate}
                                </div>
                                {this.props.initialValues.shoppingCartItems.documentaryId == null &&
                                    <div>
                                        License Duration: {this.props.initialValues.shoppingCartItems.isTripleYearLicense ? "3 Years" : "1 Year"}
                                    </div>
                                }
                                {this.props.initialValues.licenseTier === PricingTiers.Four &&
                                    <div>Additional Students: {this.props.initialValues.additionalLicenseCount}</div>
                                }
                                <div style={{ marginTop: 10 }}>Total Students: {this.props.initialValues.shoppingCartItems.documentaryId == null ? this.props.initialValues.shoppingCartItems.licenseCount : "Unlimited"}</div>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={10} lg={10} xl={10}>
                            <div style={ { borderRight: "1px solid #000" } }>
                                {this.props.initialValues.shoppingCartItems.documentaryId == null &&
                                    <div>
                                        Number of Students: {this.renderPricingTierLabel()}
                                    </div>
                                }
                                {this.props.initialValues.licenseTier === PricingTiers.Four &&
                                    <div>Additional Students: {this.props.initialValues.additionalLicenseCount}</div>
                                }
                                <div>
                                    Start Date: {this.props.initialValues.shoppingCartItems.startDate}
                                </div>
                                {this.props.initialValues.shoppingCartItems.documentaryId == null &&
                                    <div>
                                        License Duration: {this.props.initialValues.shoppingCartItems.isTripleYearLicense ? "3 Years" : "1 Year"}
                                    </div>
                                }
                                <div style={{ marginTop: 10 }}>Total Students: {this.props.initialValues.shoppingCartItems.documentaryId == null ? this.props.initialValues.shoppingCartItems.licenseCount : "Unlimited"}</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={11} xl={11}>
                            {this.renderCostCalculation()}
                            Total: {convertCentsToDollars(this.props.initialValues.paymentAmountInCents)}
                        </Col>
                        <Col xs={24} md={0}>
                            <Row justify="start">
                                <Col>
                                    <Tooltip title="Edit" placement="top">
                                        <Link to={Routes.TEACHER_ADD_EDIT_MY_SCHOOL(this.props.initialValues.shoppingCartItems.licenseId != null ? this.props.initialValues.shoppingCartItems.licenseId : "null", this.props.initialValues.shoppingCartItems.id).toRoute}>
                                            <Button shape="circle" style={{ marginRight: 10, marginTop: 10 }} icon={<EditOutlined />} />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                        <Button shape="circle" style={{ marginTop: 10 }} icon={<DeleteOutlined />} onClick={() => this.handleDelete(this.props.initialValues.shoppingCartItems.id)}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={0} md={4} lg={3}>
                            <Row justify="end">
                                <Col>
                                    {this.props.initialValues.shoppingCartItems.documentaryId == null &&
                                        <Tooltip title="Edit" placement="top">
                                            <Link to={Routes.TEACHER_ADD_EDIT_MY_SCHOOL(this.props.initialValues.shoppingCartItems.licenseId != null ? this.props.initialValues.shoppingCartItems.licenseId : "null", this.props.initialValues.shoppingCartItems.id).toRoute}>
                                                <Button shape="circle" style={{ marginRight: 10, marginTop: 10 }} icon={<EditOutlined />} />
                                            </Link>
                                        </Tooltip>
                                    }
                                    <Tooltip title="Delete" placement="top">
                                        <Button shape="circle" style={{ marginTop: 10 }} icon={<DeleteOutlined />} onClick={() => this.handleDelete(this.props.initialValues.shoppingCartItems.id)}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>

            </Col>

            <Modal
                title="Delete Confirmation"
                visible={this.state.visible}
                closable={false}
                footer={[
                    <Button onClick={() => this.handleDelete(this.props.initialValues.shoppingCartItems.id)}>
                        Delete
                    </Button>,
                    <Button danger onClick={this.handleCancel}>
                        Cancel
                    </Button>
                ]}>
                <h3>Are you sure you want to remove item?</h3>
          </Modal>
        </Row>;
    }
}

export default ShoppingCartItem;
