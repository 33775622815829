import * as React from 'react';
import { connect } from 'react-redux';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import "./Workflow.scss";
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { Dispatch, Action } from 'redux';
import WorkflowAssessmentPage from './WorkflowAssessmentPage';

interface WorkflowStartAssessmentPageProps extends PageProps<{ classroomId?: string, courseId?: string, lessonId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    setButtonState: (next: ButtonTriState, back: ButtonTriState) => void;
}

class WorkflowStartAssessmentPage extends React.Component<WorkflowStartAssessmentPageProps> {
    constructor(props: WorkflowStartAssessmentPageProps) {
        super(props);
    }

    render() {
        return <WorkflowAssessmentPage {...this.props} isStartAssessment={true} />
    }
}
function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowStartAssessmentPage);
