import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { Spin } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import DebugUtil from '../../utils/DebugUtil';
import ReduxStoreModel from '../../redux/ReduxModel';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import WorkflowAction, { ButtonTriState } from '../../redux/WorkflowAction';
import { Roles } from '../../constants/Roles';
import { LessonDTO } from '../../models/lesson/lessonDTO';
import { LessonContentTypes } from '../../constants/LessonContentTypes';
import { LessonContentDTO } from '../../models/lessonContent/lessonContentDTO';
import { LessonContentController } from '../../api/LessonContentController';
import { EncodeSpecialCharacters } from '../../utils/EncodeSpecialCharacters';
import { DocumentaryDTO } from '../../models/documentary/documentaryDTO';
import { DocumentaryController } from '../../api/DocumentaryController';
import ConfigureURLs from '../../core/ConfigureUrls';
import { ConstantState } from '../../redux/ConstantsReducer';
import { RouteComponentProps } from 'react-router';

interface DocumentaryVideoPageProps extends RouteComponentProps<{ documentaryId: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
    Constants: ConstantState;
}

interface DocumentaryVideoPageState {
    documentary: DocumentaryDTO;
}

class DocumentaryVideoPage extends React.Component<DocumentaryVideoPageProps, DocumentaryVideoPageState> {
    constructor(props: DocumentaryVideoPageProps) {
        super(props);
        bindAllOfThis(this, DocumentaryVideoPage.prototype);

        this.state = {
            documentary: this.props.Constants.documentaries[0]
        };
    }

    renderDebugInfo() {
        if (DebugUtil.isDebugEnabled() && this.state.documentary != null) {
            return <div>Content Url: {this.state.documentary.videoUrl}</div>;
        }
        return null;
    }

    renderVideo() {
        let url = EncodeSpecialCharacters(this.state.documentary.videoUrl);

        return <ReactPlayer
            url={url}
            className="react-video-container"
            height={null} // WHY DOES THIS WORK
            width={null}
            controls={true}
            playing={false}
        />;
    }

    render() {
        return <div className="documentary-video">
            <h1>{this.state.documentary.name}</h1>
            <p>{this.state.documentary.description}</p>
            {this.renderDebugInfo()}
            {this.renderVideo()}
        </div>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        setButtonState: (next: ButtonTriState, back: ButtonTriState) => WorkflowAction.SetButtonState(dispatch, { next, back }),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
        Constants: state.Constants
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentaryVideoPage);
