import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Row, Col, Button, Layout } from 'antd';
import bindAllOfThis from '../../../src/utils/BindThisHelper';

import ReduxStoreModel from '../../redux/ReduxModel';
import Routes from '../../core/Routes';
import { UserState } from '../../redux/UserReducer';
import { WorkflowState } from '../../redux/WorkflowReducer';
import WorkflowAction from '../../redux/WorkflowAction';
import { Checkpoints, Checkpoint } from '../../constants/Checkpoints';
import { Link } from 'react-router-dom';
import { Roles } from '../../constants/Roles';
import { LocationDescriptorObject } from 'history';

const { Footer } = Layout;

interface DocumentaryFooterProps extends RouteComponentProps<{ classroomId?: string, courseId: string, lessonId: string }> {
    // From Redux
    Workflow: WorkflowState;
    User: UserState;
    UpdateCheckpoint: (classroomId: string, lessonId: string, checkpointId: string, discussionQuestionResponse?: string) => void;
    ResetButtonState: () => void;
    ResetOnNextCallback: () => void;
}

class DocumentaryFooter extends React.Component<DocumentaryFooterProps> {
    constructor(props: DocumentaryFooterProps) {
        super(props);
        bindAllOfThis(this, DocumentaryFooter.prototype);
    }

    handleOnNextClick(checkpoint: Checkpoint) {
        let route = this.getProperRoute(checkpoint);
        let callbackPromise = this.props.Workflow.onNextCallback != null
            ? this.props.Workflow.onNextCallback()
            : Promise.resolve("");

        if ([Roles.Student, Roles.Parent].includes(this.props.User.role)) {
            return callbackPromise.then(result => {
                if (result == null) {
                    return;
                }

                this.props.UpdateCheckpoint(this.props.match.params.classroomId, this.props.match.params.lessonId, checkpoint.id, result);
                this.props.ResetButtonState();
                this.props.ResetOnNextCallback();
                this.props.history.push(route);
            });
        }

        this.props.ResetButtonState();
        this.props.history.push(route);
    }

    handleOnBackClick(checkpoint: Checkpoint) {
        let route = this.getProperRoute(checkpoint);
        this.props.ResetButtonState();
        this.props.history.push(route);
    }

    /**
     * No idea what to call this.
     *
     * Returns a History LocationDescriptor based on current role and query params
     */
    getProperRoute(checkpoint: Checkpoint): LocationDescriptorObject {
        if (this.props.User.role === Roles.Student) {
            return {
                pathname: Routes.STUDENT_WORKFLOW(
                    this.props.match.params.classroomId,
                    this.props.match.params.courseId,
                    this.props.match.params.lessonId,
                    checkpoint.name.toLowerCase()).toRoute,
            };
        }
        if (this.props.User.role === Roles.Parent) {
            return {
                pathname: Routes.STUDENT_WORKFLOW(
                    this.props.match.params.classroomId,
                    this.props.match.params.courseId,
                    this.props.match.params.lessonId,
                    checkpoint.name.toLowerCase()).toRoute,
                search: "parent=true"
            };
        }

        return {
            pathname: Routes.WORKFLOW(
                this.props.match.params.courseId,
                this.props.match.params.lessonId,
                checkpoint.name.toLowerCase()).toRoute
        };
    }

    renderLoadingText() {
        if (this.props.Workflow.nextButtonState !== "loading") {
            return null;
        }

        return <span>Saving Questions...</span>;
    }

    renderNextButton(checkpoint: Checkpoint) {
        if (checkpoint != null) {
            if ([Roles.Student, Roles.Parent].includes(this.props.User.role)) {
                let isDisabled = this.props.Workflow.nextButtonState === "disabled";
                let isLoading = this.props.Workflow.nextButtonState === "loading";
                return <Button type="primary" disabled={isDisabled || isLoading} loading={isLoading} className="right-align" onClick={() => this.handleOnNextClick(checkpoint)}>Next</Button>;
            }

            return <Button type="primary" className="right-align" onClick={() => this.handleOnNextClick(checkpoint)}>Next</Button>;
        }
        return null;
    }

    renderBackButton(checkpoint: Checkpoint) {
        if (checkpoint != null) {
            if ([Roles.Student, Roles.Parent].includes(this.props.User.role)) {
                let isDisabled = this.props.Workflow.backButtonState === "disabled";
                let isLoading = this.props.Workflow.backButtonState === "loading";
                return <Button type="primary" disabled={isDisabled || isLoading} loading={isLoading} className="right-align" onClick={() => this.handleOnBackClick(checkpoint)}>Back</Button>;
            }

            return <Button type="primary" className="right-align" onClick={() => this.handleOnBackClick(checkpoint)}>Back</Button>;
        }
        return null;
    }

    render() {
        let path = this.props.match.path;
        let checkpointName = path.slice(path.lastIndexOf("/") + 1);

        if (path.indexOf(":courseId") < 1) {
            return null;
        }

        let previousCheckpoint: Checkpoint = null;
        let nextCheckpoint: Checkpoint = null;
        let roleCheckpoints = [...Checkpoints.GetCheckpointsByRole(this.props.User.role)].sort((a, b) => a.order - b.order);
        if (this.props.Workflow.isDemoMode) {
            roleCheckpoints = [...Checkpoints.Demo].sort((a, b) => a.order - b.order);
        }
        let currentIndex = roleCheckpoints.findIndex(x => x.name.toLowerCase() === checkpointName);
        if (currentIndex >= 0) {
            //skip the start self-assessment page on all but the first lesson
            //skip the end self-assessment page on all but the last lesson
            let skipAssessmentNext: boolean = false;
            let skipAssessmentPrev: boolean = false;
            if (this.props.Workflow.currentCourse) {
                let currentLessonIndex = this.props.Workflow.currentCourse.lessons.findIndex(x => x.id === this.props.match.params.lessonId);
                let lastLessonIndex = this.props.Workflow.currentCourse.lessons.length - 1;
                let startAssessmentIndex = roleCheckpoints.findIndex(x => x === Checkpoints.StartSelfAssessment);
                let endAssessmentIndex = roleCheckpoints.findIndex(x => x === Checkpoints.EndSelfAssessment);
                skipAssessmentNext = (currentLessonIndex !== 0 && startAssessmentIndex - 1 === currentIndex) ||
                    (currentLessonIndex !== lastLessonIndex && endAssessmentIndex - 1 === currentIndex);
                skipAssessmentPrev = (currentLessonIndex !== 0 && startAssessmentIndex + 1 === currentIndex) ||
                    (currentLessonIndex !== lastLessonIndex && endAssessmentIndex + 1 === currentIndex);
            }
            // Have to rely on indexes as the orders are not next to each other, since we can have checkpoints that are skipped for a given role
            let nextIndexIncrement = skipAssessmentNext ? 2 : 1;
            let prevIndexDecrement = skipAssessmentPrev ? 2 : 1;
            previousCheckpoint = currentIndex < 1 ? null : roleCheckpoints[currentIndex - prevIndexDecrement];
            nextCheckpoint = currentIndex > roleCheckpoints.length - 1 ? null : roleCheckpoints[currentIndex + nextIndexIncrement];
        }

        return <Footer className="footer-navigation">
            <Row justify="space-between" >
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    {this.renderBackButton(previousCheckpoint)}
                </Col>
                <Col>
                    {this.renderLoadingText()}
                    {this.renderNextButton(nextCheckpoint)}
                </Col>
            </Row>
        </Footer>;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        UpdateCheckpoint: (classroomId: string, lessonId: string, checkpointId: string, discussionQuestionResponse?: string) => WorkflowAction.UpdateUserCheckpoint(dispatch, classroomId, lessonId, checkpointId, discussionQuestionResponse),
        ResetButtonState: () => WorkflowAction.SetButtonState(dispatch, { next: "disabled", back: "disabled" }),
        ResetOnNextCallback: () => WorkflowAction.SetOnNextCallback(dispatch, null),
    };
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentaryFooter));
