import * as React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { PageProps } from '../../../src/models/common/ComponentProps';

interface ErrorPageLocationProps {
    errors: string;
}

interface ErrorPageProps extends PageProps<{}> {
}

class ErrorPage extends React.Component<ErrorPageProps> {
    // Using inline-css to avoid import errors and keep the error page self contained
    titleCss: React.CSSProperties = { display: "inline-block", fontWeight: "bold" };
    dataCss: React.CSSProperties = { display: "inline-block" };
    coolCss: React.CSSProperties = { paddingLeft: "2vw" };
    helperCss: React.CSSProperties = { fontSize: "x-large", padding: "20px 0" };

    constructor(props: ErrorPageProps) {
        super(props);
        bindAllOfThis(this, ErrorPage.prototype);
    }

    renderHelperMessage(error: Error) {
        var output: JSX.Element[] = [];

        if (error.message.includes("Check the render method of `Route`")) {
            output.push(<div>Hey, you might have messed up your routing! Check the RouteLoader</div>);
        }
        if (error.message.includes("Actions must be plain objects")) {
            output.push(<div>Check your dispatch calls from Redux. You should only use dispatch in binding calls</div>);
        }
        if (error.message.includes("Cannot read property 'errors'")) {
            output.push(<div>Easter Egg! Congratulations(?) on breaking the error page</div>);
        }

        if (output.length > 0) {
            return <div style={this.helperCss}>
                <div style={{ ...this.coolCss, ...this.titleCss }}>Helper Message</div>
                <div style={this.coolCss}>{output}</div>
            </div>;
        }
        return null;
    }

    render() {
        // get the object from text
        var error: Error = JSON.parse((this.props.location.state as ErrorPageLocationProps).errors);

        return <div style={{ backgroundColor: "white" }}>
            <div className="row">
                <h1>What have you done?!</h1>
            </div>
            <Row gutter={4}>
                <Col span={4}>
                    <Link className='navbar-brand' to={Routes.BASE_ROUTE().toRoute} title="Take me down to the very last city...">Get me out of here!</Link>
                </Col>
            </Row>
            <div className="row">
                <div style={this.titleCss}>Name:</div>
                <br />
                <div style={this.dataCss}>{error.name}</div>
            </div>
            {this.renderHelperMessage(error)}
            <div className="row">
                <div style={this.titleCss}>Message:</div>
                <br />
                <div style={this.dataCss}>{error.message}</div>
            </div>
            <div className="row">
                <div style={this.titleCss}>Stack:</div>
                <br />
                <pre style={this.dataCss}>{error.stack}</pre>
            </div>
        </div>;
    }
}

export default ErrorPage;
