import * as React from 'react';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { UnregisterCallback } from 'history';
import { NavLink, withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { EditOutlined, HomeOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../../src/core/Routes';
import { WorkflowState } from '../../redux/WorkflowReducer';
import ReduxStoreModel from '../../redux/ReduxModel';
import { CollapseType } from 'antd/lib/layout/Sider';
import { MenuInfo } from 'rc-menu/lib/interface';

import '../GeneralFooter.scss';
import DebugUtil from '../../utils/DebugUtil';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface AdminSideNavProps extends RouteComponentProps<{}> {
    // From Redux
    Workflow: WorkflowState;
}

interface AdminSideNavState {
    selectedKey: string[];
    openKey: string[];
    /** Set exclusively by the sider. DO NOT SET ANYWHERE ELSE */
    isSiderCollapsed: boolean;
}

class AdminSideNav extends React.Component<AdminSideNavProps, AdminSideNavState> {
    unlisten: UnregisterCallback;
    constructor(props: AdminSideNavProps) {
        super(props);
        bindAllOfThis(this, AdminSideNav.prototype);

        // Forces rerender when URL changes since React doesn't always run render
        this.unlisten = props.history.listen((x) => {
            this.forceUpdate();
        });

        this.state = {
            selectedKey: [],
            openKey: [],
            isSiderCollapsed: false,
        };
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        this.setOpenKey(this.props.match.path);
    }

    componentWillReceiveProps(nextProps: AdminSideNavProps) {
        if (this.props.match.url !== nextProps.match.url) {
            // Check open key
            this.setOpenKey(nextProps.match.path);
        }
    }

    onSiderCollapse(collapsed: boolean, type: CollapseType) {
        this.setState({ isSiderCollapsed: collapsed, openKey: collapsed ? [] : [this.props.match.url] });
    }

    menuOnClick(e: MenuInfo) {
        this.setOpenKey(e.key.toString());
    }

    setOpenKey(matchPath: string) {
        // Sorry not sorry
        let openKey = matchPath.split("/").filter(x => x).slice(0, 2).join("/");
        let openKeys = openKey.slice(-1) === "s" ? [`/${openKey}`] : [`/${openKey}s`];
        if (this.state.isSiderCollapsed) {
            // Always close the menu
            openKeys = [];
        }

        this.setState({
            selectedKey: [matchPath],
            openKey: openKeys
        });
    }

    subMenuOnClick(url: string) {
        this.props.history.push(url);
    }

    subMenuBuilder(row: { listUrl: string, editUrl: string, editUrlOriginal: string, title: string, titleRender?: React.ReactNode, listIcon: string }) {
        return (
            <SubMenu key={row.listUrl}
                onTitleClick={() => this.subMenuOnClick(row.listUrl)}
                title={[
                    <Tooltip key="title-icon" placement="topRight" title={row.title} mouseEnterDelay={0.25}><LegacyIcon type={row.listIcon} /></Tooltip>,
                    <span key="title-span">{row.titleRender || row.title}</span>
                ]}
            >
                <Menu.Item key={row.listUrl}>
                    <NavLink to={row.listUrl}>
                        <OrderedListOutlined /><span>List</span>
                    </NavLink>
                </Menu.Item>
                <Menu.Item key={row.editUrlOriginal}>
                    <NavLink to={row.editUrl}>
                        <EditOutlined /><span>Add/Edit</span>
                    </NavLink>
                </Menu.Item>
            </SubMenu>
        );
    }

    renderSubMenus() {
        // TODO: JB - Holy shit what was i thinking here?
        let subMenus: { listUrl: string, editUrl: string, editUrlOriginal: string, title: string, titleRender?: React.ReactNode, listIcon: string }[];
        subMenus = [{
            title: "Districts", listIcon: "build", listUrl: Routes.GET.ADMIN_DISTRICTS,
            editUrl: Routes.ADMIN_DISTRICT_ADD_EDIT("").toRoute, editUrlOriginal: Routes.GET.ADMIN_DISTRICT_ADD_EDIT
        }, {
            title: "Schools", listIcon: "bank", listUrl: Routes.GET.ADMIN_SCHOOLS,
            editUrl: Routes.ADMIN_SCHOOL_ADD_EDIT("").toRoute, editUrlOriginal: Routes.GET.ADMIN_SCHOOL_ADD_EDIT
        }, {
            title: "Courses", listIcon: "profile", listUrl: Routes.GET.ADMIN_COURSES,
            editUrl: Routes.ADMIN_COURSE_ADD_EDIT("").toRoute, editUrlOriginal: Routes.GET.ADMIN_COURSE_ADD_EDIT
        }, {
            title: "Licenses", listIcon: "idcard", listUrl: Routes.GET.ADMIN_LICENSES,
            editUrl: Routes.ADMIN_LICENSE_ADD_EDIT("").toRoute, editUrlOriginal: Routes.GET.ADMIN_LICENSE_ADD_EDIT
        }, {
            title: "Coupons", listIcon: "container", listUrl: Routes.GET.ADMIN_COUPONS,
            editUrl: Routes.ADMIN_COUPON_ADD_EDIT("").toRoute, editUrlOriginal: Routes.GET.ADMIN_COUPON_ADD_EDIT
        }];

        if (DebugUtil.isDebugEnabled()) {
            subMenus.push({
                title: "Users", listIcon: "team", listUrl: Routes.GET.ADMIN_USERS,
                titleRender: <span>Users (<b style={{color: "red"}}>Debug Only</b>)</span>,
                editUrl: Routes.ADMIN_USER_ADD_EDIT("").toRoute, editUrlOriginal: Routes.GET.ADMIN_USER_ADD_EDIT
            });
        }

        return subMenus.map(this.subMenuBuilder);
    }

    public render() {
        return (
            <Sider
                width="250"
                className="side-nav"
                breakpoint="md"
                collapsible
                onCollapse={this.onSiderCollapse}
            >
                <Menu
                    mode="inline"
                    // defaultSelectedKeys={this.state.selectedKey}
                    // defaultOpenKeys={this.state.openKey}
                    subMenuOpenDelay={0}
                    subMenuCloseDelay={0.2}
                    selectedKeys={this.state.selectedKey}
                    openKeys={this.state.openKey}
                    onClick={this.menuOnClick}
                >
                    <Menu.Item key={Routes.GET.ADMIN_DASHBOARD}>
                        <NavLink to={Routes.GET.ADMIN_DASHBOARD}>
                            <HomeOutlined /><span>Dashboard</span>
                        </NavLink>
                    </Menu.Item>
                    {this.renderSubMenus()}
                </Menu>
                {/* <Menu
                    mode="inline"
                    selectedKeys={this.state.selectedKey}
                    openKeys={this.state.openKey}
                    // defaultSelectedKeys={this.state.selectedKey}
                    // defaultOpenKeys={this.state.openKey}
                    subMenuOpenDelay={0}
                    subMenuCloseDelay={0.2}
                >
                    <Menu.Item key={Routes.GET.ADMIN_HOME}>
                        <NavLink to={Routes.GET.ADMIN_HOME}>
                            <Icon type="home" /><span>Home</span>
                        </NavLink>
                    </Menu.Item>
                    {this.renderSubMenus()}
                </Menu> */}
            </Sider>
        );
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow
    };
}

export default connect(mapStateToProps)(withRouter(AdminSideNav));
