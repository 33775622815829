import * as React from 'react';
import { connect } from 'react-redux';
import bindAllOfThis from '../../../src/utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import "./Workflow.scss";

interface WorkflowCourseHomePageProps extends PageProps<{ classroomId?: string, courseId?: string }> {
    // From Redux
    User: UserState;
    Workflow: WorkflowState;
}

interface WorkflowCourseHomePageState {
}

class WorkflowCourseHomePage extends React.Component<WorkflowCourseHomePageProps, WorkflowCourseHomePageState> {
    constructor(props: WorkflowCourseHomePageProps) {
        super(props);
        bindAllOfThis(this, WorkflowCourseHomePage.prototype);
    }

    handleButtonClick(lessonId: string) {
        // TODO: JB - This causes issues and would need a change to the sidenav layout engine, NO
        if (this.props.match.params.classroomId != null && this.props.match.params.classroomId.length > 0) {
            this.props.history.push(
                Routes.STUDENT_WORKFLOW_HOME(
                    this.props.match.params.classroomId,
                    this.props.match.params.courseId,
                    lessonId
                ).toRoute);
        } else {
            this.props.history.push(
                Routes.WORKFLOW_HOME(
                    this.props.match.params.courseId,
                    lessonId
                ).toRoute);
        }
    }

    render() {
        // let lessons = [...this.props.Workflow.currentCourse.lessons].sort((a, b) => a.ordering - b.ordering);
        // let lesson = lessons[0];
        return <div>
            <h1>{this.props.Workflow.currentCourse.name}</h1>
            <p>Welcome to your course! Please select your desired lesson from the blue toolbar on the left portion of your screen.</p>
            {/* <p>Or, you can select the first lesson here</p> */}
            {/* <Button type="primary" onClick={() => this.handleButtonClick(lesson.id)}>{lesson.name}</Button> */}
        </div>;
    }
}
function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
        Workflow: state.Workflow,
    };
}

export default connect(mapStateToProps)(WorkflowCourseHomePage);
