import * as React from 'react';
import { connect } from 'react-redux';
import { Spin, notification } from 'antd';
import bindAllOfThis from '../../utils/BindThisHelper';
import Routes from '../../core/Routes';
import { PageProps } from '../../models/common/ComponentProps';
import ReduxStoreModel from '../../redux/ReduxModel';
import { UserState } from '../../redux/UserReducer';

import { StudentDTO } from '../../models/student/studentDTO';
import { StudentController } from '../../api/StudentController';
import { CreateStudentDTO } from '../../models/student/createStudentDTO';
import { UpdateStudentDTO } from '../../models/student/updateStudentDTO';
import { AccountController } from '../../api/AccountController';
import ErrorDTO from '../../models/common/ErrorDTO';
import TeacherAddEditStudentForm from './TeacherAddEditStudentForm';

interface TeacherAddEditStudentProps extends PageProps<{ studentId?: string }> {
    // From Redux
    User: UserState;
}

interface TeacherAddEditStudentState {
    isSubmitting: boolean;
    /** Indicates that the page is in edit mode or in add mode */
    isEdit: boolean;
    /** The ID retrieved from the URL. UserId, SchoolId, etc. They are all IDs */
    idFromUrl?: string;
    /** Indicates that the page is loading data for the form */
    isLoading: boolean;
    /** The data for the form */
    formData: StudentDTO;
}

class TeacherAddEditStudent extends React.Component<TeacherAddEditStudentProps, TeacherAddEditStudentState> {
    private notificationKey = "TeacherAddEditStudent";
    constructor(props: TeacherAddEditStudentProps) {
        super(props);
        bindAllOfThis(this, TeacherAddEditStudent.prototype);

        let isEdit = props.match.params.studentId != null;

        this.state = {
            isSubmitting: false,
            isEdit: isEdit,
            idFromUrl: props.match.params.studentId,
            isLoading: true,
            formData: null,
        };
    }

    componentDidMount() {
        this.loadAll();
    }

    loadAll() {
        return Promise.all([this.loadFormData()]).then(x => {
            this.setState({ isLoading: false });
        });
    }

    loadFormData() {
        if (!this.state.isEdit) {
            return this.setState({
                formData: null
            });
        }

        return StudentController.GetStudent(this.state.idFromUrl).then(result => {
            this.setState({ formData: result.data });
        });
    }

    handleOnSubmit(data: StudentDTO) {
        notification.info({
            key: this.notificationKey,
            message: "Saving Student",
            description: "Please wait while we save the student...",
            duration: 0
        });

        this.setState({ isSubmitting: true });
        let request = this.state.isEdit ? this.handleOnSubmitUpdate(data) : this.handleOnSubmitAdd(data);
        return request.then(result => {
            notification.success({
                key: this.notificationKey,
                message: "Saved Student",
                description: "Successfully saved the student!",
                duration: 5,
                onClick: () => notification.close(this.notificationKey)
            });

            this.setState({ isSubmitting: false });
            this.props.history.push(Routes.TEACHER_STUDENTS().toRoute);
        }).catch(error => {
            let messages = error != null && error.response != null && error.response.data.messages != null
                ? (error.response.data as ErrorDTO).messages
                : ["Critical Error"];
            notification.error({
                key: this.notificationKey,
                message: "Failed to Save Student",
                description: messages.map(x => <div>{x}</div>),
                duration: 10
            });

            this.setState({ isSubmitting: false });
        });
    }

    handleOnSubmitAdd(data: StudentDTO) {
        let request: CreateStudentDTO = {
            email: data.email,
            firstName: data.firstName == null ? "" : data.firstName,
            lastName: data.lastName,
            guardianName: data.guardianName,
            guardianEmail: data.guardianEmail,
            schoolId: this.props.User.schoolId,
            schoolYearId: data.schoolYearId,
        };

        return StudentController.PostCreateStudent(request);
    }

    handleOnSubmitUpdate(data: StudentDTO) {
        let request: UpdateStudentDTO = {
            studentId: data.id,
            email: data.email,
            firstName: data.firstName == null ? "" : data.firstName,
            lastName: data.lastName,
            guardianName: data.guardianName,
            guardianEmail: data.guardianEmail,
            schoolId: this.props.User.schoolId,
            schoolYearId: data.schoolYearId,
        };

        return StudentController.PutUpdateStudent(request);
    }

    renderHeader() {
        if (this.state.isEdit) {
            return <h1>Edit Student</h1>;
        }
        return <h1>Add Student</h1>;
    }

    render() {
        if (this.state.isLoading) {
            return <Spin className="spinner-centered very-large-spinner" />;
        }

        return <div>
            {this.renderHeader()}
            <TeacherAddEditStudentForm
                isSubmitting={this.state.isSubmitting}
                onSubmit={this.handleOnSubmit}
                initialValues={this.state.formData}
            />
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherAddEditStudent);
