import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import bindAllOfThis from '../../utils/BindThisHelper';
import ReduxStoreModel from '../../redux/ReduxModel';
import { WorkflowState } from '../../redux/WorkflowReducer';
import { UserState } from '../../redux/UserReducer';
import Routes from '../../core/Routes';
import { Button, Col, Row, Table } from 'antd';
import { SchoolController } from '../../api/SchoolController';
import { ClassroomDTO } from '../../models/classroom/classroomDTO';
import './TeacherHome.scss';

interface TeacherHomePageProps extends RouteComponentProps<{}> {
    // From Redux
    Workflow: WorkflowState;
    User: UserState;
}

interface TeacherHomePageState {
    isLoading: boolean;
    schoolHasLicense: boolean;
    myClassrooms: ClassroomDTO[];
}

class TeacherHomePage extends React.Component<TeacherHomePageProps, TeacherHomePageState> {
    constructor(props: TeacherHomePageProps) {
        super(props);
        bindAllOfThis(this, TeacherHomePage.prototype);

        this.state = {
            isLoading: true,
            schoolHasLicense: false,
            myClassrooms: []
        };
    }

    async componentDidMount() {
        let licenses = await SchoolController.GetLicensesBySchool(this.props.User.schoolId);
        let classrooms = await SchoolController.GetClassroomsBySchool(this.props.User.schoolId);

        // Hacking this together because I am lazy
        var today = new Date();
        var endOfSchoolYear = new Date(today.getFullYear(), 6, 1); // Can edit to determine End of school years
        var yearToFilter = (endOfSchoolYear < today) // Today is after end of school year
            ? today.getFullYear()
            : (today.getFullYear() - 1);

        let myClassrooms = classrooms.data.filter(x => x.teacherId === this.props.User.id
            && x.schoolYear.startYear === yearToFilter);

        // Sort this way for now
        myClassrooms.sort((a, b) => a.name.localeCompare(b.name));

        this.setState({
            schoolHasLicense: licenses.data.length > 0,
            myClassrooms,
            isLoading: false
        });
    }

    renderNewTeacherContent() {
        if (this.props.User.schoolId == null || this.props.User.schoolId.length < 1) {
            // No school, aka new
            return <React.Fragment>
                <p>
                    <Link to={Routes.DEMO().toRoute}>
                        <Button type="primary">Free Trial Lessons</Button>
                    </Link>
                </p>
                <h2>New to Rock Digi?</h2>
                <p>First things first, you need to find your school! Click the link below to select a school</p>
                <Link to={Routes.TEACHER_SELECT_MY_SCHOOL().toRoute}>
                    <Button type="primary">Select My School</Button>
                </Link>
            </React.Fragment>;
        }
        return <React.Fragment>
            <div className='add-some-space'>
                {
                    !this.state.schoolHasLicense &&
                    <React.Fragment>
                        <h2>Free Trial Lesson</h2>
                        <p>Check out the Lessons we have available for free!</p>
                        <Link to={Routes.DEMO().toRoute}>
                            <Button type="primary" style={{ marginBottom: "14px" }}>Free Trial Lessons</Button>
                        </Link>
                    </React.Fragment>
                }
                <h2>Active Classes</h2>
                {this.state.myClassrooms.length === 0
                    ? <p>No classrooms!
                        <Link to={Routes.TEACHER_CLASSROOMS().toRoute}>
                            <Button type="link" style={{padding: '0 4px'}}>Want to see all of them?</Button>
                        </Link></p>
                    : null
                }
                {this.state.myClassrooms.length > 0
                    ? <Row>
                        <Col xs={24} md={16} lg={12}>
                            <Table
                                rowClassName={(_, index) => index % 2 === 0 ? '' : 'table-row-dark'}
                                rowKey={record => record.id}
                                size="middle"
                                columns={[{
                                    title: 'Classroom Name',
                                    key: 'name',
                                    dataIndex: 'name',
                                    // width: 200,
                                    sorter: false,
                                    showSorterTooltip: false
                                }, {
                                    title: '',
                                    key: 'buttons',
                                    // width: 200,
                                    sorter: false,
                                    showSorterTooltip: false,
                                    render: (text: any, record: ClassroomDTO) => (
                                        <Link to={Routes.TEACHER_MY_CLASSROOM(record.id).toRoute}>
                                            <Button type="primary" style={{ padding: '0 4px' }}>Go to Classroom</Button>
                                        </Link>)
                                }]}
                                dataSource={this.state.myClassrooms}
                                pagination={{
                                    hideOnSinglePage: true,
                                    pageSize: 1000
                                }}
                            />
                        </Col>
                    </Row>
                    : null}
            </div>

            <div className='add-some-space'>
                <h2>Active Courses</h2>
                <Link to={Routes.TEACHER_MY_SCHOOL().toRoute}>
                    <Button type="primary">My School&rsquo;s Courses</Button>
                </Link>
            </div>

            <div className='add-some-space'>
                <h2>Quick Links</h2>
                <Link to={Routes.TEACHER_ADD_EDIT_CLASSROOM().toRoute}>
                    <Button type="primary">Add Class</Button>
                </Link>
                <br />
                <Link to={Routes.TEACHER_ADD_EDIT_STUDENT().toRoute}>
                    <Button type="primary">Add Student</Button>
                </Link>
            </div>

            <div className='add-some-space'>
                <h2>Free Trial Lessons</h2>
                <p>Want to try before you buy? Check out the free trial lessons that we have available for each curriculum!</p>
                <Link to={Routes.DEMO().toRoute}>
                    <Button type="primary">Free Trial Lessons</Button>
                </Link>
            </div>

            <div className='add-some-space'>
                <h2>Shop</h2>
                <p>Check out our different curricula for grades 2-12!</p>
                <Link to={Routes.PRICING().toRoute}>
                    <Button type="primary">Shop</Button>
                </Link>
            </div>
        </React.Fragment>;
    }

    render() {
        return <div className='teacher-home'>
            <h1>Teacher Dashboard</h1>
            <p>Welcome to the Rock Digi experience! Rock Digi helps teach students Social Emotional Learning (SEL) and life skills for success so they are better equipped with the skills needed to achieve their goals.</p>
            {this.renderNewTeacherContent()}
        </div>;
    }
}

function mapStateToProps(state: ReduxStoreModel) {
    return {
        Workflow: state.Workflow,
        User: state.User,
    };
}

export default connect(mapStateToProps)(TeacherHomePage);
