import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import bindAllOfThis from '../../../utils/BindThisHelper';
import { FieldDecorator } from '../../../components/formHelpers';
import { LoginDTO } from '../../../models/account/loginDTO';
import { ForgotPasswordDTO } from '../../../models/account/forgotPasswordDTO';
import { NameOf } from '../../../utils/NameOf';

interface ForgotPasswordFormProps extends FormComponentProps {
    isSubmitting: boolean;
    onSubmit: (data: ForgotPasswordDTO) => void;
}

interface ForgotPasswordFormState {
}

class ForgotPasswordForm extends React.Component<ForgotPasswordFormProps, ForgotPasswordFormState> {
    constructor(props: ForgotPasswordFormProps) {
        super(props);
        bindAllOfThis(this, ForgotPasswordForm.prototype);

        this.state = {
        };
    }

    handleFormSubmit(e: React.FormEvent) {
        // Stop the button from reloading the page
        e.preventDefault();

        // Form needs to be validated
        this.props.form.validateFields((errors: any, values: LoginDTO): void => {
            if (errors == null) {
                this.props.onSubmit(values);
            }
        });
    }

    render() {
        return <Form onSubmit={this.handleFormSubmit}>
            <FieldDecorator
                form={this.props.form}
                name={NameOf<ForgotPasswordDTO>("email")}
                rules={[{ required: true, message: 'Email is required.' }]}
            >
                <Input size="large" placeholder="Email" />
            </FieldDecorator>

            <Row>
                <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={this.props.isSubmitting}>
                    Reset Password
                </Button>
            </Row>
        </Form>;
    }
}

export default Form.create<ForgotPasswordFormProps>()(ForgotPasswordForm);
